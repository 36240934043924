import { Coordinate } from "ol/coordinate";

/**
 * Deltatime is expected in fractional
 * @param param0 
 */
export function smoothDamp(
  { current, target, inVelocity, smoothTime, deltaTime, maxSpeed = Infinity }: 
  { current: number; target: number; inVelocity: number; smoothTime: number; deltaTime: number; maxSpeed?: number; }): { damped: number, velocityOut: number} {

    smoothTime = Math.max(0.0001, smoothTime);
    let omega = 2 / smoothTime;

    let x = omega * deltaTime;
    let exp = 1 / (1 + x + 0.48 * x * x + 0.235 * x * x * x);
    let change = current - target;
    let originalTo = target;

    let maxChange = maxSpeed * smoothTime;
    if (change > maxChange)
      change = maxChange;
    if (change < -maxChange)
      change = -maxChange;

    target = current - change;

    let currentVelocity = inVelocity;
    let temp = (inVelocity + omega * change) * deltaTime;
    currentVelocity = (currentVelocity - omega * temp) * exp;

    let output = target + (change + temp) * exp;

    if (originalTo - current > 0.0 == output > originalTo)
    {
      output = originalTo;
      currentVelocity = (output - originalTo) / deltaTime;
    }
    
    return {
      damped: output,
      velocityOut: currentVelocity
    }
};

export function smoothDampCoordinate(current: Coordinate, target: Coordinate, inVelocity: Coordinate, deltaTime: number, smoothTime: number): { damped: Coordinate, velocityOut: Coordinate} {
  const dampX = smoothDamp({
    current: current[0],
    target: target[0],
    inVelocity: inVelocity[0],
    deltaTime,
    smoothTime
  });
  const dampY = smoothDamp({
    current: current[1],
    target: target[1],
    inVelocity: inVelocity[1],
    deltaTime,
    smoothTime
  });
  return {
    damped: [dampX.damped, dampY.damped],
    velocityOut: [dampX.velocityOut, dampY.velocityOut]
  }
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from https://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
export function hslToRgb(h: number, s: number, l: number): number[] {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRgb(p, q, h + 1/3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1/3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

const componentToHex = (c: number): string => {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(rgb: number[]): string {
  return `#${componentToHex(rgb[0])}${componentToHex(rgb[1])}${componentToHex(rgb[2])}`;
}

function hueToRgb(p: number, q: number, t: number): number {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1/6) return p + (q - p) * 6 * t;
  if (t < 1/2) return q;
  if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
  return p;
}