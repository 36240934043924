import React, {useEffect, useRef} from "react";
import {useGlobalState} from "../Menu/GlobalState";
import {shallow} from "zustand/shallow";
import {notification} from "antd";
import {DatabaseGameData} from "../../types";
import {useMapState} from "../Map/MapDisplay";

export default function GameDataSync() {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  const editingGameId = useGlobalState((state) => state.editingGameId);
  const lastGameId = useRef<string>();
  const ignoreNextChange = useRef<boolean>(false);
  const [editingGameData, setEditingGameData] = useGlobalState((state) => [state.editingGameData, state.setEditingGameData], shallow);
  const [api, contextHolder] = notification.useNotification();
  const channels = useMapState((state) => state.channels);
  const editingMapId = useGlobalState((state) => state.editingMapId);

  const playersList = useMapState((state) => state.tablePlayersList);
  const amGM = useMapState((state) => state.amGM);
    
  // Pull the initial version when our game ID changes
  useEffect(() => {
    if (!supabase)
      return;
    
    if (editingGameId != lastGameId.current) {
      supabase.from('games')
        .select('*')
        .eq('game_id', editingGameId)
        .maybeSingle()
        .then((result) => {
          if (result.error) {
            console.error(result.error.message);
            api.error({
              message: 'Could not find the game data',
              description: result.error.message,
            });
          } else {
            setEditingGameData({
              ...result.data as any,
              isPlaceholder: false,
            } as DatabaseGameData);
            ignoreNextChange.current = true;
          }
        })
      lastGameId.current = editingGameId;
    }
  }, [supabase, editingGameId]);
  
  // Send updates up the wire
  useEffect(() => {
    if (!supabase || !session)
      return;
    
    if (!editingGameData)
      return;
    
    if (session.user.id != editingGameData.owner_id)
      return;
    
    if (ignoreNextChange.current) {
      ignoreNextChange.current = false;
      return;
    }
    
    const toSend = {...editingGameData};
    delete toSend['isPlaceholder'];
    delete toSend['gm_on_map_id'];
    
    supabase.from('games')
      .update(toSend)
      .eq('game_id', editingGameData.game_id)
      .then((res) => {
        if (res.error) {
          api.error({
            message: 'Error updating game data, try refreshing',
            description: res.error.message,
          });
          console.error(res.error.message)
        } else {
          channels['main']?.send({
            type: 'broadcast',
            event: 'update-gamedata',
            payload: JSON.stringify(editingGameData)
          });
        }
      });
  }, [session, supabase, editingGameData, channels]);
  
  // Update the map the GM is on
  useEffect(() => {
    if (!amGM)
      return;
    
    setEditingGameData((previous) => ({
      ...previous,
      gm_on_map_id: editingMapId
    }));
  }, [amGM, editingMapId]);
  
  // Update the players count
  useEffect(() => {
    if (!editingGameData)
      return;
    
    if (!amGM)
      return;
    
    const nonHidden = playersList.filter((p) => !p.hidden);
    if (nonHidden.length != editingGameData.players_count) {
      const newGameData = {
        ...editingGameData,
        players_count: nonHidden.length,
      };
      setEditingGameData(newGameData);
    }
  }, [amGM, playersList, editingGameData]);
  
  return (
    <>
      {contextHolder}
    </>
  )
}