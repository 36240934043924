import * as React from "react";

import styled from "styled-components";
import {useEffect} from "react";

import bgImage from '../../assets/DeanSpencer-Cover.png';
import magicGate from '../../assets/DeanSpencer-MagicGate.png';
import {FaPatreon} from "react-icons/fa6";

const HeaderImageDiv = styled.div`
    width: 100%;
    height: 800px;
    position: absolute;
    top: 0px;
    
    & img {
        object-fit: cover;
        object-position: 0px -300px;
        mask-image: linear-gradient(to top, transparent 33%, black 75%);
        width: 100%;
        height: 100%;
        max-height: 100%;
    }
`;

const FooterImageDiv = styled.div`
    width: 100%;
    height: 800px;
    position: absolute;
    bottom: 0px;
    
    & img {
        object-fit: cover;
        object-position: 0px -300px;
        mask-image: linear-gradient(to bottom, transparent 33%, black 75%);
        width: 100%;
        height: 100%;
        max-height: 100%;
    }
`;

const FooterContent = styled.div`
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0px;
    background: #0000004f;
    font-size: 1.2rem;
`;

const BodyContent = styled.div`
    width: 100%;
    max-width: min(1200px, calc(100vw - 20px));
    margin: 50px 133px;
    position: absolute;
    overflow-y: scroll;
    
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
`;

const NavOptions = styled.nav`
    width: 480px;
    max-width: calc(100vw - 40px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
    
    & svg {
        vertical-align: sub;
    }
    
    & a:first-child {
        flex: 1;
        text-align: center;
        height: 64px;
        margin-top: 32px;
        background: transparent;
        line-height: 64px;
        font-family: inherit;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
        border: 1px solid rgb(208, 205, 193);
        border-right: 1px solid transparent;
        border-radius: 10px 0 0 10px;
        padding-left: 12px;
        color: rgb(208, 205, 193);
    }

    & a:last-child {
        flex: 1;
        text-align: center;
        height: 64px;
        margin-top: 32px;
        background: transparent;
        line-height: 64px;
        font-family: inherit;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
        border: 1px solid rgb(208, 205, 193);
        border-left: 1px solid transparent;
        border-radius: 0 10px 10px 0;
        padding-right: 12px;
        color: rgb(208, 205, 193);
    }
    
    & a:hover {
        background-color: rgba(255, 251, 237, 0.14);
        cursor: pointer;
    }

    & img {
        width: 128px;
        height: 128px;
    }
`;

const MainHeader = styled.h1`
    font-weight: bold;
    text-shadow: 2px 2px 8px black;
    color: #f2eee1;
    margin: 160px auto 0;
    font-size: 5rem;
`;

const SubHeader = styled.h1`
    font-weight: bold;
    text-shadow: 2px 2px 8px black;
    color: #d9d5c8;
    margin: -14px auto 80px;
    font-size: 1.7rem;
`;

const Aside = styled.aside`
    max-width: 80vw;
    margin: 40px auto;
    padding: 0 1rem;
    font-size: 1rem;
    color: rgb(208, 205, 193);
    text-align: center;
`;

export default function NewMainMenu() {
  return (
    <div style={{ fontFamily: '"Alegreya", serif', fontOpticalSizing: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '2000px', width: '100%', position: 'relative' }}>
      <HeaderImageDiv>
        <img src={bgImage} />
      </HeaderImageDiv>
      <BodyContent>
        <MainHeader>MapsforTable.Top</MainHeader>
        <SubHeader>Modern Map Navigation for Persistent Digital Worlds</SubHeader>
        <NavOptions>
          <a href={'https://www.patreon.com/MapsforTabletop'} target={'_blank'} rel="noreferrer noopener">
            <FaPatreon /> PATREON
          </a>
          <img src={magicGate} />
          <a href={'https://demo.mapsfortable.top'} target={'_self'}>
            DEMO
          </a>
        </NavOptions>
        <Aside>
          MapsforTable.Top is an online mapping software. It supports any desktop platform with an internet connection, and mobile support is on the roadmap.<br />
          It was designed for D&D 5th edition, but can be used to map all sorts of fantasy worlds in an interactive, searchable, and always online way.<br />
        </Aside>
        <div style={{ width: '100px', height: '800px'}}>
          
        </div>
        <Aside>
          Page WIP
        </Aside>
      </BodyContent>
      <FooterImageDiv>
        <img src={bgImage} />
      </FooterImageDiv>
      <FooterContent>
        <h4>MapsforTable.Top</h4>
        <span id={'copyright'}>MapsforTable.Top {'\u{00A9}'} Copyright 2024, Maypoint Studios, LLC. All rights reserved.</span>
      </FooterContent>
    </div>
  )
}