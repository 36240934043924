import React, {useEffect, useRef, useState} from "react";
import { useGlobalState } from "../../Menu/GlobalState";
import { SupabaseClient, Session } from "@supabase/supabase-js";
import {AndroidOutlined, AppleOutlined, BorderInnerOutlined, SettingOutlined} from "@ant-design/icons";
import {
  Tooltip,
  FloatButton,
  Modal,
  Form,
  Switch,
  ColorPicker,
  InputNumber,
  Typography,
  Tabs,
  TabsProps,
  AutoComplete, Skeleton, Steps, Radio, Checkbox, Table
} from "antd";
import { shallow } from 'zustand/shallow';
import Title from "antd/es/typography/Title";
import {FaFacebook} from "react-icons/fa";
import {DatabaseMap, DefaultExtraJson} from "../../../types";
import { JsonEditor } from "json-edit-react";
import {DefaultFont} from "../../../util/config";
import {useLocalStorage} from "usehooks-ts";
import {useMapState} from "../MapDisplay";

interface Props {
  open: boolean,
  setOpen: (b: boolean) => void,
}

export default function MapSettings({open, setOpen}: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);

  const editingGameId = useGlobalState((state) => state.editingGameId);
  const [fontLoadStatus, setFontLoadStatus] = useGlobalState((state) => [state.fontLoadStatus, state.setFontLoadStatus], shallow);
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const [newMapColor, setNewMapColor] = useState(undefined);
  const [activeTab, setActiveTab] = useLocalStorage<string>('active-map-settings-key', undefined);
  const [allFontOptions, setAllFontOptions] = useState<{ value: string, id: string }[]>();
  const [editingGameData, setEditingGameData] = useGlobalState((state) => [state.editingGameData, state.setEditingGameData], shallow);
  const [poiFont, setPoiFont] = useState<string>();
  const tablePlayers = useMapState((state) => state.tablePlayersList);
  const [form] = Form.useForm();
  const lastOpenState = useRef<boolean>();
  const fontFamiliesHashSet = useRef<Set<string>>(new Set());
  
  useEffect(() => {
    if (open == lastOpenState.current)
      return;
    
    if (!editingMapData)
      return;

    lastOpenState.current = open;
    setPoiFont(editingMapData.font_setting && editingMapData.font_setting.length > 0 ? editingMapData.font_setting : DefaultFont);
  }, [open, editingMapData]);

  useEffect(() => {
    if (newMapColor === undefined)
      return;
    if (newMapColor == editingMapData.grid_line_color)
      return;

    const updateGridColor = setTimeout(() => {
      setEditingMapData({
        ...editingMapData,
        grid_line_color: newMapColor
      });
    }, 100);

    return () => clearTimeout(updateGridColor);
  }, [newMapColor, setEditingMapData, editingMapData]);

  // Load the fonts through the javascript font api
  useEffect(() => {
    if (!allFontOptions)
      return;
    
    if (!editingMapData)
      return;
    
    let anyChange = false;
    let thisCopy = {
      ...fontLoadStatus
    };
    
    const checkOrLoadFont = (family: string): boolean => {
      if (family in thisCopy)
        return false;
      
      fetch(`https://api.fontsource.org/v1/fonts/${allFontOptions.find((i) => i.value == family).id}`).then((response) => {
        response.json().then((result) => {
          const fontUrls: string[] = [];
          if (result.variants) {
            if ('400' in result.variants)
            {
              if ('normal' in result.variants['400'])
              {
                const url = result.variants['400'].normal.latin.url;
                Object.values(url).forEach((valueUrl: string) => {
                  fontUrls.push(valueUrl);
                });
              }
            }
          }
          
          if (fontUrls.length == 0)
          {
            console.error('No fonts fetched for ', family);
            return true;
          }
          
          const urlsString = fontUrls.map((url) => `url(${url}) format('${url.substring(url.lastIndexOf('.') + 1)}')`).join(', ');
          const myFont = new FontFace(family, urlsString);
          myFont.load().then(() => {
            document.fonts.add(myFont);
            setFontLoadStatus((previous) => ({
              ...previous,
              [myFont.family]: 'loaded',
            }));
          }); 
        });
      });
      
      thisCopy[family] = 'started';
      return true;
    }
    
    checkOrLoadFont(DefaultFont);
    
    if (editingMapData.font_setting && editingMapData.font_setting.length > 0)
      checkOrLoadFont(editingMapData.font_setting);
    
    if (anyChange)
      setFontLoadStatus((previous) => ({
        ...previous,
        ...thisCopy,
      }));
  }, [fontLoadStatus, allFontOptions, editingMapData]);
  
  // Fetch all the fonts we have at our disposal 
  useEffect(() => {
    fetch('https://api.fontsource.org/v1/fonts').then((res) => {
      res.json().then((result) => {
        setAllFontOptions(result.map((item: any) => ({value: item.family, id: item.id})));
        result.forEach((item: any) => fontFamiliesHashSet.current.add(item.family));
      });
    })
  }, []);
  
  // When we get a new POI change, check if it matches a real font family, and if so set it to the new one on the map
  useEffect(() => {
    if (fontFamiliesHashSet.current.has(poiFont))
      setEditingMapData((previous) => ({
        ...previous,
        font_setting: poiFont
      }));
  }, [poiFont]);
  
  let gridContent = editingMapData ? (
    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      autoComplete="off"
      form={form}
      initialValues={editingMapData}
    >
      <Form.Item
        label="Has Grid?"
        name="has_grid"
      >
        <Switch checked={editingMapData.has_grid} onChange={(checked, evt) => {
          setEditingMapData({
            ...editingMapData,
            has_grid: checked
          })
        }}/>
      </Form.Item>
      <Form.Item
        label="Grid Color"
        name="grid_line_color"
      >
        <ColorPicker defaultValue={editingMapData.grid_line_color ?? '#000000'} value={editingMapData.grid_line_color ?? '#000000'} onChange={(_, hex) => {
          setNewMapColor(hex)
        }}/>
      </Form.Item>
      <Form.Item
        label="Grid Spacing"
        name="grid_line_spacing"
      >
        <InputNumber min={1} max={300} keyboard={true} defaultValue={editingMapData.grid_line_spacing ?? 100} value={editingMapData.grid_line_spacing ?? 100} onChange={(value) => setEditingMapData({
          ...editingMapData,
          grid_line_spacing: value
        })}/>
      </Form.Item>
      <Form.Item
        label="Grid Line Width"
        name="grid_line_width"
      >
        <InputNumber min={1} max={100} keyboard={true} defaultValue={editingMapData.grid_line_width ?? 2} value={editingMapData.grid_line_width ?? 2} onChange={(value) => setEditingMapData({
          ...editingMapData,
          grid_line_width: value
        })}/>
      </Form.Item>
      <Form.Item
        label="Grid Padding"
        name="grid_padding"
      >
        <div style={{ display: 'flex' }}>
          <Tooltip title="Left Padding">
            <InputNumber min={0} max={500} keyboard={true} defaultValue={editingMapData.grid_padding_left ?? 0} value={editingMapData.grid_padding_left ?? 0} onChange={(value) => setEditingMapData({
              ...editingMapData,
              grid_padding_left: value
            })}/>
          </Tooltip>
          <Tooltip title="Top Padding">
            <InputNumber min={0} max={500} keyboard={true} defaultValue={editingMapData.grid_padding_top ?? 0} value={editingMapData.grid_padding_top ?? 0} onChange={(value) => setEditingMapData({
              ...editingMapData,
              grid_padding_top: value
            })}/>
          </Tooltip>
        </div>
      </Form.Item>
      <Form.Item
        label="Default Token Size"
        name="default_token_size"
      >
        <InputNumber min={1} max={20} keyboard={true} defaultValue={editingMapData.default_token_size ?? 1} value={editingMapData.default_token_size ?? 1} onChange={(value) => setEditingMapData({
          ...editingMapData,
          default_token_size: value
        })}/>
      </Form.Item>
      <Form.Item
        label="Drag Arrow Width"
        name="move_arrow_width"
      >
        <InputNumber min={1} max={50} keyboard={true} defaultValue={editingMapData.move_arrow_width ?? 10} value={editingMapData.move_arrow_width ?? 10} onChange={(value) => setEditingMapData({
          ...editingMapData,
          move_arrow_width: value
        })}/>
      </Form.Item>
    </Form>
  ) : (<></>);
  
  let movementContent = editingGameData ? (
    <>
      <Typography.Title level={5}>Diagonal Measurement</Typography.Title>
      <Radio.Group value={editingGameData?.diagonal_rule ?? '510'} defaultValue={editingGameData?.diagonal_rule ?? '510'} onChange={(e) => setEditingGameData((previous) => ({
        ...previous,
        diagonal_rule: e.target.value,
      }))}>
        <Radio.Button value={'510'}><Tooltip title={"The first diagonal is 5 ft, the second is 10, third is 5, etc..."}>5 ft 10 ft alternate</Tooltip></Radio.Button>
        <Radio.Button value={'5 Diagonal'}><Tooltip title={"Diagonal spaces are 5ft of movement just like horizontal or vertical ones"}>5ft always</Tooltip></Radio.Button>
      </Radio.Group>
      <div style={{ marginTop: '12px' }}>
        <Checkbox checked={editingGameData.request_movement_off_turn} onChange={(e) => setEditingGameData((previous) => ({
          ...previous,
          request_movement_off_turn: e.target.checked as boolean
        }))}>
          <Typography.Title level={5} style={{ margin: '0px' }}>Players can request movement when it's not their turn?</Typography.Title>
        </Checkbox>
      </div>
      <div style={{ marginTop: '12px' }}>
        <Checkbox checked={editingGameData.request_movement_not_your_tokens} onChange={(e) => setEditingGameData((previous) => ({
          ...previous,
          request_movement_not_your_tokens: e.target.checked as boolean
        }))}>
          <Typography.Title level={5} style={{ margin: '0px' }}>Players can request movement for tokens that aren't theirs?</Typography.Title>
        </Checkbox>
      </div>
    </>
  ) : (
    <Skeleton />
  );
  
  const parsedDrawingBans = JSON.parse(editingGameData?.specific_allow_drawing ?? '{}');
  
  let drawingContent = editingGameData ? (
    <>
      <div style={{marginTop: '12px'}}>
        <Checkbox checked={editingGameData.global_allow_drawing}
                  onChange={(e) => setEditingGameData((previous) => ({
                    ...previous,
                    global_allow_drawing: e.target.checked as boolean
                  }))}>
          <Typography.Title level={5} style={{margin: '0px'}}>Allow any players to draw?</Typography.Title>
        </Checkbox>
      </div>
      {
        editingGameData.global_allow_drawing ? (
          <div style={{marginTop: '24px'}}>
            <Typography.Title level={5} style={{margin: '0px'}}>Allow/disallow specific players?</Typography.Title>`
            <Table
              columns={[
                {
                  title: 'Can Draw?',
                  dataIndex: 'allowed',
                  key: 'allowed',
                  render: (_, record) => (
                    <Checkbox checked={record.allowed} onChange={(e) => {
                      parsedDrawingBans[record.user_id] = e.target.checked;
                      setEditingGameData((previous) => ({
                        ...previous,
                        specific_allow_drawing: JSON.stringify(parsedDrawingBans),
                      }))
                    }}/>
                  ),
                },
                {
                  title: 'Player',
                  dataIndex: 'player',
                  key: 'player'
                }
              ]}
              dataSource={tablePlayers.map((player, index) => ({
                key: index,
                player: `${player.character_name} (${player.player_name})`,
                allowed: !(player.user_id in parsedDrawingBans) || parsedDrawingBans[player.user_id],
                user_id: player.user_id,
              }))}
            >
            </Table>
          </div>
        ) : (<></>)
      }
    </>
  ) : (
    <Skeleton/>
  );

  let fontsContent = allFontOptions ? (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
      <Steps
        direction={"vertical"}
        current={1}
        items={[
          {
            title: 'Select Font',
            description: (
              <>
                {'Pick your font from'}
                <a href="https://fontsource.org">FontSource.org</a>
              </>
            )
          },
          {
            title: 'Select POI Font',
            description: (
              <>
                <AutoComplete
                  options={allFontOptions}
                  placeholder={"Type and search for a font..."}
                  filterOption={(inputValue, option) => option!.value.toLowerCase().includes(inputValue.toLowerCase())}
                  onSelect={(val) => setPoiFont(val)}
                  onSearch={(val) => setPoiFont(val)}
                  onChange={(val) => setPoiFont(val)}
                  value={poiFont}
                  style={{width: '100%'}}
                />
              </>
            )
          },
        ]}
      />
    </div>
  ) : (
    <Skeleton />
  );
  
  let tokenSettingsContent = editingGameData ? (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start'}}>
      <Typography.Title level={3}>Default Token Settings</Typography.Title>
      <Typography.Text>The following settings apply to every token by default.</Typography.Text>
      <Typography.Text>You can edit them on an individual token by clicking on its icon in the token sidebar.</Typography.Text>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 12,
        width: '100%',
      }}>
        <JsonEditor 
          data={editingGameData.default_token_settings ? JSON.parse(editingGameData.default_token_settings) : DefaultExtraJson}
          onUpdate={({newData}) => setEditingGameData({...editingGameData, default_token_settings: JSON.stringify(newData)})}
          restrictDelete={true}
          restrictAdd={true}
          rootName={""}
          theme={"githubDark"}
        />
      </div>
    </div>
  ) : (
    <Skeleton/>
  );

  return (
    <>
      <Modal
        centered
        open={open}
        onOk={() => {
          setOpen(false);
        }}
        onCancel={() => {
          setOpen(false);
        }}
      >
        {
          !editingMapData ? (<></>) :
            (
              <>
                <Title level={2}>Map Settings</Title>
                <Tabs
                  defaultActiveKey="1"
                activeKey={activeTab}
                onChange={setActiveTab}
                items={[
                  {
                    key: '1',
                    label: `Grid`,
                    children: <div style={{ background: '#252525', width: '100%', height: '400px', borderRadius: '20px', padding: '12px', overflowY: 'scroll' }}>
                      {gridContent}
                    </div>,
                  } as any,
                  {
                    key: '2',
                    label: `Fonts`,
                    children: <div style={{ background: '#252525', width: '100%', height: '400px', borderRadius: '20px', padding: '12px', overflowY: 'scroll' }}>
                      {fontsContent}
                    </div>,
                  } as any,
                  {
                    key: '3',
                    label: `Movement`,
                    children: <div style={{ background: '#252525', width: '100%', height: '400px', borderRadius: '20px', padding: '12px', overflowY: 'scroll' }}>
                      {movementContent}
                    </div>,
                  } as any,
                  {
                    key: '4',
                    label: `Tokens`,
                    children: <div style={{ background: '#252525', width: '100%', height: '400px', borderRadius: '20px', padding: '12px', overflowY: 'scroll' }}>
                      {tokenSettingsContent}
                    </div>,
                  } as any,
                  {
                    key: '5',
                    label: `Drawing`,
                    children: <div style={{ background: '#252525', width: '100%', height: '400px', borderRadius: '20px', padding: '12px', overflowY: 'scroll' }}>
                      {drawingContent}
                    </div>,
                  } as any,
                  // {
                  //   key: '2',
                  //   label: `Conditions`,
                  //   children: <div style={{ background: '#252525', width: '100%', height: '400px', borderRadius: '20px', padding: '12px' }}>
                  //     {conditionContent}
                  //   </div>,
                  // } as any,
                ]}
              />
            </>
          )
        }
      </Modal>
    </>
  );
}