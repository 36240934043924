import {TutorialTemplate} from "../NewTutorial";
import {MdDraw} from "react-icons/md";
import * as React from "react";

export default {
  title: 'Drawing Tutorial',
  description: 'Learn about the various drawing tools available in MapsforTable.top',
  icon: <MdDraw size={32} />,
  steps: [
    {
      
    }
  ]
} as TutorialTemplate;