import { BorderInnerOutlined, HeatMapOutlined, PlusOutlined } from "@ant-design/icons";
import { FloatButton, Image, theme } from "antd";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useMapState } from "../../MapDisplay";
import Typography from "antd/es/typography/Typography";
import DraggablePlayerImage from "./DraggablePlayerImage";
import {DatabaseGamePlayer} from "../../../../types";

interface Props {
  addNewTokenToMap: (tokeName: string, data: DatabaseGamePlayer) => void,
}

const LeftStack = styled.div`
  z-index: 90;
  height: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  
  & > div {
    display: -ms-flexbox;
    display: flex;
  }
`;

const TrayInset = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 8px;
  padding: 8px;
  place-content: flex-start center;
`;

const TrayContainer = styled.div`
  row-gap: 8px;

  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row-reverse nowrap;
  flex-flow: row-reverse nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
  transition: height 0.2s linear, top 0.2s linear, width ease-in-out 0.3s;
  z-index: 90;
  pointer-events: none;
  gap: 8px;
`;

const Column = styled.div<{}>`
  height: 100%;
  width: 48px;

  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
  transition: height 0.2s linear, top 0.2s linear, width ease-in-out 0.3s;
  gap: 8px;
`;

const PlayersSet = styled.div<{ background: string }>`
  width: 48px;
  flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  pointer-events: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  box-shadow: 0px 2px 10px rgba(5, 0, 56, 0.2);
  background-color: ${props => props.background};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 2px;
`;

const IndividualPlayer = styled.div<{ hoveredBackground: string, imageActive: boolean }>`
  padding: 0;
  border: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  cursor: pointer;
  transition: 0.3s, 0s box-shadow, 0s border-radius;
  border-radius: 4px;

  width: 44px;
  height: 44px;

  & img {
    max-width: 80%;
    max-height: 80%;
  }

  & span {
    width: 50%;
    height: 50%;
  }

  & span svg {
    width: 100%;
    height: 100%;
  }

  & img {
    filter: ${props => !props.imageActive ? 'grayscale(1)' : 'unset'};
  }

  & > svg {
    width: 50%;
    height: 50%;
  }

  &:hover {
    background-color: ${props => props.hoveredBackground};
  }

  &[active='1'] {
    background-color: ${props => props.hoveredBackground};
  }
`;

export default function PlayerSidebar({addNewTokenToMap}: Props) {
  const ownerId = useMapState((state) => state.ownerId);
  const playersList = useMapState((state) => state.tablePlayersList);
  const onlinePlayerIds = useMapState((state) => state.onlinePlayerIds);

  const displayImages = useMemo(() => {
    return playersList.filter((p) => !p.hidden).map((item, index) => {
      return (
        <IndividualPlayer hoveredBackground={'rgba(255, 255, 255, 0.063)'} imageActive={onlinePlayerIds.includes(item.user_id)} key={index}>
          <DraggablePlayerImage userId={item.user_id} droppedCallback={(dropped: string) => {
            addNewTokenToMap(dropped, item);
          }}/>
        </IndividualPlayer>
      )
    });
  }, [playersList, ownerId, onlinePlayerIds]);
  
  if (playersList.length == 0)
    return <></>;

  return (
    <LeftStack>
      <TrayInset>
        <TrayContainer>
          <Column>
            <PlayersSet background='rgb(23, 23, 23)'>
              {displayImages}
            </PlayersSet>
          </Column>
        </TrayContainer>
      </TrayInset>
    </LeftStack>
  )
};