import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../Menu/GlobalState";

export default function NewVersionDetector() {
  const session = useGlobalState((state) => state.session);
  const supabase = useGlobalState((state) => state.supabase);
  const [api, contextHolder] = notification.useNotification();
  const [lastBuildId, setLastBuildId] = useState<string | undefined>();

  useEffect(() => {
    if (!session || !supabase)
      return;

    const interval = setInterval(() => {
      supabase
        .from('app_state')
        .select('latest_build')
        .then((response) => {
          if (response.data.length > 0) {
            const {latest_build} = response.data[0];

            let updateDetected = false;
            setLastBuildId((last) => {
              if (last !== undefined && last !== latest_build)
                updateDetected = true;

              return latest_build;
            });

            if (updateDetected) {
              console.log('update detected');

              const timeout = setTimeout(() => {
                window.location.reload();
              }, 10000);

              api.info({
                message: 'New Version Released!',
                description: "You will automatically refresh in 10 seconds. Close this to cancel.",
                duration: 50,
                onClose: () => {
                  clearTimeout(timeout);
                }
              });
            }
          }
        });
    }, 10000);

    return () => {
      clearInterval(interval);
    }
  }, [session, supabase, setLastBuildId]);

  return (
    <>
      {contextHolder}
    </>
  );
}