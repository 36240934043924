import * as React from "react";
import { Avatar, Breadcrumb, Button, Dropdown, Input, List, Pagination, Popconfirm, Skeleton, Space, Tag, Tooltip, Typography } from "antd";
import {Coordinate} from "ol/coordinate";
const { Text, Link } = Typography;

export function filterTitle(baseTitle: string, highlightIndexes: number[] | undefined, processHighlighted = (text: string) => (<>{text}</>), processNormal = (text: string) => (<Text type="secondary">{text}</Text>)): string | React.ReactNode {
  if (highlightIndexes) {
    const children = [];

    let beginningIndex = 0;
    for (let i = 0; i < highlightIndexes.length; i += 1) {
      const thisIndex = highlightIndexes[i];

      let splitBefore = baseTitle.substring(beginningIndex, thisIndex);
      children.push(processNormal(splitBefore));

      beginningIndex += splitBefore.length;

      let toTake = 1;
      while (i != highlightIndexes.length - 1 && highlightIndexes[i + 1] == highlightIndexes[i] + 1){
        // Allow us to take multiple characters that are right next to each other
        toTake += 1;
        i += 1;
      }

      let splitAfter = baseTitle.substring(thisIndex, thisIndex + toTake);
      children.push(processHighlighted(splitAfter));

      beginningIndex += splitAfter.length;
    }
    if (beginningIndex != baseTitle.length) {
      children.push(processNormal(baseTitle.substring(beginningIndex)));
    }

    return <>{children}</>
  } else
    return baseTitle;
};

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function arraysEqual(a: any[], b: any[]): boolean {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const toMoveRangeKey = (coord: Coordinate) => `${coord[0]}x${coord[1]}`;

export function bufferToString(buf: Uint8Array | ArrayBuffer) {
  return String.fromCharCode(...new Uint8Array(buf));
  // return String.fromCharCode.apply(null, new Uint8Array(buf));
}

export function stringToUint8Array(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return bufView;
}
