import * as React from "react";
import {PropsWithChildren} from "react";
const D6 = (props: any) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    {...props}
  >
    <g id="XMLID_1_">
      <path fill="#FFFFFF" id="XMLID_35_" d="M760.6980591,352.8495789c-0.008667-0.0964661-0.0064087-0.1919861-0.019165-0.2881775
        c-0.0499878-0.3764954-0.1401978-0.7420654-0.2492676-1.1010437c-0.0369263-0.1211853-0.0790405-0.2399292-0.1228638-0.3599548
        c-0.1282349-0.3513794-0.2767334-0.6937866-0.4589233-1.0189819c-0.0147705-0.0263672-0.0213623-0.0547791-0.036499-0.0810547
        c-0.013855-0.0239868-0.0334473-0.0422974-0.0476074-0.06604c-0.1925659-0.3250122-0.4180908-0.6286011-0.6616821-0.9191284
        c-0.0790405-0.0942993-0.1578369-0.186615-0.2410278-0.2757874c-0.2600708-0.278717-0.5358276-0.5436707-0.842041-0.778656
        c-0.0621948-0.0476685-0.1300659-0.0843201-0.1937866-0.1297302c-0.1834717-0.1313171-0.3664551-0.2633972-0.564209-0.3776245
        l-253.782135-146.5211334c-2.1526184-1.2430267-4.8050842-1.2430267-6.9577026,0L242.7390289,347.4533997
        c-0.1977844,0.1142273-0.3807373,0.2463074-0.5642242,0.3776245c-0.0637054,0.0454102-0.1315613,0.0820618-0.1938019,0.1297302
        c-0.3061981,0.2349854-0.5819855,0.499939-0.8419952,0.778656c-0.0832062,0.0891724-0.1619873,0.181488-0.2410278,0.2757874
        c-0.2436371,0.2905273-0.4691467,0.5941162-0.6617279,0.9191284c-0.0141296,0.0237427-0.0337219,0.0420532-0.0475922,0.06604
        c-0.0151672,0.0262756-0.0217438,0.0546875-0.0364838,0.0810547c-0.1821747,0.3251953-0.3307343,0.6676025-0.4589233,1.0189819
        c-0.0438538,0.1200256-0.085968,0.2387695-0.1228943,0.3599548c-0.109024,0.3589783-0.1992493,0.7245483-0.2492676,1.1010437
        c-0.0127411,0.0961914-0.0104828,0.1917114-0.0191498,0.2881775c-0.0189819,0.2088928-0.0417786,0.4171448-0.0417786,0.629303
        v293.0422363c0,2.4860229,1.3262329,4.7825317,3.4788666,6.0255127L496.5211487,799.067749
        c0.2104187,0.121521,0.4290771,0.2211914,0.6481934,0.3189697c0.0534058,0.0239258,0.1025391,0.0545044,0.1567078,0.0771484
        c0.3678284,0.1531982,0.7469177,0.2635498,1.1305237,0.3508911c0.1020203,0.0231934,0.2041931,0.0420532,0.3078613,0.0606689
        C499.173584,799.9494019,499.5858154,800,500,800s0.826416-0.0505981,1.2355652-0.1245728
        c0.1036682-0.0186157,0.2058411-0.0374756,0.3078613-0.0606689c0.383606-0.0873413,0.7626953-0.1976929,1.1305237-0.3508911
        c0.0541687-0.022644,0.103302-0.0532227,0.1567078-0.0771484c0.2191162-0.0977783,0.4377747-0.1974487,0.6481934-0.3189697
        l253.782135-146.5211182c2.1525879-1.242981,3.4788208-3.5394897,3.4788208-6.0255127V353.4788818
        C760.7398071,353.2667236,760.717041,353.0584717,760.6980591,352.8495789z M500,214.9920349l239.8687134,138.4878235
        L500,491.9660339L260.1312561,353.4798584L500,214.9920349z M253.175705,365.532074l239.8665619,138.4849548v276.973877
        L253.175705,642.5043945V365.532074z M506.9577332,780.9909058v-276.973877L746.8242798,365.532074v276.9723206
        L506.9577332,780.9909058z"/>
    </g>
  </svg>
);
export default D6;
