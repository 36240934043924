import React, {useEffect, useRef, useState} from "react";
import {ColorPicker, Modal} from "antd";
import {useGlobalState} from "../Menu/GlobalState";
import {shallow} from "zustand/shallow";
import {DatabaseMap, MousePosition} from "../../types";
import {NormalDrawingColors, useMapState} from "../Map/MapDisplay";
import {useLocalStorage} from "usehooks-ts";
import styled from "styled-components";
import {Coordinate} from "ol/coordinate";

export interface TextPoiColorModalOptions {
  poi_id: string,
  mousePosition: Coordinate,
}

const ModalParent = styled.div<{mousePositionX: number, mousePositionY: number }>`
    top: ${props => props.mousePositionY}px;
    left: ${props => props.mousePositionX}px;
    position: absolute;
    
    & .ant-modal-close {
        display: none;
    }
`;

const ModalName = 'changetextpoicolor';

export default function ChangeTextPoiColorModal() {
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const [poiColorModalOptions, setPoiColorModalOptions] = useMapState((state) => [state.poiColorModalOptions, state.setPoiColorModalOptions], shallow);
  
  const setModalOpen = useGlobalState((state) => state.setModalOpen)
  const lastColorRef = useRef<string>();
  const [desiredColor, setDesiredColor] = useState<string>();
  const [previousPoiColors, setPreviousPoiColors] = useLocalStorage<string[]>('previous-poi-colors', []);
  
  // Actually update the POI
  useEffect(() => {
    if (!poiColorModalOptions)
      return;
    
    const timeout = setTimeout(() => {
      setEditingMapData((previous) => {
        const cloned = structuredClone(previous) as DatabaseMap;
        const poiIndex = cloned.pois.findIndex((poi) => poi.poi_id == poiColorModalOptions.poi_id);
        cloned.pois[poiIndex] = {
          ...cloned.pois[poiIndex],
          color_text_poi: desiredColor
        };
        return cloned;
      });
    }, 50);
    
    return () => clearTimeout(timeout);
  }, [poiColorModalOptions, desiredColor]);
  
  // Update last color when the modal is closed
  useEffect(() => {
    if (!poiColorModalOptions) {
      setModalOpen(ModalName, false);
      if (lastColorRef.current)
        setPreviousPoiColors((previous) => ([lastColorRef.current, ...previous]));
    } else {
      // We are opening the options
      setModalOpen(ModalName, true);
      setDesiredColor(editingMapData?.pois?.find((poi) => poi.poi_id == poiColorModalOptions.poi_id)?.color_text_poi ?? '#ffffff');
    }
  }, [poiColorModalOptions]);
  
  if (!editingMapData || !editingMapData.pois)
    return <></>;
  
  const closeModal = () => {
    setPoiColorModalOptions(undefined);
  }
  
  return (
    <Modal
      open={poiColorModalOptions !== undefined}
      onCancel={() => closeModal()}
      afterClose={() => closeModal()}
      okButtonProps={{style: ({display: 'none'})}}
      cancelButtonProps={{style: ({display: 'none'})}}
      closeIcon={<></>}
      wrapClassName={'colormodal'}
      modalRender={(base) => {
        if (!poiColorModalOptions)
          return <></>;
        
        return (
          <ModalParent mousePositionX={poiColorModalOptions?.mousePosition[0]} mousePositionY={poiColorModalOptions?.mousePosition[1]}>
            {base}
          </ModalParent>
        );
      }}
      mask={false}
    >
      <ColorPicker value={desiredColor} onChange={(_, hex) => {
        lastColorRef.current = hex;
        setDesiredColor(hex);
      }} presets={[
        {
          label: 'Recommended',
          colors: NormalDrawingColors
        },
        {
          label: 'Recent',
          colors: previousPoiColors,
        },
      ]} />
    </Modal>
  )
}