import {CommandFunctionParameters} from "../components/Map/Commands/CommandTemplate";
import {DatabaseMap} from "../types";
import React from "react";
import {GiPirateGrave} from "react-icons/gi";
import {Grab, Meditation, Blindfold, Charm, HearingDisabled, Terror, MedusaHead, Knockout, PoisonBottle} from "react-game-icons-auto";

export const toggleStatus = (props: CommandFunctionParameters, status: string) => {
  const myData = structuredClone(props.editingMapData) as DatabaseMap;
  const myIndex = props.editingMapData.pois.findIndex((i) => i.poi_id === props.mouseOverFeature.poi_id);
  if (!myData.pois[myIndex].status)
    myData.pois[myIndex].status = [];

  if (myData.pois[myIndex].status.includes(status))
    myData.pois[myIndex].status = myData.pois[myIndex].status.filter((i) => i !== status);
  else
    myData.pois[myIndex].status.push(status);

  props.logger.info('Changing token status', {
    poi_id: props.mouseOverFeature.poi_id,
    map_id: props.editingMapData.map_id,
    status: myData.pois[myIndex].status,
  });

  props.setEditingMapData(myData);
  props.setOpen(2);
}

export const BaseStatuses: {icon: any, status: string, color?: string}[] = [
  {
    icon: GiPirateGrave,
    status: 'downed'
  },
  {
    icon: Grab,
    status: 'grappled',
    color: '#610c04'
  },
  {
    icon: Meditation,
    status: 'concentration',
    color: '#ffd700'
  },
  {
    icon: Blindfold,
    status: 'blinded',
    color: '#613C62'
  },
  {
    icon: Charm,
    status: 'charmed',
    color: '#CB0E44'
  },
  {
    icon: HearingDisabled,
    status: 'deafened',
    color: '#5C6C4A'
  },
  {
    icon: Terror,
    status: 'frightened',
    color: '#41433E'
  },
  {
    icon: MedusaHead,
    status: 'petrified',
    color: '#212121'
  },
  {
    icon: PoisonBottle,
    status: 'poisoned',
    color: '#543760'
  },
  {
    icon: Knockout,
    status: 'stunned',
    color: '#348384'
  }
];