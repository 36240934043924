import * as React from "react";
import {PropsWithChildren} from "react";
const D8 = (props: any) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    {...props}
  >
    <g id="XMLID_1_">
      <path fill="#FFFFFF" id="XMLID_24_" d="M760.333374,648.8185425c0.0560303-0.1607056,0.1187744-0.3185425,0.1629639-0.4835815
		c0.0424805-0.1581421,0.0655518-0.319397,0.0964355-0.4794922c0.026001-0.1348877,0.0615234-0.2669067,0.0795898-0.4038696
		c0.0370483-0.2817383,0.0540771-0.5653687,0.0563354-0.848938c0.0001831-0.0179443,0.0036011-0.0352783,0.0036011-0.0532227
		V353.4506531c0-2.4659119-1.3155518-4.7437744-3.4506226-5.9767456L503.450592,200.9245148
		c-0.0130005-0.0074463-0.026886-0.0122986-0.0400696-0.0196686c-0.2492981-0.1418304-0.5065918-0.2698669-0.7721863-0.379776
		c-0.1099243-0.045517-0.2241821-0.0752563-0.3359985-0.1147766c-0.171814-0.0610352-0.3415527-0.1273499-0.5184021-0.1747589
		c-0.1352234-0.0361481-0.273407-0.0546112-0.4102173-0.0824432c-0.1577454-0.0321655-0.313446-0.0722198-0.4743347-0.0933685
		c-0.2236633-0.0293884-0.4487-0.0372772-0.6737366-0.0447235C500.1500549,200.0124817,500.0762024,200,499.9999084,200
		c-0.0759277,0-0.1497803,0.0124817-0.2255554,0.0149994c-0.2250366,0.0074463-0.4502563,0.0153351-0.6739197,0.0447235
		c-0.1605225,0.0211487-0.3162231,0.061203-0.4741516,0.0933685c-0.1368103,0.027832-0.2748108,0.0462952-0.4100342,0.0824432
		c-0.1770325,0.0474091-0.3467712,0.1137238-0.5187378,0.1747589c-0.1116638,0.0395203-0.2257385,0.0692596-0.3359985,0.1147766
		c-0.265625,0.1099091-0.5227356,0.2379456-0.7722168,0.379776c-0.0128174,0.00737-0.026886,0.0122223-0.0398865,0.0196686
		L242.718338,347.4739075c-2.1352692,1.2329712-3.4506683,3.5108337-3.4506683,5.9767456v293.0987854
		c0,0.3013916,0.0206299,0.6027222,0.0598145,0.9021606c0.0176849,0.133606,0.0523682,0.2623901,0.0776825,0.3939819
		c0.0313721,0.1636963,0.05513,0.3283691,0.098465,0.4899902c0.0433502,0.1619263,0.105072,0.3169556,0.1600342,0.4746704
		c0.0438538,0.1260376,0.0780182,0.2544556,0.1291656,0.378418c0.1126862,0.2715454,0.2427216,0.5349731,0.3887024,0.7896118
		c0.0039825,0.0070801,0.0065918,0.0146484,0.0107422,0.0217285c0.0024261,0.0045166,0.0059052,0.0083618,0.0083313,0.0127563
		c0.1487579,0.2563477,0.3139801,0.5032349,0.494812,0.7387695c0.0814819,0.1060791,0.1750946,0.199707,0.2623138,0.300415
		c0.1093903,0.126709,0.2130737,0.2579956,0.3318329,0.3768311c0.1170349,0.1169434,0.2463684,0.218811,0.3708496,0.3266602
		c0.1028137,0.0889282,0.1983337,0.1846313,0.3068695,0.2678833c0.2163696,0.1660156,0.4441833,0.3150635,0.6782379,0.4541626
		c0.0253143,0.0149536,0.0473328,0.0339355,0.0728149,0.0487061L496.549408,799.0755005
		C497.6170654,799.6915894,498.8086548,800,499.9999084,800c1.1914368,0,2.3830261-0.3084106,3.4506836-0.9244995
		l253.8310852-146.5493164c0.0341187-0.0197144,0.0634155-0.0447998,0.0970459-0.0650024
		c0.2249146-0.1350098,0.4452515-0.2775879,0.6538086-0.4376221c0.1123657-0.0862427,0.2115479-0.1853638,0.3178101-0.277771
		c0.1204834-0.1047974,0.2463379-0.2032471,0.3597412-0.3166504c0.1227417-0.1229248,0.2302246-0.2584839,0.3432617-0.3898315
		c0.0828857-0.0965576,0.1730347-0.1858521,0.2510986-0.2873535c0.1809692-0.2359009,0.3460083-0.4830933,0.4951172-0.7398071
		c0.0024414-0.0042114,0.0057373-0.0078735,0.0081787-0.012146c0.0039673-0.0070801,0.0067139-0.0146484,0.0107422-0.0217285
		c0.145813-0.2546387,0.276001-0.5180664,0.3884888-0.7896118
		C760.2572632,649.0674438,760.2904053,648.9418335,760.333374,648.8185425z M746.9298096,620.7935791l-228.0749512-395.037262
		l228.0749512,131.6785583V620.7935791z M741.8775024,639.6481934H258.1221313l241.8777771-418.944519L741.8775024,639.6481934z
		 M481.1451111,225.7563171L253.0701752,620.793457V357.4348755L481.1451111,225.7563171z M499.9999084,785.1295776
		L271.9242859,653.4506226h456.1514587L499.9999084,785.1295776z"/>
    </g>
  </svg>
);
export default D8;
