import GeoImage from "ol-ext/layer/GeoImage";
import { getIntersection, isEmpty } from "ol/extent";
import { fromUserExtent } from "ol/proj";
import ViewHint from "ol/ViewHint";
import ImageState from "ol/ImageState";
import ImageSource from "ol/source/Image";
import CanvasImageLayerRenderer from "ol/renderer/canvas/ImageLayer";

class GeoImageCanvasRenderer extends CanvasImageLayerRenderer {
  constructor(options: any) {
    super(options);
  }

  /**
   * Determine whether render should be called.
   * @param {import("../../Map.js").FrameState} frameState Frame state.
   * @return {boolean} Layer is ready to be rendered.
   */
  prepareFrame(frameState: { layerStatesArray: { [x: string]: any; }; layerIndex: string | number; pixelRatio: any; viewState: any; viewHints: any; extent: any; }) {
    const layerState = frameState.layerStatesArray[frameState.layerIndex];
    const pixelRatio = frameState.pixelRatio;
    const viewState = frameState.viewState;
    const viewResolution = viewState.resolution;

    const imageSource = this.getLayer().getSource();

    const hints = frameState.viewHints;

    let renderedExtent = frameState.extent;
    if (layerState.extent !== undefined) {
      renderedExtent = getIntersection(
        renderedExtent,
        fromUserExtent(layerState.extent, viewState.projection)
      );
    }

    // This is the mod. The normal ImageLayer will only draw if not animating and not moving. For our purposes, we want to be able to draw even when moving the map around.
    if (!isEmpty(renderedExtent)) {
      if (imageSource) {
        const projection = viewState.projection;
        const image = imageSource.getImage(
          renderedExtent,
          viewResolution,
          pixelRatio,
          projection
        );
        if (image) {
          if (this.loadImage(image)) {
            this.image_ = image;
          } else if (image.getState() === ImageState.EMPTY) {
            this.image_ = null;
          }
        }
      } else {
        this.image_ = null;
      }
    }

    return !!this.image_;
  }
}

export default class GeoImageLayerExt extends GeoImage {

  constructor(options: ImageSource) {
    super(options);
  }

  // Overrides our default renderer.
  createRenderer() {
    // @ts-ignore
    return new GeoImageCanvasRenderer(this);
  }
}