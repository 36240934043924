import React, { useEffect } from "react";
import { shallow } from "zustand/shallow";
import { useGlobalState } from "../Menu/GlobalState";
import { PatreonData } from "../../types";
import { notification } from "antd";

export default function PatreonHook() {
  const [patreon, setPatreon] = useGlobalState((state) => [state.patreon, state.setPatreonData], shallow);
  const session = useGlobalState((state) => state.session);
  const supabase = useGlobalState((state) => state.supabase);
  const logger = useGlobalState((state) => state.logger);

  const [api, contextHolder] = notification.useNotification();


  // Check if we have a patreon key being passed
  useEffect(() => {
    if (!session || !logger || !supabase)
      return;

    if (patreon)
      return;
      
    const patreonFormData = new FormData();
    const params = new URLSearchParams(window.location.search);

    // There may be some error with this.
    if (params.has('error')) {
      logger.error("Error Linking Patreon", {
        user_id: session.user.id
      });
      api.error({
        message: 'Error Linking Patreon',
        description: "Couldn't connect your patreon. Please try again!"
      })
      return;
    }

    if (params.has('state')) {
      const state = params.get('state');
      if (!state || state != session.user.id) {
        logger.error("Error Validating Patreon", {
          user_id: session.user.id
        });
        api.error({
          message: 'Error Validating Patreon',
          description: "Couldn't connect your patreon. Please try again!"
        })
        return;
      }
    }

    if (params.has('code')) {
      patreonFormData.append('code', params.get('code'));
    }


    patreonFormData.append('redirect', 'http://www.localhost:8080');
    supabase.functions.invoke('patreon', {
      body: patreonFormData
      // @ts-ignore
    }).then((response) => {
      if (response.error) {
        api.error({
          message: 'Error linking patreon',
          description: response.error,
        });
        setPatreon({
          patron_status: null
        });
      } else if (response.data && response.data.patreon) {
        setPatreon(response.data.patreon as PatreonData);
        logger.info('Linked patreon', {
          user: session.user.id,
          patreon: response.data.patreon
        });
      }
      else {
        setPatreon({
          patron_status: null
        });
      }
    });
  }, [session, supabase, logger, patreon]);

  return (
    <>
      {contextHolder}
    </>
  );
}