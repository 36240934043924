import {
  Circle as CircleStyle,
  Fill,
  Image,
  RegularShape,
  Stroke,
  Style,
  Text,
} from 'ol/style.js';
import { TextJustify } from 'ol/style/Text';

// export const createLabelStyle = (props: MapLabelOptions): Style => {
//   let imageStyle: Image = undefined;

//   if (props.useCircleClickbox)
//     imageStyle = new CircleStyle({
//       radius: props.clickboxRadius ?? 50,
//       fill: new Fill({ color: `rgba(255, 0, 0, ${props.debug !== undefined ? '1' : '0'})` }),
//     });
//   else
//     imageStyle = new RegularShape({
//       points: 4,
//       radius: props.clickboxRadius ?? 50,
//       angle: props.squareClickboxAngle,
//       fill: new Fill({ color: `rgba(255, 0, 0, ${props.debug !== undefined ? '1' : '0'})` }),
//     });

//   return new Style({
//     image: imageStyle,
//     text: new Text({
//       font: props.font ?? '16px sans-serif',
//       textAlign: props.textAlign ?? 'center',
//       justify: props.justify,
//       text: props.text,
//       fill: new Fill({
//         color: props.fillColor?.splice(0, 3).concat(
//             props.textOpacity 
//             ? props.textOpacity 
//             : (props.fillColor?.length > 3 ? props.fillColor[3] : 1)
//           ) ?? [255, 255, 255, props.textOpacity ?? 1],
//       }),
//       padding: props.textPadding?.splice(0, 4) ?? [2, 2, 2, 2],
//       stroke: new Stroke({ color: props.textColor?.splice(0, 3).concat(
//         props.textOpacity 
//         ? props.textOpacity 
//         : (props.textColor?.length > 3 ? props.textColor[3] : 1)
//       ) ?? [0, 0, 0, props.textOpacity ?? 1], width: 4 }),
//     }),
//   });
// };

export const measureLabelDimensions = (text: string, fontSize: string, font: string = 'Serif'): number[] => {
  var size = fontSize;
  var bold = false;

  var div = document.createElement("div");
    div.innerHTML = text;
    div.style.position = 'absolute';
    div.style.top  = '-9999px';
    div.style.left = '-9999px';
    div.style.fontFamily = font;
    div.style.fontWeight = bold ? 'bold' : 'normal';
    div.style.fontSize = size + 'pt'; // or 'px'
  document.body.appendChild(div);
  var sz = [ div.offsetWidth, div.offsetHeight ];
  document.body.removeChild(div);
  return sz;
}