import React, {useEffect, useRef, useState} from "react";
import {message, Modal, notification} from "antd";
import {useLocalStorage} from "usehooks-ts";
import {useGlobalState} from "../Menu/GlobalState";
import {useMapState} from "../Map/MapDisplay";
import {shallow} from "zustand/shallow";

export const DontPesterAdBlock = 'dontpesteradblock';

export default function AdBlockChecker() {
  const imageRefAd = useRef<HTMLImageElement>();
  const imageWhitelistTwo = useRef<HTMLImageElement>();
  
  const [openModals, setModalOpen] = useGlobalState((state) => [state.openModals, state.setModalOpen], shallow);
  const [error, setError] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  
  useEffect(() => {
    if (window.localStorage.getItem(DontPesterAdBlock))
      return;
    
    imageRefAd.current.src = `https://widgets.outbrain.com/images/widgetIcons/ob_logo_16x16.png?advertiser=1&${new Date()}`;
    imageWhitelistTwo.current.src = `https://gstatic.com/webp/gallery3/1.png?ads=1&${new Date()}`;
    
    // imageRefAd.current.addEventListener('load', () => {
    //   console.log('loadedA');
    // });
    imageRefAd.current.addEventListener('error', (e) => {
      setError(true);
    });
    // imageWhitelistTwo.current.addEventListener('load', () => {
    //   console.log('loadedB');
    // });
    imageWhitelistTwo.current.addEventListener('error', (e) => {
      setError(true);
    });
  }, []);
  
  useEffect(() => {
    if (!error)
      return;
    
    if (window.localStorage.getItem(DontPesterAdBlock))
      return;
    
    api.warning({
      message: 'Ad-Block Detected',
      duration: 600,
      key: 123,
      description: (
        <div style={{ cursor: 'pointer' }}>
          There are no ads and there will never be ads on MapsforTable.top. 
          <br />
          <br />
          Unfortunately, your ad blocker will also block our attempts to collect analytics and error data.
          <br />
          <br />
          Please consider disabling it on this site. <b>Click here for more info</b> or close this message and we won't pester you about it.
        </div>
      ),
      onClick: () => {
        setModalOpen(DontPesterAdBlock, true);
        window.localStorage.setItem(DontPesterAdBlock, '1');
        message.destroy(123);
      },
      onClose: () => {
        window.localStorage.setItem(DontPesterAdBlock, '1');
      }
    })
  }, [error]);
  
  return (
    <>
      {contextHolder}
      <Modal
        centered
        open={openModals[DontPesterAdBlock]}
        onCancel={() => setModalOpen(DontPesterAdBlock, false)}
        cancelButtonProps={{ style: { display: 'none'} }}
        onOk={() => setModalOpen(DontPesterAdBlock, false)}
      >
        <h3>Ad Blockers and MapsforTable.Top</h3>
        <p>Look, I love ad blockers. Ads are the worst thing to come to the internet, and we'll never have ads on MapsforTable.top.</p>
        <p>That said, some (like UBlock Origin, for example) detect our logging solution as an "ad" and block it.</p>
        <p>Because MapsforTable.top is a project by one developer, I use analytics and logging to get notifications and context data when one of you runs into an error or problem with their game. If you have your ad blocker on, please consider turning it off for this site so that I can get those reports.</p>
        <p>Regardless of your choice, this popup and notification will never be shown again. Thanks for using the site!</p>
      </Modal>
      <img id="test-ad" ref={imageRefAd} src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
           style={{ visibility: 'hidden', width: '1px', height: '1px', position: 'absolute', top: '-20px'}} alt="test-ad"/>
      <img id="test-whitelist" ref={imageWhitelistTwo} src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
           style={{ visibility: 'hidden', width: '1px', height: '1px', position: 'absolute', top: '-20px'}} alt="test-whitelist"/>
    </>
  );
}