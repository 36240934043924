import * as React from "react";
import {PropsWithChildren} from "react";
const D20 = (props: any) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    {...props}
  >
    <g id="XMLID_1_">
      <path fill="#FFFFFF" id="XMLID_29_" d="M771.555603,338.8777771c-0.0079956-0.0405884,0-0.0812073-0.0079956-0.1299438
		c0-0.0244751,0-0.0569153-0.0083008-0.089386v-0.0487366c-0.0083008-0.1056824-0.0162964-0.2113647-0.0325928-0.316925
		c-0.0079956-0.065094-0.0159912-0.1300659-0.024292-0.1950378c-0.0079956-0.0487671-0.0159912-0.089447-0.024292-0.1382141
		c-0.0163574-0.0812073-0.0326538-0.1543579-0.048584-0.2356262c-0.0166626-0.089386-0.0409546-0.1787415-0.0569458-0.2680969
		c-0.0162964-0.0487671-0.0246582-0.089447-0.0409546-0.1381226c-0.0159302-0.0569153-0.0322876-0.1056824-0.048584-0.1625977
		c-0.0325928-0.113739-0.0732422-0.227478-0.1138306-0.3331299c0,0,0,0,0-0.0080872
		c-0.0162964-0.0406799-0.024292-0.0812683-0.0405884-0.1218872c-0.0079956-0.0325317-0.024292-0.0649719-0.0405884-0.0975037
		c-0.0406494-0.1056824-0.0895386-0.2113647-0.1381226-0.3169556c-0.0326538-0.0568237-0.0569458-0.1218872-0.0895386-0.1787109
		c0-0.0162964-0.0079956-0.0244751-0.0079956-0.0325317c-0.0079956-0.0081482-0.0162964-0.0243835-0.024292-0.0406799
		c-0.0489502-0.089386-0.0975342-0.1705933-0.1464233-0.2518616c-0.048584-0.0812073-0.0892334-0.1625061-0.1381226-0.2438049
		c-0.0569458-0.0812073-0.1135254-0.1625061-0.1704102-0.2437134c-0.0569458-0.0812683-0.1138306-0.1625671-0.1707764-0.2437744
		c-0.0325928-0.0325317-0.0568848-0.0650024-0.0811768-0.1056824c-0.0409546-0.0405884-0.0811768-0.0893555-0.1221313-0.1300354
		c-0.0569458-0.0730591-0.1138306-0.1462097-0.1784058-0.2193298c-0.0409546-0.0406189-0.0895386-0.089447-0.130127-0.1300659
		l-0.1381226-0.1381226c-0.0489502-0.0487366-0.0975342-0.0975037-0.1544189-0.1462708
		c-0.0652466-0.0568237-0.130127-0.113739-0.1950684-0.1706543c-0.0488892-0.0405884-0.0974731-0.0812073-0.1544189-0.1217957
		c-0.0325928-0.0325317-0.0648804-0.0569153-0.0975342-0.089447c-0.0974731-0.0731506-0.2033081-0.1381226-0.3088379-0.2112732
		c-0.0405884-0.0243835-0.0811768-0.0568237-0.1297607-0.0893555c-0.1950684-0.1218872-0.3984375-0.2356262-0.6014404-0.3413086
		c-0.0083008-0.0081482-0.0083008-0.0081482-0.0162964-0.0081482L502.9820862,200.6906891
		c-0.0406189-0.0162201-0.0812073-0.0325317-0.1218262-0.0487671c-0.130127-0.0650635-0.2682495-0.1138153-0.4063721-0.1706543
		c-0.1218262-0.0487518-0.2519531-0.1055756-0.3817444-0.1462708c-0.1381226-0.0487518-0.2762451-0.0812073-0.4147034-0.113739
		c-0.1381226-0.0325317-0.2679138-0.0731354-0.4063721-0.0975189c-0.1381226-0.0243835-0.2762451-0.0406952-0.4143677-0.0568237
		c-0.1381226-0.0244751-0.2762451-0.0406952-0.4223328-0.0487671C500.2762451,200,500.1460876,200,500.0079956,200
		c-0.1461182,0-0.2845764,0-0.4306946,0.0081482c-0.1381226,0.0080719-0.2682495,0.024292-0.4063721,0.0406036
		c-0.1460876,0.024292-0.2842102,0.0406036-0.4223328,0.0649872s-0.2682495,0.0649872-0.4063721,0.0975189
		s-0.2762451,0.0649872-0.4143677,0.113739c-0.130127,0.0406952-0.2599182,0.0975189-0.3817444,0.1462708
		c-0.1381226,0.056839-0.2762451,0.1055908-0.4063721,0.1706543c-0.0406189,0.0162354-0.081543,0.032547-0.1218262,0.0487671
		L232.1253815,333.1409607c-0.0083313,0-0.0083313,0-0.0163116,0.0081482
		c-0.2113495,0.1056824-0.4063721,0.2194214-0.6014099,0.3413086c-0.048584,0.0325317-0.0895386,0.0649719-0.1301422,0.0893555
		c-0.1054993,0.0731506-0.2113342,0.1381226-0.3085175,0.2112732c-0.032608,0.0325317-0.0652313,0.0569153-0.0975037,0.089447
		c-0.0569153,0.0405884-0.1058502,0.0812073-0.1544342,0.1217957c-0.0652466,0.0569153-0.1381226,0.1138306-0.1950378,0.1706543
		c-0.0569153,0.0487671-0.1054993,0.0975342-0.1544342,0.1462708c-0.0489197,0.0406189-0.0891876,0.0893555-0.1381226,0.1381226
		c-0.0405884,0.0406189-0.0895233,0.089447-0.130127,0.1300659c-0.0648956,0.0731201-0.1218109,0.1462708-0.1867065,0.2193298
		c-0.0326233,0.0406799-0.0732269,0.089447-0.1138306,0.1300354c-0.024292,0.0406799-0.0489349,0.0731506-0.0812073,0.1056824
		c-0.0569153,0.0812073-0.1138306,0.1625061-0.1707458,0.2437744c-0.0569,0.0812073-0.1138153,0.1625061-0.1707306,0.2437134
		c-0.048584,0.0812988-0.0891876,0.1625977-0.1381226,0.2438049c-0.048584,0.0812683-0.0975189,0.1706543-0.1461029,0.2518616
		c-0.0083313,0.0162964-0.0163116,0.0325317-0.0246429,0.0406799c0,0.0080566-0.0079803,0.0162354-0.0079803,0.0243835
		c-0.0322723,0.0649719-0.0569153,0.1300354-0.0891876,0.1868591c-0.0489349,0.1055908-0.0975189,0.2112732-0.1381226,0.3169556
		c-0.0163116,0.0325317-0.0326233,0.0649719-0.0409393,0.1055603c-0.015976,0.0325623-0.024292,0.0731506-0.0406036,0.1138306
		v0.0080872c-0.0406036,0.1056519-0.0812073,0.2193909-0.1134796,0.3331299
		c-0.0163116,0.0569153-0.0409546,0.1056824-0.0489349,0.1625977c-0.0163116,0.0486755-0.024292,0.0893555-0.0406036,0.1381226
		c-0.0163116,0.0893555-0.0406036,0.1787109-0.0569153,0.2680969c-0.024292,0.0812683-0.0326233,0.1625671-0.048584,0.2356262
		c-0.0083313,0.0487671-0.0163116,0.089447-0.024292,0.1382141c-0.0083313,0.0649719-0.0166626,0.1299438-0.0246429,0.203186
		c-0.0163116,0.0974121-0.024292,0.2030945-0.0322723,0.3087769c0,0.0162048,0,0.0405884-0.0083313,0.0649719v0.0731506
		c-0.0079803,0.0487366-0.0079803,0.0893555-0.0079803,0.1381226v0.4874878l12.4484863,304.0101013
		c0,0.0406494,0.0083313,0.0731201,0.0083313,0.1218872c0.0079803,0.0487671,0,0.0893555,0.0079803,0.1462402
		c0.0079803,0.1463013,0.0406036,0.2925415,0.0569153,0.4387817c0.0163116,0.0975952,0.024292,0.1950684,0.0406036,0.2844849
		c0.0569153,0.2843628,0.1301422,0.5687866,0.2193298,0.836853c0.0083313,0.0325317,0.024292,0.0568848,0.0326233,0.0894165
		c0.0895386,0.2438354,0.1867065,0.4794312,0.2925415,0.7069092c0.0485992,0.0894775,0.0975189,0.1706543,0.1461029,0.2601318
		c0.0895386,0.1705933,0.1790771,0.3330688,0.2845764,0.4874878c0.0569153,0.0893555,0.1218109,0.1787109,0.1867065,0.2763062
		c0.105835,0.1381226,0.2193298,0.2762451,0.3331451,0.4143677c0.0732269,0.0893555,0.1464539,0.1706543,0.2276611,0.2518921
		c0.1218109,0.1300659,0.2519531,0.2519531,0.3900604,0.3738403c0.0812073,0.0731201,0.1624146,0.1462402,0.2519531,0.2194214
		c0.1461029,0.1217651,0.3088684,0.2355957,0.4712677,0.3411865c0.0812073,0.0569458,0.1544342,0.1138306,0.2356415,0.1625977
		c0.024292,0.0162354,0.0406036,0.0324707,0.0648956,0.0405884l252.3055878,150.1401367
		c0.0326233,0.0162354,0.0652466,0.0407104,0.0895386,0.0568848l0.048584,0.0244141
		c0.0246277,0.0162354,0.0409546,0.024353,0.0569153,0.0324707c0.2276611,0.1300049,0.4552917,0.2438354,0.6909485,0.3494263
		c0.0565491,0.0244141,0.1134644,0.0487671,0.1703796,0.0731201c0.2113647,0.0812988,0.422699,0.1543579,0.6420288,0.2194214
		c0.0569153,0.0162354,0.1221619,0.0324707,0.1787109,0.0487671c0.2356567,0.0650024,0.4796143,0.1137695,0.7152405,0.1461792
		c0.0406189,0.0081787,0.072876,0.0081787,0.1054993,0.0163574C499.4228821,799.9755249,499.7154236,800,500,800
		c0.471283,0,0.926239-0.0487671,1.3811951-0.1463623c0,0,0.0083313,0,0.0163269,0
		c0.1950073-0.0405884,0.3820801-0.0975342,0.5687866-0.1543579c0.0405884-0.0162354,0.081543-0.024353,0.130127-0.0405884
		c0.1624146-0.0568848,0.3331604-0.1218872,0.495575-0.1868896c0.0569153-0.0244751,0.1138306-0.0487671,0.1787109-0.0732422
		c0.1464539-0.072998,0.292572-0.1542969,0.446991-0.2355957c0.0648804-0.0324707,0.130127-0.0650024,0.1950378-0.1055908
		c0.1381226-0.0812988,0.2682495-0.1706543,0.4063721-0.2681885c0.0648804-0.0487671,0.1297913-0.0893555,0.1950378-0.1381226
		c0.1297913-0.0975342,0.2519531-0.203186,0.3817444-0.3168945c0.0569153-0.0487671,0.1138306-0.0975342,0.1707153-0.15448
		c0.1218262-0.1136475,0.2436218-0.2355347,0.3574524-0.3574829c0.0326233-0.0406494,0.0732422-0.0812378,0.1138306-0.1218262
		c0.0163269-0.0244141,0.0326233-0.0487671,0.048584-0.0650635c0.0326233-0.0405884,0.0732422-0.0730591,0.1058655-0.1137695
		l0.0079651-0.0162354L753.65448,649.6566162c0.5039063-0.0893555,0.9995117-0.2355347,1.4870605-0.4468994
		c2.0881348-0.9263306,3.5345459-2.8440552,3.8840332-5.0704346c0.0811768-0.3413086,0.1544189-0.6907959,0.1787109-1.0483398
		c0.0326538-0.430542,0.0162964-0.8613281-0.0322876-1.2838135l12.383606-302.4336853V338.8777771z M506.6630859,217.4378967
		l196.2295532,98.118866l-192.7678833-51.7449951l-3.4616699-0.9343872V217.4378967z M756.0351563,343.62323
		l-49.7540283,130.7438965l-33.2261963,87.3035278L533.3886108,313.6959839l-19.7941895-35.1521606L756.0351563,343.62323z
		 M500,281.5828247l162.8243408,289.0830688H337.1753235L500,281.5828247z M493.3365784,217.4378967v45.4394836
		l-196.2295837,52.6793823L493.3365784,217.4378967z M486.4056091,278.5438232L326.9450684,561.6706543l-67.9234314-178.474762
		L243.9644775,343.62323L486.4056091,278.5438232z M243.4442749,379.7504578l38.8658447,102.124939L317.852417,575.28125
		l-21.8666992,18.3887329l-42.3271637,35.5909424L243.4442749,379.7504578z M259.021637,642.1727905l65.8349915-55.3610229
		l146.6299438,181.7982788L259.021637,642.1727905z M499.8292542,782.5050659L343.9199524,589.2171021l-4.209198-5.2168579
		h320.2373657L499.8292542,782.5050659z M529.2689209,767.6674194l145.8741455-180.8556519l65.4938354,55.0766602
		L529.2689209,767.6674194z M746.3657227,628.6351929l-64.0310669-53.8495483l74.2207031-195.0351868L746.3657227,628.6351929z"/>
    </g>
  </svg>
);
export default D20;
