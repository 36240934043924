import * as React from "react";

interface Props {
  marginLeftRight?: string | undefined,
}

export default function FeatureSpacer({ marginLeftRight }: Props) {
  return (
    <div style={{ backgroundColor: '#343434', width: 1, height: '32px', marginLeft: marginLeftRight, marginRight: marginLeftRight }} />
  )
}