// react
import * as React from "react";
import { useState, useEffect, useRef, useContext, useCallback } from 'react';

// openlayers
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Draw from 'ol/interaction/Draw.js';
import XYZ from 'ol/source/XYZ'
import { Projection, transform } from 'ol/proj'
import { Coordinate, toStringXY } from 'ol/coordinate';
import Feature, { FeatureLike } from 'ol/Feature';
import {Collection, Graticule, Kinetic, MapEvent} from 'ol';
import {
  Circle,
  Geometry, LinearRing,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
  SimpleGeometry
} from 'ol/geom';
import { Pixel } from 'ol/pixel';
import Static from 'ol/source/ImageStatic.js';
import ImageLayer from 'ol/layer/Image';
import { containsExtent, getCenter } from 'ol/extent';
import { measureLabelDimensions } from '../../../util/maputils';
import GeoJSON from 'ol/format/GeoJSON.js';
import ImageSource from 'ol/source/Image';
import ImageCanvas from 'ol/source/ImageCanvas';

import GeoImageSource, { Options } from "ol-ext/source/GeoImage";
import GeoImageLayer from "ol-ext/layer/GeoImage";
import Layer from 'ol/layer/Layer';
import { Button, Popover } from 'antd';
import { useGlobalState } from "../../Menu/GlobalState";
import { shallow } from "zustand/shallow";
import {NormalDrawingColors, useMapState} from "../MapDisplay";
import {
  BlankBase64, DatabaseGamePlayer,
  DatabaseMap,
  DatabaseMapPoi, DatabaseToken,
  GridSettings,
  InitiativeSpot, isDemo,
  MapCost,
  PlayerTableState, RequestedMovement, SvgPathDrawSettings,
  ToolType,
  TrayTool
} from '../../../types';
import { Session } from "@supabase/supabase-js";
import { Fill, Stroke, Style, Text as TextStyle } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { zlibSync, unzlibSync } from 'fflate';
import {bufferToString, stringToUint8Array, toMoveRangeKey} from "../../../util/filterutil";
import { v4 } from "uuid";
import concaveman from "concaveman";
import RenderEvent from "ol/render/Event";
import {smoothDamp, smoothDampCoordinate} from "../../../util/mathtuil";
import GeoImageLayerExt from "./GeoImageLayerExtentions";
import TokenGeoImageExtension, { TokenGeoImageOptions } from "./TokenGeoImageExtension";
import {StyleLike} from "ol/style/Style";
import chroma from "chroma-js";
import * as jsts from 'jsts';
import FillPattern from "ol-ext/style/FillPattern";
import {DragPan, MouseWheelZoom} from "ol/interaction";
import { Grab, Meditation } from 'react-game-icons-auto';
import {BaseStatuses} from "../../../util/basestatuses";
import iwanthue from 'iwanthue';
import Chart from "ol-ext/style/Chart";
import pale = Chart.colors.pale;
import {DefaultFont, HoverForMillisecondsToShowLabel} from "../../../util/config";
import {useMainMenuState} from "../../Menu/MainMenu";

interface Props {
}

export default function InnerMapWrapper({ }: Props) {
  const session = useGlobalState((state) => state.session);
  const logger = useGlobalState((state) => state.logger);

  const fontLoadStatus = useGlobalState((state) => state.fontLoadStatus);
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const [workingMoveArrows, setWorkingMoveArrows] = useMapState((state) => [state.workingMoveArrows, state.setWorkingMoveArrows], shallow);
  const moveArrowFadeState = useRef<{[poi_id: string]: {stamp: number, safeToDelete?: boolean, started?: number}}>({});
  const moveArrowBeingUsedForMovement = useRef<Set<string>>(new Set<string>());
  const [editingMapId, setEditingMapId] = useGlobalState((state) => [state.editingMapId, state.setEditingMapId], shallow);
  const [mouseOverFeature, setMouseOverFeature] = useMapState((state) => [state.mouseOverFeature, state.setMouseOverFeature], shallow);
  const [mouseOverMovementApproval, setMouseOverMovementApproval] = useMapState((state) => [state.mouseOverMovementApproval, state.setMouseOverMovementApproval], shallow);
  const [hoverDrawFeature, setHoverDrawFeature] = useMapState((state) => [state.hoverDrawFeature, state.setHoverDrawFeature], shallow);
  const [currentZoomFromOtherMap, setCurrentZoomFromOtherMap] = useMapState((state) => [state.currentZoomFromOtherMap, state.setCurrentZoomFromOtherMap], shallow);
  const [mouseOverFeatureRefPoint, setMouseOverFeatureRefPoint] = useState<number[]>();
  const [mouseOverFeatureRefPointOffset, setMouseOverFeatureRefPointOffset] = useState<number>();
  const [anyModalOpen, setModalOpen] = useGlobalState((state) => [state.anyModalOpen, state.setModalOpen], shallow);
  const [map, setMap] = useMapState((state) => [state.map, state.setMap], shallow);
  const viewAsPlayer = useMapState((state) => state.viewAsPlayer);
  const editingGameData = useGlobalState((state) => state.editingGameData);
  
  const [mapReadyForDisplay, setMapReadyForDisplay] = useMapState((state) => [state.mapReadyForDisplay, state.setMapReadyForDisplay], shallow);

  const amGM = useMapState((state) => state.amGM);

  const shortcutKeys = useGlobalState((state) => state.shortcutKeys);
  const onlinePlayerIds = useMapState((state) => state.onlinePlayerIds);
  const [trayFocusedTool, setTrayFocusedTool] = useMapState((state) => [state.trayFocusedTool, state.setTrayFocusedTool], shallow);
  const [trayTwoTool, setTrayTwoFocusedTool] = useMapState((state) => [state.trayTwoFocusedTool, state.setTrayTwoFocusedTool], shallow);
  const [trayTwoToolDown, setTrayTwoToolDown] = useState<{isdown: boolean, when: number}>({isdown: false, when: 0});
  const [myDrawSource, setMyDrawSource] = useMapState((state) => [state.myDrawSource, state.setMyDrawSource], shallow);
  const [fillMode, setFillMode] = useMapState((state) => [state.fillMode, state.setFillMode], shallow);
  const [localDrawSource, setLocalDrawSource] = useMapState((state) => [state.localDrawSource, state.setLocalDrawSource], shallow);
  const [allDrawSources, setAllDrawSources] = useState<{ source: VectorSource, isMe: boolean, isGM: boolean, id: string, layer?: Layer | undefined}[]>([]);
  const drawSourceFadeTrackers = useRef<{ [key: string]: {[key: string]: { started?: number, lastUpdated?: number, lastUpdateCount?: number }}}>({});
  const [myDrawInteraction, setMyDrawInteraction] = useState<Draw | undefined>();
  const [playerTableState, setPlayerTableState] = useMapState((state) => [state.playerTableState, state.setPlayerTableState], shallow);
  const [forceMapDrawingSync, setForceMapDrawingSync] = useMapState((state) => [state.forceMapDrawingSync, state.setForceMapDrawingSync], shallow);
  const [forceMapMovePoi, setForceMapMovePoi] = useMapState((state) => [state.forceMapMovePoi, state.setForceMapMovePoi], shallow);
  const [allPlayerTableStates, setAllPlayerTableStates] = useMapState((state) => [state.allPlayerTableStates, state.setAllPlayerTableStates], shallow);
  const tablePlayersList = useMapState((state) => state.tablePlayersList);
  const setRecentDrawColors = useMapState((state) => state.setRecentDrawColors);

  const playersList = useMapState((state) => state.tablePlayersList);
  const originalGameTokens = useMainMenuState((state) => state.originalGameTokens);

  // set intial state
  const [featuresLayer, setFeaturesLayer] = useState<VectorLayer<VectorSource>>();
  const [backgroundLayer, setBackgroundLayer] = useState<ImageLayer<Static>>();
  const [staticSizeFeatureLayers, setStaticSizeFeatureLayers] = useState<Layer[]>();
  const [windowDimensions, setWindowDimensions] = useGlobalState((state) => [state.windowDimensions, state.setWindowDimensions], shallow);

  const [gridImage, setGridImage] = useState<HTMLImageElement>();
  const [gridSettings, setGridSettings] = useState<GridSettings>();

  const [buildingFillCells, setBuildingFillCells] = useState<Coordinate[]>();
  const [fillCellFeature, setFillCellFeature] = useState<Feature>();

  const [moveFeatureOffsetCoordinate, setMoveFeatureOffsetCoordinate] = useState<Coordinate>();

  const mapDebug = useMapState((state) => state.mapDebug);
  const channels = useMapState((state) => state.channels);
  const myDrawColor = useMapState((state) => state.myDrawColor);
  const changedDrawColor = useRef<string>('#000000');
  
  const updateMoveArrowTimeout = useRef<any>();
  const moveArrowWidth = useRef<number>(10);
  
  // For unwalkable calculation
  const unwalkableTilesSet = useRef<Set<string>>(new Set<string>());
  const lastUnwalkableData = useRef<string>('');
  const unwalkableJstsGeometry = useRef<jsts.geom.Geometry[]>([]);
  const jstsParser = useRef<jsts.io.OL3Parser>(new jsts.io.OL3Parser());

  const mapElement = useRef(null);

  const [poiCoordinateChanges, setPoiCoordinateChanges] = useGlobalState((state) => [state.poiCoordinateChanges, state.setPoiCoordinateChanges], shallow);
  const desiredPoiCoordinates = useRef<{[id: string]: { desiredPosition: Coordinate, velocity: Coordinate, currentPosition: Coordinate, lastFrame: number, totalTime: number, followPath?: Coordinate[][], pathTimeStamp?: number }}>({});
  
  const fadeAfter = 3000;
  const fadeDuration = 1000;
  
  // create state ref that can be accessed in OpenLayers onclick callback function
  //  https://stackoverflow.com/a/60643670
  const mapRef = useRef<Map>()
  if (map !== undefined)
    mapRef.current = map

  changedDrawColor.current = myDrawColor;

  const featureMoveRef = useRef<{ poi: DatabaseMapPoi, time: number, withinBounds?: Geometry[] | undefined} | undefined>();
  const featureMoveArrow = useRef<{feature: Feature, startPoint: Coordinate, endPoint?: Coordinate | undefined, tempEndPoint?: Coordinate | undefined, cost: MapCost}[]>();

  //#region Canvas Features
  const [labelFeatureCanvas, setLabelFeatureCanvas] = useState<HTMLCanvasElement>();
  const [gridCanvas, setGridCanvas] = useState<HTMLCanvasElement>();

  function createHiPPICanvas(existingCanvas: HTMLCanvasElement | undefined, width: number, height: number): HTMLCanvasElement {
    const ratio = window.devicePixelRatio;
    const canvas = existingCanvas === undefined ? document.createElement("canvas") : existingCanvas;

    canvas.width = width * ratio;
    canvas.height = height * ratio;
    canvas.style.width = width + "px";
    canvas.style.height = height + "px";
    canvas.getContext("2d").scale(ratio, ratio);

    return canvas;
  }

  const createCanvasFeature = (mapElement: Map, poi: DatabaseMapPoi, workingList: Layer[]) => {
    let canvasElement = labelFeatureCanvas;
    let text = poi.map_text;

    if (poi.links_to_map)
      text = `\u{21B7} ${text}`;

    if (poi.has_description)
      text = `\u{232F} ${text}`;
    
    if (poi.hidden)
      text = `\u{2687} ${text}`;
    
    if (poi.locked)
      text = `\u{003F} ${text}`;

    const fontSetting = poi.font_override ?? "84px serif";
    const parsedFont = parseInt(fontSetting);
    
    const chosenFontFamily = editingMapData.font_setting && editingMapData.font_setting.length > 0 ? editingMapData.font_setting : DefaultFont;

    const dimensions = measureLabelDimensions(text, `${parsedFont}`, chosenFontFamily);

    let width = dimensions[0];
    let height = dimensions[1];

    // Removes some padding around the sides
    let padding = poi.text_padding_override ?? [0.8, 0.75];
    width *= padding[0];
    height *= padding[1];

    const startedUndefined = canvasElement == undefined;
    canvasElement = createHiPPICanvas(canvasElement, width, height)

    if (startedUndefined)
      setLabelFeatureCanvas(canvasElement);

    let context = canvasElement.getContext('2d');

    if (mapDebug) {
      context.globalAlpha = 0.7;
      context.fillStyle = 'red';
      context.fillRect(0, 0, width * 3, height * 3);
      context.globalAlpha = 1;
    }

    context.font = `${parsedFont}px ${chosenFontFamily}`;
    context.strokeStyle = 'black';
    context.lineWidth = parsedFont * (poi.text_outline_ratio ?? 0.125);
    context.textAlign = 'center';
    const oldGlobalAlpha = context.globalAlpha;
    if (poi.locked || poi.hidden)
      context.globalAlpha = poi.locked_alpha;
    
    if (poi.color_text_poi)
      context.globalAlpha *= (Number(`0x${poi.color_text_poi.substring(7)}`) / 255)
    
    // If a poi is hidden, we won't render it, UNLESS you are the GM AND have viewAsPlayer OFF.
    if (!poi.hidden || (!viewAsPlayer && session && editingMapData && editingMapData.owner_id == session.user.id)) {
      context.strokeText(text, width / 2, height * 3 / 4);
      if (!poi.color_text_poi)
        context.fillStyle = 'white';
      else
        context.fillStyle = poi.color_text_poi.substring(0, 7);
      context.fillText(text, width / 2, height * 3 / 4);
    }

    context.globalAlpha = oldGlobalAlpha;

    const newImage = new Image();
    newImage.src = canvasElement.toDataURL();

    const source = new GeoImageSource({
      image: newImage,
      imageCenter: [poi.coordinate_x, poi.coordinate_y],
      imageScale: [0.5,0.5],
      //imageMask: [[273137.343,6242443.14],[273137.343,6245428.14],[276392.157,6245428.14],[276392.157,6242443.14],[273137.343,6242443.14]],
      imageRotate: 0,
    } as Options);

    var geoimg = new GeoImageLayerExt({
      // @ts-ignore
      name: text,
      opacity: 1,
      source: source,
      data: poi,
    });

    desiredPoiCoordinates.current[poi.poi_id] = {
      desiredPosition: [poi.coordinate_x, poi.coordinate_y],
      currentPosition: [poi.coordinate_x, poi.coordinate_y],
      velocity: [0, 0],
      lastFrame: 0,
      totalTime: 0,
    };

    geoimg.on('postrender', (event: RenderEvent ) => {
      let {desiredPosition: targetPos, currentPosition, velocity, lastFrame, totalTime } = desiredPoiCoordinates.current[poi.poi_id];
      if (+currentPosition[0].toFixed(3) == +targetPos[0].toFixed(3) && +currentPosition[1].toFixed(3) == +targetPos[1].toFixed(3)) {
        desiredPoiCoordinates.current[poi.poi_id] = {
          desiredPosition: targetPos,
          currentPosition,
          velocity,
          lastFrame: 0,
          totalTime: 0,
        };
        return;
      }

      const time = event.frameState.time;

      if (lastFrame == 0)
      {
        lastFrame = time;
        desiredPoiCoordinates.current[poi.poi_id] = {
          desiredPosition: targetPos,
          currentPosition,
          velocity,
          lastFrame,
          totalTime
        };
        mapRef.current.render();
        return;
      }

      // By default time is in milliseconds, so we need to convert this to fractional seconds.
      const deltaTime = (time - lastFrame) / 1000;
      lastFrame = time;

      const {damped: newPosition, velocityOut } = smoothDampCoordinate(currentPosition, targetPos, velocity, deltaTime, amGM ? 0.1 : 0.3);
      velocity = velocityOut;
      source.setCenter(newPosition);
      currentPosition = newPosition;
      desiredPoiCoordinates.current[poi.poi_id] = {
        desiredPosition: targetPos,
        currentPosition,
        velocity,
        lastFrame,
        totalTime
      };

      mapRef.current.render();
    });

    // @ts-ignore
    workingList.push(geoimg);
  };

  const createCanvasImageFeature = (mapElement: Map, poi: DatabaseMapPoi, workingList: Layer[]) => {
    const scaleImage = parseFloat(poi.font_override ?? '0.5');

    const isSvg = poi.map_text.startsWith('svg');
    
    const source = new TokenGeoImageExtension({
      is_svg: isSvg,
      url: isSvg ? BlankBase64 : `https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${editingMapData.owner_id}/${poi.map_text}`,
      inRawSvgData: isSvg ? poi.map_text : undefined,
      imageCenter: [poi.coordinate_x, poi.coordinate_y],
      imageScale: [scaleImage, scaleImage],
      //imageMask: [[273137.343,6242443.14],[273137.343,6245428.14],[276392.157,6245428.14],[276392.157,6242443.14],[273137.343,6242443.14]],
      imageRotate: 0,
      statusUrls: poi.status?.map((s) => {
        if (s == 'downed')
          return undefined;
        
        const match = BaseStatuses.find((i) => i.status == s);
        if (match) {
          const newGrab = match.icon({});
          const path = newGrab.props.children.props.d;
          const startChroma = chroma.hex(match.color);

          return `svg${JSON.stringify({
            path: path,
            foreground_color: startChroma.brighten(2).hex(),
            background_color: startChroma.hex(),
            draw_background: true,
          } as SvgPathDrawSettings)}`;
        }

        return undefined;
      }).filter((s) => s !== undefined),
      grayscale: poi.status?.includes('downed'),
    } as Options & TokenGeoImageOptions);

    const geoimg = new GeoImageLayerExt({
      // @ts-ignore
      name: poi.map_text,
      opacity: 1,
      source: source,
      data: poi,
    });

    desiredPoiCoordinates.current[poi.poi_id] = {
      desiredPosition: [poi.coordinate_x, poi.coordinate_y],
      currentPosition: [poi.coordinate_x, poi.coordinate_y],
      velocity: [0, 0],
      lastFrame: 0,
      totalTime: 0,
    };

    geoimg.on('postrender', (event: RenderEvent ) => {
      let {desiredPosition: targetPos, currentPosition, velocity, lastFrame, totalTime, followPath, pathTimeStamp } = desiredPoiCoordinates.current[poi.poi_id];
      if (+currentPosition[0].toFixed(3) == +targetPos[0].toFixed(3) && +currentPosition[1].toFixed(3) == +targetPos[1].toFixed(3)) {
        
        // Indicate we may begin fading it.
        if (followPath && !(poi.poi_id in moveArrowFadeState.current)) {
          moveArrowFadeState.current[poi.poi_id] = {
            stamp: pathTimeStamp,
          };
        }
        
        desiredPoiCoordinates.current[poi.poi_id] = {
          desiredPosition: targetPos,
          currentPosition,
          velocity,
          lastFrame: 0,
          totalTime: 0,
          followPath: undefined,
        };
        return;
      }

      const time = event.frameState.time;

      if (lastFrame == 0)
      {
        lastFrame = time;
        desiredPoiCoordinates.current[poi.poi_id] = {
          ...desiredPoiCoordinates.current[poi.poi_id],
          desiredPosition: targetPos,
          currentPosition,
          velocity,
          lastFrame,
          totalTime: 0,
        };
        mapRef.current.render();
        return;
      }

      // By default time is in milliseconds, so we need to convert this to fractional seconds.
      const deltaTime = (time - lastFrame) / 1000;
      lastFrame = time;
      totalTime += deltaTime;
      
      let shouldSmoothDamp = followPath == undefined;

      if (followPath) {
        const maxTime = 1;
        const distanceAlong = Math.min(1, totalTime / maxTime);
        
        if (distanceAlong < 1) {
          const allPoints: Coordinate[] = [];

          for (let legIndex = 0; legIndex < followPath.length; legIndex++) {
            for (let legPositionIndex = legIndex > 0 ? 1 : 0; legPositionIndex < followPath[legIndex].length; legPositionIndex++) {
              const thisPoint = followPath[legIndex][legPositionIndex];
              allPoints.push(thisPoint);
            }
          }

          const fractionalPos = allPoints.length * distanceAlong;
          const positionPrevious = allPoints[Math.floor(fractionalPos)];
          const positionNext = allPoints.length > Math.ceil(fractionalPos) ? allPoints[Math.ceil(fractionalPos)] : targetPos;

          const percentageBetweenThoseTwo = fractionalPos - Math.floor(fractionalPos);
          const differenceNextPrev = [positionNext[0] - positionPrevious[0], positionNext[1] - positionPrevious[1]];
          const lerpedPosition = [positionPrevious[0] + (differenceNextPrev[0] * percentageBetweenThoseTwo), positionPrevious[1] + (differenceNextPrev[1] * percentageBetweenThoseTwo)] as Coordinate;
          source.setCenter(lerpedPosition);
          currentPosition = lerpedPosition;
        }
      }
      
      if (shouldSmoothDamp) {
        const maxTime = amGM ? 0.1 : 0.3;

        const {damped: newPosition, velocityOut } = smoothDampCoordinate(currentPosition, targetPos, velocity, deltaTime, maxTime);
        velocity = velocityOut;
        source.setCenter(newPosition);
        currentPosition = newPosition;
      }
      
      desiredPoiCoordinates.current[poi.poi_id] = {
        ...desiredPoiCoordinates.current[poi.poi_id],
        desiredPosition: targetPos,
        currentPosition,
        velocity,
        lastFrame,
        totalTime
      };

      mapRef.current.render();
    });

    // @ts-ignore
    workingList.push(geoimg);
  };

  const designGridLayer = () => {
    let canvasElement = gridCanvas;

    const startedUndefined = canvasElement == undefined;
    canvasElement = createHiPPICanvas(canvasElement, editingMapData.projection_x, editingMapData.projection_y);
    if (startedUndefined)
      setGridCanvas(canvasElement);

    let context = canvasElement.getContext('2d');

    const [paddingLeft, paddingTop, paddingRight, paddingBottom] = [editingMapData.grid_padding_left ?? 0, editingMapData.grid_padding_top ?? 0, 0, 0];
    const lineSpacing = editingMapData.grid_line_spacing ?? 100;
    const lineWidth = editingMapData.grid_line_width ?? 2;

    const oldLineWidth = context.lineWidth;
    context.lineWidth = lineWidth;
    for (let x = 0; x <= editingMapData.projection_x; x += lineSpacing) {
      context.moveTo(x + paddingLeft, paddingTop);
      context.lineTo(x + paddingLeft, editingMapData.projection_y - paddingBottom);
    }

    for (let y = editingMapData.projection_y; y >= 0; y -= lineSpacing) {
      context.moveTo(paddingLeft, y + paddingTop);
      context.lineTo(editingMapData.projection_x - paddingRight, y + paddingTop);
    }

    context.strokeStyle = editingMapData.grid_line_color ?? "black";
    context.stroke();
    context.lineWidth = oldLineWidth;

    const newImage = new Image();
    newImage.src = canvasElement.toDataURL();
    setGridImage(newImage);
  }

  const createGridImageFeature = (mapData: DatabaseMap, workingList: Layer[]) => {
    var geoimg = new GeoImageLayerExt({
      // @ts-ignore
      name: 'grid',
      opacity: 1,
      source: new GeoImageSource({
        image: gridImage,
        imageCenter: [mapData.projection_x / 2, mapData.projection_y / 2],
        imageScale: [0.66775, 0.66775],
        //imageMask: [[273137.343,6242443.14],[273137.343,6245428.14],[276392.157,6245428.14],[276392.157,6242443.14],[273137.343,6242443.14]],
        imageRotate: 0,
      } as Options),
    });
    // @ts-ignore
    workingList.push(geoimg);

    return true;
  };
  //#endregion
  
  useEffect(() => {
    // @ts-ignore
    jstsParser.current.inject(
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon,
    );
  }, []);

  // initialize map on first render - logic formerly put into componentDidMount
  useEffect(() => {
    const backgroundUrl = `https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${editingMapData.owner_id}/${editingMapData.background_image}`;

    // create and add vector source layer
    const initalFeaturesLayer = new VectorLayer({
      source: new VectorSource()
    });

    const imageLayer = new ImageLayer({
      source: new Static({
        url: backgroundUrl,
        imageExtent: [0, 0, editingMapData.projection_x, editingMapData.projection_y]
      }),
    });
    
    // Initialize the pathfinding graph

    const thisProjection = new Projection({
      code: 'flat-image',
      units: 'pixels',
      extent: [0, 0, editingMapData.projection_x, editingMapData.projection_y],
      worldExtent: [0, 0, editingMapData.projection_x, editingMapData.projection_y],
    });

    const lastMapId = window.localStorage.getItem('last-map-id') || undefined;
    const originalCenter = getCenter([0, 0, editingMapData.projection_x, editingMapData.projection_y]);
    let desiredCenter = originalCenter;
    const lastCenterValue = window.localStorage.getItem('last-center');

    if (lastCenterValue !== undefined && lastCenterValue !== 'null')
      desiredCenter = JSON.parse(lastCenterValue);
    const lastCenter = (lastMapId === undefined || lastMapId == editingMapId) ? desiredCenter : originalCenter;
    let lastZoom = (lastMapId === undefined || lastMapId == editingMapId) ? parseFloat(window.localStorage.getItem('last-zoom')) : 2;
    if (!lastZoom)
      lastZoom = 2;

    // create map
    console.log('creating map')
    console.log(backgroundUrl);
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        imageLayer,
        initalFeaturesLayer
      ],
      maxTilesLoading: 48,
      view: new View({
        projection: thisProjection,
        center: lastCenter,
        zoom: lastZoom,
        maxZoom: 8,
      }),
      controls: [],
      interactions: [
        new DragPan({
          kinetic: new Kinetic(-0.005, 0.05, 100)
        }),
        new MouseWheelZoom(),
      ]
    });

    // save map and vector layer references to state
    setMap(initialMap);
    setFeaturesLayer(initalFeaturesLayer);
    setBackgroundLayer(imageLayer);
  }, []);

  useEffect(() => {
    if (!map)
      return;

    let setTo = false;
    if (session || isDemo)
      setTo = true;

    map.getInteractions().forEach((interaction) => interaction.setActive(setTo));
  }, [map, session]);

  // Allow making us move things with commands.
  useEffect(() => {
    if (!forceMapMovePoi)
      return;

    console.log('Trying to force move of', forceMapMovePoi);

    featureMoveRef.current = {
      poi: forceMapMovePoi,
      time: Date.now(),
    };
    // console.log('setting move ref to', featureMoveRef.current);
    mapRef.current.getInteractions().forEach((interaction) => {
      interaction.setActive(false);
    });
    setMoveFeatureOffsetCoordinate([0, 0]);

    setForceMapMovePoi(undefined);
  }, [forceMapMovePoi]);

  useEffect(() => {
    if (!editingMapData)
      return;
    
    moveArrowWidth.current = editingMapData.move_arrow_width ?? 10;

    if (!gridSettings) {
      setGridSettings(editingMapData);
    } else if (
      editingMapData.grid_padding_top == gridSettings.grid_padding_top &&
      editingMapData.grid_padding_left == gridSettings.grid_padding_left &&
      editingMapData.grid_line_spacing == gridSettings.grid_line_spacing &&
      editingMapData.grid_line_width == gridSettings.grid_line_width &&
      editingMapData.grid_line_color == gridSettings.grid_line_color
    )
      return;

    designGridLayer();
  }, [editingMapData, gridSettings, setGridSettings]);

  // update map if features prop changes - logic formerly put into componentDidUpdate
  useEffect(() => {
    /**
     * https://openlayers.org/en/latest/examples/draw-features-style.html here's a url we're referencing.
     */

    let draw: Draw = undefined;
    if (editingMapData.pois !== undefined && featuresLayer && map) {
      // may be null on first render
      // set features to map

      let lDrawSource: VectorSource;
      if (!localDrawSource) {
        lDrawSource = new VectorSource({
          wrapX: false,
          features: [],
          format: new GeoJSON(),
        });
        setLocalDrawSource(lDrawSource);
      } else
        lDrawSource = localDrawSource;

      const newLayers: any[] = [
        backgroundLayer,
        featuresLayer,
        new VectorLayer({
          source: lDrawSource,
        })
      ];

      const addedGrid = editingMapData.has_grid ? createGridImageFeature(editingMapData, newLayers) : false;

      const allDrawSources: { source: VectorSource, isMe: boolean, isGM: boolean, id: string, layer?: Layer | undefined}[] = [];
      const addDrawingLayer = (id: string) => {
        let layerContents: string = editingMapData.drawings && id in editingMapData.drawings ? editingMapData.drawings[id] : undefined;

        if (!layerContents)
        {
          if (id in allPlayerTableStates && allPlayerTableStates[id].drawing)
            layerContents = allPlayerTableStates[id].drawing;
        }

        const isGM = id == 'GM';
        const isMe = (session && session.user.id == id) || (isGM && amGM);

        let playerBaseColor = changedDrawColor.current;

        // const playerBaseColor = tablePlayersList.find((item) => item.user_id == id)?.border_color ?? '#000000';
        
        let myStyle = {
          "circle-radius": 5,
          "circle-fill-color": `${playerBaseColor}aa`,
          'stroke-color': playerBaseColor,
          'stroke-width': 5,
          'fill-color': `${playerBaseColor}aa`,
          "shape-fill-color": `${playerBaseColor}aa`,
          "shape-stroke-color": playerBaseColor,
          'shape-stroke-width': 5,
        };

        if (layerContents || isMe) {
          // Decode this into an actual json string
          let drawSource: VectorSource;

          if (!myDrawSource || !isMe) {
            let unencoded: string = undefined;
            if (layerContents) {
              const compressed = stringToUint8Array(layerContents);
              const unzlibd = unzlibSync(compressed);
              unencoded = new TextDecoder().decode(unzlibd);
            }

            const existing: any = layerContents ? (new GeoJSON()).readFeatures(unencoded) : [];

            // There may be things causing this to have problems.
            if (id in drawSourceFadeTrackers.current) {
              const myFadeTrackers = drawSourceFadeTrackers.current[id];
              
              for (let i = existing.length; i >= 0; i -= 1) {
                const feat = existing[i];
                if (feat && feat.hasProperties()) {
                  const featureId: string = feat.getProperties()['id'];
                  if (featureId in myFadeTrackers) {
                    // We're recreating this feature because we received another change, but we want to continue fading it out all the same

                    const started = myFadeTrackers[featureId].started;
                    const now = Date.now();

                    if (now - started < fadeAfter) {
                      // It's been less than 5 seconds, do nothing.
                      continue;
                    }
                    
                    if (now - started > fadeAfter + fadeDuration) {
                      existing.splice(i, 1);
                      continue;
                    }

                    const hasStyle = feat.getProperties()['style'];
                    const intendedColor = hasStyle ? hasStyle['color'] : undefined;
                    const playerBaseColor = intendedColor ?? (tablePlayersList.find((item) => item.user_id == id)?.border_color ?? '#ff0000');

                    myStyle = {
                      "circle-radius": 5,
                      "circle-fill-color": `${playerBaseColor}aa`,
                      'stroke-color': playerBaseColor,
                      'stroke-width': 5,
                      'fill-color': `${playerBaseColor}aa`,
                      "shape-fill-color": `${playerBaseColor}aa`,
                      "shape-stroke-color": playerBaseColor,
                      'shape-stroke-width': 5,
                    };

                    // Else fade out over a period of 1 second
                    const fadePercent = 1 + (Math.min(1, ((now - started) - fadeAfter) / (fadeDuration)) * -1);
                    const fadedFillColor = Math.round(170 + (Math.min(1, ((now - started) - fadeAfter) / (fadeDuration)) * -150));

                    const fadeStrokeColor = myStyle["stroke-color"].substring(0,7) + Math.max(Math.round(fadePercent * 255), 1).toString(16).padStart(2, '0');
                    const fadeFillColor = myStyle["fill-color"].substring(0,7) + Math.max(fadedFillColor, 1).toString(16).padStart(2, '0');

                    feat.setStyle(new Style({
                      stroke: new Stroke({
                        color: fadeStrokeColor,
                        width: 5,
                      }),
                      fill: new Fill({
                        color: fadeFillColor,
                      }),
                    }));
                  }
                }
              }
            }

            existing.forEach((feat: Feature) => {
              if (feat.hasProperties()) {
                const featStyle = feat.getProperties()['style'];
                if (featStyle) {
                  feat.setStyle(new Style({
                    stroke: new Stroke({
                      width: 5,
                      color: featStyle['stroke'] ?? featStyle['color'],
                    }),
                    fill: new Fill({
                      color: featStyle['fill'] ?? `${featStyle['color']}aa`,
                    })
                  }));
                  
                  if (!isMe) {
                    playerBaseColor = featStyle['color'] ?? '#ff0000';
                    myStyle = {
                      "circle-radius": 5,
                      "circle-fill-color": `${playerBaseColor}aa`,
                      'stroke-color': playerBaseColor,
                      'stroke-width': 5,
                      'fill-color': `${playerBaseColor}aa`,
                      "shape-fill-color": `${playerBaseColor}aa`,
                      "shape-stroke-color": playerBaseColor,
                      'shape-stroke-width': 5,
                    };
                  }
                }
              }
            });

            drawSource = new VectorSource({ 
              wrapX: false,
              features: existing,
              format: new GeoJSON(),
            });

            const anyCircles = unencoded ? JSON.parse(unencoded)['circles'] ?? [] : [];
            anyCircles.forEach((item: any) => {
              const newFeat = new Feature({
                name: 'circle',
                geometry: new Circle(item['center'], item['radius'], item['layout']),
              });

              newFeat.setProperties(item['properties'] ?? {});

              if (item['style']) {
                newFeat.setStyle(new Style({
                  stroke: new Stroke({
                    width: 5,
                    color: item['style']['stroke'] ?? '#000000',
                  }),
                  fill: new Fill({
                    color: item['style']['fill'] ?? '#000000aa',
                  })
                }))
              }

              drawSource.addFeature(newFeat);
            });
          } else 
            drawSource = myDrawSource;

          const drawLayer = new VectorLayer({
            source: drawSource,
            style: myStyle,
          });

          allDrawSources.push({
            source: drawSource,
            isMe: isMe,
            isGM: isMe && amGM || id == 'GM',
            id: id,
            layer: drawLayer,
          });

          newLayers.push(drawLayer);

          map.setLayers(newLayers);
          if (isMe) {
            setMyDrawSource(drawSource);
            switch (trayTwoTool) {
              case ToolType.Circle:
              {
                draw = new Draw({
                  source: drawSource,
                  type: 'Circle',
                  style: myStyle,
                  geometryName: 'circle',
                });
                map.addInteraction(draw);
                break;
              }
              case ToolType.Freehand:
              {
                draw = new Draw({
                  source: drawSource,
                  type: 'LineString',
                  freehand: true,
                  style: myStyle,
                });
                map.addInteraction(draw);
                break;
              }
              case ToolType.Polygon:
              {
                draw = new Draw({
                  source: drawSource,
                  type: 'Polygon',
                  style: myStyle,
                });
                map.addInteraction(draw);
                break;
              }
              case ToolType.Eraser: 
              {
                draw = new Draw({
                  source: drawSource,
                  type: 'LineString',
                  freehand: true,
                  style: {
                    ...myStyle,
                    "circle-stroke-color": 'white',
                    "circle-fill-color": '#ffffffaa',
                    "stroke-color": null,
                    "stroke-width": 0,
                  },
                  geometryName: "eraser",
                });
                map.addInteraction(draw);
                break;
              }
              case ToolType.Fill: 
              {
                draw = new Draw({
                  source: drawSource,
                  type: 'LineString',
                  freehand: true,
                  style: {
                    ...myStyle,
                    "circle-stroke-color": 'white',
                    "circle-fill-color": '#ffffffaa',
                    "stroke-color": null,
                    "stroke-width": 0,
                  },
                  geometryName: "fill",
                });
                map.addInteraction(draw);
                break;
              }
              default:
                break;
            }
            setMyDrawInteraction(draw);
          }
        }
      }

      onlinePlayerIds.forEach((id) => {
        addDrawingLayer(id);
      });
      addDrawingLayer('GM');

      setAllDrawSources(allDrawSources);

      editingMapData.pois.forEach((poi) => {
        if (poi.is_image) {
          createCanvasImageFeature(map, poi, newLayers);
        } else
          createCanvasFeature(map, poi, newLayers);
      });

      // If we added a grid, take it out of our features layer so we don't
      // target it with mouseclicks and such.
      let staticLayers = newLayers.slice((editingMapData.has_grid ? 4 : 3) + allDrawSources.length);
      setStaticSizeFeatureLayers(staticLayers);

      // Create draw layers for each connected player, including ourselves.

      // Add a final layer for local programatic drawings.

      map.setLayers(newLayers);
    }

    return () => {
      if (map && draw)
        map.removeInteraction(draw);
    };
  }, [editingMapData, featuresLayer, map, mapDebug, viewAsPlayer, gridImage, trayTwoTool, allPlayerTableStates, fontLoadStatus]);
  
  const shouldSaveFeatureNormal = (feat: Feature) => {
    const props = feat.getProperties();
    
    if (props)
    {
      if (props['type'] == 'unwalkable')
        return false;
    }
    
    return true;
  }

  // Sends changes over the wire
  const saveChangesToMyLayer = useCallback(() => {
    myDrawSource.forEachFeature((feat) => {
      if (!feat.hasProperties())
        feat.setProperties({});

      const properties = feat.getProperties();
      if (!('id' in properties)) {
        properties['id'] = v4();
        feat.setProperties(properties);
      }

      let style = feat.getStyle();
      
      if (!style) {
        feat.setStyle(new Style({
          stroke: new Stroke({
            width: 5,
            color: myDrawColor,
          }),
          fill: new Fill({
            color: `${myDrawColor}aa`,
          })
        }));
        
        style = feat.getStyle();
      }

      if (style) {
        const filterStyle = {
          fill: (style as any).fill_?.color_,
          stroke: (style as any).stroke_?.color_,
        };

        if (!('style' in properties) || properties['style'] != filterStyle)
        {
          properties['style'] = {
            ...filterStyle,
            color: myDrawColor
          };
          feat.setProperties(properties);
        }
      }
    });
    
    const allFeatures = myDrawSource.getFeatures().filter((feature) => shouldSaveFeatureNormal(feature));

    let draw = new GeoJSON().writeFeatures(allFeatures);

    const drawJSON = JSON.parse(draw);

    const circles: any[] = [];
    const colors: {[key: string]: string} = {};
    myDrawSource.forEachFeature((feat) => {
      if (feat.getGeometryName() == 'circle') {
        const circle = feat.getGeometry() as Circle;

        circles.push({
          center: circle.getCenter(),
          radius: circle.getRadius(),
          layout: circle.getLayout(),
          id: feat.getProperties()['id'],
          style: feat.getProperties()['style'],
        });
      }
      
      const style = feat.getProperties()['style'];
      if (style && style['color'])
        colors[feat.getProperties()['id']] = style['color'];
    });

    drawJSON['circles'] = circles;
    drawJSON['colors'] = colors;
    draw = JSON.stringify(drawJSON);

    const enc = new TextEncoder();
    const encoded = enc.encode(draw);
    const zlibd = zlibSync(encoded);
    
    if (!NormalDrawingColors.includes(myDrawColor.toUpperCase())) {
      setRecentDrawColors((recent) => {
        const extra = [...recent];
        
        const currentIndex = extra.indexOf(myDrawColor.toUpperCase());
        if (currentIndex >= 0)
          extra.splice(currentIndex, 1);
        
        const temp = extra.slice(0, 4);
        temp.unshift(myDrawColor.toUpperCase());
        return temp;
      })
    }

    if (amGM) {
      setEditingMapData({
        ...editingMapData,
        drawings: {
          ...editingMapData.drawings,
          GM: bufferToString(zlibd),
        }
      })
    } else {
      setPlayerTableState({
        ...playerTableState,
        drawing: bufferToString(zlibd),
        last_updated_drawing: Date.now(),
      })
    }
  }, [amGM, editingMapData, myDrawSource, myDrawColor, setRecentDrawColors]);
  
  // Add styles to drawn features that don't have one.
  useEffect(() => {
    if (!myDrawSource)
      return;
    
    if (trayTwoToolDown.isdown)
      return;
    
    if (trayTwoTool == ToolType.Unselectable)
      return;
    
    const percentageLightening = 170 / 255;

    const substring = myDrawColor.substring(0,7);
    let lightenDecimal = 'aa';
    if (myDrawColor.length == 9) {
      const existingValue = parseInt(myDrawColor.substring(7,9), 16);
      lightenDecimal = Math.ceil(existingValue * percentageLightening).toString(16);
    }
    
    // First update the data on the layer so our draw icon is right.
    let myStyle: any = {
      "circle-radius": 5,
      "circle-fill-color": `${myDrawColor}aa`,
      'stroke-color': myDrawColor,
      'stroke-width': 5,
      'fill-color': `${myDrawColor}aa`,
      "shape-fill-color": `${myDrawColor}aa`,
      "shape-stroke-color": myDrawColor,
      'shape-stroke-width': 5,
    };
    
    allDrawSources.forEach((source) => {
      if (source.isMe && source.layer && source.layer instanceof VectorLayer) {
        source.layer.setStyle(myStyle);
        
        mapRef.current.getInteractions().forEach((interaction) => {
          if (interaction instanceof Draw) {
            const drawInteract = interaction as Draw;
            
            switch (trayTwoTool) {
              case ToolType.Eraser:
              {
                myStyle = {
                  ...myStyle,
                  "circle-stroke-color": 'white',
                  "circle-fill-color": '#ffffffaa',
                  "stroke-color": null,
                  "stroke-width": 0,
                };
                break;
              }
              case ToolType.Fill: {
                myStyle = {
                  ...myStyle,
                  "circle-stroke-color": 'white',
                  "circle-fill-color": '#ffffffaa',
                  "stroke-color": null,
                  "stroke-width": 0,
                };
                break;
              }
            }
            
            drawInteract.getOverlay().setStyle(myStyle);
          }
        })
      }
    });
    
    myDrawSource.forEachFeature((feat) => {
      if (!feat.getStyle())
      {
        feat.setStyle(new Style({
          stroke: new Stroke({
            width: 5,
            color: myDrawColor,
          }),
          fill: new Fill({
            color: `${substring}${lightenDecimal}`,
          })
        }));
      }
    });
  }, [myDrawSource, myDrawColor, trayTwoToolDown, allDrawSources, trayTwoTool]);
  
  const updatingMapData = useCallback((fn: (map: DatabaseMap) => DatabaseMap) => {
    setEditingMapData((previous) => {
      return fn(previous);
    });
  }, [setEditingMapData]);

  // Detect when we lift our mouse up for a drawing and serialize the layer
  useEffect(() => {
    if (!trayTwoTool)
      return;

    if (!myDrawSource)
      return;

    const drawMouseUp = (event: MouseEvent) => {
      if (trayTwoTool == ToolType.Polygon && myDrawInteraction && (myDrawInteraction as any).finishCoordinate_ != undefined)
        return;
      if (trayTwoTool == ToolType.Circle && myDrawInteraction && (myDrawInteraction as any).finishCoordinate_ != null)
        return;
      if (trayTwoTool == ToolType.Eraser) {
        myDrawSource.forEachFeature((feature) => {
          if (feature.getGeometryName() == 'eraser')
            myDrawSource.removeFeature(feature);
        });
      }
      if (trayTwoTool == ToolType.Fill) {
        myDrawSource.forEachFeature((feature) => {
          if (feature.getGeometryName() == 'fill')
            myDrawSource.removeFeature(feature);
        });
      }

      saveChangesToMyLayer();
    };

    window.addEventListener('mouseup', drawMouseUp);

    return () => {
      window.removeEventListener('mouseup', drawMouseUp);
    }
  }, [myDrawSource, trayTwoTool, myDrawInteraction, fillMode, updatingMapData]);

  // Apply new POI changes into desired poi changes (so they can be smoothly rendered)
  useEffect(() => {
    if (!poiCoordinateChanges || Object.keys(poiCoordinateChanges).length == 0)
      return;

    Object.keys(poiCoordinateChanges).forEach((id) => {
      desiredPoiCoordinates.current[id] = {
        ...desiredPoiCoordinates.current[id],
        desiredPosition: poiCoordinateChanges[id],
        followPath: workingMoveArrows[id]?.path,
        pathTimeStamp: workingMoveArrows[id]?.stamp,
      };
      if (desiredPoiCoordinates.current[id].followPath && !moveArrowBeingUsedForMovement.current.has(id))
        moveArrowBeingUsedForMovement.current.add(id);
    });
    setPoiCoordinateChanges(undefined);
    if (mapRef && mapRef.current)
      mapRef.current.render();
  }, [mapRef, poiCoordinateChanges, workingMoveArrows]);

  // Draw a feature when we are in tool mode ruler
  useEffect(() => {
    if (!localDrawSource)
      return;

    if (!mouseOverFeature || trayFocusedTool != ToolType.Ruler) {
      if (localDrawSource.getFeatures().length > 0) {
        localDrawSource.forEachFeature((feat) => {
          const properties = feat.getProperties() ?? {};
          if ('type' in properties && properties['type'] == 'measure')
            localDrawSource.removeFeature(feat);
        });
      }
      return;
    }

    const viewExtent = mapRef.current.getView().calculateExtent();

    const width_grid = editingMapData?.grid_line_spacing ?? 100;
    const multiplierIncrement = width_grid / 250;
    
    const findClosest = (setOne: Coordinate[], setTwo: Coordinate[]): {a?: Coordinate, b?: Coordinate, distanceRaw: number, distanceDisplay: number} => {
      let bestSoFar: {a?: Coordinate, b?: Coordinate, distanceRaw: number, distanceDisplay: number} = {
        distanceRaw: Number.MAX_SAFE_INTEGER,
        distanceDisplay: Number.MAX_SAFE_INTEGER
      };
      for (let i = 0; i < setOne.length; i++) {
        const thisCoord = setOne[i];
        for (let j = 0; j < setTwo.length; j++) {
          const thatCoord = setTwo[j];

          let distanceRaw = Math.sqrt(Math.pow(thatCoord[0] - thisCoord[0], 2) + Math.pow(thatCoord[1] - thisCoord[1], 2));
          
          let displayDist = distanceRaw;
          displayDist /= (editingMapData.grid_line_spacing ?? 100);
          displayDist = Math.ceil(displayDist);
          
          if (distanceRaw < bestSoFar.distanceRaw) {
            bestSoFar = {
              distanceRaw: distanceRaw,
              distanceDisplay: displayDist,
              a: thisCoord,
              b: thatCoord
            }
          }
        }
      }
      return bestSoFar;
    }

    const getOccupiedTiles = (feature: DatabaseMapPoi) => {
      let hoveredFeatureWidthTiles = 1;
      if (feature.font_override) {
        const temp = parseFloat(feature.font_override);
        hoveredFeatureWidthTiles = temp / multiplierIncrement;
      }

      let featureCoordinates = [];
      const even = hoveredFeatureWidthTiles % 2 == 0;
      if (even) {
        // For ruler purposes, we'll always measure from some tile on the outside of our box (i.e. never an interior tile)
        // We can skip these to save processing time
        const radius = Math.floor(hoveredFeatureWidthTiles / 2);
        for (let i = -radius; i <= radius; i += 1) {
          let iOnEdge = true;
          if (i != -radius && i != radius)
            iOnEdge = false;
          if (i == 0)
            continue;
          
          for (let j = -radius; j <= radius; j += 1) {
            let jOnEdge = true;
            if (j != -radius && j != radius)
              jOnEdge = false;
            if (j == 0)
              continue;

            if (!iOnEdge && !jOnEdge)
              continue;
            
            const negatorFactorX = i > 0 ? -1 : 1;
            const negatorFactorY = j > 0 ? -1 : 1;

            featureCoordinates.push([feature.coordinate_x + (i * width_grid + (negatorFactorX * width_grid / 2)), feature.coordinate_y + (j * width_grid + (negatorFactorY * width_grid / 2))]);
          }
        }
      } else {
        const radius = Math.floor(hoveredFeatureWidthTiles / 2);
        // For ruler purposes, we'll always measure from some tile on the outside of our box (i.e. never an interior tile)
        // We can skip these to save processing time
        for (let i = -radius; i <= radius; i += 1) {
          let iOnEdge = true;
          if (i != -radius && i != radius)
            iOnEdge = false;
          for (let j = -radius; j <= radius; j += 1) {
            let jOnEdge = true;
            if (j != -radius && j != radius)
              jOnEdge = false;
            
            if (!iOnEdge && !jOnEdge)
              continue;
            
            featureCoordinates.push([feature.coordinate_x + (i * (width_grid)), feature.coordinate_y + (j * (width_grid))]);
          }
        }
      }
      return featureCoordinates;
    }
    const featureCoordinates = getOccupiedTiles(mouseOverFeature);
    
    let countInRange = 0;
    editingMapData?.pois?.forEach((poi) => {
      if (poi == mouseOverFeature)
        return;
      if (!poi.is_image)
        return;

      if (!containsExtent(viewExtent, [poi.coordinate_x - 1, poi.coordinate_y -1, poi.coordinate_x + 1, poi.coordinate_y + 1]))
        return;
      
      countInRange += 1;
    });
    
    let palette = iwanthue(countInRange);
    let thisIndex = 0;
    editingMapData?.pois?.forEach((poi) => {
      if (poi == mouseOverFeature)
        return;
      if (!poi.is_image)
        return;

      if (!containsExtent(viewExtent, [poi.coordinate_x - 1, poi.coordinate_y -1, poi.coordinate_x + 1, poi.coordinate_y + 1]))
        return;
      
      const myColor = palette[thisIndex++];

      const myOccupiedCells = getOccupiedTiles(poi);
      const closest = findClosest(featureCoordinates, myOccupiedCells);

      const newFeature = new Feature({
        geometry: new LineString([closest.a, closest.b]),
        description: 'placeholder'
      });

      var labelStyle = new Style({
        text: new TextStyle({
          font: '18px Calibri,sans-serif',
          text: `${closest.distanceDisplay * 5} ft`,
          overflow: true,
          fill: new Fill({
            color: myColor
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 3
          })
        })
      });

      const stylesArray = [new Style({
        stroke: new Stroke({
          width: 5,
          color: `${myColor}ff`,
          lineDash: [4,8],
        })
      }), labelStyle];
      newFeature.setStyle(stylesArray);

      newFeature.setProperties({
        styles: stylesArray,
        type: 'measure'
      });

      localDrawSource.addFeature(newFeature);
    });
  }, [mapRef, localDrawSource, trayFocusedTool, mouseOverFeature]);

  // Draw a label when we are hovering over a feature
  useEffect(() => {
    if (!localDrawSource)
      return;
    
    const tickLabelText = setInterval(() => {
      let matchingPoi: DatabaseMapPoi = featureMoveRef.current?.poi ?? mouseOverFeature ?? undefined;
      let shouldHaveId = matchingPoi?.poi_id;
      
      // Basically, if we are only hovering over something -- we want to show the display name after
      let delayToShow = featureMoveRef.current === undefined;
      
      // If's not an image, we don't want to make an icon for it.
      if (matchingPoi && !matchingPoi.is_image) {
        matchingPoi = undefined;
        shouldHaveId = undefined;
      }

      // Get the text we should display for this label
      let displayName = matchingPoi?.map_text;
      if (featureMoveArrow.current && featureMoveArrow.current.length > 0 && shouldHaveId in desiredPoiCoordinates.current && featureMoveRef.current?.poi == matchingPoi) {
        let totalCost: number = 0;

        for (let i = 0; i < featureMoveArrow.current.length; i++) {
          const thisLeg = featureMoveArrow.current[i];
          const thisMoveRangeKey = toMoveRangeKey(thisLeg.endPoint ?? desiredPoiCoordinates.current[shouldHaveId].desiredPosition);
          const pointInGeom = thisLeg.cost[thisMoveRangeKey];
          totalCost += pointInGeom.cost;
        }
        displayName = `${totalCost * 5}ft`;
      } else {
        if (matchingPoi) {
          if (matchingPoi.map_text.startsWith('svg')) {
            const svgSettings: SvgPathDrawSettings = JSON.parse(matchingPoi.map_text.substring(3)) as SvgPathDrawSettings;
            displayName = `${svgSettings.iconName}${svgSettings.extraIdentifier ? ` ${svgSettings.extraIdentifier}` : ''}`;
          }
          else {
            const matchingToken = originalGameTokens.find((token) => token.token_id == matchingPoi.token_id);
            if (matchingToken) {
              displayName = matchingToken.display_name;
            } else {
              const playerData = playersList.find((player) => matchingPoi.user_id == player.user_id);
              if (playerData) {
                displayName = `${playerData.character_name} (${playerData.player_name})`;
              }
            }
          }
        }
      }
      
      // Get our new point
      let newLabelPoint = [0, 0];
      if (mouseOverFeature)
        newLabelPoint = [mouseOverFeature.coordinate_x, mouseOverFeature.coordinate_y + (parseFloat(mouseOverFeature.font_override ?? '0.5') * 200)];
      if (featureMoveRef.current) {
        if (shouldHaveId in desiredPoiCoordinates.current) {
          const desired = desiredPoiCoordinates.current[shouldHaveId].currentPosition;
          newLabelPoint = [desired[0], desired[1] + (parseFloat(featureMoveRef.current.poi.font_override ?? '0.5') * 200)];
        }
      }
      
      let foundThisOne = false;
      
      // Go through the existing features and see if there are existing labels. Fade in and out appropriately. 
      localDrawSource.forEachFeature((feat) => {
        const properties = feat.getProperties();
        if ('type' in properties) {
          const thisType = properties['type'] as string;
          if (thisType.startsWith('label')) {
            let shouldBeVisible = thisType.includes(shouldHaveId);
            
            if (shouldBeVisible)
              foundThisOne = true;
            
            if (!properties.lastTick)
              properties.lastTick = Date.now();
            
            if (shouldBeVisible) {
              const startPoint = properties.startPoint as Coordinate;
              feat.getGeometry().translate(newLabelPoint[0] - startPoint[0], newLabelPoint[1] - startPoint[1]);
              properties.startPoint = newLabelPoint;
            }
              
            const timeNow = Date.now();
            const deltaTime = (timeNow - properties.lastTick) / 1000;
            
            if (shouldBeVisible) {
              if (delayToShow) {
                properties.visibleFor = (properties.visibleFor ?? 0) + deltaTime;
                if (properties.visibleFor > HoverForMillisecondsToShowLabel)
                  properties.desiredAlpha = 1;
                else
                  properties.desiredAlpha = 0;
              } else
                properties.desiredAlpha = 1;
            } else
              properties.desiredAlpha = 0;
              
            const damped = smoothDamp({
              current: properties.currentAlpha as number ?? 0,
              target: properties.desiredAlpha as number,
              inVelocity: properties.fadeVelocity as number ?? 0,
              smoothTime: 0.1,
              deltaTime: deltaTime,
              maxSpeed: Number.MAX_VALUE,
            });
            properties.fadeVelocity = damped.velocityOut;
            properties.currentAlpha = damped.damped;
            properties.lastTick = timeNow;

            const fadedStroke = '#ffffff' + Math.max(Math.round(properties.currentAlpha * 255), 1).toString(16).padStart(2, '0');
            const fadedFill = '#000000' + Math.max(Math.round(properties.currentAlpha * 255), 1).toString(16).padStart(2, '0');

            if (shouldBeVisible)
              properties.text = displayName;
            
            feat.setStyle(new Style({
              text: new TextStyle({
                font: '18px Calibri,sans-serif',
                text: properties.text,
                overflow: true,
                fill: new Fill({
                  color: fadedFill
                }),
                stroke: new Stroke({
                  color: fadedStroke,
                  width: 3
                })
              })
            }));
            
            if (!shouldBeVisible && properties.desiredAlpha == 0 && properties.currentAlpha < 0.05) {
              localDrawSource.removeFeature(feat);
            } else
              feat.setProperties(properties);
          }
        }
      })
      
      if (!foundThisOne && shouldHaveId) {
        const myType = `label${shouldHaveId}`;
        
        const labelFeature = new Feature({
          geometry: new Point(newLabelPoint, 'XY'),
          description: 'label',
        });
        labelFeature.setProperties({
          type: myType,
          text: displayName,
          currentAlpha: 0,
          startPoint: newLabelPoint
        });
        labelFeature.setStyle(new Style({
          text: new TextStyle({
            font: '18px Calibri,sans-serif',
            text: displayName,
            overflow: true,
            fill: new Fill({
              color: '#00000000'
            }),
            stroke: new Stroke({
              color: '#ffffff00',
              width: 3
            })
          })
        }));
        localDrawSource.addFeature(labelFeature);
      }
    }, 10);
    
    return () => {
      clearInterval(tickLabelText);
    }
  }, [mapRef, localDrawSource, mouseOverFeature, playersList, originalGameTokens]);
  
  // Fade out the drawn movement paths quickly after use
  useEffect(() => {
    if (!localDrawSource)
      return;
    
    const tickMoveArrowFade = setInterval(() => {
      localDrawSource.forEachFeature((feature) => {
        const props = feature.getProperties();
        if (!props || props['type'] != 'move-range-wip')
          return;
        
        // This is a move range feature. Check if we should fade it.
        const poiId = props['poi_id'];
        if (!(poiId in moveArrowFadeState.current))
          return;
        
        const currentFade = moveArrowFadeState.current[poiId];
        if (!currentFade.started)
          currentFade.started = Date.now();
        
        const fadeAmount = 1 - Math.min(1, (Date.now() - currentFade.started) / 500);
        
        const fadedStroke = '#000000' + Math.max(Math.round(fadeAmount * 255), 1).toString(16).padStart(2, '0');
        const fadedFill = '#000000' + Math.max(Math.round(fadeAmount * 177), 1).toString(16).padStart(2, '0');

        feature.setStyle(new Style({
          stroke: new Stroke({
            color: fadedStroke,
            width: 5,
          }),
          fill: new Fill({
            color: fadedFill,
          }),
        }));
        
        moveArrowFadeState.current[poiId] = currentFade;
        
        if (fadeAmount <= 0) {
          if (amGM) {
            setWorkingMoveArrows((previous) => {
              const newOne = {...previous};
              if (poiId in newOne)
                delete newOne[poiId];
              return newOne;
            });
          }
          currentFade.safeToDelete = true;
        }
        
        if (fadeAmount <= 0 && currentFade.safeToDelete) {
          localDrawSource.removeFeature(feature);
          delete moveArrowFadeState.current[poiId];
          if (moveArrowBeingUsedForMovement.current.has(poiId))
            moveArrowBeingUsedForMovement.current.delete(poiId);
        }
      });
    }, 10);
    
    return () => clearInterval(tickMoveArrowFade);
  }, [localDrawSource, amGM]);

  // Fade out drawn features if you are not the GM
  useEffect(() => {
    const tickFeatures = setInterval(() => {
      const fadeTrackers = drawSourceFadeTrackers.current;
      let anyFadeTrackerChange = false;
      let myStateChanged = false;
      allDrawSources.forEach((parsing) => {
        // In full release, don't fade the GMs markings.
        if (parsing.isGM)
          return;

        const thisPersonsTrackers = fadeTrackers[parsing.id] ?? {};
        let anyPersonTrackerChange = false;
        let featureCount = parsing.source.getFeatures().length;
        const startingFeatureCount = featureCount;
        parsing.source.forEachFeature((feat) => {
          // Can only fade drawn features we know the ID of.
          if (feat.hasProperties() && feat.getProperties()['id']) {
            const drawingId: string = feat.getProperties()['id'] as string;

            if (!(drawingId in thisPersonsTrackers) || !thisPersonsTrackers[drawingId].started) {
              thisPersonsTrackers[drawingId] = {
                started: Date.now(),
              };
              anyPersonTrackerChange = true;
            }
  
            let started = thisPersonsTrackers[drawingId].started;

            const updateCount: number = feat.getProperties()['updates'];
            if (updateCount) {
              if (!thisPersonsTrackers[drawingId].lastUpdated) {
                // We didn't know this drawing had been updated, so track it now.
                thisPersonsTrackers[drawingId] = {
                  ...thisPersonsTrackers[drawingId],
                  lastUpdated: Date.now(),
                  lastUpdateCount: updateCount,
                }
                anyPersonTrackerChange = true;
                started = thisPersonsTrackers[drawingId].lastUpdated;
              } else if (thisPersonsTrackers[drawingId].lastUpdateCount && thisPersonsTrackers[drawingId].lastUpdateCount != updateCount) {
                thisPersonsTrackers[drawingId] = {
                  ...thisPersonsTrackers[drawingId],
                  lastUpdated: Date.now(),
                  lastUpdateCount: updateCount,
                }
                anyPersonTrackerChange = true;
                started = thisPersonsTrackers[drawingId].lastUpdated;
              } else if (thisPersonsTrackers[drawingId].lastUpdated) {
                started = thisPersonsTrackers[drawingId].lastUpdated;
              }
            }

            const now = Date.now();
  
            if (now - started < fadeAfter) {
              // It's been less than 5 seconds, do nothing.
              return;
            }

            const hasStyle = feat.getProperties()['style'];
            const intendedColor = hasStyle ? hasStyle['color'] : undefined;
            const playerBaseColor = intendedColor ?? (tablePlayersList.find((item) => item.user_id == parsing.id)?.border_color ?? '#000000');

            let defaultStyle = {
              "circle-radius": 5,
              "circle-fill-color": `${playerBaseColor}aa`,
              'stroke-color': playerBaseColor,
              'stroke-width': 5,
              'fill-color': `${playerBaseColor}aa`,
              "shape-fill-color": `${playerBaseColor}aa`,
              "shape-stroke-color": playerBaseColor,
              'shape-stroke-width': 5,
            };
            // Else fade out over a period of 1 second
            const fadePercent = 1 + (Math.min(1, ((now - started) - fadeAfter) / (fadeDuration)) * -1);
            const fadedFillColor = Math.round(170 + (Math.min(1, ((now - started) - fadeAfter) / (fadeDuration)) * -150));
  
            const fadeStrokeColor = defaultStyle["stroke-color"].substring(0,7) + Math.max(Math.round(fadePercent * 255), 1).toString(16).padStart(2, '0');
            const fadeFillColor = defaultStyle["fill-color"].substring(0,7) + Math.max(fadedFillColor, 1).toString(16).padStart(2, '0');
  
            feat.setStyle(new Style({
              stroke: new Stroke({
                color: fadeStrokeColor,
                width: 5,
              }),
              fill: new Fill({
                color: fadeFillColor,
              }),
            }));
  
            if (fadePercent == 0 && parsing.isMe) {
              if (fillCellFeature && (fillCellFeature.getProperties()['id'] as string ?? '') == drawingId) {
                // If our fill thing fades out while we are working clear our building list.
                setFillCellFeature(undefined);
                setBuildingFillCells([]);
              }
              parsing.source.removeFeature(feat);
              featureCount -= 1;
              myStateChanged = true;
            }
          }
        });

        if (featureCount == 0) {
          if (featureCount != startingFeatureCount) {
            setEditingMapData((old) => {
              const toReturnDrawings = {
                ...old.drawings
              };

              delete toReturnDrawings[parsing.id];

              return {
                ...old,
                drawings: toReturnDrawings
              };
            });
            
            delete fadeTrackers[parsing.id];
            anyFadeTrackerChange = true;
          }
        } else if (anyPersonTrackerChange) {
          fadeTrackers[parsing.id] = thisPersonsTrackers;
          anyFadeTrackerChange = true;
        }
      });
      if (anyFadeTrackerChange)
        fadeTrackers.current = fadeTrackers;
      if (myStateChanged) {
        saveChangesToMyLayer();
      }
    }, 10);

    return () => {
      window.clearInterval(tickFeatures);
    }
    
  }, [allDrawSources, setEditingMapData]);
  
  // Detect tool change and reset mouse down.
  useEffect(() => {
    setTrayTwoToolDown({isdown: false, when: 0});
  }, [trayTwoTool, trayFocusedTool]);

  // Detect tool down
  useEffect(() => {
    if (!trayTwoTool)
      return;

    if (!myDrawSource)
      return;

    const mouseDown = () => {
      setTrayTwoToolDown({isdown: true, when: Date.now()});
    };

    const mouseUp = () => {
      setTrayTwoToolDown({isdown: false, when: 0});
      
      setBuildingFillCells([]);
      setFillCellFeature(undefined);
    };

    window.addEventListener('mousedown', mouseDown);
    window.addEventListener('mouseup', mouseUp)

    return () => {
      window.removeEventListener('mousedown', mouseDown);
      window.removeEventListener('mouseup', mouseUp);
    }
  }, [myDrawSource, trayTwoTool, myDrawInteraction, setTrayTwoToolDown]);

  // Allow commands to force update the drawing states.
  useEffect(() => {
    if (!forceMapDrawingSync)
      return;

    saveChangesToMyLayer();
    setForceMapDrawingSync(false);
  }, [forceMapDrawingSync]);

  // Detect eraser
  useEffect(() => {
    if (!trayTwoTool)
      return;

    if (!myDrawSource)
      return;

    if (trayTwoTool != ToolType.Eraser)
      return;

    if (!trayTwoToolDown.isdown)
      return;

    const detectFeatureToErase = (event: MouseEvent) => {
      if (mapRef.current) {
        const result = mapRef.current.getCoordinateFromPixel([event.clientX, event.clientY]);
        if (result) {
          myDrawSource.forEachFeatureAtCoordinateDirect(result, (feat) => {
            if (feat.getGeometryName() == 'eraser')
              return;

            myDrawSource.removeFeature(feat);
          });

          myDrawSource.forEachFeature((feat) => {
            const lineGeometry = feat.getGeometry() as LineString;
            if (lineGeometry) {
              const myPoint = lineGeometry.getClosestPoint(result);
              const distance = Math.sqrt(Math.pow(result[0] - myPoint[0], 2) + Math.pow(result[1] - myPoint[1], 2));
              if (distance < 25)
                myDrawSource.removeFeature(feat);
            }
          })
        }
      }
    }

    window.addEventListener('mousemove', detectFeatureToErase);

    return () => {
      window.removeEventListener('mousemove', detectFeatureToErase);
    }
  }, [myDrawSource, trayTwoTool, myDrawInteraction, trayTwoToolDown]);

  // Track the fill tool
  useEffect(() => {
    if (!trayTwoTool)
      return;

    if (!myDrawSource)
      return;

    if (trayTwoTool != ToolType.Fill)
      return;

    if (!trayTwoToolDown.isdown)
      return;

    const detectSquareToFill = (event: MouseEvent) => {
      if (mapRef.current) {
        let result = mapRef.current.getCoordinateFromPixel([event.clientX, event.clientY]);
        if (result) {
          // We should be dragging to fill the closest grid square
          const width_grid = editingMapData.grid_line_spacing ?? 100;
          const paddingLeft = editingMapData.grid_padding_left ?? 0;
          const paddingTop = editingMapData.grid_padding_top?? 0;

          const currentScaleFactor = 1;

          let gridX = (result[0] - paddingLeft) / (width_grid * currentScaleFactor);
          let gridY = (result[1] + paddingTop) / (width_grid * currentScaleFactor);
  
          gridX = Math.floor(gridX * currentScaleFactor) / currentScaleFactor;
          gridY = Math.floor(gridY * currentScaleFactor) / currentScaleFactor;
  
          result = [(gridX + 0.5) * (width_grid * currentScaleFactor) + paddingLeft, (gridY + 0.5) * (width_grid * currentScaleFactor) - paddingTop];

          let found = false;
          for (let i = 0; i < buildingFillCells?.length ?? 0; i += 1) {
            if (buildingFillCells[i][0] == result[0] && buildingFillCells[i][1] == result[1]) {
              found = true;
              break;
            }
          }

          if (!found) {
            const newOnes = [...(buildingFillCells ?? []), result];
            setBuildingFillCells(newOnes);
          }
        }
      }
    }

    window.addEventListener('mousemove', detectSquareToFill);

    return () => {
      window.removeEventListener('mousemove', detectSquareToFill);
    }
  }, [myDrawSource, trayTwoTool, myDrawInteraction, trayTwoToolDown, buildingFillCells]);

  // Draw the feature for the highlighted cells
  useEffect(() => {
    if (!buildingFillCells)
      return;

    if (!trayTwoTool) {
      if (buildingFillCells && buildingFillCells.length > 0) {
        setBuildingFillCells([]);
        setFillCellFeature(undefined);
      }
      return;
    }

    if (!myDrawSource)
      return;

    if (trayTwoTool != ToolType.Fill) {
      if (buildingFillCells && buildingFillCells.length > 0) {
        setBuildingFillCells([]);
        setFillCellFeature(undefined);
      }
      return;
    }

    if (!trayTwoToolDown.isdown) {
      if (buildingFillCells && buildingFillCells.length > 0) {
        setBuildingFillCells([]);
        setFillCellFeature(undefined);
      }
      return;
    }
    
    // Require longer than a 300ms click to draw a thing.
    if (Date.now() - trayTwoToolDown.when < 50)
      return;

    const width_grid = editingMapData.grid_line_spacing ?? 100;
    const lineWidth = editingMapData.grid_line_width ?? 2;
    const buildGeometryForSelected = () => {
      const x = buildingFillCells.map((coord) => ([
        coord, 
        [coord[0] + width_grid, coord[1]], 
        [coord[0], coord[1] + width_grid], 
        [coord[0] + width_grid, coord[1] + width_grid]
      ])).flat().map((coords) => ([Math.round(coords[0] - width_grid / 2) + lineWidth, Math.round(coords[1] - width_grid / 2) - Math.round(lineWidth / 2)]));
      if (!x || x.length == 0)
        return null;
      var points = concaveman(x, .5, Math.round(width_grid / 2));
      return new Polygon([points.map((set) => ([set[0], set[1]]) as Coordinate)]);
    };

    let foundFeature: Feature = fillCellFeature;
    if (!foundFeature) {
      const result = buildGeometryForSelected();

      if (!result)
        return;

      foundFeature = new Feature({
        geometry: buildGeometryForSelected(),
      });
      
      if (fillMode == 'unwalkable') {
        foundFeature.setProperties({
          type: 'unwalkable',
          tiles: buildingFillCells
        });
        
        foundFeature.setStyle(new Style({
          stroke: new Stroke({
            width: 5,
            color: 'rgba(0,0,0,0.63)',
          }),
          fill: new FillPattern(
            {
              pattern: "hatch",
              ratio: 1,
              color: 'yellow',
              offset: 0,
              scale: 2,
              fill: new Fill({ color: "#000000aa" }),
              size: 2,
              spacing: 10,
              angle: 45
            })
        }))
      }
      
      myDrawSource.addFeature(foundFeature);
      setFillCellFeature(foundFeature);
    } else {
      const result = buildGeometryForSelected();

      if (!result)
        return;

      foundFeature.setGeometry(result);
      
      if (fillMode == 'unwalkable') {
        foundFeature.setProperties({
          ...foundFeature.getProperties(),
          tiles: buildingFillCells,
          updates: (foundFeature.getProperties()['updates'] ?? 0) + 1,
        });
      } else {
        foundFeature.setProperties({
          ...foundFeature.getProperties(),
          updates: (foundFeature.getProperties()['updates'] ?? 0) + 1,
        });
      }
    }
  }, [buildingFillCells, fillCellFeature, myDrawSource, fillMode]);
  
  // Save unwalkable tool
  useEffect(() => {
    if (!myDrawSource)
      return;
    
    if (trayFocusedTool != ToolType.Draw || (trayTwoTool != ToolType.Eraser && !(trayTwoTool == ToolType.Fill && fillMode == 'unwalkable'))) {
      let anyFound = false;
      const allUnwalkable: Coordinate[][] = [];
      
      // Hide all the features related to unwalkable
      const features = myDrawSource.getFeatures();
      for (let i = features.length - 1; i >= 0; i--) {
        const thisFeature = features[i];
        const props = thisFeature.getProperties();
        if (props && props['type'] == 'unwalkable')
        {
          anyFound = true;
          if (props['tiles']) {
            allUnwalkable.push(props['tiles']);
          }
          // Remove the unwalkable feature
          myDrawSource.removeFeature(thisFeature);
        }
      }
      
      if (fillMode != 'unwalkable' && !anyFound)
        return;
      
      setEditingMapData((previous) => ({
        ...previous,
        unwalkable: JSON.stringify(allUnwalkable)
      }));
      setFillMode('fill');
      return;
    }
    
    if (trayFocusedTool == ToolType.Draw && trayTwoTool == ToolType.Fill && fillMode == 'unwalkable') {
      // Get state from map data but don't use it in updates.
      setEditingMapData((previous) => {
        // TODO load from previous unwalkable.
        const unwalkableCoords = JSON.parse(previous.unwalkable) as Coordinate[][];
        
        const width_grid = editingMapData.grid_line_spacing ?? 100;
        const lineWidth = editingMapData.grid_line_width ?? 2;
        const buildGeometryForSelected = (coords: Coordinate[]) => {
          const x = coords.map((coord) => ([
            coord,
            [coord[0] + width_grid, coord[1]],
            [coord[0], coord[1] + width_grid],
            [coord[0] + width_grid, coord[1] + width_grid]
          ])).flat().map((coords) => ([Math.round(coords[0] - width_grid / 2) + lineWidth, Math.round(coords[1] - width_grid / 2) - Math.round(lineWidth / 2)]));
          if (!x || x.length == 0)
            return null;
          var points = concaveman(x, .5, Math.round(width_grid / 2));
          return new Polygon([points.map((set) => ([set[0], set[1]]) as Coordinate)]);
        };
        
        unwalkableCoords.forEach((unwalkable_feature) => {
          const data = buildGeometryForSelected(unwalkable_feature);
          
          const newFeature = new Feature({
            geometry: data
          });
          newFeature.setProperties({
            type: 'unwalkable',
            tiles: unwalkable_feature,
          });

          newFeature.setStyle(new Style({
            stroke: new Stroke({
              width: 5,
              color: 'rgba(0,0,0,0.63)',
            }),
            fill: new FillPattern(
              {
                pattern: "hatch",
                ratio: 1,
                color: 'yellow',
                offset: 0,
                scale: 2,
                fill: new Fill({ color: "#000000aa" }),
                size: 2,
                spacing: 10,
                angle: 45
              })
          }));
          
          myDrawSource.addFeature(newFeature);
        });

        return previous;
      })
    }
  }, [myDrawSource, trayTwoTool, trayFocusedTool, setEditingMapData, fillMode]);

  // Set the background image 
  useEffect(() => {
    if (backgroundLayer && session && editingMapData) {
      backgroundLayer.setSource(
        new Static({
          url: `https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${editingMapData.owner_id}/${editingMapData.background_image}`,
          imageExtent: [0, 0, editingMapData.projection_x, editingMapData.projection_y]
        })
      );
    }
  }, [editingMapData, session, backgroundLayer]);

  // Track window resize
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions([window.innerWidth, window.innerHeight]);
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // Detect mouse over feature.
  useEffect(() => {
    const detectPopoup = (event: MouseEvent) => {
      if (anyModalOpen)
        return;

      // console.log(myDrawInteraction);
      if (mapRef.current && staticSizeFeatureLayers) {
        window.localStorage.setItem('last-zoom', mapRef.current.getView().getZoom().toString());
        window.localStorage.setItem('last-map-id', editingMapId);
        window.localStorage.setItem('last-center', JSON.stringify(mapRef.current.getView().getCenter()));

        const result = mapRef.current.getCoordinateFromPixel([event.clientX, event.clientY]);
        if (result) {
          const [coordX, coordY] = result;

          let anyFound = false;
          let bestSoFar = Number.MAX_SAFE_INTEGER;

          staticSizeFeatureLayers?.forEach((layer: Layer) => {
            const [xMin, yMin, xMax, yMax] = layer.getExtent();

            if (coordX >= xMin && coordX <= xMax && coordY >= yMin && coordY <= yMax) {
              // const myPriority = imageLayer.values_.name === "low-priority" ? 0 : (imageLayer == window.hoveringGeoImagePopup ? 10 : 5);
              // // If we drag our geo image over another one, we want to keep the one we were already interacting with before
        
              // if (myPriority > foundPriority) {
              //   found = imageLayer;
              //   foundPriority = myPriority;
              // }
              const valueData = (layer as any).values_?.data;
              if (valueData && valueData as DatabaseMapPoi && !anyModalOpen) {
                const mapPoi = valueData as DatabaseMapPoi;
                if (mapPoi.hidden && viewAsPlayer)
                  return;

                const distance = Math.pow(coordX - ((xMax + xMin) / 2), 2) + Math.pow(coordY - ((yMax + yMin) / 2), 2);
                if (distance >= bestSoFar)
                  return;

                bestSoFar = distance;
                setMouseOverFeature(mapPoi);

                let pixelCoords = mapRef.current.getPixelFromCoordinate([(xMax + xMin) / 2, yMax]);

                setMouseOverFeatureRefPoint(pixelCoords);

                // From center, add this to get our ymax
                setMouseOverFeatureRefPointOffset(yMax - ((yMax + yMin) / 2));
                anyFound = true;
              }
            }
          });
          
          // The GM can hover over requested move arrows and approve or deny them.
          if (amGM && localDrawSource) {
            let anyMoveRange: RequestedMovement | undefined;
            let bestSoFar: number = Infinity;

            localDrawSource.forEachFeature((feature) => {
              const featProps = feature.getProperties();
              if (!featProps || featProps['type'] != 'move-range-wip')
                return;
              
              if (feature.getGeometry().containsXY(coordX, coordY)) {
                const [xMin, yMin, xMax, yMax] = feature.getGeometry().getExtent();
                const distance = Math.pow(coordX - ((xMax + xMin) / 2), 2) + Math.pow(coordY - ((yMax + yMin) / 2), 2);

                if (distance < bestSoFar) {
                  anyMoveRange = {
                    poi_id: featProps['poi_id'],
                    path: featProps['path']
                  };
                  bestSoFar = distance;
                }
              }
            });
            
            if (mouseOverMovementApproval != anyMoveRange) {
              setMouseOverMovementApproval(anyMoveRange);
            }
          }

          // Can only hover your own drawings
          if (myDrawSource) {
            let anyDrawFeature: Feature | undefined;
            let bestSoFar: number = Infinity;
          
            myDrawSource.forEachFeature((feature) => {
              if (!anyDrawFeature && feature.getGeometry().containsXY(coordX, coordY)) {
                const [xMin, yMin, xMax, yMax] = feature.getGeometry().getExtent();
                const distance = Math.pow(coordX - ((xMax + xMin) / 2), 2) + Math.pow(coordY - ((yMax + yMin) / 2), 2);

                if (distance < bestSoFar) {
                  anyDrawFeature = feature;
                  bestSoFar = distance;
                }
              }
            });

            myDrawSource.forEachFeature((feat) => {
              const lineGeometry = feat.getGeometry() as LineString;
              if (lineGeometry) {
                const myPoint = lineGeometry.getClosestPoint(result);
                const distance = Math.sqrt(Math.pow(result[0] - myPoint[0], 2) + Math.pow(result[1] - myPoint[1], 2));
                if (distance < 25 && distance < bestSoFar) {
                  anyDrawFeature = feat;
                  bestSoFar = distance;
                }
              }
            })

            if (hoverDrawFeature != anyDrawFeature) {
              setHoverDrawFeature(anyDrawFeature);
            }
          }

          if (!anyFound && mouseOverFeature) {
            setMouseOverFeature(undefined);
            setMouseOverFeatureRefPoint(undefined);
            setMouseOverFeatureRefPointOffset(undefined);
          }
        }
      }
    };

    window.addEventListener("mousemove", detectPopoup);
    window.addEventListener('mousedown', handleMapMouseDownMoveImages);
    window.addEventListener('mouseup', handleMouseUpMove);

    if (mapRef.current)
      mapRef.current.on('click', handleMapClick);

    return () => {
      window.removeEventListener("mousemove", detectPopoup);
      window.removeEventListener('mousedown', handleMapMouseDownMoveImages);
      window.removeEventListener('mouseup', handleMouseUpMove);

      if (mapRef.current)
        mapRef.current.un('click', handleMapClick);
    }
  }, [anyModalOpen, editingMapData, map, staticSizeFeatureLayers, windowDimensions, anyModalOpen, mouseOverFeature, mouseOverFeatureRefPoint, mouseOverFeatureRefPointOffset, myDrawSource, amGM, localDrawSource, mouseOverMovementApproval]);

  // Handle zoom in animations from other maps.
  useEffect(() => {
    if (!currentZoomFromOtherMap)
      return;

    if (!editingMapData || !editingMapId)
      return;

    if (editingMapId != currentZoomFromOtherMap.map_id)
      return;

    if (editingMapData.map_id != currentZoomFromOtherMap.map_id)
      return;

    if (!mapReadyForDisplay)
      return;

    if (mapRef && mapRef.current) {
      const duration = 500;
      setTimeout(() => {
        mapRef.current.getView().animate(
          {
            center: currentZoomFromOtherMap.toCoordinate,
            duration: duration,
          },
          () => {}
        );
        mapRef.current.getView().animate(
          {
            zoom: currentZoomFromOtherMap.zoom,
            duration: duration,
          },
          () => {}
        );
      }, 300);
      setCurrentZoomFromOtherMap(undefined);
    }
  }, [editingMapData, editingMapId, currentZoomFromOtherMap, mapReadyForDisplay]);

  // Change the cursor depending on what we hover over.
  useEffect(() => {
    if (mouseOverFeature && !anyModalOpen) {
      let thingsItDoes = 0;

      if (mouseOverFeature.links_to_map)
        thingsItDoes += 1;

      if (mouseOverFeature.has_description)
        thingsItDoes += 1;

      if (mouseOverFeature.is_image && (amGM || (false && mouseOverFeature.map_text.includes(session.user.id))))
        document.body.style.cursor = 'move';
      else if (thingsItDoes === 0)
        document.body.style.cursor = 'context-menu';
      else
        document.body.style.cursor = 'pointer';
    }
    else
      document.body.style.cursor = 'default';
  }, [mouseOverFeature, amGM, session, anyModalOpen]);
  
  // Register breaking up dragging path into legs with space key, and also UNDOING a break if you press it on the start coord. 
  useEffect(() => {
    const pressedSpace = (e: KeyboardEvent) => {
      if (e.key != ' ')
        return;
      
      if (!editingMapData.initiative)
        return;
      
      let myInitiative: InitiativeSpot = undefined;
      let nowPoint: Coordinate = undefined;
      
      if (featureMoveRef.current?.poi?.poi_id) {
        myInitiative = editingMapData.initiative.spots.find((spot) => spot.poi_id == featureMoveRef.current.poi.poi_id)
        nowPoint = desiredPoiCoordinates.current[featureMoveRef.current.poi.poi_id].desiredPosition;
      }
      
      if (featureMoveArrow.current) {
        
        // Check if we are on the start point of our latest leg.
        let lastOne = featureMoveArrow.current[featureMoveArrow.current.length - 1];
        
        if (toMoveRangeKey(nowPoint) == toMoveRangeKey(lastOne.startPoint)) {
          // Dont do anything if we don't have more than 1 leg.
          if (featureMoveArrow.current.length <= 1)
            return;
          
          featureMoveArrow.current.pop();
          lastOne = featureMoveArrow.current[featureMoveArrow.current.length - 1];
          
          let costUpToLastPoint = 0;
          let lastDiagonalBit = 1;
          for (let i = 0; i < featureMoveArrow.current.length - 1; i++) {
            const thisPriorLeg = featureMoveArrow.current[i];
            const priorPoint = thisPriorLeg.cost[toMoveRangeKey(thisPriorLeg.endPoint ?? nowPoint)];
            costUpToLastPoint += priorPoint.cost;
            lastDiagonalBit = priorPoint.diagonalBit;
          }
          
          lastOne.endPoint = undefined;
          lastOne.tempEndPoint = nowPoint;
          
          // Remove old range draw feature
          localDrawSource.forEachFeature((feat) => {
            const props = feat.getProperties() ?? {};
            if ('type' in props && props['type'] == 'range')
              localDrawSource.removeFeature(feat);
          });

          const cost = drawMovementRange(localDrawSource, lastOne.startPoint, (myInitiative?.movement ?? 6) - costUpToLastPoint, true, lastDiagonalBit);
          lastOne.cost = cost;
          featureMoveArrow.current[featureMoveArrow.current.length - 1] = lastOne;
          
          return;
        }
        
        let costSoFar = 0;
        for (let i = 0; i < featureMoveArrow.current.length; i++) {
          const thisPriorLeg = featureMoveArrow.current[i];
          costSoFar += thisPriorLeg.cost[toMoveRangeKey(thisPriorLeg.endPoint ?? nowPoint)].cost;
        }

        if (costSoFar == (myInitiative?.max_movement ?? 6))
          return;
        
        // Split this into a new leg with it's own movement range (redrawing the first move range
        // with remaining movement);
        lastOne.endPoint = lastOne.tempEndPoint;
        featureMoveArrow.current[featureMoveArrow.current.length - 1] = lastOne;
        
        // Remove old range draw feature
        localDrawSource.forEachFeature((feat) => {
          const props = feat.getProperties() ?? {};
          if ('type' in props && props['type'] == 'range')
            localDrawSource.removeFeature(feat);
        });
        
        // Add a new arrow for next leg
        const newArrow = new Feature({
          name: 'movearrow'
        });
        newArrow.setProperties({
          type: 'movearrow'
        });
        newArrow.setStyle(new Style({
          stroke: new Stroke({
            color: '#000000',
            width: 5,
          }),
          fill: new Fill({
            color: '#000000aa',
          }),
        }));
        localDrawSource.addFeature(newArrow);
        
        const cost = drawMovementRange(localDrawSource, lastOne.tempEndPoint, (myInitiative?.movement ?? 6) - costSoFar, true, lastOne?.cost[toMoveRangeKey(lastOne.tempEndPoint)]?.diagonalBit ?? 1);
        featureMoveArrow.current.push({
          feature: newArrow,
          startPoint: lastOne.tempEndPoint,
          cost: cost,
        });
      }
    }
    
    window.addEventListener('keydown', pressedSpace);

    return () => {
      window.removeEventListener('keydown', pressedSpace);
    }
  }, [localDrawSource, editingMapData]);

  // Handle moving features
  useEffect(() => {
    if (!featureMoveRef || !featureMoveRef.current)
      return;

    const handleMouseMove = (event: MouseEvent) => {
      if (!mapRef || !mapRef.current)
        return;

      if (!featureMoveRef || !featureMoveRef.current)
        return;
        
      document.body.style.cursor = 'move';

      const point = [event.clientX, event.clientY];
      let newCoord = mapRef.current.getCoordinateFromPixel(point);

      newCoord[0] += moveFeatureOffsetCoordinate[0];
      newCoord[1] += moveFeatureOffsetCoordinate[1];

      if (editingMapData && editingMapData.has_grid && featureMoveRef.current && featureMoveRef.current.poi.is_image) {
        // We should be dragging to fill the closest grid square
        const width_grid = editingMapData.grid_line_spacing ?? 100;
        const paddingLeft = editingMapData.grid_padding_left ?? 0;
        const paddingTop = editingMapData.grid_padding_top ?? 0;

        const basedUpon = 250;

        const multiplierIncrement = width_grid / basedUpon;

        let currentMultiplier = multiplierIncrement;
        if (featureMoveRef.current && featureMoveRef.current.poi.font_override)
          currentMultiplier = parseFloat(featureMoveRef.current.poi.font_override);

        let currentScaleFactor = currentMultiplier / multiplierIncrement;

        let gridX = (newCoord[0] - paddingLeft) / (width_grid * currentScaleFactor);
        let gridY = (newCoord[1] + paddingTop) / (width_grid * currentScaleFactor);

        gridX = Math.floor(gridX * currentScaleFactor) / currentScaleFactor;
        gridY = Math.floor(gridY * currentScaleFactor) / currentScaleFactor;

        newCoord = [(gridX + 0.5) * (width_grid * currentScaleFactor) + paddingLeft, (gridY + 0.5) * (width_grid * currentScaleFactor) - paddingTop];
      }

      if (featureMoveRef.current && featureMoveRef.current.withinBounds && !shortcutKeys.shift) {
        // We want to only drag within those bounds.
        let anyFound = false;
        for (let i = 0; i < featureMoveRef.current.withinBounds.length; i++) {
          const thisOne = featureMoveRef.current.withinBounds[i];
          if (thisOne.containsXY(newCoord[0], newCoord[1])) {
            anyFound = true;
            break;
          }
        }
        if (!anyFound)
          return;
      }
      
      if (featureMoveArrow.current?.length > 0) {
        let needsSend = false;
        let allPaths: Coordinate[][] = [];
        
        for (let i = 0; i < featureMoveArrow.current.length; i++) {
          const thisLeg = featureMoveArrow.current[i];
          const thisMoveRangeKey = toMoveRangeKey(thisLeg.endPoint ?? newCoord);
          const pointInGeom = thisLeg.cost[thisMoveRangeKey];
          
          // If we don't have this point or it's the tile we're currently on.
          if (!pointInGeom || pointInGeom.cost == 0) {
            if (thisLeg.feature.getGeometry())
              needsSend = true;
            thisLeg.feature.setGeometry(undefined);
            continue;
          }
          
          allPaths.push(pointInGeom.bestPath);
          let geom = new LineString(pointInGeom.bestPath);
          let jstsGeom = jstsParser.current.read(geom);
          jstsGeom = jstsGeom.buffer(moveArrowWidth.current);
          geom = jstsParser.current.write(jstsGeom);
          thisLeg.feature.setGeometry(geom);
          
          if (thisMoveRangeKey != (thisLeg.tempEndPoint ? toMoveRangeKey(thisLeg.tempEndPoint) : '')) {
            needsSend = true;
          }
          
          thisLeg.tempEndPoint = newCoord;
          featureMoveArrow.current[i] = thisLeg;
        }

        if (needsSend && featureMoveRef.current) {
          if (!amGM) {
            if (updateMoveArrowTimeout.current)
              clearTimeout(updateMoveArrowTimeout.current);
            const timeout = setTimeout(() => {
              channels['personal'].send({
                type: 'broadcast',
                event: 'move-range-wip',
                payload: {
                  paths: allPaths,
                  for: featureMoveRef.current.poi.poi_id
                }
              });
            }, 100);
            updateMoveArrowTimeout.current = timeout;
          } else {
            // GM can just update this directly so it gets echoed out to clients.
            if (!shortcutKeys.shift) {
              setWorkingMoveArrows((previous) => ({
                ...previous,
                [featureMoveRef.current.poi.poi_id]: {
                  path: allPaths,
                  state: 'wip',
                  stamp: Date.now(), // Marks this as updated.
                }
              }))
            }
          }
        }
      }

      desiredPoiCoordinates.current[featureMoveRef.current.poi.poi_id] = {
        ...desiredPoiCoordinates.current[featureMoveRef.current.poi.poi_id],
        desiredPosition: newCoord
      };
      if (mapRef && mapRef.current)
        mapRef.current.render();
    };
    
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [featureMoveRef, moveFeatureOffsetCoordinate, shortcutKeys, channels, amGM]);

  // map click handler
  const handleMapClick = (event: { pixel: Pixel; }) => {
    if (mapRef.current && staticSizeFeatureLayers) {
      const clickedCoord = mapRef.current.getCoordinateFromPixel(event.pixel);
      const [coordX, coordY] = clickedCoord;
      
      let anyFound = false;
      staticSizeFeatureLayers?.forEach((layer: Layer) => {
        if (anyFound)
          return;

        const [xMin, yMin, xMax, yMax] = layer.getExtent();
        if (coordX >= xMin && coordX <= xMax && coordY >= yMin && coordY <= yMax) {
          anyFound = true;

          const valueData = (layer as any).values_?.data;
          if (valueData && valueData as DatabaseMapPoi && !anyModalOpen) {
            const mapPoi = valueData as DatabaseMapPoi;

            let thingsItDoes = 0;

            if (mapPoi.links_to_map)
              thingsItDoes += 1;
    
            if (mapPoi.has_description)
              thingsItDoes += 1;

            if (thingsItDoes !== 1)
              return;
    
            // If we have just one thing to do, let's do it.
    
            if (mapPoi.links_to_map)
            {
              if ((mapPoi.locked && viewAsPlayer) || (mapPoi.hidden && viewAsPlayer))
                return;
                
              logger.info('Clicking on map to navigate', {
                user: session.user.id,
                from: editingMapData.map_id,
                to: mapPoi.links_to_map,
                using: mapPoi.poi_id
              });
              setEditingMapId(mapPoi.links_to_map);
              setEditingMapData(undefined);
              setMapReadyForDisplay(false);

              if (featureMoveRef && featureMoveRef.current) {
                featureMoveRef.current = undefined;
                mapRef.current.getInteractions().forEach((interaction) => {
                  interaction.setActive(false);
                });
              }
            }

            if (mapPoi.has_description) {
              if ((mapPoi.locked && viewAsPlayer) || (mapPoi.hidden && viewAsPlayer))
                return;
              setModalOpen('featuredesc', true);

              if (featureMoveRef && featureMoveRef.current) {
                featureMoveRef.current = undefined;
                mapRef.current.getInteractions().forEach((interaction) => {
                  interaction.setActive(false);
                });
              }
            }
          }
        }
      });
    }
  }

  const handleMouseUpMove = (event: MouseEvent) => {
    if (featureMoveRef && featureMoveRef.current) {
      const forPoiId = featureMoveRef.current.poi?.poi_id;

      // Allow us to click and drag or hold to drag.
      if (Date.now() - featureMoveRef.current.time < 200)
        return;

      if (editingMapData && editingMapData.has_grid && localDrawSource && localDrawSource.getFeatures().length > 0) {
        localDrawSource.forEachFeature((feat) => {
          const props = feat.getProperties() ?? {};
          if ('type' in props && props['type'] == 'range')
            localDrawSource.removeFeature(feat);
        });
      }

      const myCoordinateChanges = desiredPoiCoordinates.current[featureMoveRef.current.poi.poi_id] ?? undefined;
      if (myCoordinateChanges) {
        if (amGM) {
          const mapData = structuredClone(editingMapData) as DatabaseMap;
          const foundIndex = mapData.pois?.findIndex((poi) => poi.poi_id == featureMoveRef.current.poi.poi_id) ?? -1;
          
          // We have a move arrow for this feature, reduce its movement value.
          if (featureMoveArrow.current?.length > 0) {
            if (!shortcutKeys.shift) {
              let totalCost: number = 0;

              for (let i = 0; i < featureMoveArrow.current.length; i++) {
                const thisLeg = featureMoveArrow.current[i];
                const thisMoveRangeKey = toMoveRangeKey(thisLeg.endPoint ?? myCoordinateChanges.desiredPosition);
                const pointInGeom = thisLeg.cost[thisMoveRangeKey];
                totalCost += pointInGeom.cost;
              }

              const initiatveIndex = mapData.initiative.spots.findIndex((match) => match.poi_id == featureMoveRef.current.poi.poi_id);
              const thatInitiative = mapData.initiative.spots[initiatveIndex];
              if (thatInitiative) {
                thatInitiative.movement -= totalCost;
                mapData.initiative.spots[initiatveIndex] = thatInitiative;
              }
              
              // console.log('on server allowing fade now',featureMoveRef.current.poi.poi_id);
              // moveArrowFadeState.current[featureMoveRef.current.poi.poi_id] = {
              //   stamp: Date.now(),
              // };
            }
          }
          
          if (foundIndex >= 0)
            mapData.pois[foundIndex] = {
              ...featureMoveRef.current.poi,
              coordinate_x: myCoordinateChanges.desiredPosition[0],
              coordinate_y: myCoordinateChanges.desiredPosition[1],
            };
          setEditingMapData(mapData);
          featureMoveRef.current = undefined;
          mapRef.current.getInteractions().forEach((interaction) => {
            interaction.setActive(true);
          });
        } else {
          // Update the host as far as what our end move path state is.
          let startPosition: Coordinate = [featureMoveRef.current.poi.coordinate_x, featureMoveRef.current.poi.coordinate_y];
          
          if (featureMoveArrow.current.length > 0) {
            let allPaths: Coordinate[][] = [];

            for (let i = 0; i < featureMoveArrow.current.length; i++) {
              const thisLeg = featureMoveArrow.current[i];
              
              if (i == 0)
                startPosition = thisLeg.startPoint;
              
              const thisMoveRangeKey = toMoveRangeKey(thisLeg.endPoint ?? myCoordinateChanges.desiredPosition);
              const pointInGeom = thisLeg.cost[thisMoveRangeKey];
              allPaths.push(pointInGeom.bestPath);
            }

            channels['personal'].send({
              type: 'broadcast',
              event: 'move-range-done',
              payload: {
                paths: allPaths,
                for: featureMoveRef.current.poi.poi_id
              }
            });
          }
          
          if (editingMapData.initiative && editingMapData.has_grid) {
            // Move back to the start place since our request has been made.
            desiredPoiCoordinates.current[featureMoveRef.current.poi.poi_id] = {
              ...desiredPoiCoordinates.current[featureMoveRef.current.poi.poi_id],
              desiredPosition: startPosition
            };
          } else {
            // Directly request a move to the new position.
            channels['personal'].send({
              type: 'broadcast',
              event: 'request-move',
              payload: {
                id: featureMoveRef.current.poi.poi_id,
                to: myCoordinateChanges.desiredPosition
              }
            });
          }
          featureMoveRef.current = undefined;
          mapRef.current.getInteractions().forEach((interaction) => {
            interaction.setActive(true);
          });
        }
        document.body.style.cursor = 'default';
        setMouseOverFeature(undefined);
        setMouseOverFeatureRefPoint(undefined);
        setMouseOverFeatureRefPointOffset(undefined);
      }

      // We're releasing the move arrow itself.
      if (featureMoveArrow.current?.length > 0 && forPoiId) {
        localDrawSource.forEachFeature((feature) => {
          if (feature.getProperties() && feature.getProperties()['type'] == 'movearrow') {
            feature.setProperties({
              ...feature.getProperties(),
              type: 'move-range-wip',
              poi_id: forPoiId,
            });
            
            if (amGM) {
              moveArrowFadeState.current[forPoiId] = {
                stamp: Date.now(),
              };
            }
          }
        });
      }
    }
  }
  
  // Draw move arrows from other users as they get updated.
  // TODO We also need to track this as the GM in our presence channel so that the other clients can add it to THEIR workingMoveArrows.
  useEffect(() => {
    if (!localDrawSource)
      return;
    
    const featureName = 'move-range-wip';
    
    const currentlyFading = new Set<string>(Object.keys(moveArrowFadeState.current));
    Object.keys(workingMoveArrows).forEach((poi_id) => {
      const workingStamp = workingMoveArrows[poi_id].stamp;
      const fadingStamp = moveArrowFadeState.current[poi_id]?.stamp ?? 0;

      if (workingStamp != fadingStamp && poi_id in moveArrowFadeState.current) {
        delete moveArrowFadeState.current[poi_id];
      }

      if (currentlyFading.has(poi_id))
        currentlyFading.delete(poi_id);
    });
    currentlyFading.forEach((fadingPoi) => {

      moveArrowFadeState.current[fadingPoi] = {
        ...moveArrowFadeState.current[fadingPoi],
        safeToDelete: true
      }
    });

    // Remove all old drawn arrow ranges
    localDrawSource.forEachFeature((feat) => {
      const props = feat.getProperties() ?? {};
      if ('type' in props && props['type'] == featureName) {
        const forPoi = props['poi_id'];
        if (forPoi in moveArrowFadeState.current)
          return;
        // Don't delete old arrows that are going to be followed
        if (props['state'] == 'moving')
          return;
        if (moveArrowBeingUsedForMovement.current.has(forPoi))
          return;
        localDrawSource.removeFeature(feat);
      }
    });
    
    const makeArrow = (id: string, points: Coordinate[], state: 'wip' | 'pending_acceptance' | 'moving') => {
      if (points.length <= 1)
        return;
      const newArrow = new Feature({
        name: featureName
      });
      newArrow.setProperties({
        type: featureName,
        poi_id: id,
        state: state,
        path: points,
      });
      const done = state == 'pending_acceptance' || state == 'moving';
      newArrow.setStyle(new Style({
        stroke: new Stroke({
          color: done ? '#000000' : '#242424aa',
          width: 5,
        }),
        fill: new Fill({
          color: done ? '#000000aa' : '#2424248c',
        }),
      }));

      let geom = new LineString(points);
      let jstsGeom = jstsParser.current.read(geom);
      jstsGeom = jstsGeom.buffer(moveArrowWidth.current);
      geom = jstsParser.current.write(jstsGeom);
      newArrow.setGeometry(geom);
      
      localDrawSource.addFeature(newArrow);
    }

    if (workingMoveArrows && Object.keys(workingMoveArrows).length > 0) {
      for (const [key, value] of Object.entries(workingMoveArrows)) {
        if (key in moveArrowFadeState.current)
          continue;
        
        value.path.forEach((path) => {
          if (featureMoveRef.current && featureMoveRef.current.poi?.poi_id == key)
            return;
          makeArrow(key, path, value.state);
        });
      }
    }
  }, [amGM, localDrawSource, workingMoveArrows, channels]);
  
  // Store the points that aren't walkable in a dictionary
  useEffect(() => {
    if (!editingMapData)
      return;
    
    if (editingMapData.unwalkable == lastUnwalkableData.current)
      return;
    
    lastUnwalkableData.current = editingMapData.unwalkable;
    unwalkableTilesSet.current.clear();
    
    const parsed = JSON.parse(editingMapData.unwalkable) as Coordinate[][];
    if (!parsed)
      return;
    
    parsed.flat(1).forEach((flatCoord) => {
      unwalkableTilesSet.current.add(toMoveRangeKey(flatCoord));
    });
    
    // Now build our geometry for use later when drawing the regions
    const width_grid = editingMapData.grid_line_spacing ?? 100;
    const lineWidth = editingMapData.grid_line_width ?? 2;
    const buildGeometryForSelected = (coords: Coordinate[]) => {
      const x = coords.map((coord) => ([
        coord,
        [coord[0] + width_grid, coord[1]],
        [coord[0], coord[1] + width_grid],
        [coord[0] + width_grid, coord[1] + width_grid]
      ])).flat().map((coords) => ([Math.round(coords[0] - width_grid / 2) + lineWidth, Math.round(coords[1] - width_grid / 2) - Math.round(lineWidth / 2)]));
      if (!x || x.length == 0)
        return null;
      var points = concaveman(x, .5, Math.round(width_grid / 2));
      return new Polygon([points.map((set) => ([set[0], set[1]]) as Coordinate)]);
    };
    
    const unwalkableGeometry: jsts.geom.Geometry[] = [];
    parsed.forEach((feature) => {
      const data = buildGeometryForSelected(feature);
      const readBad = jstsParser.current.read(data);
      unwalkableGeometry.push(readBad);  
    });
    unwalkableJstsGeometry.current = unwalkableGeometry;
  }, [editingMapData]);

  const drawMovementRange = (source: VectorSource, start: Coordinate, rangeTiles: number, andSetMoveRef: boolean = false, startingDiagonalBit: number = 1): MapCost => {
    const width_grid = editingMapData.grid_line_spacing ?? 100;
    const lineWidth = editingMapData.grid_line_width ?? 2;

    interface PathStep {
      point: Coordinate,
      links: PathStep[]
    }
    
    const visited: Coordinate[] = [start];
    const visitedSet: Set<string> = new Set();
    const cost: MapCost = {};
    
    const loopAndPush = (point: Coordinate, steps: number, visited: Coordinate[], visitedSet: Set<string>, cost: MapCost, diagonalBit: number = 1, totalDiagonals = 0, thisPath: Coordinate[] = []) => {
      if (steps > rangeTiles)
        return;

      const myKey = toMoveRangeKey(point);
      
      if (!visitedSet.has(myKey)) {
        visited.push(point);
        visitedSet.add(myKey);
      }
      
      if (myKey in cost) {
        const existing = cost[myKey];
        // If we got here in a more expensive way than we already found, it's not worth continuing on the trail from here.
        
        if (existing.cost < steps || (existing.cost == steps && existing.diagonalBit < diagonalBit))
          return;
      }
      cost[myKey] = {
        cost: steps,
        bestPath: thisPath,
        diagonalBit: diagonalBit,
        totalDiagonals: totalDiagonals,
      }
      
      for (let x = -1; x <= 1; x += 1) {
        for (let y = -1; y <= 1; y += 1) {
          if (x == 0 && y == 0)
            continue;

          const nextCoordinate = [point[0] + (x * width_grid), point[1] + (y * width_grid)];
          
          const thatKey = toMoveRangeKey(nextCoordinate);
          if (unwalkableTilesSet.current.has(thatKey))
            continue;
          
          let nextStep = steps + 1;
          const diagonal = x != 0 && y != 0;
          if (diagonal)
            nextStep = steps + diagonalBit;

          let nextDiagonal = diagonalBit;
          if (diagonal)
            nextDiagonal += 1;
          
          if (nextDiagonal > 2)
            nextDiagonal = 1;
          
          if (editingGameData.diagonal_rule == '5 Diagonal')
            nextDiagonal = 1;
          
          const nextTotalDiagonals = totalDiagonals + (diagonal ? 1 : 0);

          // We could have got to that square for cheaper, so don't bother. 
          if (thatKey in cost) {
            const thatCost = cost[thatKey];
            
            if (thatCost.cost < nextStep || (thatCost.cost == nextStep && thatCost.diagonalBit < nextDiagonal))
              continue;
          }

          loopAndPush(nextCoordinate, nextStep, visited, visitedSet, cost, nextDiagonal, nextTotalDiagonals, [...thisPath, nextCoordinate]);
        }
      }
    };
    
    if (rangeTiles > 0) {
      loopAndPush(start, 0, visited, visitedSet, cost, startingDiagonalBit, 0, [start]);
    } else {
      cost[toMoveRangeKey(start)] = {
        cost: 0,
        bestPath: [],
        diagonalBit: 1,
        totalDiagonals: 0,
      };
    }
    const toPush = visited;
    
    // Drawing
    {
      const scale = chroma.scale(['#FFFFFF', '#52C41A', '#8BBB11', '#FADB14', '#FA8C16', '#F5222D']);
      
      const pointsOfRange: {[key: number]: Coordinate[]} = {};
      for (let i = 0; i <= rangeTiles; i += 1) {
        pointsOfRange[i] = [];
      }
      
      toPush.forEach((p: Coordinate) => {
        
        const corners = [
          [p[0] - (width_grid / 2), p[1] - (width_grid / 2)],
          [p[0] + (width_grid / 2), p[1] - (width_grid / 2)],
          [p[0] - (width_grid / 2), p[1] + (width_grid / 2)],
          [p[0] + (width_grid / 2), p[1] + (width_grid / 2)],
        ];

        const key = toMoveRangeKey(p);
        const costHere = cost[key];
        pointsOfRange[costHere.cost].push(...corners);
      });
      
      const featureEachLevel: {[key: number]: Feature} = {};
      
      const matchingPolys: Polygon[] = [];
      
      for (let i = rangeTiles > 0 ? 1 : 0; i <= rangeTiles; i += 1) {
        const pointsThree = pointsOfRange[i];
        const concaved = concaveman(pointsThree, 0.5, Math.round(width_grid));
        const result = [concaved.map((set) => ([set[0], set[1]]) as Coordinate)];
        const newPoly = new Polygon(result);

        const newFeature = new Feature({
          geometry: newPoly,
        });

        const percentage = i / rangeTiles;
        const chosenColors = scale(percentage);

        newFeature.setStyle(new Style({
          stroke: new Stroke({
            color: `${chosenColors}99`,
            width: 5,
          }),
          fill: new Fill({
            color: `${chosenColors}44`,
          }),
        }));
        newFeature.setProperties({
          type: 'range'
        });
        source.addFeature(newFeature);
        featureEachLevel[i] = newFeature;
      }
      
      const geomTest: Geometry[] = [];
      
      if (rangeTiles > 0) {
        // For each feature, subtract using jsts the geometry of the one under it.
        for (let i = rangeTiles; i >= 2; i -= 1) {
          const thisFeature = featureEachLevel[i];
          const jstsGeom = jstsParser.current.read(thisFeature.getGeometry());

          const prior = featureEachLevel[i - 1];
          const thatJstsGeom = jstsParser.current.read(prior.getGeometry());

          let newGeom = jstsGeom.difference(thatJstsGeom);
          for (let j = unwalkableJstsGeometry.current.length - 1; j >= 0; j--) {
            const thisUnwalkable = unwalkableJstsGeometry.current[j];
            newGeom = newGeom.difference(thisUnwalkable);
          }
          thisFeature.setGeometry(jstsParser.current.write(newGeom));
          geomTest.push(thisFeature.getGeometry());
        }
        geomTest.push(featureEachLevel[1].getGeometry());
      } else {
        geomTest.push(featureEachLevel[0].getGeometry());
      }
      
      if (andSetMoveRef) {
        featureMoveRef.current.withinBounds = geomTest;
      }
    }
    
    return cost;
  }

  const handleMapMouseDownMoveImages = (event: MouseEvent) => {
    if (!mapRef || !mapRef.current)
      return;

    // If we're already moving something don't queue it up again.
    if (featureMoveRef && featureMoveRef.current)
      return;

    if (!mouseOverFeature)
      return;

    if (anyModalOpen)
      return;

    // If we're clicking on a feature using another mouse button, ignore it.
    if (event.button != 0)
      return;

    const coordinate = mapRef.current.getCoordinateFromPixel([event.clientX, event.clientY]);
    const [coordX, coordY] = coordinate;
    let anyFound = false;
    staticSizeFeatureLayers?.forEach((layer: Layer) => {
      if (anyFound)
        return;
      
      const [xMin, yMin, xMax, yMax] = layer.getExtent();
      if (coordX >= xMin && coordX <= xMax && coordY >= yMin && coordY <= yMax) {
        const valueData = (layer as any).values_?.data;
        if (valueData && valueData as DatabaseMapPoi && !anyModalOpen) {
          const mapPoi = valueData as DatabaseMapPoi;

          // TODO WHO CAN MOVE POI SETTINGS
          
          let canMove = mapPoi.is_image;
          
          if (!amGM && !editingGameData.request_movement_not_your_tokens)
            canMove = canMove && mapPoi.map_text.includes(session.user.id);
          
          if (!amGM && !editingGameData.request_movement_off_turn && editingMapData.initiative) {
            // We are in initiative, check if we can move this since it's not our turn.
            const currentActivePoi = editingMapData.initiative.spots[editingMapData.initiative.current];

            if (currentActivePoi.poi_id != mapPoi.poi_id)
              canMove = false;
            else {
              const matchingPoi = editingMapData.pois.find((poi) => poi.poi_id == currentActivePoi.poi_id);
              if (!matchingPoi)
                canMove = false;
              else
                canMove = canMove && matchingPoi.user_id == session.user.id;
            }
          }
          
          if (editingGameData.gm_on_map_id != editingMapId)
            canMove = false;
          
          // if (mapPoi.is_image && (amGM || ( false && mapPoi.map_text.includes(session.user.id)))) {
          if (canMove) {
            anyFound = true;
            
            featureMoveRef.current = {
              poi: mouseOverFeature,
              time: Date.now(),
            };
            // console.log('setting move ref to', featureMoveRef.current);
            mapRef.current.getInteractions().forEach((interaction) => {
              interaction.setActive(false);
            });
            
            let startPosition: Coordinate = [featureMoveRef.current.poi.coordinate_x, featureMoveRef.current.poi.coordinate_y];
            if (featureMoveRef.current.poi.poi_id in desiredPoiCoordinates.current) {
              startPosition = desiredPoiCoordinates.current[featureMoveRef.current.poi.poi_id].desiredPosition;
            }
            setMoveFeatureOffsetCoordinate([startPosition[0] - coordX, startPosition[1] - coordY]);
          }
        }
      }
    });
    
    const shiftHeld = shortcutKeys.shift;
    const inInitiative = editingMapData.initiative != undefined;
    

    if (anyFound && editingMapData && editingMapData.has_grid && localDrawSource && (inInitiative || shiftHeld)) {
      let myInitiative: InitiativeSpot = undefined;
      if (inInitiative) {
        myInitiative = editingMapData.initiative.spots.find((spot) => spot.poi_id == featureMoveRef.current.poi.poi_id);
      }
      
      let startPosition: Coordinate = [featureMoveRef.current.poi.coordinate_x, featureMoveRef.current.poi.coordinate_y];
      if (featureMoveRef.current.poi.poi_id in desiredPoiCoordinates.current) {
        startPosition = desiredPoiCoordinates.current[featureMoveRef.current.poi.poi_id].desiredPosition;
      }
      
      const cost = drawMovementRange(localDrawSource, startPosition, myInitiative?.movement ?? 6, true);
      
      // Remove old arrows
      localDrawSource.forEachFeature((feature) => {
        if (feature.getProperties() && feature.getProperties()['type'] == 'movearrow')
          localDrawSource.removeFeature(feature);
      });
      
      if (inInitiative) {
        const newArrow = new Feature({
          name: 'movearrow'
        });
        newArrow.setProperties({
          type: 'movearrow',
          poi_id: featureMoveRef.current.poi.coordinate_x,
        });
        newArrow.setStyle(new Style({
          stroke: new Stroke({
            color: '#000000',
            width: 5,
          }),
          fill: new Fill({
            color: '#000000aa',
          }),
        }));
        featureMoveArrow.current = [{
          feature: newArrow,
          startPoint: startPosition,
          cost: cost,
        }];
        localDrawSource.addFeature(newArrow);
      }
    }
  };

  return (
    <div style={{width: `${windowDimensions[0]}px`, height: `${windowDimensions[1]}px`}}>
      <div ref={mapElement} className="map-container"></div>
    </div>
  )
};