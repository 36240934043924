import { Button, Input, Modal, Typography, theme } from "antd";
import { DatabaseToken, DefaultExtraJson } from '../../../../types';
import { Image as AntImage } from "antd";
import { useGlobalState } from "../../../Menu/GlobalState";
import React, { useEffect, useState } from "react";
import diff from "microdiff";
import { InboxOutlined } from "@ant-design/icons";
import CreateTokenModelNewImage from "../../../Menu/Operations/CreateTokenModelNewImage";
import {JsonEditor} from "json-edit-react";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (b: boolean) => void,
  forceRefresh: () => void,
  dbToken: DatabaseToken,
}

export default function TokenDetailsModal({isModalOpen, dbToken, setIsModalOpen, forceRefresh}: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  const editingGameData = useGlobalState((state) => state.editingGameData);

  const [workingToken, setWorkingToken] = useState<DatabaseToken>(dbToken);
  const [diffToken, setDiffToken] = useState<any[]>();

  const [replaceImage, setReplaceImage] = useState<string>(null);

  const setModalOpen = useGlobalState((state) => state.setModalOpen);

  useEffect(() => {
    if (!isModalOpen)
      return;
    setWorkingToken(dbToken);
  }, [isModalOpen, dbToken]);

  useEffect(() => {
    setModalOpen('token-details', isModalOpen);
  }, [isModalOpen]);
  
  const { token } = theme.useToken();

  useEffect(() => {
    setDiffToken(diff(dbToken, workingToken));
  }, [workingToken]);

  if (!workingToken)
    return <></>;

  return (
    <>
      <Modal centered title={`Editing '${workingToken.display_name}'`} open={isModalOpen} onCancel={() => setIsModalOpen(false)} okButtonProps={diffToken && diffToken.length > 0 ? undefined : {disabled: true}} onOk={() => {
        const toSend = {};
        diffToken.forEach((change) => {
          // @ts-ignore
          toSend[change['path'][0]] = change['value']
        });
        supabase
          .from('tokens')
          .update(toSend)
          .eq('token_id', workingToken.token_id)
          .eq('owner_id', workingToken.owner_id)
          .then(() => {
            setIsModalOpen(false);
            forceRefresh();
          })
      }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 12 }}>
          <Typography style={{ color: token.colorTextSecondary, flexGrow: 2, width: '30%' }}>Display Name</Typography>
          <Input value={workingToken.display_name ?? ''} onChange={(e) => {
            setWorkingToken({
              ...workingToken,
              display_name: e.target.value,
            });
          }} style={{ flexGrow: 10 }} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 12, width: '100%', left: '50%' }}>
          <AntImage
            style={{ display: 'block', width: 'auto', height: 'auto', maxWidth: '125px', maxHeight: '125px'}}
            src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${workingToken.owner_id}/${workingToken.background_image}?v=${Math.floor(Math.random() * 10000)}`}
            preview={false}
          />
        </div>
        
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 12, width: '100%', left: '50%' }}>
          <Button onClick={() => {
            setReplaceImage(workingToken.background_image);
          }}>Replace!</Button>
          <Button onClick={() => {
            supabase
              .from('tokens')
              .delete()
              .eq('owner_id', workingToken.owner_id)
              .eq('token_id', workingToken.token_id)
              .then(() => {
                setIsModalOpen(false);
                forceRefresh();
              })
          }}>Delete</Button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 12, width: '100%', left: '50%' }}>
          <Typography.Text style={{ marginBottom: '4px' }}>Token Settings Overrides</Typography.Text>
          <JsonEditor
            data={workingToken.extra_json_data ? ({
              ...(editingGameData.default_token_settings ? JSON.parse(editingGameData.default_token_settings) : DefaultExtraJson),
              ...JSON.parse(workingToken.extra_json_data)
            }) : (editingGameData.default_token_settings ? JSON.parse(editingGameData.default_token_settings) : DefaultExtraJson)}
            onUpdate={({newData}) => setWorkingToken({...workingToken, extra_json_data: JSON.stringify(newData)})}
            restrictDelete={true}
            restrictAdd={true}
            rootName={""}
            theme={"githubDark"}
          />
        </div>
      </Modal>
      <CreateTokenModelNewImage isModalOpen={replaceImage && replaceImage.length > 0} setIsModalOpen={(b) => {
          if (!b)
            setReplaceImage(null);
        }} forceRefresh={forceRefresh} replacementMode={replaceImage} />
    </>
  )
}