import React from "react";
import { LiaDiceD20Solid } from "react-icons/lia";
import CommandTemplate, { CommandFunctionParameters } from "../CommandTemplate";
import { TiCancel } from "react-icons/ti";
import { FaPatreon } from "react-icons/fa";

export default [
  // {
  //   icon: <FaPatreon />,
  //   text: 'Connect Patreon',
  //   keywords: 'patreon',
  //   category: 'Patreon',
  //
  //   perform: (props: CommandFunctionParameters) => {
  //     const clientId = 'mDIp5kbr4u7ef5lSpY_rGJ8Jxs9qrU8D40jJTPYcm9ROdyMUilwyLimBUyGeTrKF';
  //     // const redirect = 'http://www.mapsfortable.top';
  //     const redirect = 'http://play.localhost:8080';
  //     window.location.replace(`http://www.patreon.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect}&state=${props.session.user.id}`);
  //   },
  //
  //   shouldInclude: (props: CommandFunctionParameters) => {
  //     return true;
  //   },
  // }
] as CommandTemplate[];