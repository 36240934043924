import React, { useCallback, useEffect, useRef, useState } from "react";
import { dice_box } from "./dice";
import { parse_notation } from "./dice";
import { useGlobalState } from "../../Menu/GlobalState";
import { shallow } from "zustand/shallow";
import { notification, theme } from "antd";
import Title from "antd/es/typography/Title";
import { LiaDiceD20Solid } from "react-icons/lia";

export const DiceRollOpenKey = 'dicerollopen';

export default function DiceRolling() {
  const canvasRef = useRef<HTMLDivElement>(null);
  const rollsState = useRef<number>(0);
  const boxRef = useRef<dice_box>();
  const [box, setBox] = useGlobalState((state) => [state.diceBox, state.setDiceBox], shallow);
  const [windowDimensions, setWindowDimensions] = useGlobalState((state) => [state.windowDimensions, state.setWindowDimensions], shallow);
  const setDiceRoll = useGlobalState((state) => state.setDiceRoll);
  const [api, contextHolder] = notification.useNotification();
  const { token } = theme.useToken();

  useEffect(() => {
    if (!canvasRef || !canvasRef.current)
      return;

    const box = new dice_box(canvasRef.current, { w: 500, h: 500});

    setDiceRoll((passedNotation: string) => {
      const thisRoll = rollsState.current;
      rollsState.current += 1;
      box.start_throw(() => parse_notation(passedNotation), null, (notation: any, result: number[]) => {
        let resultText = result.join('+');
        if (resultText.length > 9)
          resultText = resultText.substring(0, 9) + '...';
        api.open({
          message: '',
          description: (<div className="roll" style={{ display: 'flex', justifyContent: 'space-between'}}>
            <div style={{ flexGrow: 1.2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
              <span style={{ fontSize: '15px' }}>Roll Result</span>
              <div style={{ alignItems: 'center', justifyContent: 'start', display: 'flex'}}>
                <LiaDiceD20Solid style={{ fontSize: 30, marginRight: '20px' }} />
                <span style={{ fontSize: '20px', color: token.colorText }}>{resultText}</span>
              </div>
              <Title level={5} style={{ color: token.colorTextSecondary, margin: 0 }}>{passedNotation}</Title>
            </div>
            <div style={{ flexGrow: 1, display: 'flex'}}>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ width: '1px', height: '35px', backgroundColor: '#575757'}}></div>
                <span style={{ fontSize: '30px', color: '#575757', lineHeight: '30px', textAlign: 'center', marginTop: '-8px'}}>=</span>
                <div style={{ width: '1px', height: '35px', backgroundColor: '#575757'}}></div>
              </div>
              <Title level={1} style={{ textAlign: 'center', flexGrow: 2 }}>{result.reduce((p, c) => p + c, 0)}</Title>
            </div>
          </div>),
          duration: 5,
          onClose: () => {
            if (rollsState.current - thisRoll == 1)
              boxRef.current.clear();
          }
        });
      });
    });

    boxRef.current = box;
    setBox(box);
  }, [canvasRef]);

  useEffect(() => {
    if (!canvasRef || !canvasRef.current)
      return;

    if (!box)
      return;

    if (box.rolling)
      return;

    box.reinit(canvasRef.current, {w: windowDimensions[0], h: windowDimensions[1]});
  }, [box, windowDimensions]);

  return (
    <>
      {contextHolder}
      <div style={{ position: 'absolute', zIndex: 99, height: windowDimensions[1], width: windowDimensions[0], top: '0px', left: '0px', pointerEvents: 'none'}}>
        <div ref={canvasRef} style={{ width: windowDimensions[0], height: windowDimensions[1]}}></div>
      </div>
    </>
  );
};