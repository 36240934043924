import GeoImage, {Options as GeoImageOptions} from "ol-ext/source/GeoImage";
import { Coordinate } from "ol/coordinate";
import { Extent } from "ol/extent";
import { Size } from "ol/size";
import {SvgPathDrawSettings} from "../../../types";
import Path from "ol-ext/featureanimation/Path";
import chroma from "chroma-js";

export interface TokenGeoImageOptions {
  statusUrls?: string[],
  grayscale?: boolean,

  statusesPerRow?: number,
  statusesPadding?: number,
  
  is_svg?: boolean;
  inRawSvgData?: string;
}

export default class TokenGeoImageExtension extends GeoImage {
  center?: Coordinate;
  _imageSize?: number[];
  _image?: HTMLImageElement;
  mask?: Coordinate[];
  crop?: Extent;
  rotate?: number;
  scale?: any;
  
  svgData?: SvgPathDrawSettings;


  statusPerRow: number;
  statusPadding: number;

  _statuses?: (HTMLImageElement | string | SvgPathDrawSettings)[];

  options: GeoImageOptions & TokenGeoImageOptions;

  constructor(options: GeoImageOptions & TokenGeoImageOptions) {
    super(options);

    this.options = options;
    
    if (this.options.is_svg) {
      const rawData = this.options.inRawSvgData.substring(3);
      const parsed = JSON.parse(rawData) as SvgPathDrawSettings;
      this.svgData = {
        ...parsed,
        matrix: document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGMatrix(),
        basePath2D: new Path2D(parsed.path)
      };
    }
      
    this._statuses = options.statusUrls?.map((url) => {
      // If it's a color simply return the default value
      if (/^#[0-9A-F]{1,6}[0-9a-f]{0,2}$/i.test(url))
        return url;
      
      if (url.startsWith("svg")) {
        const rawData = url.substring(3);
        const parsed = JSON.parse(rawData) as SvgPathDrawSettings;
        
        const matrix = document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGMatrix();
        
        return {
          ...parsed,
          matrix: matrix,
          basePath2D: new Path2D(parsed.path)
        };
      }

      const newImage = new Image(64, 64);
      newImage.src = url;
      newImage.onload = () => {
        this.changed();
      }
      return newImage;
    });

    this.statusPerRow = options.statusesPerRow ?? 3;
    this.statusPadding = options.statusesPadding ?? 10;
  }

  calculateImage(extent: Extent, resolution: number, pixelRatio: number, size: Size): HTMLCanvasElement {
    if (!this.center)
      return;
    var canvas = document.createElement('canvas')
    canvas.width = size[0]
    canvas.height = size[1]
    var ctx = canvas.getContext('2d')

    if (!this._imageSize)
      return canvas
    // transform coords to pixel
    function tr(xy: number[]) {
      return [
        (xy[0] - extent[0]) / (extent[2] - extent[0]) * size[0],
        (xy[1] - extent[3]) / (extent[1] - extent[3]) * size[1]
      ]
    }
    // Clipping mask
    if (this.mask) {
      ctx.beginPath()
      var p = tr(this.mask[0])
      ctx.moveTo(p[0], p[1])
      for (var i = 1; i < this.mask.length; i++) {
        p = tr(this.mask[i])
        ctx.lineTo(p[0], p[1])
      }
      ctx.clip()
    }

    // Draw
    var pixel = tr(this.center)
    var dx = (this._image.naturalWidth / 2 - this.crop[0]) * this.scale[0] / resolution * pixelRatio
    var dy = (this._image.naturalHeight / 2 - this.crop[1]) * this.scale[1] / resolution * pixelRatio
    var sx = this._imageSize[0] * this.scale[0] / resolution * pixelRatio
    var sy = this._imageSize[1] * this.scale[1] / resolution * pixelRatio

    ctx.translate(pixel[0], pixel[1])
    if (this.rotate)
      ctx.rotate(this.rotate)

    if (this.options.grayscale) {
      ctx.filter = 'grayscale(1)';
      ctx.globalAlpha = 0.6;
    }

    // Draw the original image
    if (this.options.is_svg) {
      const toDraw = this.svgData;

      const destX = -dx;
      const destY = -dy;
      const sizeX = sx;
      const sizeY = sy;

      // Trace the border
      ctx.beginPath();
      ctx.arc(destX + sizeX / 2, destY + sizeY / 2, sizeX / 2, 0, 2 * Math.PI, false);
      ctx.fillStyle = toDraw.border_color ?? '#ff0000';
      ctx.fill();
      
      if (toDraw.draw_background !== undefined ? toDraw.draw_background : true) {
        const borderWidth = 5 * this.scale[0] / resolution * pixelRatio;
        const sizeXMB = sizeX - (borderWidth * 2);
        const sizeYMB = sizeY - (borderWidth * 2);
        
        ctx.beginPath();
        ctx.arc((borderWidth) + destX + sizeXMB / 2, (borderWidth) + destY + sizeYMB / 2, sizeXMB / 2, 0, 2 * Math.PI, false);
        ctx.fillStyle = toDraw.background_color ?? '#000';
        ctx.fill();
      }
      ctx.save();
      ctx.clip();

      ctx.beginPath();
      const multiple = toDraw.scale ?? 0.9;
      const offset = [((1 - multiple) * sx) / 2, ((1 - multiple) * sy) / 2];

      const p = new Path2D();
      const t = toDraw.matrix.translate(destX + offset[0], destY + offset[1]).scale(multiple * (sizeX / 512),multiple * (sizeY / 512));
      p.addPath(toDraw.basePath2D, t);

      ctx.fillStyle = toDraw.foreground_color ?? '#fff';
      ctx.fill(p);
      ctx.restore();
      
      // Now draw the extra ID if we have one
      if (toDraw.extraIdentifier) {
        ctx.beginPath()
        ctx.font = `bold ${(120 * this.scale[0] / resolution * pixelRatio).toString()}px sans-serif`;
        ctx.fillStyle = chroma(toDraw.border_color ?? '#ff0000').darken(1).hex();
        ctx.strokeStyle = toDraw.border_color ?? '#ff0000';
        const metrics = ctx.measureText(toDraw.extraIdentifier);
        ctx.fillText(toDraw.extraIdentifier, destX + (3 * sizeX / 4), destY + (3 * sizeY / 4) + (metrics.fontBoundingBoxAscent / 2));
        ctx.strokeText(toDraw.extraIdentifier, destX + (3 * sizeX / 4), destY + (3 * sizeY / 4) + (metrics.fontBoundingBoxAscent / 2));
      }
    } else
      ctx.drawImage(this._image, this.crop[0], this.crop[1], this._imageSize[0], this._imageSize[1], -dx, -dy, sx, sy)

    // Draw any statuses we may need to render on top
    if (this._statuses && this._statuses.length > 0) {
      const padding = this.statusPadding * this.scale[0] / resolution * pixelRatio;
      const perRow = this.statusPerRow;

      const sizeX = ((sx - (padding * perRow)) / perRow);
      const sizeY = ((sy - (padding * perRow)) / perRow);

      this._statuses.forEach((statusImage, index) => {
        const destX = -dx + ((index % 3) * (sizeX + padding + (padding / perRow)));
        const destY = -dy + (Math.floor(index / 3) * (sizeY + padding + (padding / perRow)));

        if (statusImage instanceof HTMLImageElement) {
          ctx.drawImage(statusImage as HTMLImageElement, this.crop[0], this.crop[1], this._imageSize[0], this._imageSize[1], destX, destY, sizeX, sizeY);
        } else if (statusImage.constructor == Object && (statusImage as SvgPathDrawSettings).path) {
          const toDraw = (statusImage as SvgPathDrawSettings);
          
          if (toDraw.draw_background !== undefined ? toDraw.draw_background : true) {
            ctx.beginPath();
            ctx.arc(destX + sizeX / 2, destY + sizeY / 2, sizeX / 2, 0, 2 * Math.PI, false);
            ctx.fillStyle = toDraw.background_color ?? '#000';
            ctx.fill();
          }
          
          ctx.beginPath();
          const multiple = toDraw.scale ?? 0.9;
          const offset = [((1 - multiple) * sizeX) / 2, ((1 - multiple) * sizeY) / 2];
          
          const p = new Path2D();
          const t = toDraw.matrix.translate(destX + offset[0], destY + offset[1]).scale(multiple * (sizeX / 512),multiple * (sizeY / 512));
          p.addPath(toDraw.basePath2D, t);
          
          ctx.fillStyle = toDraw.foreground_color ?? '#fff';
          ctx.fill(p);
        } else {
          ctx.beginPath();
          ctx.arc(destX + sizeX / 2, destY + sizeY / 2, sizeX / 2, 0, 2 * Math.PI, false);
          ctx.fillStyle = statusImage as string;
          ctx.fill();
        }
      });
    }

    return canvas
  }
}