import React, { useState, useContext } from "react";
import { useDrag } from "react-dnd";
import { Image as AntImage } from "antd";
import { useMapState } from "../../MapDisplay";

interface Props {
  ownerId: string;
  backgroundImage: string;
  setDrawerOpen: (b: boolean) => void,
  drawerOpen: boolean,
  droppedCallback: () => void,
  setCurrentlyDragging: (s: string) => void,
  onClick: () => void,
}

export default function DraggableTokenImage({ backgroundImage, ownerId, setDrawerOpen, drawerOpen, droppedCallback, setCurrentlyDragging, onClick }: Props) {
  const content = (
    <AntImage
      style={{ display: 'block', width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%'}}
      src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${ownerId}/${backgroundImage}`}
      preview={false}
    />
  );

  const nonGMContent = (
    <img
      style={{ display: 'block', width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '100%'}}
      src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${ownerId}/${backgroundImage}`}
      draggable={false}
    />
  )

  const amGM = useMapState((state) => state.amGM);

  const [draggingState, setDraggingState] = useState(false);

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'token-sidebar',
      item: { content },
      collect: (monitor) => {
        const isDraggingNow = monitor.isDragging();
        // if (isDraggingNow)
        //   setDrawerOpen(false);

        if (isDraggingNow != draggingState) {
          if (!drawerOpen && !isDraggingNow) {
            droppedCallback();
          }

          setDraggingState(isDraggingNow);
          if (isDraggingNow)
            setCurrentlyDragging(backgroundImage);
        }

        return ({
          opacity: isDraggingNow ? 0.5 : 1
        });
      }
    }),
    [drawerOpen, draggingState, setDraggingState, droppedCallback, setCurrentlyDragging]
  );

  return (
    amGM ? (
      <div ref={dragRef} style={{ opacity, maxWidth: '100%', maxHeight: '100%', cursor: 'grab' }} className="constrainChildren" onClick={(e) => {
        onClick();
      }}>
        { content }
      </div>
    ) : (
      <>
        { nonGMContent }
      </>
    )
  );
};