import { create } from "zustand";
import { DatabaseGameData, DatabaseMap, DatabaseMapPoi, MousePosition, PatreonData, UserData } from "../../types";
import { SupabaseClient } from "@supabase/supabase-js";
import { createClient, Session } from '@supabase/supabase-js';
import { Coordinate } from "ol/coordinate";
import { Logger, datadogLogs } from "@datadog/browser-logs";
import {dice_box} from "../Map/Dice/dice";

export enum View {
  Menu,
  Map,
}
const supabaseUrl = 'https://slsihiyehgypzhrfndiw.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNsc2loaXllaGd5cHpocmZuZGl3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODQyODgyMzEsImV4cCI6MTk5OTg2NDIzMX0.yhS1wcaE1lcE0xwOyUrorFUcqiRj53sRUqnGUD_z_r8';

export interface ShortcutState {
  shift?: boolean | undefined,
  alt?: boolean | undefined,
  ctrl?: boolean | undefined,
}

export interface GlobalState {
  editingGameId?: string | undefined,
  setEditingGameId: (gameId: string | undefined) => void,
  
  editingGameData?: DatabaseGameData | undefined,
  setEditingGameData: (fn: DatabaseGameData | undefined | ((prev: DatabaseGameData) => DatabaseGameData)) => void,

  editingMapId?: string | undefined,
  setEditingMapId: (gameId: string | undefined) => void,

  editingMapData?: DatabaseMap | undefined,
  setEditingMapData: (fn: DatabaseMap | undefined | ((prev: DatabaseMap) => DatabaseMap)) => void,
  
  fontLoadStatus: {[family: string]: 'started' | 'loaded'},
  setFontLoadStatus: (fn: (prev: {[family: string]: 'started' | 'loaded'}) => ({[family: string]: 'started' | 'loaded'})) => void,

  allDatabaseMaps?: DatabaseMap[] | undefined,
  setAllDatabaseMaps: (maps: DatabaseMap[] | undefined) => void,

  allDatabasePois?: DatabaseMapPoi[] | undefined,
  setAllDatabasePois: (maps: DatabaseMapPoi[] | undefined) => void,

  poiCoordinateChanges: {[id: string]: Coordinate},
  setPoiCoordinateChanges: (changes: {[id: string]: Coordinate}) => void,

  shortcutKeys: ShortcutState,
  setShortcutKeys: (newValue: ShortcutState) => void,

  view: View,
  setView: (s: View) => void,

  logger: Logger | undefined,

  supabase?: SupabaseClient | undefined,
  session?: Session | undefined,
  setSession: (s: Session | undefined) => void,

  userData?: UserData | undefined,
  setUserData: (u: UserData | undefined) => void,

  patreon?: PatreonData | undefined,
  setPatreonData: (p: PatreonData) => void,

  openModals: {[key: string]: boolean},
  preventModalClose: {[key: string]: boolean},
  setPreventModalClose: (s: string, preventClose: boolean) => void,
  anyModalOpen: boolean,
  setModalOpen: (s: string, open: boolean) => void,

  windowDimensions: number[],
  setWindowDimensions: (n: number[]) => void,

  diceRoll?: undefined | ((notation: string) => void),
  setDiceRoll: (func: (notation: string) => void) => void,
  
  diceBox?: dice_box | undefined,
  setDiceBox: (b: dice_box | undefined) => void,

  lastStoredAnnouncementId?: string | undefined,
  setLastStoredAnnouncementId: (s: string) => void,
}

export const useGlobalState = create<GlobalState>((set, get) => ({
  editingGameId: (function(){
    if (window.location.href.toLocaleLowerCase().indexOf('play') > -1)
      return '5d86e99d-bb18-4948-9d56-783d364da523';
    
    // The demo game.
    if (window.location.href.toLocaleLowerCase().indexOf('demo') > -1)
      return '034d98c1-b5f8-4d09-a4e7-f1d9f680c309';
    
    return (window.localStorage.getItem('editing-game') || undefined);
  })(),
  setEditingGameId: (s) => set((state) => {
    if (!s)
      window.localStorage.removeItem('editing-game');
    else
      window.localStorage.setItem('editing-game', s);
    return ({
      editingGameId: s,
    });
  }),

  editingMapId: (function() {
    const normalResult = (window.localStorage.getItem('editing-map') || undefined)
    
    if (!normalResult && window.location.href.toLocaleLowerCase().indexOf('demo') > -1)
      return 'e08d3493-1654-40d6-a4be-67c5e61b4e2e';
    
    return normalResult;
  })(),
  setEditingMapId: (s) => set((state) => {
    if (!s)
      window.localStorage.removeItem('editing-map');
    else
      window.localStorage.setItem('editing-map', s);
    return ({
      editingMapId: s,
    });
  }),

  view: (window.location.href.toLocaleLowerCase().indexOf('play') > -1 || window.location.href.toLocaleLowerCase().indexOf('demo') > -1 ? View.Map : (parseInt(window.localStorage.getItem('view')) as View || View.Menu)),
  setView: (s) => set((state) => {
    if (!s)
      window.localStorage.removeItem('view');
    else
      window.localStorage.setItem('view', (s as number).toString());
    return ({
      view: s,
    });
  }),

  setEditingMapData: (fn: DatabaseMap | undefined | ((prev: DatabaseMap) => DatabaseMap)) => {
    // console.warn('setting map data in here', fn)
    if (fn === undefined)
      return set((state) => ({
        editingMapData: undefined,
      }))

    if (typeof(fn) != 'function') {
      return set((state) => ({
        editingMapData: fn,
      }));
    }

    return set((state) => ({
      editingMapData: fn(state.editingMapData)
    }));
  },

  setEditingGameData: (fn: DatabaseGameData | undefined | ((prev: DatabaseGameData) => DatabaseGameData)) => {
    // console.warn('setting map data in here', fn)
    if (fn === undefined)
      return set((state) => ({
        editingGameData: undefined,
      }))

    if (typeof(fn) != 'function') {
      return set((state) => ({
        editingGameData: fn,
      }));
    }

    return set((state) => ({
      editingGameData: fn(state.editingGameData)
    }));
  },
  
  fontLoadStatus: {},
  setFontLoadStatus: (fn: (prev: {[family: string]: 'started' | 'loaded'}) => ({[family: string]: 'started' | 'loaded'})) => {
    return set((state) => ({
      fontLoadStatus: fn(state.fontLoadStatus)
    }));
  },

  logger: (function getLogger(){
    datadogLogs.init({
      clientToken: 'pub7691711fa3a1e4d100fbcdeaa9cd3df9',
      site: 'us5.datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: 'mapsfortabletop',
    });

    return datadogLogs.logger;
  })(),

  supabase: createClient(supabaseUrl, supabaseKey, {
    realtime: {
      params: {
        eventsPerSecond: 10,
      }
    }
  }),
  setSession: (s: Session) => set((state) => ({
    session: s,
  })),

  poiCoordinateChanges: {},
  setPoiCoordinateChanges: (b: {[id: string]: Coordinate}) => set((state) => ({
    poiCoordinateChanges: b
  })),

  setAllDatabaseMaps: (maps: DatabaseMap[] | undefined) => set((state) => ({
    allDatabaseMaps: maps,
  })),

  setAllDatabasePois: (maps: DatabaseMapPoi[] | undefined) => set((state) => ({
    allDatabasePois: maps,
  })),

  shortcutKeys: {
    shift: false,
    alt: false,
  },
  setShortcutKeys: (newValue: ShortcutState) => set((state) => ({
    shortcutKeys: {
      ...state.shortcutKeys,
      ...newValue
    },
  })),

  openModals: {},
  preventModalClose: {},
  setPreventModalClose: (s: string, preventClose: boolean) => set((state) => {
    const newModals = {
      ...state.preventModalClose,
      [s]: preventClose
    };

    return ({
      preventModalClose: newModals,
    });
  }),
  anyModalOpen: false,
  setModalOpen: (s: string, open: boolean) => set((state) => {
    if (state && state.preventModalClose && s in state.preventModalClose && state.preventModalClose[s] && !open) {
      return ({});
    }
    
    const newModals = {
      ...state.openModals,
      [s]: open
    };

    return ({
      openModals: newModals,
      anyModalOpen: Object.values(newModals).some(e => e),
    });
  }),

  windowDimensions: [0,0],
  setWindowDimensions: (n: number[]) => set((state) => ({
    windowDimensions: n,
  })),

  setDiceRoll: (func: (notation: string) => void) => set((state) => ({
    diceRoll: func
  })),

  setPatreonData: (p: PatreonData) => set((state) => ({
    patreon: p,
  })),

  setUserData: (u: UserData | undefined) => set((state) => ({
    userData: u
  })),
  
  setDiceBox: (b: dice_box | undefined) => set((state) => ({
    diceBox: b,
  })),

  lastStoredAnnouncementId: window.localStorage.getItem('latest-announcement'),
  setLastStoredAnnouncementId: (s: string) => set((state) => {
    window.localStorage.setItem('latest-announcement', s);

    return ({
      lastStoredAnnouncementId: s,
    });
  })
}));