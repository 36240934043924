import { theme } from "antd";
import React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";

interface Props extends PropsWithChildren {

}

const Set = styled.div<{ background: string }>`
  height: 48px;
  flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  pointer-events: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  box-shadow: 0px 2px 10px rgba(5, 0, 56, 0.2);
  background-color: ${props => props.background};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 2px;
`;

export default function ToolSet({children}: Props) {

  const { token } = theme.useToken();

  return (
    <Set background='rgb(23, 23, 23)' className={"toolbarset"}>
      {children}    
    </Set>
  )
}