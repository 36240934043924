import React, { KeyboardEvent, useContext, useEffect, useState } from 'react';
import { useActive, useChainedCommands, useCommands } from '@remirror/react';
import { AutoComplete, Button, Dropdown, FloatButton, MenuProps, Popover, Tooltip, Typography } from 'antd';
import { InlineButtonGroup } from '../AntExtras/InlineButtonGroup';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, BoldOutlined, FileImageOutlined, FontSizeOutlined, ItalicOutlined, OrderedListOutlined, PlusOutlined, RedoOutlined, UndoOutlined, UnorderedListOutlined } from '@ant-design/icons';
import FeatureSpacer from '../Map/Controls/FeaturePopover/FeatureSpacer';
import { useMainMenuState } from '../Menu/MainMenu';
import { Session, SupabaseClient } from '@supabase/supabase-js';
import { DatabaseImageData, MapAutoComplete } from '../../types';
import { useGlobalState } from '../Menu/GlobalState';

const { Title } = Typography;

export const RichEditorButtons = () => {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  
  // Access the commands and the activity status of the editor.
  const { toggleItalic, toggleHeading, toggleBold, insertHardBreak, undo, redo, insertImage, leftAlign, rightAlign, centerAlign } = useCommands();
  const chainCommands = useChainedCommands();
  const active = useActive();

  const items: MenuProps['items'] = [
    {
      label: <a onClick={() => toggleHeading({level: 1})}><Title level={1}>Heading 1</Title ></a>,
      key: '0',
    },
    {
      label: <a onClick={() => toggleHeading({level: 2})}><Title level={2}>Heading 2</Title ></a>,
      key: '1',
    },
    {
      label: <a onClick={() => toggleHeading({level: 3})}><Title level={3}>Heading 3</Title ></a>,
      key: '2',
    },
    {
      label: <a onClick={() => toggleHeading({level: 4})}><Title level={3}>Heading 4</Title ></a>,
      key: '3',
    },
    {
      label: <a onClick={() => toggleHeading({level: 5})}><Title level={5}>Heading 5</Title ></a>,
      key: '5',
    },
  ];

  const [imageTooltipVisible, setImageTooltipVisible] = useState(false);
  const [imagePopupVisible, setImagePopupVisible] = useState(false);
  const [originalImages, setOriginalImages] = useState<MapAutoComplete[]>();
  const [workingImageChoice, setWorkingImageChoice] = useState<string>(undefined);

  useEffect(() => {
    if (!imagePopupVisible)
      return;

    if (!session || !supabase)
      return;

    setWorkingImageChoice(undefined);

    const getData = async () => {
      const {error, data} = await supabase
        .from('images')
        .select('id, created_at, file_size, width, height, file_name')
        .eq('owner_id', session.user.id);

      if (data) {
        const newImages: MapAutoComplete[] = [];
        data.forEach((image) => {
          newImages.push({
            value: image.file_name,
            label: image.file_name,
          });
        });
        setOriginalImages(newImages);
        console.log(newImages);
      }
    };

    getData();
  }, [supabase, imagePopupVisible, session, setOriginalImages, setWorkingImageChoice]);

  const uploadImageContent = (
    <div style={{ width: '150px' }}>
      <AutoComplete
        value={workingImageChoice}
        options={originalImages}
        onSelect={(val) => {
          setWorkingImageChoice(val);
        }}
        onSearch={(val) => {
          setWorkingImageChoice(val);
        }}
        onChange={(val) => {
          setWorkingImageChoice(val);
        }}
        placeholder="Insert images"
      />
      <Button type={workingImageChoice !== undefined && workingImageChoice !== null ? 'primary' : 'dashed'} disabled={workingImageChoice === undefined || workingImageChoice === null} onClick={() => {
        const path = `https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${session.user.id}/${workingImageChoice}`;
        chainCommands.insertImage({
          src: path,
        }).run();
        setImagePopupVisible(false);
        setImageTooltipVisible(false);
      }}>
        Insert
      </Button>
    </div>
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
      <InlineButtonGroup>
        <Tooltip placement={"top"} title={"Undo the last action"}>
          <FloatButton icon={<UndoOutlined />} onClick={() => undo()} />
        </Tooltip>
        <Tooltip placement={"top"} title={"Redo the next action"}>
          <FloatButton icon={<RedoOutlined />} onClick={() => redo()} />
        </Tooltip>
      </InlineButtonGroup>
      <FeatureSpacer marginLeftRight='8px' />
      <InlineButtonGroup>
        <Tooltip placement={"top"} title={"Italicize the selection"}>
            <FloatButton icon={<ItalicOutlined />} type={active.italic() ? 'primary' : 'default'} onClick={() => toggleItalic()}/>
        </Tooltip>
        <Tooltip placement={"top"} title={"Bold the selection"}>
          <FloatButton icon={<BoldOutlined />} type={active.bold() ? 'primary' : 'default'} onClick={() => toggleBold()} />
        </Tooltip>
        <Tooltip placement={"top"} title={"Change heading size"}>
          <Dropdown menu={{ items }} trigger={['click']}>
            <FloatButton icon={<FontSizeOutlined />} />
          </Dropdown>
        </Tooltip>
      </InlineButtonGroup>
      <FeatureSpacer marginLeftRight='8px' />
      <InlineButtonGroup>
        <Tooltip placement={"top"} title={"Align content left"}>
          <FloatButton icon={<AlignLeftOutlined />} onClick={() => leftAlign()} />
        </Tooltip>
        <Tooltip placement={"top"} title={"Align content center"}>
          <FloatButton icon={<AlignCenterOutlined />} onClick={() => centerAlign()} />
        </Tooltip>
        <Tooltip placement={"top"} title={"Align content right"}>
          <FloatButton icon={<AlignRightOutlined />} onClick={() => rightAlign()} />
        </Tooltip>
      </InlineButtonGroup>
      <FeatureSpacer marginLeftRight='8px' />
      <InlineButtonGroup>
        <Tooltip placement={"top"} title={"Create an ordered list"}>
          <FloatButton icon={<OrderedListOutlined />} type={active.orderedList() ? 'primary' : 'default'} onClick={() => chainCommands.toggleOrderedList().run()} />
        </Tooltip>
        <Tooltip placement={"top"} title={"Create an unordered list"}>
          <FloatButton icon={<UnorderedListOutlined />} type={active.orderedList() ? 'primary' : 'default'} onClick={() => chainCommands.toggleBulletList().run()} />
        </Tooltip>
      </InlineButtonGroup>
      <FeatureSpacer marginLeftRight='8px' />
      <InlineButtonGroup>
        <Popover content={uploadImageContent} title={null} open={imagePopupVisible} trigger={"click"} onOpenChange={(v) => {
          if (!v)
            setImagePopupVisible(v)
        }}>
          <Tooltip placement={"top"} title={"Insert an image"} open={imageTooltipVisible} onOpenChange={(v) => setImageTooltipVisible(v && !imagePopupVisible)} >
            <FloatButton icon={<FileImageOutlined />} type={'default'} onClick={() => {
              setImagePopupVisible(true);
              setImageTooltipVisible(false);
            }} />
          </Tooltip>
        </Popover>
      </InlineButtonGroup>
    </div>
  );
};