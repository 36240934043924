import React from "react";
import { PropsWithChildren } from "react";
import styled from "styled-components";

/**
 * This is the parent class for our MapUI
 */

interface Props extends PropsWithChildren {

}

const BodyUI = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  
  overflow: hidden;

  width: 100%;
  height: 100%;

  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  pointer-events: none;
  -ms-flex-align: end;
  align-items: flex-end;
  will-change: left;
`;

export default function MapUI({children}: Props) {
  return (
    <BodyUI>
      {children}
    </BodyUI>
  )
}