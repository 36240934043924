import { AutoComplete, ColorPicker, InputNumber, Modal, Slider, Steps, Typography, theme, Image as AntImage, message, Spin, Input } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import CanvasComponent from "../../Utility/CanvasComponent";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { AvatarOptions, NewPlayerOptions } from "../../../types";
import { useLocalStorage } from "usehooks-ts";
import { PresetsItem } from "antd/es/color-picker/interface";
import { SupabaseClient, Session } from "@supabase/supabase-js";
import { useGlobalState } from "../../Menu/GlobalState";
import { useMapState } from "../../Map/MapDisplay";

interface Props {
  needsPlayerData: boolean,
  setNeedsPlayerData: (b: boolean) => void,
}

export default function CreatePlayerModal({ needsPlayerData, setNeedsPlayerData }: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);

  const [currentStep, setCurrentStep] = useState(0);
  const [portraitFile, setPortraitFile] = useState<File>();
  
  const editingGameId = useGlobalState((state) => state.editingGameId);
  const editingMapData = useGlobalState((state) => state.editingMapData);

  const [workingPlayerOptions, setWorkingPlayerOptions] = useState<NewPlayerOptions>({});
  
  const [avatarOptions, setAvatarOptions] = useState<AvatarOptions>({});
  const [previousColorOptions, setPreviousColorOptions] = useLocalStorage<string[]>('previous-colors', []);
  
  const { token } = theme.useToken();
  
  const cropStepAvatarEditor = useRef<AvatarEditor>(null);
  const borderStepCanvasRef = useRef<HTMLCanvasElement>(null);

  const [cropStepDataUrl, setCropStepDataUrl] = useState<string>();
  const [borderStepDataUrl, setBorderStepDataUrl] = useState<string>();
  const [borderStepUploading, setBorderStepUploading] = useState(false);
  const [borderStepRedraw, setBorderStepRedraw] = useState(false);
  const [randomChosenBorderColor, setRandomChosenBorderColor] = useState<string>();

  const [finalStepUploading, setFinalStepUploading] = useState(false);

  const [randomImageUpdateString, setRandomImageUpdateString] = useState('');

  const [msg, contextHolder] = message.useMessage();

  const colorPickerOptions: PresetsItem[] = [
    {
      label: 'Recommended',
      colors: [
        '#000000',
        '#000000E0',
        '#000000A6',
        '#00000073',
        '#00000040',
        '#00000026',
        '#0000001A',
        '#00000012',
        '#0000000A',
        '#00000005',
        '#F5222D',
        '#FA8C16',
        '#FADB14',
        '#8BBB11',
        '#52C41A',
        '#13A8A8',
        '#1677FF',
        '#2F54EB',
        '#722ED1',
        '#EB2F96',
        '#F5222D4D',
        '#FA8C164D',
        '#FADB144D',
        '#8BBB114D',
        '#52C41A4D',
        '#13A8A84D',
        '#1677FF4D',
        '#2F54EB4D',
        '#722ED14D',
        '#EB2F964D',
      ],
    },
    {
      label: 'Recent',
      colors: previousColorOptions,
    },
  ];

  useEffect(() => {
    if (needsPlayerData) {
      setCurrentStep(0);
      setPortraitFile(undefined);
      setAvatarOptions({});
      setRandomChosenBorderColor((colorPickerOptions[0].colors[Math.floor(Math.random() * colorPickerOptions[0].colors.length)] as string).substring(0, 7));
      setCropStepDataUrl(undefined);
      setBorderStepDataUrl(undefined);
      setBorderStepUploading(false);
    }
  }, [needsPlayerData]);

  const doUpload = (blob: Blob) => {
    const formData = new FormData();
    formData.append('count', "1");

    let fileName = `TOKENPLAYER${session.user.id}.png`;

    formData.append(`image0`, blob, fileName);

    formData.append('tokenDisplayName', 'PLAYERTOKEN');
    formData.append('tokenForGameId', editingGameId);
    formData.append('tokenIsGlobal', '0');

    supabase.functions.invoke('imagecrud', {
      body: formData
    }).then(({data, error}) => {
      if (error) {
        msg.error(error);
      } else if (data.error) {
        msg.error(data.error);
      } else {
        msg.success('Uploaded your token!');
        setCurrentStep(currentStep + 1);
      }
      setBorderStepUploading(false);
    });
  };

  const creationSteps = [
    {
      title: 'Hi',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center', justifyContent: 'center'}}>
          <Typography style={{ color: token.colorText, marginTop: 24  }}>Hi! Welcome to the Game!</Typography>
          <Typography style={{ color: token.colorTextSecondary, marginBottom: 12 }}>This will walk you through creation of<br />your <b>player</b>.</Typography>
        </div>
      ),
      canProgress: () => true,
      canPrevious: () => false,
    },
    {
      title: 'Upload',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center', justifyContent: 'center', width: '100%'}}>
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>Upload a file for your portrait</Typography>
          <Typography style={{ color: token.colorTextTertiary, marginBottom: 24 }}>This is what will show up for your character's full art.</Typography>
          <Dragger name="file" multiple={false} action={(file: RcFile) => {
            setCurrentStep(currentStep + 1);
            setPortraitFile(file);
            setAvatarOptions({});
            return "";
          }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drop a file to upload</p>
            <p className="ant-upload-hint">
              We'll edit this image in upcoming<br />steps to make your token.
            </p>
          </Dragger>
        </div>
      ),
      canProgress: () => false,
      canPrevious: () => true,
    },
    {
      title: 'Crop',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center'}}>
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>Crop your token</Typography>
          <Typography style={{ color: token.colorTextTertiary, marginBottom: 12 }}>You can drag the interior image!</Typography>
          <AvatarEditor
            image={portraitFile}
            width={250}
            height={250}
            borderRadius={125}
            color={[0, 0, 0, 0.4]} // RGBA
            scale={avatarOptions.scale ?? 1}
            rotate={avatarOptions.rotate ?? 0}
            backgroundColor={avatarOptions.backgroundColor ?? "#ffffff"}
            ref={cropStepAvatarEditor}
            crossOrigin="anonymous"
            position={avatarOptions.position ?? undefined}
            onPositionChange={(pos) => setAvatarOptions({
              ...avatarOptions,
              position: pos
            })}
          />
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginTop: 12 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Scale</Typography>
            <Slider value={avatarOptions.scale ?? 1} min={0.1} max={2} onChange={(v: number) => setAvatarOptions({
              ...avatarOptions,
              scale: v
            })} step={0.01} style={{ flexGrow: 10 }} />
            <InputNumber min={0.01} max={50} value={avatarOptions.scale ?? 1} step={0.01} onChange={(v: number) => {
              setAvatarOptions({
                ...avatarOptions,
                scale: v
              });
            }} style={{ flexGrow: 1 }} />
          </div>
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center'}}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Rotation</Typography>
            <Slider value={avatarOptions.rotate ?? 0} min={0} max={360} onChange={(v: number) => setAvatarOptions({
              ...avatarOptions,
              rotate: v
            })} step={0} style={{ flexGrow: 10 }} />
            <InputNumber min={1} max={360} value={avatarOptions.rotate ?? 0} step={0.01} onChange={(v: number) => {
              setAvatarOptions({
                ...avatarOptions,
                rotate: v
              });
            }} style={{ flexGrow: 1 }} />
          </div>
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginBottom: 24 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Background Color</Typography>
            <div style={{ flexGrow: 10 }}>
              <ColorPicker presets={colorPickerOptions} value={avatarOptions.backgroundColor ?? "#ffffff"} onChange={(_, hex) => setAvatarOptions({
                ...avatarOptions,
                backgroundColor: hex,
              })} />
            </div>
          </div>
        </div>
      ),
      canProgress: () => true,
      canPrevious: () => true,
    },
    {
      title: 'Border',
      content: borderStepUploading ? (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center', justifyContent: 'center'}}>
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>Uploading your image to the server</Typography>
          <Typography style={{ color: token.colorTextTertiary, marginBottom: 24 }}>Give us a moment...</Typography>
          <Spin size="large" />
        </div>
      ): (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center'}}>
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>Add a border to your token</Typography>
          <Typography style={{ color: token.colorTextTertiary }}>Borders help your token stand out against any map</Typography>
          <Typography style={{ color: token.colorTextTertiary, marginBottom: 12 }}>Size of 10 is recommended by default.</Typography>
          <CanvasComponent 
            style={{ width: '250px', height: '250px'}}
            borderImagePadding={avatarOptions.borderSize ?? 5}
            needsRedraw={borderStepRedraw}
            setNeedsRedraw={setBorderStepRedraw}
            baseDataUrl={cropStepDataUrl}
            drawFunction={(c, ctx) => {
              const borderRadius = avatarOptions.borderSize ?? 5;
              ctx.beginPath();
              ctx.arc(c.width/2, c.height/2, 125 - (borderRadius / 2), 0, Math.PI*2);
              ctx.lineWidth = avatarOptions.borderSize ?? 5;
              ctx.strokeStyle = avatarOptions.borderColor ?? randomChosenBorderColor;
              ctx.stroke();
            }}
            passRef={borderStepCanvasRef}
            fillBackColor={(avatarOptions.scale ?? 1) < 1 ? avatarOptions.backgroundColor : undefined}
          />
          {/* <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginTop: 12 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 2 }}>Border Size</Typography>
            <Slider value={avatarOptions.borderSize ?? 5} min={1} max={50} onChange={(v: number) => {
              setAvatarOptions({
                ...avatarOptions,
                borderSize: v
              });
              setBorderStepRedraw(true);
            }} step={0.1} style={{ flexGrow: 10 }} />
            <InputNumber min={1} max={50} value={avatarOptions.borderSize ?? 10} step={0.1} onChange={(v: number) => {
              setAvatarOptions({
                ...avatarOptions,
                borderSize: v
              });
              setBorderStepRedraw(true);
            }} style={{ flexGrow: 1 }} />
          </div> */}
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginBottom: 24 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Border Color</Typography>
            <div style={{ flexGrow: 10 }}>
              <ColorPicker presets={colorPickerOptions} value={avatarOptions.borderColor ?? randomChosenBorderColor} onChange={(_, hex) => {
                setAvatarOptions({
                  ...avatarOptions,
                  borderColor: hex,
                });
                setBorderStepRedraw(true);
              }} />
            </div>
          </div>
        </div>
      ),
      canProgress: () => !borderStepUploading,
      canPrevious: () => !borderStepUploading,
    },
    {
      title: 'Names',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center'}}>
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>What is your character named?</Typography>
          <Typography style={{ color: token.colorTextTertiary }}>This will be shown when moused over</Typography>
          <Input value={workingPlayerOptions.character_name} placeholder="Character Name" onChange={(element) => setWorkingPlayerOptions({
            ...workingPlayerOptions,
            character_name: element.target.value,
          })} size="large" style={{ width: '80%', marginTop: 12, marginBottom: 12 }} maxLength={30}/>
          <AntImage 
            src={editingMapData ? `https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${editingMapData.owner_id}/TOKENPLAYER${session.user.id}.png?${randomImageUpdateString}` : null}
          />
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>And what is your name? (Yes <b>You</b>, the human)</Typography>
          <Input value={workingPlayerOptions.player_name} placeholder="Player Name" onChange={(element) => setWorkingPlayerOptions({
            ...workingPlayerOptions,
            player_name: element.target.value,
          })} size="large" style={{ width: '80%', marginTop: 12, marginBottom: 12 }} maxLength={30}/>
          
        </div>
      ),
      canProgress: () => workingPlayerOptions.character_name && workingPlayerOptions.player_name,
      canPrevious: () => false,
    },
  ];

  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  const offset = Math.floor(currentStep / 4) * 4;
  const items = creationSteps.map((item) => ({ key: item.title, title: item.title }));
  
  return (
    <>
      {contextHolder}
      <Modal 
        centered title={null} open={needsPlayerData} closeIcon={<div style={{ display: 'none'}}></div>}
        cancelButtonProps={{ disabled: currentStep == 0 || !creationSteps[currentStep].canPrevious() }}
        okButtonProps={{ disabled: !creationSteps[currentStep].canProgress() }}
        onCancel={() => {
          setCurrentStep(Math.max(0, currentStep - 1));
        }}
        onOk={() => {
          setRandomImageUpdateString(Date.now().toString());
          if (currentStep < creationSteps.length - 1) {
            if (creationSteps[currentStep].title == 'Crop') {
              if (cropStepAvatarEditor && cropStepAvatarEditor.current) {
                setCropStepDataUrl(cropStepAvatarEditor.current.getImageScaledToCanvas().toDataURL());
                setBorderStepRedraw(true);
              }
            } else if (creationSteps[currentStep].title == 'Border') {
              if (borderStepCanvasRef && borderStepCanvasRef.current) {
                setBorderStepDataUrl(borderStepCanvasRef.current.toDataURL());
                setBorderStepUploading(true);
                borderStepCanvasRef.current.toBlob((b) => {
                  doUpload(b);
                });
                return;
              }
            }

            setCurrentStep(currentStep + 1);
          } else {
            setFinalStepUploading(true);
            supabase
              .from('game_players')
              .insert({
                game_id: editingGameId,
                user_id: session.user.id,
                ...workingPlayerOptions,
                border_color: avatarOptions.borderColor ?? randomChosenBorderColor,
              })
              .then(({data, error}) => {
                if (error) {
                  msg.error(error.message);
                } else if (data && (data as any).error) {
                  msg.error((data as any).error);
                } else {
                  msg.success('Player details saved!');
                  setNeedsPlayerData(false);
                }
                setFinalStepUploading(false);
              })
          }
        }}
        okText={currentStep == creationSteps.length - 1 ? 'Done!' : 'Next Step'}
        cancelText={'Previous Step'}
      >
        <Steps current={currentStep} items={items.splice(offset, 4)} initial={offset}/>
        <div style={contentStyle}>{creationSteps[currentStep].content}</div>
      </Modal>
    </>
  )
}