import React from "react";
import { LuEraser } from "react-icons/lu";
import CommandTemplate, { CommandFunctionParameters } from "../CommandTemplate";
import { IoColorPaletteOutline } from "react-icons/io5";
import { Fill, Stroke, Style } from "ol/style";
import { ColorPicker } from "antd";

export default [
  {
    icon: <LuEraser />,
    text: "Erase drawing",
    keywords: 'erase,drawing',
    category: 'Drawing',

    perform: (props: CommandFunctionParameters) => {
      props.myDrawSource.removeFeature(props.hoveredDrawFeature);
      props.setHoveredDrawFeature(undefined);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.hoveredDrawFeature)
        return false;

      if (!props.myDrawSource)
        return false;

      return true;
    }
  },
  {
    icon: <IoColorPaletteOutline />,
    text: "Color drawing",
    keywords: 'color,drawing',
    category: 'Drawing',

    nestedPlaceholder: 'What color?',
    nestedBreadcrumbs: ['Map', 'Drawing / Color'],
    nestedTemplates: (props: CommandFunctionParameters) => {
      const colorOptions = [
        '#000000',
        '#F5222D',
        '#FA8C16',
        '#FADB14',
        '#8BBB11',
        '#52C41A',
        '#13A8A8',
        '#1677FF',
        '#2F54EB',
        '#722ED1',
        '#EB2F96',
        '#F5222D4D',
        '#FA8C164D',
        '#FADB144D',
        '#8BBB114D',
        '#52C41A4D',
        '#13A8A84D',
        '#1677FF4D',
        '#2F54EB4D',
        '#722ED14D',
        '#EB2F964D',
      ];

      return colorOptions.map((color) => ({
        icon: (<>
          <ColorPicker presets={[]} value={color} onChange={(_, hex) => {}} open={false} />
        </>),
        text: color,
        alwaysShow: true,

        perform: (props: CommandFunctionParameters) => {
          // This is the normal amount the fill is lightened by.
          const percentageLightening = 170 / 255;

          const substring = color.substring(0,7);
          let lightenDecimal = 'aa';
          if (color.length == 9) {
            const existingValue = parseInt(color.substring(7,9), 16);
            lightenDecimal = Math.ceil(existingValue * percentageLightening).toString(16);
          }

          props.hoveredDrawFeature.setStyle(new Style({
            stroke: new Stroke({
              width: 5,
              color: color,
            }),
            fill: new Fill({
              color: `${substring}${lightenDecimal}`,
            })
          }));

          props.setForceDrawingUpdate(true);
        }
      }));
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.hoveredDrawFeature)
        return false;

      if (!props.myDrawSource)
        return false;

      return true;
    }
  },
] as CommandTemplate[];