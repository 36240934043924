import React from "react";
import { LiaDiceD20Solid } from "react-icons/lia";
import CommandTemplate, { CommandFunctionParameters } from "../CommandTemplate";
import { TiCancel } from "react-icons/ti";
import {LuGrab} from "react-icons/lu";

export default [
  {
    icon: <LiaDiceD20Solid />,
    text: "Roll Dice",
    keywords: 'roll,dice,d20,d4,d6,d12,d8,d100',
    category: 'Misc',

    nestedPlaceholder: 'Roll what? (Ex: 4d6 + 2, 1d4, 3d20)',
    nestedBreadcrumbs: ['Misc', 'Roll Dice'],
    nestedTemplates: [
      {
        icon: <LiaDiceD20Solid />,
        text: "Roll!",
        alwaysShow: true,
        category: "Options",
        perform: (props: CommandFunctionParameters) => {
          if (props.currentText.current.length > 0)
            props.diceRoll(props.currentText.current);
          else
            props.setOpen(2);
        }
      },
      {
        icon: <TiCancel />,
        text: 'Cancel',
        keywords: '',
        category: "Options",
        alwaysShow: true,
      
        perform: (props: CommandFunctionParameters) => {
          props.setOpen(1);
        }
      },
    ] as CommandTemplate[],

    shouldInclude: (props: CommandFunctionParameters) => {
      if (props.mouseOverFeature && !props.isFullMenu)
        return false;

      return props.diceRoll !== undefined;
    }
  },
  {
    icon: <LuGrab />,
    text: "Bring Players to this Map",
    keywords: 'bring,players,here',
    category: 'Misc',
    
    perform: (props: CommandFunctionParameters) => {
      props.channels['main'].send({
        type: 'broadcast',
        event: 'change-map',
        payload: props.editingMapData.map_id,
      });
    },
    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;
      
      if (!props.isFullMenu)
        return false;
      
      return true;
    }
  }
] as CommandTemplate[];