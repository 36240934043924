import { UserOutlined, LaptopOutlined, NotificationOutlined, PaperClipOutlined, CompassOutlined } from "@ant-design/icons";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { Badge, Layout, Menu, MenuProps, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import MyGames from "./AccountPages/MyGames";
import SignIn from "./AccountPages/SignIn";
import MyUploads from "./AccountPages/MyUploads";
import { create } from "zustand";
import { DatabaseGameData, DatabaseImageData, DatabaseMap, DatabaseToken } from '../../types';
import { useGlobalState } from "./GlobalState";
import { shallow } from "zustand/shallow";
import GameMaps from "./GamePages/GameMaps";
import GameTokens from "./GamePages/GameTokens";
import MyAccount from "./AccountPages/MyAccount";
import AnnouncementsPage from "./InfoPages/AnnouncementsPage";
import AboutPage from "./InfoPages/AboutPage";

interface Props {
  session: Session | undefined
}

export interface MainMenuState {
  // For the MyGames page.
  displayGamesData?: DatabaseGameData[] | undefined,
  setDisplayGamesData: (newArray: DatabaseGameData[]) => void,
  originalGamesData?: DatabaseGameData[] | undefined,
  setOriginalGamesData: (newArray: DatabaseGameData[]) => void,

  // For the MyUploads page.
  displayImagesData?: DatabaseImageData[] | undefined,
  setDisplayImagesData: (newArray: DatabaseImageData[]) => void,
  originalImagesData?: DatabaseImageData[] | undefined,
  setOriginalImagesData: (newArray: DatabaseImageData[]) => void,

  // For the GameMaps page.
  displayGameMaps?: DatabaseMap[] | undefined,
  setDisplayGameMaps: (newArray: DatabaseMap[]) => void,
  originalGameMaps?: DatabaseMap[] | undefined,
  setOriginalGameMaps: (newArray: DatabaseMap[]) => void,

  // For the GameTokens page.
  displayGameTokens?: DatabaseToken[] | undefined,
  setDisplayGameTokens: (newArray: DatabaseToken[]) => void,
  originalGameTokens?: DatabaseToken[] | undefined,
  setOriginalGameTokens: (newArray: DatabaseToken[]) => void,

  editingGameData?: DatabaseGameData | undefined,
  setEditingGameData: (editing: DatabaseGameData) => void,

  forceRefresh: boolean,
  setForceRefresh: (b: boolean) => void,

  selectedTab: string[],
  setSelectedTab: (tab: string[]) => void,
}

export const useMainMenuState = create<MainMenuState>((set) => ({
  setDisplayGamesData: (newArray) => set((state: MainMenuState) => ({
    displayGamesData: newArray,
  })),
  setOriginalGamesData: (newArray) => set((state: MainMenuState) => ({
    originalGamesData: newArray,
  })),

  setDisplayImagesData: (newArray) => set((state: MainMenuState) => ({
    displayImagesData: newArray,
  })),
  setOriginalImagesData: (newArray) => set((state: MainMenuState) => ({
    originalImagesData: newArray,
  })),

  setDisplayGameMaps: (newArray) => set((state: MainMenuState) => ({
    displayGameMaps: newArray,
  })),
  setOriginalGameMaps: (newArray) => set((state: MainMenuState) => ({
    originalGameMaps: newArray,
  })),

  setDisplayGameTokens: (newArray) => set((state: MainMenuState) => ({
    displayGameTokens: newArray,
  })),
  setOriginalGameTokens: (newArray) => set((state: MainMenuState) => ({
    originalGameTokens: newArray,
  })),

  setEditingGameData: (editing) => set((state: MainMenuState) => ({
    editingGameData: editing,
  })),

  forceRefresh: true,
  setForceRefresh: (b) => set((state) => ({
    forceRefresh: b,
  })),

  selectedTab: JSON.parse(window.localStorage.getItem('selected-tab')) || ['login'],
  setSelectedTab: (s) => set((state) => {
    window.localStorage.setItem('selected-tab', JSON.stringify(s));
    return ({
      selectedTab: s,
    });
  }),
}));

export default function MainMenu({session}: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const [openedKeys, setOpenedKeys] = useLocalStorage<string[]>('menu-open', [
    'info', 'user'
  ]);

  const [selectedTab, setSelectedTab] = useMainMenuState((state) => [state.selectedTab, state.setSelectedTab], shallow);
  const [editingGame, setEditingGame] = useGlobalState((state) => [state.editingGameId, state.setEditingGameId], shallow);

  let menuSidebar: MenuProps["items"] = [
    {
      key: 'info',
      icon: (<PaperClipOutlined />),
      label: 'Info',
  
      children: session ? [
        {
          key: 'news',
          label: 'Announcements',
        },
        {
          key: 'about',
          label: 'About',
        },
        // {
        //   key: 'support',
        //   label: 'Support',
        // }
      ] : [
        {
          key: 'news',
          label: 'Announcements',
        },
        {
          key: 'about',
          label: 'About',
        },
      ],
    },
    {
      key: 'user',
      icon: (<UserOutlined />),
      label: 'User',

      // TODO change the children here depending on login status
      children: session ? [
        {
          key: 'account',
          label: 'My Account'
        },
        {
          key: 'games',
          label: 'Games',
        },
        {
          key: 'uploads',
          label: 'Uploads',
        },
        {
          key: 'logout',
          label: 'Sign Out'
        },
      ] : [
        {
          key: 'login',
          label: 'Sign-In'
        },
      ],
    }
  ];

  if (session && editingGame) {
    menuSidebar.push({
      key: 'game',
      icon: (<CompassOutlined />),
      label: 'Game',

      children: [
        // {
        //   key: 'details',
        //   label: 'Details',
        // },
        {
          key: 'maps',
          label: 'Maps',
        },
        // {
        //   key: 'tokens',
        //   label: 'Tokens',
        // },
        {
          key: 'players',
          label: 'Players',
        },
      ]
    })
  }
  
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  let page = <>You shouldn't see this</>;
  if (selectedTab[0] == 'games')
    page = <MyGames editingGame={editingGame} setEditingGame={setEditingGame} />;
  else if (selectedTab[0] == 'login')
    page = <SignIn setSelectedPage={(s) => setSelectedTab([s])} />;
  else if (selectedTab[0] == 'uploads')
    page = <MyUploads session={session} />;
  else if (selectedTab[0] == 'maps')
    page = <GameMaps />;
  else if (selectedTab[0] == 'tokens')
    page = <GameTokens />;
  else if (selectedTab[0] == 'account')
    page = <MyAccount />;
  else if (selectedTab[0] == 'news')
    page = <AnnouncementsPage />;
  else if (selectedTab[0] == 'about')
    page = <AboutPage />;

  return (
    <Layout style={{ position: "absolute", top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: '20px', width: '80%', height: '80%', background: colorBgContainer }}>
      <Sider style={{ background: colorBgContainer, overflow: 'scroll', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }} width={200}>
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['info']}
          style={{ height: '100%', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}
          items={menuSidebar}
          onChange={(out) => console.log(out)}
          openKeys={openedKeys}
          onOpenChange={(openOnes) => setOpenedKeys(openOnes)}
          selectedKeys={selectedTab}
          onSelect={(info) => {
            if (info.selectedKeys && info.selectedKeys.length > 0 && info.selectedKeys[0] == 'logout') {
              setSelectedTab(['news']);
              setOpenedKeys([]);
              setEditingGame(undefined);
              supabase.auth.signOut();
            } else
              setSelectedTab(info.selectedKeys)
          }}
        />
      </Sider>
      <Content style={{ padding: '24px 24px', minHeight: 280, display: 'flex', flexDirection: 'column' }}>
        {page}
      </Content>
    </Layout>
  )
};