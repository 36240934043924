import {
  Button,
  Card,
  Drawer,
  Input,
  List,
  Skeleton,
  Tooltip,
  Typography,
  Image,
  theme,
  CollapseProps,
  Collapse,
  Popconfirm,
  message,
  ColorPicker
} from "antd";
import React, { useContext, useEffect, useState, useRef } from "react";
import {NormalDrawingColors, useMapState} from "../../MapDisplay";
import { shallow } from "zustand/shallow";
import { SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { useMainMenuState } from "../../../Menu/MainMenu";
import { SupabaseClient, Session } from "@supabase/supabase-js";
import {DatabaseMap, DatabaseToken, isDemo, SvgPathDrawSettings} from "../../../../types";
import { useGlobalState } from "../../../Menu/GlobalState";
import * as fuzzysort from "fuzzysort";
import { filterTitle } from '../../../../util/filterutil';
import DraggableTokenImage from "./DraggableTokenImage";
import { MdOutlineFavorite, MdOutlineFavoriteBorder } from "react-icons/md";
import { LuInfo, LuSettings } from "react-icons/lu";
import { useLocalStorage } from "usehooks-ts";
import GroupChangeDropZone from "./GroupChangeDropZone";
import { useDragDropManager } from "react-dnd";
import CreateTokenModal from "../../../Menu/Operations/CreateTokenModal";
import CreateTokenModelNewImage from "../../../Menu/Operations/CreateTokenModelNewImage";
import TokenDetailsModal from "./TokenDetailsModal";
import {FaInfoCircle, FaDice} from "react-icons/fa";
import chroma from "chroma-js";
import * as Icons from 'react-game-icons-auto';
import DraggableSVGToken from "./DraggableSVGToken";

interface Props {
  addNewTokenToMap: (tokeName: string, token: DatabaseToken, addingExtraSvgInfo?: string | undefined) => void,
}

export default function TokenSidebar({addNewTokenToMap}: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  const editingGameId = useGlobalState((state) => state.editingGameId);
  const [tokenSidebarOpen, setTokenSidebarOpen] = useMapState((state) => [state.tokenSidebarOpen, state.setTokenSidebarOpen], shallow);
  const [filterTokens, setFilterTokens] = useState<string>();
  const editingMapData = useGlobalState((state) => state.editingMapData);
  
  const [forceRefresh, setForceRefresh] = useState(false);
  const [originalGameTokens, setOriginalGameTokens] = useMainMenuState((state) => [state.originalGameTokens, state.setOriginalGameTokens], shallow);
  const [displayGameTokens, setDisplayGameTokens] = useMainMenuState((state) => [state.displayGameTokens, state.setDisplayGameTokens], shallow);
  const [currentlyDragging, setCurrentlyDragging] = useState<string>();
  const [lastDragging, setLastDragging] = useState<string>();
  const [pendingChangeGroup, setPendingChangeGroup] = useState<string>();
  const [newGroupName, setNewGroupName] = useState<string>();

  const setModalOpen = useGlobalState((state) => state.setModalOpen);

  const [createTokenModalOpen, setCreateTokenModalOpen] = useState(false);

  const [tokenDetailsOpen, setTokenDetailsOpen] = useState(false);
  const [tokenDetailsToken, setTokenDetailsToken] = useState<DatabaseToken>(null);

  const [localFavorites, setLocalFavorites] = useLocalStorage('token-favorites', []);
  const [localOpenedKeys, setLocalOpenKeys] = useLocalStorage('sidebar-collapse-opened', ['0', 'builtin']);
  const [forceExtraGroups, setForceExtraGroups] = useLocalStorage('force-local-token-groups', []);
  const [messageApi, contextHolder] = message.useMessage({ });
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();
  const drawerRef = useRef(null);
  const [showingBuiltInIconNames, setShowingBuiltInIcons] = useState<{ name: string, highlightTitleIndexes?: number[]; }[]>([]);
  const [newBorderColor, setNewBorderColor] = useLocalStorage('builtintoken-lastborder', '#F5222D');
  const [foregroundColor, setForegroundColor] = useLocalStorage('builtintoken-lastfore', '#ffffff');
  const [backgroundColor, setBackgroundColor] = useLocalStorage('builtintoken-lastback', '#000000');
  const [previousColorOptions, setPreviousColorOptions] = useLocalStorage<string[]>('previous-colors', []);

  const { token } = theme.useToken();

  const amGM = useMapState((state) => state.amGM);
  
  // This one makes sure we have token data
  useEffect(() => {
    if (originalGameTokens && !forceRefresh)
      return;

    if (supabase && session && session.user && editingGameId) {
      const getData = async () => {
        const { error, data } = await supabase
          .from('tokens')
          .select('*')
          .or(`is_global.eq.TRUE,and(owner_id.eq.${session.user.id},game_id.eq.${editingGameId})`);

        if (data) {
          setOriginalGameTokens(data.map((item) => ({
            ...(item as unknown as DatabaseToken)
          })));
          setForceRefresh(false);
        }
      };

      getData();
    }
  }, [editingGameId, supabase, session, setOriginalGameTokens, forceRefresh, setForceRefresh, originalGameTokens]);

  useEffect(() => {
    setModalOpen('token-sidebar', tokenSidebarOpen);
  }, [tokenSidebarOpen]);

  useEffect(() => {
    if (!originalGameTokens)
      return;

    if (!tokenSidebarOpen)
      return;

    let result: DatabaseToken[] = [];
    if (filterTokens && filterTokens.length > 0) {
      fuzzysort.cleanup();
      result = fuzzysort.go(filterTokens, originalGameTokens, { key: 'display_name' }).map((i) => {
        return {
          ...i.obj,
          highlightTitleIndexes: (i as any)._indexes
        }
      });
    } else
      result = originalGameTokens;
    setDisplayGameTokens(result.filter((t) => !t.is_global && t.display_name != 'PLAYERTOKEN').sort((a, b) => {
      const aIncluded = localFavorites.includes(a.token_id);
      const bIncluded = localFavorites.includes(b.token_id);

      const aValue = aIncluded ? 0 : 1;
      const bValue = bIncluded ? 0 : 1;

      const current = aValue - bValue;

      // If one has a favorite and another doesn't, return that sort.
      if (current != 0)
        return current;

      // If they are still the same, return their names sorted alphabetically.
      return a.display_name == b.display_name ? 0 : (a.display_name.substring(0, 1) > b.display_name.substring(0, 1) ? 1 : -1);
    }));
  }, [originalGameTokens, setDisplayGameTokens, filterTokens, forceRefresh, tokenSidebarOpen]);

  // Search through built in icons that match our filter string, or default to create and monsters
  useEffect(() => {
    if (!amGM)
      return;
    
    const timeout = setTimeout(() => {
      let filterString = 'Creature & Monster';
      if (filterTokens && filterTokens.length > 0)
        filterString = filterTokens;

      fuzzysort.cleanup();
      // @ts-ignore
      setShowingBuiltInIcons(fuzzysort.go(filterString, Object.keys(Icons.IconNameToTags).map((name) => `${name}/${Icons.IconNameToTags[name].join(', ')}`), {
        threshold: -1000
      }).map((i) => {
        const name = i.target as string;
        return {
          name: name.substring(0, name.indexOf('/')),
          highlightTitleIndexes: (i as any)._indexes
        }
      }));
    }, 400);
    
    return () => clearTimeout(timeout);
  }, [filterTokens, amGM]);
  
  const groups: {[key: string]: DatabaseToken[]} = {
    'Favorites': [],
    'Unsorted': [],
  }

  for (let i = 0; i < (displayGameTokens ?? []).length; i += 1) {
    const thisToken = displayGameTokens[i];

    if (localFavorites.includes(thisToken.token_id)) {
      groups['Favorites'].push(thisToken);
      continue;
    }

    if (!thisToken.group) {
      groups['Unsorted'].push(thisToken);
    } else {
      if (thisToken.group in groups) {
        groups[thisToken.group].push(thisToken);
      } else {
        groups[thisToken.group] = [thisToken];
      }
    }
  }

  if (amGM) {
    forceExtraGroups.forEach((extra) => {
      if (!(extra in groups))
        groups[extra] = []
    });
  }

  const collapseItems: CollapseProps['items'] = Object.keys(groups).sort((keyA, keyB) => {
    if (keyA == 'Favorites')
      return -1000;
    if (keyB == 'Favorites')
      return 1000;
    if (keyA == 'Unsorted')
      return 1000;
    if (keyB == 'Unsorted')
      return -1000;
    return keyA.localeCompare(keyB);
  }).map((key, index) => {
    return ({
      key: `${index}`,
      label: <GroupChangeDropZone name={key} addDraggedToGroup={() => {
        setPendingChangeGroup(key);
      }}> 
        {key}{filterTokens && filterTokens.length > 0 ? ` (${groups[key].length})` : ''}
      </GroupChangeDropZone>,
      children: <GroupChangeDropZone name={key} addDraggedToGroup={() => {
        setPendingChangeGroup(key)
      }}>
        {groups[key].length > 0 ? (
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 40px)', gridAutoRows: '40px', gridGap: '10px'}}>
            {groups[key].map((item, index) => (
              <Tooltip title={item.display_name} trigger={"hover"} key={index}>
                <div style={{width: 40, height: 40}}>
                  <DraggableTokenImage 
                    backgroundImage={item.background_image}
                    ownerId={editingMapData.owner_id}
                    setDrawerOpen={setTokenSidebarOpen}
                    drawerOpen={tokenSidebarOpen}
                    droppedCallback={() => {
                      addNewTokenToMap(currentlyDragging, item);
                    }}
                    setCurrentlyDragging={(s) => {
                      setCurrentlyDragging(s);
                      setLastDragging(s);
                    }}
                    onClick={() => {
                      if (!amGM)
                        return;
                      setTokenDetailsToken(item);
                      setTokenDetailsOpen(true);
                    }}
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        ) : (
          <Typography style={{ color: token.colorTextSecondary, textAlign: 'center'  }}>Drag tokens here to add them to '{key}'</Typography>
        )}</GroupChangeDropZone>
    });
  });
  
  const colorSelector = (name: string, current: string, change: (hex: string) => void, context: string) => (
    <div style={{
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      borderRadius: '12px',
      alignItems: 'center',
      border: `2px solid ${current}`,
      padding: '4px',
      flexGrow: '1',
      backgroundColor: chroma(current).darken(3).hex()
    }}>
      <ColorPicker presets={[
        {
          label: 'Recommended',
          colors: NormalDrawingColors
        },
        {
          label: 'Recent',
          colors: previousColorOptions
        }
      ]} value={current} onChange={(_, hex) => change(hex)}/>
      <Tooltip title={context}>
        <span style={{ marginLeft: '4px', marginRight: '4px' }}>
          {name}
        </span>
      </Tooltip>
      <span style={{color: current}}>{current}</span>
    </div>
  );
  
  if (amGM) {
    // @ts-ignore
    collapseItems.push({
      key: `builtin`,
      label: <>
        <Tooltip title={"This is a set of tokens provided automatically by Mapsfortable.top from Game-Icons.net"}>
          {filterTokens && filterTokens.length > 0 ? `Built-In (${showingBuiltInIconNames.length})` : 'Built-In'}
          <FaInfoCircle style={{marginLeft: '4px'}}/>
        </Tooltip>
      </>,
      children: (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '8px'}}>
            {colorSelector('Front', foregroundColor, setForegroundColor, "The below tokens will use this for their foreground")}
            <span style={{ marginLeft: '2px', marginRight: '2px'}}/>
            {colorSelector('Back', backgroundColor, setBackgroundColor, "The below tokens will use this for their background")}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '8px'}}>
            <Button type={'dashed'} onClick={() => {
              setBackgroundColor(chroma(foregroundColor).darken(2).hex());
            }}>Mix Right</Button>
            <Button type={'dashed'} onClick={() => {
              const random = chroma.random();
              const darkened = random.darken(2).hex();
              setForegroundColor(chroma(darkened).brighten(2).hex())
              setBackgroundColor(darkened);
              setNewBorderColor(chroma(darkened).brighten(2).saturate(10).hex());
            }}>
              <FaDice />
            </Button>
            <Button type={'dashed'} onClick={() => {
              setForegroundColor(chroma(backgroundColor).brighten(2).hex());
            }}>Mix Left</Button>
          </div>
          {colorSelector('Border', newBorderColor, setNewBorderColor, "The below tokens will have this border color applied when you drag them onto the map")}

          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, 40px)', gridAutoRows: '40px', gap: '10px', marginTop: '12px' }}>
            {showingBuiltInIconNames.map((item, index) => {
              const thisName = item.name;

              {/*@ts-ignore*/}
              const func = Icons[item.name];

              const lengthName = thisName.length;
              const highlightForName = item.highlightTitleIndexes.filter((index) => index < lengthName);
              const highlightForTags = item.highlightTitleIndexes.filter((index) => index > lengthName).map((i) => i - lengthName - 1);

              return (
                <Tooltip key={index} title={<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                  <span style={{ fontSize: '18px', marginBottom: '-8px'}}>
                    {filterTitle(thisName,
                      highlightForName,
                      (highlighted: string) => (<span key={highlighted} style={{ textDecoration: 'underline'}}>{highlighted}</span>),
                      (normal: string) => (<span key={normal}>{normal}</span>)
                    )}
                  </span>
                  <br />
                  <div style={{ fontSize: '12px', color: token.colorTextSecondary }}>
                    Tags:
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row'}}>
                    {/*@ts-ignore*/}
                    {filterTitle(Icons.IconNameToTags[thisName].join(', '),
                      highlightForTags,
                      (highlighted: string) => (<span key={highlighted} style={{ textDecoration: 'underline'}}>{highlighted}</span>),
                      (normal: string) => (<span key={normal}>{normal}</span>))}
                  </div>
                </div>}>
                  <div key={index} style={{ width: '42px', height: '42px'}}>
                    <DraggableSVGToken
                      ownerId={editingMapData.owner_id}
                      setDrawerOpen={setTokenSidebarOpen}
                      drawerOpen={tokenSidebarOpen}
                      droppedCallback={() => {
                        let highestMatch = 1;
                        let anyFound = false;
                        
                        editingMapData.pois.forEach((poi) => {
                          const isSvg = poi.map_text.startsWith('svg');
                          const matchesThis = poi.map_text.includes(thisName);
                          const isMatch = isSvg && matchesThis;
                          if (isMatch) {
                            anyFound = true;
                            const thisValue = JSON.parse(poi.map_text.substring(3)) as SvgPathDrawSettings;
                            if (thisValue.extraIdentifier)
                            {
                              const value = parseInt(thisValue.extraIdentifier);
                              if (value > highestMatch)
                                highestMatch = value;
                            }
                          }
                        });
                        
                        if (anyFound)
                          highestMatch += 1;
                        
                        // @ts-ignore
                        const path = Icons[thisName]({}).props.children.props.d;
                        
                        addNewTokenToMap(`svg${JSON.stringify({
                          foreground_color: foregroundColor,
                          background_color: backgroundColor,
                          border_color: newBorderColor,
                          path: path,
                          iconName: thisName,
                          extraIdentifier: anyFound ? highestMatch.toString() : undefined,
                        } as SvgPathDrawSettings)}`, undefined, anyFound ? thisName : undefined);
                        setPreviousColorOptions((previous) => ([
                          ...previous,
                          foregroundColor,
                          backgroundColor,
                          newBorderColor,
                        ]));
                      }}
                      setCurrentlyDragging={() => {
                        setCurrentlyDragging(thisName);
                        setLastDragging(thisName);
                      }}
                    >
                      <div style={{ borderRadius: '100px', backgroundColor: backgroundColor, border: `1px solid ${newBorderColor}`, width: '42px', height:'42px', overflow: 'hidden', cursor: 'pointer'}}>
                        {func({ color: foregroundColor })}
                      </div>
                    </DraggableSVGToken>
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </>
      )
    });
  }

  useEffect(() => {
    if (!pendingChangeGroup)
      return;

    const foundToken = displayGameTokens.find((token) => token.background_image == lastDragging);

    if (pendingChangeGroup == 'Favorites') {
      // Favorites are stored locally
      setLocalFavorites([...localFavorites, foundToken.token_id]);
    } else {
      if (localFavorites.includes(foundToken.token_id)) {
        // We are unfavoriting something.
        const newFavorites = [...localFavorites];
        const foundIndex = newFavorites.findIndex((i) => i == foundToken.token_id);
        newFavorites.splice(foundIndex, 1);
        setLocalFavorites(newFavorites);
      } else {
        // We are setting it to a group that needs a database change 
        if (!amGM) {
          messageApi.error('Players can only drag tokens to favorites or out of favorites.')
          return;
        }

        supabase
          .from('tokens')
          .update({
            group: pendingChangeGroup
          })
          .eq('token_id', foundToken.token_id)
          .eq('owner_id', session.user.id)
          .then(() => {
            setForceRefresh(true);
            messageApi.info(`Changed token's group to ${pendingChangeGroup}`);
          })
      }
    }

    setPendingChangeGroup(null);
  }, [pendingChangeGroup, lastDragging, displayGameTokens, localFavorites, amGM]);
  
  useEffect(() => monitor.subscribeToOffsetChange(() => {
    if (!amGM)
      return;

    if (monitor == null || !monitor.isDragging())
      return;

    let drawerWidth = -1000;
    if (drawerRef.current)
      drawerWidth = drawerRef.current.offsetWidth;

    const cutoff = window.innerWidth - (drawerWidth + 24);

    const offset = monitor.getClientOffset();
    if (offset == null)
      return;

    if (offset.x < cutoff) {
      setTokenSidebarOpen(false);
    }
    // do stuff like setState, though consider directly updating style through refs for performance
  }), [monitor, drawerRef, amGM]);

  return (
    <>
      {contextHolder}
      <Drawer title={amGM ? "Browse / Add Tokens" : "Browse Tokens"} placement={'right'} onClose={() => setTokenSidebarOpen(false)} open={tokenSidebarOpen} key="left">
        {amGM ? (
          <>
            <Typography style={{ color: token.colorTextSecondary, textAlign: 'center'  }}>Drag tokens to the map to add them</Typography>
            <Typography style={{ color: token.colorTextTertiary, textAlign: 'center', marginBottom: 12 }}>Tokens will be saved just like POIs</Typography>
          </>
        ) : (
          <>
            <Typography style={{ color: token.colorTextTertiary, textAlign: 'center', marginBottom: 12 }}>Browse Tokens Below</Typography>
            <Typography style={{ color: token.colorTextSecondary, textAlign: 'center'  }}>Some have info you can read about the character</Typography>
          </>
        )}
        
        <Input size="large" placeholder="Filter or search for tokens..." prefix={<SearchOutlined />} style={{ flexGrow: 3, justifyContent: 'space-between', marginBottom: 12 }} value={filterTokens} onChange={(change) => setFilterTokens(change.target.value)}/>
        {
          !editingGameId || !originalGameTokens ? 
          (
            <>
              <Skeleton />
            </>
          ) : (
            <>
              <Collapse items={collapseItems} activeKey={localOpenedKeys} onChange={(key) => setLocalOpenKeys(key as string[])} style={{ maxHeight: '80%', overflowY: 'auto'}} />
            </>
          )
        }
        {amGM && !isDemo ? (
          <div ref={drawerRef} style={{ display: 'flex', width: '100%', paddingTop: 12, paddingBottom: 12, position: 'absolute', bottom: 0 }}>
            <Popconfirm
              title="Create a new group?"
              description={(
                <>
                  <Input placeholder="Group name..." onChange={(e) => setNewGroupName(e.target.value)}/>
                </>
              )}
              onConfirm={(e: React.MouseEvent<HTMLElement>) => {
                setForceExtraGroups([...forceExtraGroups, newGroupName]);
              }}
              okType="primary"
              cancelText="Cancel"
            >
              <Button>Add Group</Button>
            </Popconfirm>
            <Button style={{ marginLeft: 10 }} onClick={() => setCreateTokenModalOpen(true)}>Create Token From Image</Button>
          </div>
        ) : <></>}
      </Drawer>
      <CreateTokenModelNewImage isModalOpen={createTokenModalOpen} setIsModalOpen={setCreateTokenModalOpen} forceRefresh={() => setForceRefresh(true)} />
      <TokenDetailsModal isModalOpen={tokenDetailsOpen} setIsModalOpen={setTokenDetailsOpen} forceRefresh={() => setForceRefresh(true)} dbToken={tokenDetailsToken}/>
    </>
  );
}