import React from "react";
import { useDrop } from "react-dnd";
import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  addDraggedToGroup: () => void,
  name: string,
}

export default function GroupChangeDropZone({name, addDraggedToGroup, children}: Props) {
  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: 'token-sidebar',
      drop(_item: unknown, monitor) {
        const didDrop = monitor.didDrop()
        if (didDrop) {
          return;
        }

        console.log(`We are calling done ${name}`);

        // We are wanting to move this token into the other group.
        addDraggedToGroup();
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    }),
    [name],
  );

  return (
    //@ts-ignore
    <div ref={drop} showdrop={isOver ? 'show' : undefined}>
      {children}
    </div>
  );
}