import CANNON, { ConvexPolyhedron, RigidBody, World } from "./cannon.min.js";
import THREE, { AmbientLight, CanvasRenderer, Face3, FlatShading, Geometry, Material, Mesh, MeshBasicMaterial, MeshFaceMaterial, MeshLambertMaterial, MeshPhongMaterial, PCFShadowMap, PerspectiveCamera, PlaneGeometry, Scene, SpotLight, Texture, Sphere as ThreeSphere, Vector2, Vector3, WebGLRenderer } from "./three.min.js";
import $t from "./teal";

const material_options = {
  specular: 0x172022,
  color: 0xf0f0f0,
  shininess: 40,
  shading: FlatShading,
};
let use_adaptive_timestamp: boolean = false;
const label_color = '#aaaaaa';
const dice_color = '#202020';
const ambient_light_color = 0xf0f5fb;
const spot_light_color = 0xefdfd5;
const selector_back_colors = { color: 0x404040, shininess: 0, emissive: 0x858787 };
const desk_color = 0xdfdfdf;
const use_shadows = true;

const known_types = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20', 'd100'];
const dice_face_range = { 'd4': [1, 4], 'd6': [1, 6], 'd8': [1, 8], 'd10': [0, 9], 
  'd12': [1, 12], 'd20': [1, 20], 'd100': [0, 9] };
const dice_mass = { 'd4': 300, 'd6': 300, 'd8': 340, 'd10': 350, 'd12': 350, 'd20': 400, 'd100': 350 };
const dice_inertia = { 'd4': 5, 'd6': 13, 'd8': 10, 'd10': 9, 'd12': 8, 'd20': 6, 'd100': 9 };

const frame_rate = 1 / 60;

const scale = 90;

function create_shape(vertices: THREE.Vector3[], faces: number[][], radius: number): ConvexPolyhedron {
  var cv = new Array(vertices.length), cf = new Array(faces.length);
  for (var i = 0; i < vertices.length; ++i) {
      var v = vertices[i];
      cv[i] = new CANNON.Vec3(v.x * radius, v.y * radius, v.z * radius);
  }
  for (var i = 0; i < faces.length; ++i) {
      cf[i] = faces[i].slice(0, faces[i].length - 1);
  }
  return new CANNON.ConvexPolyhedron(cv, cf);
}

function make_geom(vertices: THREE.Vector3[], faces: number[][], radius: number, tab: number, af: number): Geometry {
    var geom = new Geometry();
    for (var i = 0; i < vertices.length; ++i) {
        var vertex = vertices[i].multiplyScalar(radius);
        (vertex as any).index = geom.vertices.push(vertex) - 1;
    }
    for (var i = 0; i < faces.length; ++i) {
        var ii = faces[i], fl = ii.length - 1;
        var aa = Math.PI * 2 / fl;
        for (var j = 0; j < fl - 2; ++j) {
            geom.faces.push(new Face3(ii[0], ii[j + 1], ii[j + 2], [geom.vertices[ii[0]],
                        geom.vertices[ii[j + 1]], geom.vertices[ii[j + 2]]], 0, ii[fl] + 1));
            geom.faceVertexUvs[0].push([
                    new Vector2((Math.cos(af) + 1 + tab) / 2 / (1 + tab),
                        (Math.sin(af) + 1 + tab) / 2 / (1 + tab)),
                    new Vector2((Math.cos(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab),
                        (Math.sin(aa * (j + 1) + af) + 1 + tab) / 2 / (1 + tab)),
                    new Vector2((Math.cos(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab),
                        (Math.sin(aa * (j + 2) + af) + 1 + tab) / 2 / (1 + tab))]);
        }
    }
    geom.computeFaceNormals();
    geom.boundingSphere = new ThreeSphere(new Vector3(), radius);
    return geom;
}

function chamfer_geom(vectors: THREE.Vector3[], faces: number[][], chamfer: number) {
    var chamfer_vectors = [], chamfer_faces = [], corner_faces = new Array(vectors.length);
    for (var i = 0; i < vectors.length; ++i) corner_faces[i] = [];
    for (var i = 0; i < faces.length; ++i) {
        var ii = faces[i], fl = ii.length - 1;
        var center_point = new Vector3();
        var face = new Array(fl);
        for (var j = 0; j < fl; ++j) {
            var vv = vectors[ii[j]].clone();
            center_point.add(vv);
            corner_faces[ii[j]].push(face[j] = chamfer_vectors.push(vv) - 1);
        }
        center_point.divideScalar(fl);
        for (var j = 0; j < fl; ++j) {
            var vv = chamfer_vectors[face[j]];
            vv.subVectors(vv, center_point).multiplyScalar(chamfer).addVectors(vv, center_point);
        }
        face.push(ii[fl]);
        chamfer_faces.push(face);
    }
    for (var i = 0; i < faces.length - 1; ++i) {
        for (var j = i + 1; j < faces.length; ++j) {
            var pairs = [], lastm = -1;
            for (var m = 0; m < faces[i].length - 1; ++m) {
                var n = faces[j].indexOf(faces[i][m]);
                if (n >= 0 && n < faces[j].length - 1) {
                    if (lastm >= 0 && m != lastm + 1) pairs.unshift([i, m], [j, n]);
                    else pairs.push([i, m], [j, n]);
                    lastm = m;
                }
            }
            if (pairs.length != 4) continue;
            chamfer_faces.push([chamfer_faces[pairs[0][0]][pairs[0][1]],
                    chamfer_faces[pairs[1][0]][pairs[1][1]],
                    chamfer_faces[pairs[3][0]][pairs[3][1]],
                    chamfer_faces[pairs[2][0]][pairs[2][1]], -1]);
        }
    }
    for (var i = 0; i < corner_faces.length; ++i) {
        var cf = corner_faces[i], face = [cf[0]], count = cf.length - 1;
        while (count) {
            for (var m = faces.length; m < chamfer_faces.length; ++m) {
                var index = chamfer_faces[m].indexOf(face[face.length - 1]);
                if (index >= 0 && index < 4) {
                    if (--index == -1) index = 3;
                    var next_vertex = chamfer_faces[m][index];
                    if (cf.indexOf(next_vertex) >= 0) {
                        face.push(next_vertex);
                        break;
                    }
                }
            }
            --count;
        }
        face.push(-1);
        chamfer_faces.push(face);
    }
    return { vectors: chamfer_vectors, faces: chamfer_faces };
}

function create_geom(vertices: number[][], faces: number[][], radius: number, tab: number, af: number, chamfer: number) {
    var vectors = new Array(vertices.length);
    for (var i = 0; i < vertices.length; ++i) {
        vectors[i] = (new Vector3).fromArray(vertices[i]).normalize();
    }
    var cg = chamfer_geom(vectors, faces, chamfer);
    var geom = make_geom(cg.vectors, cg.faces, radius, tab, af);
    //var geom = make_geom(vectors, faces, radius, tab, af); // Without chamfer
    (geom as any).cannon_shape = create_shape(vectors, faces, radius);
    return geom;
}

const standart_d20_dice_face_labels = [' ', '0', '1', '2', '3', '4', '5', '6', '7', '8',
        '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'];
const standart_d100_dice_face_labels = [' ', '00', '10', '20', '30', '40', '50',
        '60', '70', '80', '90'];

function calc_texture_size(approx: number): number {
    return Math.pow(2, Math.floor(Math.log(approx) / Math.log(2)));
}

const create_dice_materials = function(face_labels: string[], size: number, margin: number) {
    function create_text_texture(text: string, color: string, back_color: string) {
        if (text == undefined) return null;
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        var ts = calc_texture_size(size + size * 2 * margin) * 2;
        canvas.width = canvas.height = ts;
        context.font = ts / (1 + 2 * margin) + "pt Arial";
        context.fillStyle = back_color;
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = color;
        context.fillText(text, canvas.width / 2, canvas.height / 2);
        if (text == '6' || text == '9') {
            context.fillText('  .', canvas.width / 2, canvas.height / 2);
        }
        var texture = new Texture(canvas);
        texture.needsUpdate = true;
        return texture;
    }
    var materials = [];
    for (var i = 0; i < face_labels.length; ++i)
        materials.push(new MeshPhongMaterial($t.copyto(material_options,
                    { map: create_text_texture(face_labels[i], label_color, dice_color) })));
    return materials;
}

const d4_labels = [
    [[], [0, 0, 0], [2, 4, 3], [1, 3, 4], [2, 1, 4], [1, 2, 3]],
    [[], [0, 0, 0], [2, 3, 4], [3, 1, 4], [2, 4, 1], [3, 2, 1]],
    [[], [0, 0, 0], [4, 3, 2], [3, 4, 1], [4, 2, 1], [3, 1, 2]],
    [[], [0, 0, 0], [4, 2, 3], [1, 4, 3], [4, 1, 2], [1, 3, 2]]
];

const create_d4_materials = function(size: number, margin: number, labels: number[][]) {
    function create_d4_text(text: number[], color: string, back_color: string) {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        var ts = calc_texture_size(size + margin) * 2;
        canvas.width = canvas.height = ts;
        context.font = (ts - margin) / 1.5 + "pt Arial";
        context.fillStyle = back_color;
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = color;
        for (var i in text) {
            context.fillText(text[i].toString(), canvas.width / 2,
                    canvas.height / 2 - ts * 0.3);
            context.translate(canvas.width / 2, canvas.height / 2);
            context.rotate(Math.PI * 2 / 3);
            context.translate(-canvas.width / 2, -canvas.height / 2);
        }
        var texture = new Texture(canvas);
        texture.needsUpdate = true;
        return texture;
    }
    var materials = [];
    for (var i = 0; i < labels.length; ++i)
        materials.push(new MeshPhongMaterial($t.copyto(material_options,
                    { map: create_d4_text(labels[i], label_color, dice_color) })));
    return materials;
}

const create_d4_geometry = function(radius: number) {
    var vertices = [[1, 1, 1], [-1, -1, 1], [-1, 1, -1], [1, -1, -1]];
    var faces = [[1, 0, 2, 1], [0, 1, 3, 2], [0, 3, 2, 3], [1, 2, 3, 4]];
    return create_geom(vertices, faces, radius, -0.1, Math.PI * 7 / 6, 0.96);
}

const create_d6_geometry = function(radius: number) {
    var vertices = [[-1, -1, -1], [1, -1, -1], [1, 1, -1], [-1, 1, -1],
            [-1, -1, 1], [1, -1, 1], [1, 1, 1], [-1, 1, 1]];
    var faces = [[0, 3, 2, 1, 1], [1, 2, 6, 5, 2], [0, 1, 5, 4, 3],
            [3, 7, 6, 2, 4], [0, 4, 7, 3, 5], [4, 5, 6, 7, 6]];
    return create_geom(vertices, faces, radius, 0.1, Math.PI / 4, 0.96);
}

const create_d8_geometry = function(radius: number) {
    var vertices = [[1, 0, 0], [-1, 0, 0], [0, 1, 0], [0, -1, 0], [0, 0, 1], [0, 0, -1]];
    var faces = [[0, 2, 4, 1], [0, 4, 3, 2], [0, 3, 5, 3], [0, 5, 2, 4], [1, 3, 4, 5],
            [1, 4, 2, 6], [1, 2, 5, 7], [1, 5, 3, 8]];
    return create_geom(vertices, faces, radius, 0, -Math.PI / 4 / 2, 0.965);
}

const create_d10_geometry = function(radius: number) {
    var a = Math.PI * 2 / 10, k = Math.cos(a), h = 0.105, v = -1;
    var vertices = [];
    for (var i = 0, b = 0; i < 10; ++i, b += a)
        vertices.push([Math.cos(b), Math.sin(b), h * (i % 2 ? 1 : -1)]);
    vertices.push([0, 0, -1]); vertices.push([0, 0, 1]);
    var faces = [[5, 7, 11, 0], [4, 2, 10, 1], [1, 3, 11, 2], [0, 8, 10, 3], [7, 9, 11, 4],
            [8, 6, 10, 5], [9, 1, 11, 6], [2, 0, 10, 7], [3, 5, 11, 8], [6, 4, 10, 9],
            [1, 0, 2, v], [1, 2, 3, v], [3, 2, 4, v], [3, 4, 5, v], [5, 4, 6, v],
            [5, 6, 7, v], [7, 6, 8, v], [7, 8, 9, v], [9, 8, 0, v], [9, 0, 1, v]];
    return create_geom(vertices, faces, radius, 0, Math.PI * 6 / 5, 0.945);
}

const create_d12_geometry = function(radius: number) {
    var p = (1 + Math.sqrt(5)) / 2, q = 1 / p;
    var vertices = [[0, q, p], [0, q, -p], [0, -q, p], [0, -q, -p], [p, 0, q],
            [p, 0, -q], [-p, 0, q], [-p, 0, -q], [q, p, 0], [q, -p, 0], [-q, p, 0],
            [-q, -p, 0], [1, 1, 1], [1, 1, -1], [1, -1, 1], [1, -1, -1], [-1, 1, 1],
            [-1, 1, -1], [-1, -1, 1], [-1, -1, -1]];
    var faces = [[2, 14, 4, 12, 0, 1], [15, 9, 11, 19, 3, 2], [16, 10, 17, 7, 6, 3], [6, 7, 19, 11, 18, 4],
            [6, 18, 2, 0, 16, 5], [18, 11, 9, 14, 2, 6], [1, 17, 10, 8, 13, 7], [1, 13, 5, 15, 3, 8],
            [13, 8, 12, 4, 5, 9], [5, 4, 14, 9, 15, 10], [0, 12, 8, 10, 16, 11], [3, 19, 7, 17, 1, 12]];
    return create_geom(vertices, faces, radius, 0.2, -Math.PI / 4 / 2, 0.968);
}

const create_d20_geometry = function(radius: number) {
    var t = (1 + Math.sqrt(5)) / 2;
    var vertices = [[-1, t, 0], [1, t, 0 ], [-1, -t, 0], [1, -t, 0],
            [0, -1, t], [0, 1, t], [0, -1, -t], [0, 1, -t],
            [t, 0, -1], [t, 0, 1], [-t, 0, -1], [-t, 0, 1]];
    var faces = [[0, 11, 5, 1], [0, 5, 1, 2], [0, 1, 7, 3], [0, 7, 10, 4], [0, 10, 11, 5],
            [1, 5, 9, 6], [5, 11, 4, 7], [11, 10, 2, 8], [10, 7, 6, 9], [7, 1, 8, 10],
            [3, 9, 4, 11], [3, 4, 2, 12], [3, 2, 6, 13], [3, 6, 8, 14], [3, 8, 9, 15],
            [4, 9, 5, 16], [2, 4, 11, 17], [6, 2, 10, 18], [8, 6, 7, 19], [9, 8, 1, 20]];
    return create_geom(vertices, faces, radius, -0.2, -Math.PI / 4 / 2, 0.955);
}

let d4_geometry: Geometry | undefined;
let d4_material: any | undefined;

const create_d4 = function() {
  if (!d4_geometry) d4_geometry = create_d4_geometry(scale * 1.2);
  if (!d4_material) d4_material = new MeshFaceMaterial(
          create_d4_materials(scale / 2, scale * 2, d4_labels[0]));
  return new Mesh(d4_geometry, d4_material);
}

let d6_geometry: Geometry | undefined;
let d8_geometry: Geometry | undefined;
let d10_geometry: Geometry | undefined;
let d12_geometry: Geometry | undefined;
let d20_geometry: Geometry | undefined;
let dice_material: any | undefined;
let d100_material: any | undefined;

const create_d6 = function() {
  if (!d6_geometry) d6_geometry = create_d6_geometry(scale * 0.9);
  if (!dice_material) dice_material = new MeshFaceMaterial(
          create_dice_materials(standart_d20_dice_face_labels, scale / 2, 1.0));
  return new Mesh(d6_geometry, dice_material);
}

const create_d8 = function() {
  if (!d8_geometry) d8_geometry = create_d8_geometry(scale);
  if (!dice_material) dice_material = new MeshFaceMaterial(
          create_dice_materials(standart_d20_dice_face_labels, scale / 2, 1.2));
  return new Mesh(d8_geometry, dice_material);
}

const create_d10 = function() {
  if (!d10_geometry) d10_geometry = create_d10_geometry(scale * 0.9);
  if (!dice_material) dice_material = new MeshFaceMaterial(
          create_dice_materials(standart_d20_dice_face_labels, scale / 2, 1.0));
  return new Mesh(d10_geometry, dice_material);
}

const create_d12 = function() {
  if (!d12_geometry) d12_geometry = create_d12_geometry(scale * 0.9);
  if (!dice_material) dice_material = new MeshFaceMaterial(
          create_dice_materials(standart_d20_dice_face_labels, scale / 2, 1.0));
  return new Mesh(d12_geometry, dice_material);
}

const create_d20 = function() {
  if (!d20_geometry) d20_geometry = create_d20_geometry(scale);
  if (!dice_material) dice_material = new MeshFaceMaterial(
          create_dice_materials(standart_d20_dice_face_labels, scale / 2, 1.0));
  return new Mesh(d20_geometry, dice_material);
}

const create_d100 = function() {
  if (!d10_geometry) d10_geometry = create_d10_geometry(scale * 0.9);
  if (!d100_material) d100_material = new MeshFaceMaterial(
          create_dice_materials(standart_d100_dice_face_labels, scale / 2, 1.5));
  return new Mesh(d10_geometry, d100_material);
}

export const parse_notation = function(notation: string) {
  var no = notation.split('@');
  var dr0 = /\s*(\d*)([a-z]+)(\d+)(\s*(\+|\-)\s*(\d+)){0,1}\s*(\+|$)/gi;
  var dr1 = /(\b)*(\d+)(\b)*/gi;
  var ret: {set: string[], constant: number, result: number[], error: boolean} = { set: [], constant: 0, result: [], error: false }, res;
  while (res = dr0.exec(no[0])) {
      var command = res[2];
      if (command != 'd') { ret.error = true; continue; }
      var count = parseInt(res[1]);
      if (res[1] == '') count = 1;
      var type = 'd' + res[3];
      if (known_types.indexOf(type) == -1) { ret.error = true; continue; }
      while (count--) ret.set.push(type);
      if (res[5] && res[6]) {
          if (res[5] == '+') ret.constant += parseInt(res[6]);
          else ret.constant -= parseInt(res[6]);
      }
  }
  while (res = dr1.exec(no[1])) {
      ret.result.push(parseInt(res[2]));
  }
  return ret;
}

export const stringify_notation = function(nn: {set: string[], constant: number, result: number[], error: boolean}) {
    var dict: {[key: string]: number} = {}, notation = '';
    for (var i in nn.set) 
        if (!dict[nn.set[i]]) dict[nn.set[i]] = 1; else ++dict[nn.set[i]];
    for (var i in dict) {
        if (notation.length) notation += ' + ';
        notation += (dict[i] > 1 ? dict[i] : '') + i;
    }
    if (nn.constant) {
        if (nn.constant > 0) notation += ' + ' + nn.constant;
        else notation += ' - ' + Math.abs(nn.constant);
    }
    return notation;
}

const get_dice_value = (dice: any): number => {
    var vector = new Vector3(0, 0, dice.dice_type == 'd4' ? -1 : 1);
    var closest_face, closest_angle = Math.PI * 2;
    for (var i = 0, l = dice.geometry.faces.length; i < l; ++i) {
        var face = dice.geometry.faces[i];
        if (face.materialIndex == 0) continue;
        var angle = face.normal.clone().applyQuaternion(dice.body.quaternion).angleTo(vector);
        if (angle < closest_angle) {
            closest_angle = angle;
            closest_face = face;
        }
    }
    var matindex = closest_face.materialIndex - 1;
    if (dice.dice_type == 'd100') matindex *= 10;
    if (dice.dice_type == 'd10' && matindex == 0) matindex = 10;
    return matindex;
}

const get_dice_values = (dices: any[]) => {
    var values = [];
    for (var i = 0, l = dices.length; i < l; ++i) {
        values.push(get_dice_value(dices[i]));
    }
    return values;
}


const make_random_vector = (vector: {x: number, y: number}) => {
  var random_angle = Math.random() * Math.PI / 5 - Math.PI / 5 / 2;
  var vec = {
      x: vector.x * Math.cos(random_angle) - vector.y * Math.sin(random_angle),
      y: vector.x * Math.sin(random_angle) + vector.y * Math.cos(random_angle)
  };
  if (vec.x == 0) vec.x = 0.01;
  if (vec.y == 0) vec.y = 0.01;
  return vec;
};

const shift_dice_faces = (dice: any, value: any, res: number) => {
  // @ts-ignore
  var r = dice_face_range[dice.dice_type];
  if (dice.dice_type == 'd10' && value == 10) value = 0;
  if (!(value >= r[0] && value <= r[1])) return;
  var num = value - res;
  var geom = dice.geometry.clone();
  for (var i = 0, l = geom.faces.length; i < l; ++i) {
      var matindex = geom.faces[i].materialIndex;
      if (matindex == 0) continue;
      matindex += num - 1;
      while (matindex > r[1]) matindex -= r[1];
      while (matindex < r[0]) matindex += r[1];
      geom.faces[i].materialIndex = matindex + 1;
  }
  if (dice.dice_type == 'd4' && num != 0) {
      if (num < 0) num += 4;
      dice.material = new MeshFaceMaterial(
              create_d4_materials(scale / 2, scale * 2, d4_labels[num]));
  }
  dice.geometry = geom;
}

const throw_dices = (box: dice_box, vector: any, boost: any, dist: any, notation_getter: any, before_roll: any, after_roll: any) => {
    var uat = use_adaptive_timestamp;
    function roll(request_results?: any | undefined) {
        if (after_roll) {
            box.clear();
            box.roll(vectors, request_results || notation.result, function(result: any) {
                if (after_roll) after_roll.call(box, notation, result);
                box.rolling = false;
                use_adaptive_timestamp = uat;
            });
        }
    }
    vector.x /= dist; vector.y /= dist;
    var notation = notation_getter.call(box);
    if (notation.set.length == 0) return;
    var vectors = box.generate_vectors(notation, vector, boost);
    box.rolling = true;
    if (before_roll) before_roll.call(box, vectors, notation, roll);
    else roll();
}

/**
 * Now things about the dice box.
 */

export class dice_box {
  use_adapvite_timestep: boolean;
  animate_selector: boolean;
  scene: any;
  world: any;
  dices: any[];
  renderer: any;
  scale: number;
  cw: number;
  ch: number;
  w: number;
  h: number;
  aspect: number;
  wh: number;
  camera: any;
  light: any;
  desk: any;
  dice_body_material: any;
  last_time: number;
  running: any;
  iteration: number;
  callback: any;
  pane: any;
  mouse_time: number;
  mouse_start: {x: number, y: number};
  rolling: boolean;

  barrier_body_material: any;
  barrierA: any;
  barrierB: any;
  barrierC: any;
  barrierD: any;

  constructor(container: HTMLElement, dimentions: {w: number, h: number}) {
    this.use_adapvite_timestep = true;
    this.animate_selector = true;

    this.dices = [];
    this.scene = new Scene();
    this.world = new CANNON.World();

    this.renderer = true
        ? new WebGLRenderer({ antialias: true, alpha: true })
        : new CanvasRenderer();
    container.appendChild(this.renderer.domElement);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFShadowMap;
    this.renderer.setClearColor(0x000000, 0);

    this.reinit(container, dimentions);

    this.world.gravity.set(0, 0, -9.8 * 800);
    this.world.broadphase = new CANNON.NaiveBroadphase();
    this.world.solver.iterations = 16;

    var ambientLight = new AmbientLight(ambient_light_color);
    this.scene.add(ambientLight);

    this.dice_body_material = new CANNON.Material();
    var desk_body_material = new CANNON.Material();
    this.barrier_body_material = new CANNON.Material();
    this.world.addContactMaterial(new CANNON.ContactMaterial(
                desk_body_material, this.dice_body_material, 0.01, 0.5));
    this.world.addContactMaterial(new CANNON.ContactMaterial(
                this.barrier_body_material, this.dice_body_material, 0, 1.0));
    this.world.addContactMaterial(new CANNON.ContactMaterial(
                this.dice_body_material, this.dice_body_material, 0, 0.5));

    this.world.add(new CANNON.RigidBody(0, new CANNON.Plane(), desk_body_material));

    this.last_time = 0;
    this.running = false;

    this.renderer.render(this.scene, this.camera);
  }

  reinit(container: HTMLElement, dimentions: {w: number, h: number}) {
    this.cw = container.clientWidth / 2;
    this.ch = container.clientHeight / 2;
    if (dimentions) {
        this.w = dimentions.w;
        this.h = dimentions.h;
    }
    else {
        this.w = this.cw;
        this.h = this.ch;
    }
    this.aspect = Math.min(this.cw / this.w, this.ch / this.h);
    this.scale = Math.sqrt(this.w * this.w + this.h * this.h) / 13;

    this.renderer.setSize(this.cw * 2, this.ch * 2);

    this.wh = this.ch / this.aspect / Math.tan(10 * Math.PI / 180);
    if (this.camera) this.scene.remove(this.camera);
    this.camera = new PerspectiveCamera(20, this.cw / this.ch, 1, this.wh * 1.3);
    this.camera.position.z = this.wh;

    var mw = Math.max(this.w, this.h);
    if (this.light) this.scene.remove(this.light);
    this.light = new SpotLight(spot_light_color, 2.0);
    this.light.position.set(-mw / 2, mw / 2, mw * 2);
    this.light.target.position.set(0, 0, 0);
    this.light.distance = mw * 5;
    this.light.castShadow = true;
    this.light.shadowCameraNear = mw / 10;
    this.light.shadowCameraFar = mw * 5;
    this.light.shadowCameraFov = 50;
    this.light.shadowBias = 0.001;
    this.light.shadowDarkness = 1.1;
    this.light.shadowMapWidth = 1024;
    this.light.shadowMapHeight = 1024;
    this.scene.add(this.light);

    if (this.desk) this.scene.remove(this.desk);
    const deskMaterial = new MeshLambertMaterial({ opacity: 0, transparent: true});
    this.desk = new Mesh(new PlaneGeometry(this.w * 2, this.h * 2, 1, 1), deskMaterial);
    this.desk.receiveShadow = use_shadows;
    this.scene.add(this.desk);

    if (this.barrierA)
      this.world.remove(this.barrierA);
    this.barrierA = new CANNON.RigidBody(0, new CANNON.Plane(), this.barrier_body_material);
    this.barrierA.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), Math.PI / 2);
    this.barrierA.position.set(0, this.h * 0.93, 0);
    this.world.add(this.barrierA);

    if (this.barrierB)
      this.world.remove(this.barrierB);
    this.barrierB = new CANNON.RigidBody(0, new CANNON.Plane(), this.barrier_body_material);
    this.barrierB.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
    this.barrierB.position.set(0, -this.h * 0.93, 0);
    this.world.add(this.barrierB);

    if (this.barrierC)
      this.world.remove(this.barrierC);
    this.barrierC = new CANNON.RigidBody(0, new CANNON.Plane(), this.barrier_body_material);
    this.barrierC.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), -Math.PI / 2);
    this.barrierC.position.set(this.w * 0.93, 0, 0);
    this.world.add(this.barrierC);

    if (this.barrierD)
      this.world.remove(this.barrierD);
    this.barrierD = new CANNON.RigidBody(0, new CANNON.Plane(), this.barrier_body_material);
    this.barrierD.quaternion.setFromAxisAngle(new CANNON.Vec3(0, 1, 0), Math.PI / 2);
    this.barrierD.position.set(-this.w * 0.93, 0, 0);
    this.world.add(this.barrierD);

    this.renderer.render(this.scene, this.camera);
  }

  generate_vectors(notation: {set: string[], constant: number, result: number[], error: boolean}, vector: {x: number, y: number}, boost: number) {
    var vectors = [];
    for (var i in notation.set) {
        var vec = make_random_vector(vector);
        var pos = {
            x: this.w * (vec.x > 0 ? -1 : 1) * 0.9,
            y: this.h * (vec.y > 0 ? -1 : 1) * 0.9,
            z: Math.random() * 200 + 200
        };
        var projector = Math.abs(vec.x / vec.y);
        if (projector > 1.0) pos.y /= projector; else pos.x *= projector;
        var velvec = make_random_vector(vector);
        var velocity = { x: velvec.x * boost, y: velvec.y * boost, z: -10 };
        // @ts-ignore
        var inertia = dice_inertia[notation.set[i]];
        var angle = {
            x: -(Math.random() * vec.y * 5 + inertia * vec.y),
            y: Math.random() * vec.x * 5 + inertia * vec.x,
            z: 0
        };
        var axis = { x: Math.random(), y: Math.random(), z: Math.random(), a: Math.random() };
        vectors.push({ set: notation.set[i], pos: pos, velocity: velocity, angle: angle, axis: axis });
    }
    return vectors;
  }

  create_dice(type: string, pos: Vector3, velocity: Vector3, angle: Vector3, axis: {x: number, y: number, z: number, a: number}) {
    let fn: (() => any) | undefined;

    if (type == 'd4')
      fn = create_d4;
    else if (type == 'd6')
      fn = create_d6;
    else if (type == 'd8')
      fn = create_d8;
    else if (type == 'd10')
      fn = create_d10;
    else if (type == 'd12')
      fn = create_d12;
    else if (type == 'd20')
      fn = create_d20;
    else
      fn = create_d100;

    var dice = fn();
    dice.castShadow = true;
    dice.dice_type = type;
    // @ts-ignore
    const mass = dice_mass[type];
    dice.body = new CANNON.RigidBody(mass, dice.geometry.cannon_shape, this.dice_body_material);
    dice.body.position.set(pos.x, pos.y, pos.z);
    dice.body.quaternion.setFromAxisAngle(new CANNON.Vec3(axis.x, axis.y, axis.z), axis.a * Math.PI * 2);
    dice.body.angularVelocity.set(angle.x, angle.y, angle.z);
    dice.body.velocity.set(velocity.x, velocity.y, velocity.z);
    dice.body.linearDamping = 0.1;
    dice.body.angularDamping = 0.1;
    this.scene.add(dice);
    this.dices.push(dice);
    this.world.add(dice.body);
  }

  check_if_throw_finished() {
    var res = true;
    var e = 6;
    if (this.iteration < 10 / frame_rate) {
        for (var i = 0; i < this.dices.length; ++i) {
            var dice = this.dices[i];
            if (dice.dice_stopped === true) continue;
            var a = dice.body.angularVelocity, v = dice.body.velocity;
            if (Math.abs(a.x) < e && Math.abs(a.y) < e && Math.abs(a.z) < e &&
                    Math.abs(v.x) < e && Math.abs(v.y) < e && Math.abs(v.z) < e) {
                if (dice.dice_stopped) {
                    if (this.iteration - dice.dice_stopped > 3) {
                        dice.dice_stopped = true;
                        continue;
                    }
                }
                else dice.dice_stopped = this.iteration;
                res = false;
            }
            else {
                dice.dice_stopped = undefined;
                res = false;
            }
        }
    }
    return res;
  }
  
  emulate_throw() {
    while (!this.check_if_throw_finished()) {
        ++this.iteration;
        this.world.step(frame_rate);
    }
    return get_dice_values(this.dices);
  }

  __animate(threadid: any) {
    var time = (new Date()).getTime();
    var time_diff = (time - this.last_time) / 1000;
    if (time_diff > 3) time_diff = frame_rate;
    ++this.iteration;
    if (this.use_adapvite_timestep) {
        while (time_diff > frame_rate * 1.1) {
            this.world.step(frame_rate);
            time_diff -= frame_rate;
        }
        this.world.step(time_diff);
    }
    else {
        this.world.step(frame_rate);
    }
    for (var i in this.scene.children) {
        var interact = this.scene.children[i];
        if (interact.body != undefined) {
            interact.position.copy(interact.body.position);
            interact.quaternion.copy(interact.body.quaternion);
        }
    }
    this.renderer.render(this.scene, this.camera);
    this.last_time = this.last_time ? time : (new Date()).getTime();
    if (this.running == threadid && this.check_if_throw_finished()) {
        this.running = false;
        if (this.callback) this.callback.call(this, get_dice_values(this.dices));
    }
    if (this.running == threadid) {
        (function(t, tid, uat) {
            if (!uat && time_diff < frame_rate) {
                setTimeout(function() { requestAnimationFrame(function() { t.__animate(tid); }); },
                    (frame_rate - time_diff) * 1000);
            }
            else requestAnimationFrame(function() { t.__animate(tid); });
        })(this, threadid, this.use_adapvite_timestep);
    }
  }

  clear() {
    this.running = false;
    var dice;
    while (dice = this.dices.pop()) {
        this.scene.remove(dice); 
        if (dice.body) this.world.remove(dice.body);
    }
    if (this.pane) this.scene.remove(this.pane);
    this.renderer.render(this.scene, this.camera);
    var box = this;
    setTimeout(function() { box.renderer.render(box.scene, box.camera); }, 100);
  }

  prepare_dices_for_roll(vectors: any) {
    this.clear();
    this.iteration = 0;
    for (var i in vectors) {
        this.create_dice(vectors[i].set, vectors[i].pos, vectors[i].velocity,
                vectors[i].angle, vectors[i].axis);
    }
  }

  roll(vectors: any, values: any, callback: any) {
    this.prepare_dices_for_roll(vectors);
    if (values != undefined && values.length) {
        this.use_adapvite_timestep = false;
        var res = this.emulate_throw();
        this.prepare_dices_for_roll(vectors);
        for (var i in res)
            shift_dice_faces(this.dices[i], values[i], res[i]);
    }
    this.callback = callback;
    this.running = (new Date()).getTime();
    this.last_time = 0;
    this.__animate(this.running);
  }

  __selector_animate(threadid: any) {
    var time = (new Date()).getTime();
    var time_diff = (time - this.last_time) / 1000;
    if (time_diff > 3) time_diff = frame_rate;
    var angle_change = 0.3 * time_diff * Math.PI * Math.min(24000 + threadid - time, 6000) / 6000;
    if (angle_change < 0) this.running = false;
    for (var i in this.dices) {
        this.dices[i].rotation.y += angle_change;
        this.dices[i].rotation.x += angle_change / 4;
        this.dices[i].rotation.z += angle_change / 10;
    }
    this.last_time = time;
    this.renderer.render(this.scene, this.camera);
    if (this.running == threadid) {
        (function(t, tid) {
            requestAnimationFrame(function() { t.__selector_animate(tid); });
        })(this, threadid);
    }
  }

  search_dice_by_mouse = function(ev: any) {
    var m = $t.get_mouse_coords(ev);
    // @ts-ignore
    var intersects = (new THREE.Raycaster(this.camera.position, 
                (new Vector3((m.x - this.cw) / this.aspect,
                                   1 - (m.y - this.ch) / this.aspect, this.w / 9))
                .sub(this.camera.position).normalize())).intersectObjects(this.dices);
    if (intersects.length) return intersects[0].object.userData;
  }
  
  draw_selector() {
    this.clear();
    var step = this.w / 4.5;
    this.pane = new Mesh(new PlaneGeometry(this.w * 6, this.h * 6, 1, 1), 
            new MeshPhongMaterial(selector_back_colors));
    this.pane.receiveShadow = true;
    this.pane.position.set(0, 0, 1);
    this.scene.add(this.pane);

    var mouse_captured = false;

    for (var i = 0, pos = -3; i < known_types.length; ++i, ++pos) {
        let fn: (() => any) | undefined;
    
        const type = known_types[i];
        if (type == 'd4')
          fn = create_d4;
        else if (type == 'd6')
          fn = create_d6;
        else if (type == 'd8')
          fn = create_d8;
        else if (type == 'd10')
          fn = create_d10;
        else if (type == 'd12')
          fn = create_d12;
        else if (type == 'd20')
          fn = create_d20;
        else
          fn = create_d100;
        var dice = fn();
        dice.position.set(pos * step, 0, step * 0.5);
        dice.castShadow = true;
        dice.userData = known_types[i];
        this.dices.push(dice); this.scene.add(dice);
    }

    this.running = (new Date()).getTime();
    this.last_time = 0;
    if (this.animate_selector) this.__selector_animate(this.running);
    else this.renderer.render(this.scene, this.camera);
  }
  
  bind_mouse(container: any, notation_getter: any, before_roll: any, after_roll: any) {
    var box = this;
    $t.bind(container, ['mousedown', 'touchstart'], function(ev: any) {
        ev.preventDefault();
        box.mouse_time = (new Date()).getTime();
        box.mouse_start = $t.get_mouse_coords(ev);
    });
    $t.bind(container, ['mouseup', 'touchend'], function(ev: any) {
        if (box.rolling) return;
        if (box.mouse_start == undefined) return;
        ev.stopPropagation();
        var m = $t.get_mouse_coords(ev);
        var vector = { x: m.x - box.mouse_start.x, y: -(m.y - box.mouse_start.y) };
        box.mouse_start = undefined;
        var dist = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
        if (dist < Math.sqrt(box.w * box.h * 0.01)) return;
        var time_int = (new Date()).getTime() - box.mouse_time;
        if (time_int > 2000) time_int = 2000;
        var boost = Math.sqrt((2500 - time_int) / 2500) * dist * 2;
        throw_dices(box, vector, boost, dist, notation_getter, before_roll, after_roll);
    });
  }

  start_throw(notation_getter: any, before_roll: any, after_roll: any) {
    var box = this;
    if (box.rolling) return;
    var vector = { x: (Math.random() * 2 - 1) * box.w, y: -(Math.random() * 2 - 1) * box.h };
    var dist = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
    var boost = (Math.random() + 3) * dist;
    throw_dices(box, vector, boost, dist, notation_getter, before_roll, after_roll);
  }
}