import React, { useEffect, useMemo, useState } from "react";
import { PropsWithChildren, useCallback } from 'react';
import { SupabaseClient, Session, RealtimeChannel } from "@supabase/supabase-js";
import { View, useGlobalState } from "../Menu/GlobalState";
import { useMapState } from "../Map/MapDisplay";
import { shallow } from "zustand/shallow";
import {
  DatabaseGameData,
  DatabaseGamePlayer,
  DatabaseMap, isDemo,
  PatreonData,
  PlayerTableState,
  TableState
} from "../../types";
import { Modal, notification } from "antd";
import CreatePlayerModal from "./Modals/CreatePlayerModal";
import { throttle } from "../../util/throttleutil";
import { arraysEqual } from "../../util/filterutil";
import { Coordinate } from "ol/coordinate";

interface Props extends PropsWithChildren {

}

/**
 * When you want to join a game session, we need to do one of two things.
 * #1, if you are logged in as the person who is the GM
 */
export default function SupabaseRoom({children}: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  const logger = useGlobalState((state) => state.logger);

  const editingGameId = useGlobalState((state) => state.editingGameId);
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const [workingMoveArrows, setWorkingMoveArrows] = useMapState((state) => [state.workingMoveArrows, state.setWorkingMoveArrows], shallow);
  const setMapReadyForDisplay = useMapState((state) => state.setMapReadyForDisplay);
  const [allDatabaseMaps, setAllDatabaseMaps] = useGlobalState((state) => [state.allDatabaseMaps, state.setAllDatabaseMaps], shallow);
  
  const [poiCoordinateChanges, setPoiCoordinateChanges] = useGlobalState((state) => [state.poiCoordinateChanges, state.setPoiCoordinateChanges], shallow);
  const [amGM, setAmGM] = useMapState((state) => [state.amGM, state.setAmGM], shallow);
  const [lastCheckedGameId, setLastCheckedGameId] = useState<string>();
  const [ownerId, setOwnerId] = useMapState((state) => [state.ownerId, state.setOwnerId], shallow);
  const [editingMapId, setEditingMapId] = useGlobalState((state) => [state.editingMapId, state.setEditingMapId]);

  const setView = useGlobalState((state) => state.setView);

  const [playerData, setPlayerData] = useMapState((state) => [state.playerData, state.setPlayerData], shallow);
  const [needsPlayerData, setNeedsPlayerData] = useMapState((state) => [state.needsPlayerData, state.setNeedsPlayerData], shallow);

  // Channel info
  const [channels, setChannels] = useMapState((state) => [state.channels, state.setChannels], shallow);
  const [joinedChannelStatus, setJoinedChannelStatus] = useState<{[key: 'main' | string]: Boolean}>({});

  const [playerTableState, setPlayerTableState] = useMapState((state) => [state.playerTableState, state.setPlayerTableState], shallow);
  const [allPlayerTableStates, setAllPlayerTableStates] = useMapState((state) => [state.allPlayerTableStates, state.setAllPlayerTableStates], shallow);

  const [tablePlayersList, setTablePlayersList] = useMapState((state) => [state.tablePlayersList, state.setTablePlayersList], shallow);
  const [onlinePlayerIds, setOnlinePlayerIds] = useMapState((state) => [state.onlinePlayerIds, state.setOnlinePlayerIds], shallow);
  const [startedSession, setStartedSession] = useState<boolean>(false);
  const [editingGameData, setEditingGameData] = useGlobalState((state) => [state.editingGameData, state.setEditingGameData], shallow);

  const [api, contextHolder] = notification.useNotification();

  // Server channel tools
  // Check if we are the GM. TODO maybe we don't need this if we know what game you're clicking into.
  useEffect(() => {
    if (isDemo) {
      setAmGM(true);
      return;
    }
    
    if (!supabase)
      return;
    if (!session)
      return;

    if (!editingGameId)
      return;

    if (editingGameId == lastCheckedGameId)
      return;

    const getData = async () => {
      const {error, data} = await supabase
        .from('maps')
        .select('owner_id')
        .eq('game_id', editingGameId)
        .limit(1)
        .single();

      if (data) {
        if (data.owner_id == session.user.id) {
          // You are the GM for this game.
          setLastCheckedGameId(editingGameId);
          setAmGM(true);
          setOwnerId(session.user.id)
        } else {
          setLastCheckedGameId(editingGameId);
          setAmGM(false);
          setOwnerId(data.owner_id);
        }
      }
    };

    getData();
  }, [supabase, session, editingGameId, lastCheckedGameId, setLastCheckedGameId, setAmGM, amGM, setOwnerId]);

   // Get our initial map data
  useEffect(() => {
    if (!supabase)
      return;
    // if (!session)
    //   return;
    if (!editingGameId)
      return;
    if (!editingMapId)
      return;

    // For now don't ever refetch map data from the map we are currently on.
    if (editingMapData && editingMapData.map_id == editingMapId)
      return;

    const getData = async () => {
      const { error, data } = await supabase
        .from('maps')
        .select('*')
        .eq('game_id', editingGameId);

      if (data) {
        const allMaps = data.map((data) => {
          if (data.initiative)
            data.initiative = JSON.parse(data.initiative);
          if (data.drawings)
            data.drawings = JSON.parse(data.drawings);
          return data as DatabaseMap;
        });
        setAllDatabaseMaps(allMaps);

        if (isDemo) {
          const stored = window.localStorage.getItem(`demomap-${editingMapId}`);
          if (stored && stored.length > 0) {
            // Pull the "source of truth" from the database and then apply our local changes.
            const newValue = {
              ...allMaps.find((map) => map.map_id == editingMapId),
              ...JSON.parse(stored) as DatabaseMap
            };
            if (newValue.drawings && typeof newValue.drawings == 'string')
              newValue.drawings = JSON.parse(newValue.drawings);
            console.log(newValue)
            setEditingMapData(newValue);
          } else
            setEditingMapData(allMaps.find((map) => map.map_id == editingMapId));
        } else
          setEditingMapData(allMaps.find((map) => map.map_id == editingMapId));

      } else {
        // We don't have the map.
        setView(View.Menu);
      }
    };

    getData();
  }, [setEditingMapData, editingGameId, editingMapId, session, supabase, editingMapData]);

  // Check if we have player data for this game already, and if not, prompt for it.
  useEffect(() => {
    if (!session)
      return;

    if (!supabase)
      return;

    if (!editingGameId)
      return;

    if (editingGameId != lastCheckedGameId)
      return;
    
    if (isDemo)
      return;

    supabase
      .from('game_players')
      .select('*')
      .eq('game_id', editingGameId)
      .then((res) => {
        if (res.data) {
          const allEntries = res.data.map((d) => d as DatabaseGamePlayer);
          setTablePlayersList(allEntries);

          const myPlayer = allEntries.find((p) => p.user_id == session.user.id);
          if (myPlayer)
            setPlayerData(myPlayer);
          else
            setNeedsPlayerData(true);
        } else {
          console.log('Some error occurred fetching data, assuming needs more');
          setNeedsPlayerData(true);
        }
      })
  }, [editingGameId, lastCheckedGameId, session, supabase, setNeedsPlayerData, setPlayerData, setTablePlayersList]);

  // Callbacks for channels
  const updateChannelState = useCallback((id: string, status: boolean) => {
    setJoinedChannelStatus((existing) => ({
      ...existing,
      [id]: status,
    }));
  }, [setJoinedChannelStatus]);
  
  // Directly move a player, used out of initiative.
  const gmHandleDirectMoveRequest = useCallback((userId: string, poiId: string, to: Coordinate) => {
    if (editingMapData.initiative && editingMapData.has_grid)
      return;
    
    setPoiCoordinateChanges({
      [poiId]: to
    });
  }, [setPoiCoordinateChanges, editingMapData]);
  
  const gmUpdateClientDrawings = useCallback((id: string, payload: string) => {
    setEditingMapData((old) => {
      return {
        ...old,
        drawings: {
          ...old.drawings,
          [id]: payload
        }
      }
    });
  }, [setEditingMapData]);
  
  const updateWorkingMoveArrows = useCallback((id: string, path: Coordinate[][], state: 'wip' | 'pending_acceptance' | 'moving') => {
    setWorkingMoveArrows((previous) => ({
      ...previous,
      [id]: {
        path: path,
        stamp: Date.now(),
        state: state,
      },
    }));
  }, [setWorkingMoveArrows]);
  
  const bulkUpdateWorkingMoveArrows = useCallback((data: {[poi_id: string]: {path: Coordinate[][], stamp: number, state: 'wip' | 'pending_acceptance' | 'moving'}}) => {
    setWorkingMoveArrows((previous) => data);
  }, [setWorkingMoveArrows]);
  
  // All connected players periodically update their presence state so we see when they were last active.
  useEffect(() => {
    if (!supabase || !session)
      return;
    
    if (!('main' in joinedChannelStatus) || !joinedChannelStatus['main'])
      return;
    
    const regularTrack = setInterval(() => {
      channels['main'].track({
        active: Date.now()
      });
    }, 5000);
    
    return () => {
      clearTimeout(regularTrack);
    }
  }, [supabase, session, channels, joinedChannelStatus]);
  
  // Any time the GM working arrows changes, track the new information.
  useEffect(() => {
    if (!supabase || !session)
      return;

    if (!amGM)
      return;

    if (!('main' in joinedChannelStatus) || !joinedChannelStatus['main'])
      return;
    
    channels['main'].send({
      type: 'broadcast',
      event: 'move-arrows',
      state: workingMoveArrows
    });
  }, [amGM, supabase, session, channels, joinedChannelStatus, workingMoveArrows]);

  // Sign up for presence changes in the main room channel to know how many clients are connected
  useEffect(() => {
    if (!supabase || !session)
      return;

    if (editingGameId != lastCheckedGameId)
      return;

    let channel: RealtimeChannel;
    console.log('Recreating the main channel');
    channel = supabase.channel(editingGameId, {
      config: {
        presence: {
          key: amGM ? 'GM' : session.user.id,
        }
      }
    });
    if (!amGM) {
      channel.on('broadcast', { event: 'state' }, ({payload}) => {
        const dbMap = payload as unknown as DatabaseMap;

        // If this is an update for a map we're not looking at, discard.
        if (dbMap.map_id != editingMapId)
          return;

        const currentPoiCoordinates = poiCoordinateChanges || {};

        for (let i = 0; i < payload.pois?.length ?? 0; i += 1) {
          const thisPoi = payload.pois[i];

          if (thisPoi.poi_id in currentPoiCoordinates) {
            const thatCoordinate = currentPoiCoordinates[thisPoi.poi_id];
            currentPoiCoordinates[thisPoi.poi_id] = [thisPoi.coordinate_x, thisPoi.coordinate_y];
            thisPoi.coordinate_x = thatCoordinate[0];
            thisPoi.coordinate_y = thatCoordinate[1];
            payload.pois[i] = thisPoi;
          } else {
            currentPoiCoordinates[thisPoi.poi_id] = [thisPoi.coordinate_x, thisPoi.coordinate_y];
          }
        }
        
        if (Object.keys(currentPoiCoordinates).length > 0)
          setPoiCoordinateChanges(Object.assign({}, currentPoiCoordinates));
        setEditingMapData(dbMap);
      });
      channel.on('broadcast', { event: 'change-map' }, ({payload}) => {
        console.log(`Supposed to change to`, payload);
        setEditingMapId(payload);
        setEditingMapData(undefined);
        setMapReadyForDisplay(false);
      });
      channel.on('broadcast', { event: 'update-gamedata'}, ({payload}) => {
        setEditingGameData(JSON.parse(payload) as DatabaseGameData);
      })
      channel.on('broadcast', { event: 'move-arrows' }, ({state}) => {
        if (state)
          bulkUpdateWorkingMoveArrows(state)
        else
          bulkUpdateWorkingMoveArrows({});
      });
    }
    channel.on('presence', { event: 'sync'}, () => {
      const state = channel.presenceState();
      const allOnlinePlayers = Object.keys(state);
      allOnlinePlayers.splice(allOnlinePlayers.indexOf('GM'), 1);
      setOnlinePlayerIds(allOnlinePlayers);
    });
    channel.subscribe(async (status) => {
      console.log('Main channel status change', status);
      if (status === 'SUBSCRIBED')
        updateChannelState('main', true);

      channel.track({}); // TODO heartbeat interval every 5 seconds, assume offline if more than 30 seconds without heartbeat.
    });
    setChannels((chanIn) => ({
      ...chanIn,
      'main': channel
    }));
  }, [session, supabase, editingGameId, lastCheckedGameId, updateChannelState]);

  // Now join our specific channel (if a client)
  useEffect(() => {
    if (!supabase || !session)
      return;

    if (editingGameId != lastCheckedGameId)
      return;

    if (amGM)
      return;

    if (session.user.id in channels)
      return;

    let channel: RealtimeChannel;
    console.log('Recreating the personal channel');
    channel = supabase.channel(session.user.id);
    channel.subscribe(async (status) => {
      console.log('Personal channel status change', status);
      if (status === 'SUBSCRIBED')
        updateChannelState('personal', true);
    });
    setChannels((chanIn) => ({
      ...chanIn,
      [session.user.id]: channel,
      personal: channel
    }));
  }, [channels, session, supabase, amGM, updateChannelState]);
  
  // GM also needs to subscribe to player channels 
  useEffect(() => {
    if (!supabase || !session)
      return;
    
    if (!amGM)
      return;
    
    let existing = channels;
    let anyNew = false;
    for (let i = 0; i < onlinePlayerIds.length; i += 1) {
      const thisPlayerId = onlinePlayerIds[0];
      
      if (thisPlayerId in channels)
        return;
      
      let channel: RealtimeChannel;
      console.log(`Joining channel ${thisPlayerId}`);
      channel = supabase.channel(thisPlayerId);
      channel.subscribe(async (status) => {
        console.log(thisPlayerId, 'Player channel status change', status);
        if (status === 'SUBSCRIBED')
          updateChannelState(thisPlayerId, true);
      });
      channel.on('broadcast', { event: 'drawing' }, ({ payload }) => {
        gmUpdateClientDrawings(thisPlayerId, payload);
      });
      channel.on('broadcast', { event: 'request-move'}, ({ payload }) => {
        gmHandleDirectMoveRequest(thisPlayerId, payload['id'], payload['to']);
      });
      channel.on('broadcast', { event: 'move-range-wip'}, ({ payload }) => {
        updateWorkingMoveArrows(payload['for'], payload['paths'], 'wip');
      });
      channel.on('broadcast', { event: 'move-range-done'}, ({ payload }) => {
        updateWorkingMoveArrows(payload['for'], payload['paths'], 'pending_acceptance');
      });
      anyNew = true;
      existing = {
        ...existing,
        [thisPlayerId]: channel
      };
    }
    
    if (anyNew)
      setChannels(existing);
  }, [amGM, onlinePlayerIds, channels, session, supabase, setEditingMapData]);

  // Send state updates down the main wire to everyone.
  useEffect(() => {
    if (!amGM)
      return;

    if (!editingMapData)
      return;

    if (!('main' in joinedChannelStatus) || !joinedChannelStatus['main'])
      return;

    channels['main'].send({
      type: 'broadcast',
      event: 'state',
      payload: editingMapData
    });
  }, [channels, session, supabase, amGM, editingMapData, joinedChannelStatus]);

  // Log that we have started a session
  useEffect(() => {
    if (!session || !session.user || !logger)
      return;

    if (startedSession)
      return;

    logger.info('Session started', {
      user: session.user.id,
    });

    setStartedSession(true);
  }, [session, logger, startedSession]);
  
  // Send my personal changes over the personal channel
  useEffect(() => {
    if (amGM)
      return;
    
    if (!playerTableState)
      return;
    
    if (!('personal' in joinedChannelStatus) || !joinedChannelStatus['personal'])
      return;
    
    channels['personal'].send({
      type: 'broadcast',
      event: 'drawing',
      payload: playerTableState.drawing
    });
  }, [playerTableState, channels, session, supabase, amGM, joinedChannelStatus]);
  
  return (
    <>
      {contextHolder}
    </>
  )
}
