import React, { ReactNode } from "react";
import styled from "styled-components";
import ToolSet from "./ToolSet";
import Tool from "./Tool";
import { TrayTool } from "../../../../types";

interface Props {
  rows: ((TrayTool[])[])[];
}

const BottomStack = styled.div`
  z-index: 90;
  width: 100%;
  
  & > div {
    display: -ms-flexbox;
    display: flex;
  }
`;

const TrayInset = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  padding: 8px;
  place-content: flex-start center;
`;

const TrayContainer = styled.div`
  row-gap: 8px;

  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column-reverse nowrap;
  flex-flow: column-reverse nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
  transition: height 0.2s linear, top 0.2s linear, width ease-in-out 0.3s;
  z-index: 90;
  pointer-events: none;
  gap: 8px;
`;

const Row = styled.div<{}>`
  height: 48px;
  width: 100%;

  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
  transition: height 0.2s linear, top 0.2s linear, width ease-in-out 0.3s;
  gap: 8px;
`;

export default function ToolTray({rows}: Props) {

  return (
    <BottomStack>
      <TrayInset>
        <TrayContainer>
          {rows.filter((i) => i).map((row, rowIdx) => {
            let rowToolIndex = 1;
            return (
              <Row key={rowIdx}>
                {row.map((set, index) => (
                  <ToolSet key={index}>
                    {set.map((tool, index) => (
                      <Tool key={index} data={tool} index={rowToolIndex++} row={rowIdx}>
                        {tool.icon}
                      </Tool>
                    ))}
                  </ToolSet>
                ))}
              </Row>
            );
          })}
        </TrayContainer>
      </TrayInset>
    </BottomStack>
  )
}