import React, {ReactElement, ReactNode, useEffect, useRef, useState} from "react";
import {
  Button, Divider,
  Drawer,
  Input,
  InputNumber, List,
  Modal,
  Progress,
  Space,
  theme,
  Tour,
  TourProps,
  TourStepProps,
  Typography
} from 'antd';
import {useGlobalState} from "../../Menu/GlobalState";
import {useLocalStorage} from "usehooks-ts";
import {useMapState} from "../MapDisplay";
import {MapBrowserEvent} from "ol";
import {Coordinate} from "ol/coordinate";
import {ToolType} from "../../../types";
import {LiaChalkboardTeacherSolid} from "react-icons/lia";
import * as Tutorials from './Implementations';
import styled from "styled-components";
import {dice_box} from "../Dice/dice";

export interface TutorialTemplate {
  icon: ReactNode,
  title: ReactNode,
  description: ReactNode,
  steps: TourStepProps[],
  updateFn?: (params: TutorialUpdateParameters) => void,
  openFn?: (params: TutorialUpdateParameters) => void,
  closeFn?: (params: TutorialUpdateParameters) => void,
}

export interface TutorialUpdateParameters {
  openModals: {[key: string]: boolean},
  setOpenModals: (s: string, open: boolean) => void,
  onTourStep: number,
  setOnTourStep: (step: number) => void,
  setPreventModalClose: (s: string, preventClose: boolean) => void,
  box: dice_box,
  setTutorialTemplate: (t: TutorialTemplate | null) => void,
}

const TutorialItem = styled.div<{colorHovered: string}>`

  & .ant-list-item-meta-avatar {
      align-self: center;
  }
    
  &:hover,
  &:focus {
      background-color: ${props => props.colorHovered};
  }
`;

export default function NewTutorial() {
  const editingGameData = useGlobalState((state) => state.editingGameData);
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const openModals = useGlobalState((state) => state.openModals);
  const setOpenModals = useGlobalState((state) => state.setModalOpen);
  const setPreventModalClose = useGlobalState((state) => state.setPreventModalClose);
  const [tutorialDrawerOpen, setTutorialDrawerOpen] = useMapState((state) => [state.tutorialDrawerOpen, state.setTutorialDrawerOpen]);
  const [tourOpen, setTourOpen] = useState<boolean>(false);
  const [tutorialTemplate, setTutorialTemplate] = useState<TutorialTemplate>();
  const box = useGlobalState((state) => state.diceBox);
  
  const [onTourStep, setOnTourStep] = useState(0);
  
  const { token } = theme.useToken();
  
  const tupleRef = useRef<TutorialUpdateParameters>();
  tupleRef.current = {
    openModals: openModals,
    onTourStep: onTourStep,
    setOnTourStep: setOnTourStep,
    setPreventModalClose: setPreventModalClose,
    box: box,
    setOpenModals: setOpenModals,
    setTutorialTemplate: setTutorialTemplate
  };
  
  // Prevent other things being pressed while the drawer is up.
  useEffect(() => {
    setOpenModals('tutorialdrawer', tutorialDrawerOpen);
  }, [tutorialDrawerOpen]);
  
  // Pass updates to the current tutorial
  useEffect(() => {
    if (!tutorialTemplate)
      return;
    
    if (!tutorialTemplate.updateFn)
      return;
    
    tutorialTemplate.updateFn(tupleRef.current);
  }, [openModals, tutorialTemplate]);
  
  if (!editingGameData || !editingGameData.is_demo)
    return <></>;
  
  return (
    <>
      <Modal centered title={"Welcome to the Demo!"} open={modalOpen} onCancel={() => {
        setModalOpen(false);
      }} okButtonProps={{ style: ({ display: 'none'}) }} cancelButtonProps={{ style: ({ display: 'none'})}}>
        <Typography style={{ color: token.colorTextSecondary, marginTop: 16 }}>This is an example game meant to demonstrate some of MapsforTable.Top's editing functionality!</Typography>
        <Typography style={{ color: token.colorTextSecondary, marginTop: 16 }}>Get a feel for what all you can do and if you think this would be useful for your games.</Typography>
        <Typography style={{ color: token.colorTextSecondary, marginTop: 16 }}>In your toolbar at the bottom, you'll see a special <b>Tutorial Button</b> <LiaChalkboardTeacherSolid /> which opens up a list of various optional tutorials you can look through.</Typography>
        <Typography style={{ color: token.colorTextSecondary, marginTop: 16 }}>This demo <b>saves changes locally</b>, but no-one can join and you can't make more maps for it. These functions are unlocked with an active Patreon subscription.</Typography>
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: 16 }}>
          <Space size='middle' style={{ alignItems: 'center', rowGap: '4px', columnGap: '4px'}}>
            <Button type={'primary'} onClick={() => setModalOpen(false)}>Let's Explore!</Button>
            <Button type={'default'} onClick={() => {
              setModalOpen(false);
              setTutorialDrawerOpen(true);
            }}>See Tutorials</Button>
          </Space>
        </div>
      </Modal>
      <Drawer
        title={"Demo Tutorials"}
        placement={"right"}
        open={tutorialDrawerOpen}
        onClose={() => setTutorialDrawerOpen(false)}
        key={"tutorialDrawer"}
      >
        <Typography style={{ color: token.colorTextSecondary, textAlign: 'center'  }}>Click on any of the below tutorials to be walked through that functionality</Typography>
        <Typography style={{ color: token.colorTextTertiary, textAlign: 'center', marginBottom: 12 }}>If you have any questions not covered below, feel free to reach out on Patreon.</Typography>
        <List
          itemLayout={"horizontal"}
          dataSource={Object.values(Tutorials)}
          renderItem={(tutorial, index) => (
            <TutorialItem onClick={() => {
              setTutorialDrawerOpen(false);
              setOnTourStep(0);
              setTutorialTemplate(tutorial);
              setTourOpen(true);
              if (tutorial.openFn)
                tutorial.openFn(tupleRef.current);
            }} colorHovered={token.colorFillSecondary} >
              {index == 0 ? (<></>) : (
                <Divider dashed style={{ marginTop: '12px', marginBottom: '12px', width: '95%', alignSelf: 'center' }} />
              )}
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <div style={{ width: '100%', height: '100%', alignSelf: 'center' }}>
                      {tutorial.icon}
                    </div>
                  }
                  title={tutorial.title}
                  description={tutorial.description}
                />
              </List.Item>
            </TutorialItem>
          )}
        />
      </Drawer>
      <Tour current={onTourStep} onChange={(e) => setOnTourStep(e)} open={tourOpen} onClose={() => {
        setTourOpen(false);
        if (tutorialTemplate.closeFn)
          tutorialTemplate.closeFn(tupleRef.current);
        setTutorialTemplate(undefined);
      }} onFinish={() => setTourOpen(false)} steps={tutorialTemplate?.steps ?? []} />
    </>
  )
}