import React, { CSSProperties, MutableRefObject, useEffect, useRef } from "react";

interface Props {
  needsRedraw: boolean,
  setNeedsRedraw: (b: boolean) => void,

  drawFunction: (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => void,

  style?: CSSProperties | undefined;
  baseDataUrl?: string;
  fillBackColor?: string;
  borderImagePadding?: number;
  passRef: MutableRefObject<HTMLCanvasElement>;
}

export default function CanvasComponent({needsRedraw, setNeedsRedraw, drawFunction, style, baseDataUrl, fillBackColor, borderImagePadding, passRef}: Props) {
  useEffect(() => {
    if (!needsRedraw)
      return;

    if (baseDataUrl && baseDataUrl.length > 0) {
      var img = new Image();
      img.onload = function() {
        if (passRef && passRef.current) {
          const c = passRef.current;
          const cctx = c.getContext('2d');

          cctx.clearRect(0,0,c.width,c.height);

          if (fillBackColor)
            cctx.fillStyle = fillBackColor;
          cctx.beginPath();
          const borderPadding = (borderImagePadding ?? 0) / 2;
          const radius = (c.width / 2) - (borderPadding);
          cctx.arc(c.width/2, c.height/2, radius, 0, Math.PI*2);
          cctx.fill();
          // use compositing to draw the logo img only
          // inside the just-filled arc
          cctx.globalCompositeOperation='source-atop';
          // draw the image in the arc
          // imgTarget will be at the center of the arc

          cctx.drawImage(img, borderPadding * 2, borderPadding * 2, c.width - (borderPadding * 4), c.height - (borderPadding * 4));
          // reset compositing to default
          cctx.globalCompositeOperation='source-over';

          // cctx.drawImage(img, 0, 0);

          drawFunction(c, cctx);
        }
      }
      img.src = baseDataUrl;
      setNeedsRedraw(false);
    }
  }, [needsRedraw, setNeedsRedraw, drawFunction]);

  return (
    <canvas
      style={style}
      width={parseInt(style.width as string) ?? 250}
      height={parseInt(style.height as string) ?? 250}
      ref={passRef}
    />
  );
}