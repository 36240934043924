import { StarOutlined, LikeOutlined, MessageOutlined, UserOutlined, CalendarOutlined, SnippetsOutlined, FileImageOutlined, ExclamationCircleOutlined, SearchOutlined, DownOutlined, SortDescendingOutlined, SortAscendingOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Breadcrumb, Button, Dropdown, Input, List, Pagination, Popconfirm, Skeleton, Space, Tag, Tooltip, Typography, message } from "antd";
const { Text, Link } = Typography;
import * as React from "react";
import { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { useHover, useLocalStorage } from 'usehooks-ts';
import * as fuzzysort from "fuzzysort";
import CreateGameModal from "../Operations/CreateGameModal";
import { filterTitle } from "../../../util/filterutil";
import { DatabaseGameData, DatabaseMap } from "../../../types";
import { useMainMenuState } from "../MainMenu";
import { shallow } from 'zustand/shallow'
import GameInvitesModal from "../Operations/GameInvitesModal";
import JoinGameModal from "../Operations/JoinGameModal";
import { useGlobalState } from "../GlobalState";
import { View } from "../GlobalState";

interface Props {
  setEditingGame: (gameId: string) => void;
  editingGame: string;
}

export default function MyGames({ editingGame, setEditingGame }: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  const patreon = useGlobalState((state) => state.patreon);
  const userData = useGlobalState((state) => state.userData);

  const [displayGamesData, setDisplayGamesData] = useMainMenuState((state) => [state.displayGamesData, state.setDisplayGamesData], shallow);
  const [originalGamesData, setOriginalGamesData] = useMainMenuState((state) => [state.originalGamesData, state.setOriginalGamesData], shallow);
  const [editingGameData, setEditingGameData] = useMainMenuState((state) => [state.editingGameData, state.setEditingGameData], shallow);
  const [filterGames, setFilterGames] = useLocalStorage<string>('game-search', '');

  const [createGameModalOpen, setCreateGameModalOpen] = useState(false);
  const [invitesOpen, setInvitesOpen] = useState(false);
  const [joinOpen, setJoinOpen] = useState(false);
  const [gameIdForModal, setGameIdForModal] = useState<string>();

  const [forceRefresh, setForceRefresh] = useMainMenuState((state) => [state.forceRefresh, state.setForceRefresh], shallow);
  
  const setSelectedTab = useMainMenuState((state) => state.setSelectedTab);

  const setView = useGlobalState((state) => state.setView);
  const setEditingGameId = useGlobalState((state) => state.setEditingGameId);
  const setEditingMapId = useGlobalState((state) => state.setEditingMapId);
  
  const [messageApi, contextHolder] = message.useMessage();

  const addNewRef = useRef(null);
  const addNewHovered = useHover(addNewRef);

  const joinRef = useRef(null);
  const joinHovered = useHover(joinRef);

  const deleteGame = useCallback((gameId: string) => {
    supabase
      .from('games')
      .delete()
      .eq('game_id', gameId).then(({error, data}) => {
        if (!error) {
          setForceRefresh(true);
        }
      });
  }, [supabase, setDisplayGamesData, displayGamesData, setOriginalGamesData]);

  let data: DatabaseGameData[] = [];
  if (displayGamesData === undefined) {
    data = Array.from({length: 1}).map(() => ({
      isPlaceholder: true,
      diagonal_rule: '510',
      request_movement_off_turn: true,
      request_movement_not_your_tokens: true,
    }));
  } else {
    data = displayGamesData;
  }

  useEffect(() => {
    if (originalGamesData && !forceRefresh)
      return;

    if (supabase && session && session.user) {
      const getData = async () => {
        const {data: invitedData, error: invitedError} = await supabase
          .from('invites')
          .select('game_id')
          .eq('acceptor_id', session.user.id);

        let extraOrConditions = [];
        if (invitedData)
          extraOrConditions = invitedData.map((item) => item.game_id);

        const {error, data} = await supabase
          .from('games')
          .select('*')
          .or(`owner_id.eq.${session.user.id}${extraOrConditions.length > 0 ? `,game_id.eq.${extraOrConditions.join(',game_id.eq.')}` : 'false'}`);

        if (data) {
          const newGames: DatabaseGameData[] = [];
          data.forEach((item) => {
            newGames.push({
              ...item as any,
              isPlaceholder: false,
            } as DatabaseGameData);
          })
          setOriginalGamesData(newGames);
          setForceRefresh(false);
        }
      };
      
      getData();
    }
  }, [supabase, session, setOriginalGamesData, forceRefresh, setForceRefresh, originalGamesData]);

  useEffect(() => {
    if (!originalGamesData)
      return;

    let result: DatabaseGameData[] = [];
    if (filterGames && filterGames.length > 0) {
      fuzzysort.cleanup();
      result = fuzzysort.go(filterGames, originalGamesData, {key:'title'}).map((i) => {
        return {
          ...i.obj,
          highlightTitleIndexes: (i as any)._indexes
        }
      });
    } else
      result = originalGamesData;
    setDisplayGamesData(result);
  }, [originalGamesData, setDisplayGamesData, filterGames, forceRefresh])
  
  const IconText = ({ icon, text, tooltip }: { icon: React.FC; text: string | React.ReactNode, tooltip: string }) => (
    <Tooltip title={tooltip}>
      <Space>
          {React.createElement(icon)}
          {text}
      </Space>
    </Tooltip>
  );

  return (
    <>
      {contextHolder}
      <Typography.Title level={1} style={{ margin: 0 }}>
        My Games
      </Typography.Title>
      <Breadcrumb
        items={[
          {
            title: <a onClick={() => setSelectedTab(['account'])}>User</a>,
          },
          {
            title: 'Games',
          },
        ]}
      />
      <div style={{ marginTop: 20, display: 'flex', marginBottom: 12 }}>
        <Input size="large" placeholder="Filter your games..." prefix={<SearchOutlined />} style={{ flexGrow: 3, justifyContent: 'space-between' }} value={filterGames} onChange={(change) => setFilterGames(change.target.value)}/>
        <Button type={joinHovered ? "primary" : 'default'} size='large' style={{ marginLeft: 12 }} ref={joinRef} onClick={() => {
          setJoinOpen(true);
        }}>
          Join Game?
        </Button>
        {
          userData?.is_admin ||( patreon && patreon.patron_status == 'active_patron') ?
          (
            <Button type={addNewHovered ? "primary" : 'default'} size='large' style={{ marginLeft: 12 }} ref={addNewRef} onClick={() => setCreateGameModalOpen(true)}>
              <PlusOutlined />Create New?
            </Button>
          ) :
          (<></>)
        }
      </div>
      <div className="ListContent" style={{ flexGrow: 1, overflow: 'scroll' }}>
        <List
          itemLayout="vertical"
          pagination={false}
          dataSource={data}
          bordered
          renderItem={(item: DatabaseGameData, index: number) => (
            <List.Item
              key={item.display_name}
              actions={[
                <IconText tooltip='Last updated' icon={CalendarOutlined} text={item.isPlaceholder ? '?' : (item.last_updated ?? 'Never')} key="list-last-updated" />,
                <IconText tooltip='Player Count' icon={UserOutlined} text={item.isPlaceholder ? '?' : item.players_count.toString()} key="list-player-count" />,
                <IconText tooltip='Number of Maps' icon={SnippetsOutlined} text={item.isPlaceholder ? '?' : item.maps_count.toString()} key="list-maps" />,
                <IconText tooltip='Number of Tokens' icon={FileImageOutlined} text={item.isPlaceholder ? '?' : item.tokens_count.toString()} key="list-tokens" />,
                <IconText tooltip='Number of Points of Interest' icon={ExclamationCircleOutlined} text={item.isPlaceholder ? '?' : item.tokens_count.toString()} key="list-points-of-interest" />
              ]}
              extra={
                item.isPlaceholder ? null : (
                  <div className="extraSection" style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {
                      session && item.owner_id == session.user.id ? 
                      <>
                        <Space direction="vertical">
                          <Space direction="horizontal">
                            <Button style={{ width: '100%'}} type="primary" disabled={editingGame == item.game_id || item.pendingDeletion} onClick={() => {
                              setEditingGame(item.game_id);
                              setEditingGameData(data[index]);
                              setSelectedTab(['maps']);
                            }}>Edit</Button>
                            <Popconfirm
                              title="Delete this game?"
                              description={`Are you sure you want to delete game '${item.display_name}'?`}
                              okText="Yes"
                              cancelText="No"
                              onConfirm={() => {
                                deleteGame(item.game_id)
                                const newGames = [...displayGamesData];
                                newGames[index].pendingDeletion = true;
                                setDisplayGamesData(newGames);
                              }}
                            >
                              <Button style={{ width: '100%'}} type={'dashed'} danger disabled={item.pendingDeletion}>
                                Delete
                              </Button>
                            </Popconfirm>
                          </Space>
                          <Button type="default" style={{width: '100%'}} disabled={item.pendingDeletion || item.private} onClick={() => {
                            if (!item.private) {
                              setGameIdForModal(item.game_id);
                              setInvitesOpen(true);
                            }
                          }}>{item.private ? 'Private' : 'Invites'}</Button>
                        </Space>
                      </> : 
                      <>
                        <Button style={{ width: '100%', height: '100%' }} type="primary" onClick={() => {
                          setEditingMapId(item.default_map_id);
                          setEditingGameId(item.game_id);
                          setView(View.Map);
                        }}>Play</Button>
                      </>
                    }
                  </div>
                  // <img
                  //   width={272}
                  //   alt="logo"
                  //   src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                  // />
                )
              }
            >
              <List.Item.Meta
                avatar={null}
                title={item.display_name ? <>
                  {filterTitle(item.display_name, item.highlightTitleIndexes)}
                  {item.private ? <Tag color="purple" style={{ marginLeft: 8 }}>private</Tag> : <></>}
                  {session && session.user.id == item.owner_id ? <Tag color="cyan" style={{ marginLeft: 8 }}>owner</Tag> : <></>}
                  {session && session.user.id != item.owner_id ? <Tag color="geekblue" style={{ marginLeft: 8 }}>player</Tag> : <></>}
                  </> : <Skeleton active />}
                description={item.desc_text}
              />
              {/* {item.content} */}
            </List.Item>
          )}
        />
      </div>
      <CreateGameModal forceRefresh={() => setForceRefresh(true)} isModalOpen={createGameModalOpen} setIsModalOpen={setCreateGameModalOpen}/>
      <GameInvitesModal isModalOpen={invitesOpen} setIsModalOpen={setInvitesOpen} gameId={gameIdForModal} />
      <JoinGameModal isModalOpen={joinOpen} setIsModalOpen={setJoinOpen} forceRefresh={() => setForceRefresh(true)} />
    </>
  )
}