import {TutorialTemplate} from "../NewTutorial";
import {MdDraw} from "react-icons/md";
import * as React from "react";
import {FaDiceD20} from "react-icons/fa6";
import {DiceRollOpenKey} from "../../Dice/DiceRolling";

export default {
  title: 'Dice Tutorial',
  description: 'Learn about the dice tool.',
  icon: <FaDiceD20 size={32} />,
  steps: [
    {
      title: 'This is your dice tool',
      description: 'Click to open the detailed roll',
      target: () => document.getElementsByClassName('tool')[2] as HTMLElement,
      nextButtonProps: {
        style: {
          display: 'none'
        }
      },
    },
    {
      title: 'Dice Roll Options',
      description: 'Here you can easily select what to roll',
      target: () => document.getElementsByClassName('ant-popover-inner')[0] as HTMLElement,
    },
    {
      title: 'Roll String',
      description: `It accepts common dice notation like 1d4 or 2d8`,
      target: () => document.getElementsByClassName('rollstring')[0] as HTMLElement,
    },
    {
      title: 'Quick Dice Buttons',
      description: `You can also click one of these buttons to add the corresponding dice to your roll string automatically`,
      target: () => document.getElementsByClassName('rolloptions')[0] as HTMLElement,
    },
    {
      title: 'Try a roll!',
      description: `Press the 'Roll' button to finish this tutorial.`,
      target: () => document.getElementsByClassName('ant-popover-inner')[0] as HTMLElement,
      nextButtonProps: {
        style: {
          display: 'none'
        }
      },
    },
  ],
  updateFn: params => {
    if (params.onTourStep == 0) {
      if (params.openModals[DiceRollOpenKey])
        setTimeout(() => params.setOnTourStep(1), 150)
    }
    if (params.onTourStep == 4)
    {
      if (params.box.rolling) {
        params.setTutorialTemplate(null);
        params.setPreventModalClose(DiceRollOpenKey, false);
        setTimeout(() => params.setOpenModals(DiceRollOpenKey, false), 100);
      }
    }
  },
  openFn: params => {
    params.setPreventModalClose(DiceRollOpenKey, true);
  },
  closeFn: params => {
  }
} as TutorialTemplate;