import { ItalicExtension, BoldExtension, HeadingExtension, HardBreakExtension, BulletListExtension, OrderedListExtension, ImageExtension, NodeFormattingExtension, LinkExtension } from "remirror/extensions";
import { WysiwygEditor } from '@remirror/react-editors/wysiwyg';
import { EditorComponent, Remirror, useChainedCommands, useRemirror } from '@remirror/react';
import { AllStyledComponent } from "@remirror/styles/styled-components";
import styled, { ThemeProvider } from "styled-components";
import React, { useEffect } from "react";
import { ItalicOutlined, BoldOutlined, FontSizeOutlined } from "@ant-design/icons";
import { Tooltip, FloatButton, Dropdown, MenuProps, Typography } from "antd";
import { InlineButtonGroup } from "../AntExtras/InlineButtonGroup";
import { RichEditorButtons } from "./RichEditorButtons";
import { EditorState } from "@remirror/pm/state";
import { chainCommands } from "remirror";
import { PropsWithChildren } from 'react';

const {Title} = Typography;

const EditorParent = styled.div`
  
  padding: 1px;

  & div[contenteditable="true"] {
    background-color: rgb(20,20,20);
    min-height: 120px;
    padding: 4px 8px 4px 8px;
  }

`;

interface Props extends PropsWithChildren {
  reset: boolean,
  setReset: (b: boolean) => void,
  stateJson: string,
  changeStateJson: (s: string) => void,
  editable?: boolean | undefined,
};

export default function RichEditor({reset, setReset, stateJson, changeStateJson, editable, children}: Props) {
  const { manager, state: remirrorState, setState: setRemirrorState, onChange: baseOnChange } = useRemirror({
    extensions: () => [
      new ItalicExtension(),
      new BoldExtension({}),
      new HeadingExtension({}),
      new HardBreakExtension({}),
      new BulletListExtension({}),
      new OrderedListExtension({}),
      new ImageExtension({ enableResizing: true }),
      new NodeFormattingExtension({}),
      new LinkExtension({ autoLink: true }),
    ],
    selection: 'start',
    stringHandler: 'html',
  });

  useEffect(() => {
    if (!reset)
      return;

    const state = stateJson ?? ''; 
    manager.view.updateState(manager.createState({ content: state ? remirrorState.schema.nodeFromJSON(JSON.parse(state)) : undefined }));
    setReset(false);
  }, [reset, setReset, manager, editable]);

  useEffect(() => {
    const state = stateJson ?? ''; 
    manager.view.updateState(manager.createState({ content: state ? remirrorState.schema.nodeFromJSON(JSON.parse(state)) : undefined }));
  }, []);

  return (<>
    <Remirror manager={manager} initialContent={remirrorState} onChange={(parameter) => {
      if (!reset)
        changeStateJson(JSON.stringify(parameter.state.doc.toJSON()));
    }} editable={editable}>
      {editable !== false ? (<RichEditorButtons />) : <></>}
      {children}
      <EditorParent>
        <EditorComponent />
      </EditorParent>
    </Remirror>
  </>);
}