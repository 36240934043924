import { NoticeType } from "antd/es/message/interface";
import { Coordinate } from "ol/coordinate";
import { Point } from "ol/geom";
import { TextJustify } from "ol/style/Text";
import { ReactNode } from "react";
import { Position } from "react-avatar-editor";
import { ShortcutState } from "./components/Menu/GlobalState";

export interface PageMeta {
  title: string;
  description: string;
  cardImage: string;
}

export const isDemo = window.location.href.toLocaleLowerCase().indexOf('demo') > -1;

export interface OpenMessage {
  type?: NoticeType;
  content: React.ReactNode;
}

export interface DatabaseGameData {
  isPlaceholder: boolean;

  display_name?: string;
  desc_text?: string;
  last_updated?: string;
  game_id?: string;
  private?: boolean;
  owner_id?: string;

  // Count variables
  players_count?: number;
  maps_count?: number;
  tokens_count?: number;
  pois_count?: number;

  pendingDeletion?: boolean;

  default_map_id?: string;

  highlightTitleIndexes?: number[];
  
  default_token_settings?: string;
  
  diagonal_rule: '510' | '5 Diagonal',
  request_movement_off_turn: boolean,
  request_movement_not_your_tokens: boolean,
  gm_on_map_id?: string,
  global_allow_drawing?: boolean,
  specific_allow_drawing?: string;
  
  is_demo?: boolean,
}

export interface DatabaseToken {
  token_id: string;

  group?: string | undefined;
  extra_json_data?: string | undefined;

  background_image: string;

  // These fields can be null for global tokens
  owner_id?: string | undefined;
  game_id?: string | undefined;

  is_global: boolean;

  grid_size: number;
  display_name: string;

  file_size: number;

  highlightTitleIndexes?: number[];
}

export const DefaultExtraJson: any = {
  initiative_mod: 0,
  max_health: 30,
  movement_tiles: 6,
}

export interface DatabaseImageData {
  imageId: string;
  createdAt: string;
  fileSize: number;
  width: number;
  height: number;
  fileName: string;

  token_id?: string;

  highlightTitleIndexes?: number[];
}

export interface DatabaseMap extends GridSettings {
  map_id: string;
  owner_id: string;
  display_name: string;
  background_image: string;
  projection_x: number;
  projection_y: number;

  drawings?: {[key: string]: string};

  default_token_size: number;

  highlightTitleIndexes?: number[];
  pois?: DatabaseMapPoi[] | undefined;

  initiative?: Initiative | undefined;
  unwalkable?: string | undefined;
  
  font_setting?: string | undefined;
  move_arrow_width?: number | undefined;
}

export interface InitiativeSpot {
  poi_id: string;
  reaction_used?: boolean;
  roll: number;
  
  max_movement?: number | undefined;
  movement?: number | undefined;

  health: number;
  max_health: number;
}

export interface Initiative {
  spots: InitiativeSpot[],
  current: number;
  unsorted_players?: InitiativeSpot[] | undefined,
}

export interface GridSettings {
  has_grid?: boolean;

  grid_padding_top?: number;
  grid_padding_left?: number;
  grid_line_spacing?: number;
  grid_line_width?: number;

  grid_line_color?: string;
}

export interface MousePosition {
  coordinate: Coordinate;
  time: number;
}

export interface DatabaseGameInvite {
  id: string;
  created_at: string;
  owner_id: string;
  acceptor_id?: string;
  game_id: string;
  invite_code: string;
  key?: string;
}

export type MapCost = {[key: string] : { cost: number, bestPath: Coordinate[], diagonalBit: number, totalDiagonals: number }};
export type RequestedMovement = {poi_id: string, path: Coordinate[]};

export interface DatabaseMapPoi {
  poi_id: string;
  map_id: string;
  owner_id: string;
  
  map_text: string;
  links_to_map?: string | undefined;

  has_description: boolean;
  desc_text?: string;
  desc_title?: string;
  
  coordinate_x: number;
  coordinate_y: number;

  // Options for the display of the label
  circle_clickbox: boolean;
  clickbox_radius?: number | undefined;
  square_clickbox_angle?: number;

  font_override?: string | undefined;

  text_color_override?: number[] | undefined;
  fill_color_override?: number[] | undefined;
  text_opacity?: number | undefined;
  text_padding_override?: number[] | undefined;
  text_outline_ratio?: number | undefined;

  // A locked feature can't be clicked on and used by players, but is still possibly visible
  locked?: boolean;
  // A hidden feature can't be seen at all.
  hidden?: boolean;
  hide_in_goto?: boolean;

  is_image?: boolean;

  locked_alpha?: number;

  find_zoom_amount: number;

  status?: string[] | undefined;
  extra_json_data?: string | undefined;
  
  token_id?: string | undefined; // If this is a token and references a tokenID, this is that ID.
  user_id?: string | undefined; // If this is a token that's a player token, this is the player's user_id.

  color_text_poi?: string | undefined;
}

export interface DatabaseGamePlayer {
  game_id: string;
  user_id: string;

  name: string;
  border_color?: string;
  hidden?: boolean;
  
  character_name: string;
  player_name: string;
}

export interface MapAutoComplete {
  value: string;
  label: string | ReactNode;
};

export interface MapGuidMapping {
  value: string;
  guid: string;
}

export interface PatreonData {
  campaign_lifetime_support_cents?: number,
  currently_entitled_amount_cents?: number,
  last_charge_date?: string;
  next_charge_date?: string;
  last_charge_status?: string;
  patron_status: "active_patron" | "declined_patron" | "former_patron" | null;
  note?: string;
}

export interface UserData {
  id: string,
  restrict_game_creation?: boolean,
  limit_games_amount_to?: number,
  is_admin?: boolean,
}

export interface TrayTool {
  type: ToolType,
  icon: ReactNode,
  click: () => void,
  checkExtraKeybind?: ((e: KeyboardEvent, s: ShortcutState) => boolean) | undefined,
  extra_id?: string | undefined,
}

export interface SvgPathDrawSettings {
  path: string,
  basePath2D?: Path2D,
  matrix?: DOMMatrix,
  foreground_color?: string;
  background_color?: string;
  border_color?: string;
  scale?: number;
  shape?: ['circle', 'square'],
  draw_background?: boolean,
  iconName?: string;
  // Automatically provided if you drag out more than one of the same icon, it'll add a number rendered on the object
  // to indicate more than one of the thing.
  extraIdentifier?: string; 
}

export const BlankBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPoAAAD6AQMAAACyIsh+AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRFAAAAp3o92gAAAAF0Uk5TAEDm2GYAAAAzSURBVHic7coxDQAADAOg1b/pSejfwE2uiCAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIu+EB7YoA+yjfcDcAAAAASUVORK5CYII=';

export enum ToolType {
  Unselectable,
  Pan,
  Draw,
  Ruler,
  Dice,

  // Level 2 draw tools
  Freehand,
  Polygon,
  Circle,
  Eraser,
  Fill,
  Color,

  Back,

  Settings,
  Token,
  Command,
  History,
  TutorialTray
}

export interface AvatarOptions {
  scale?: number;
  rotate?: number;
  backgroundColor?: string;
  position?: Position;

  borderSize?: number;
  borderColor?: string;
  stepFourName?: string;
  tokenIsGlobal?: boolean;
  group?: string;
}

export interface NewPlayerOptions {
  character_name?: string;
  player_name?: string;
  border_color?: string;
}

/**
 * Types for Room purposes.
 */

// Contains the active table for a given room.
export interface TableState {
  maps: {[key: string]: DatabaseMap};
  online_players?: string[];
}

// Contains an individual player's info, such as their last checkin time and 
export interface PlayerTableState {
  drawing?: string;
  last_updated_drawing?: number;
}

export interface MapHistoryEntry {
  timestamp: number,
  diff: {}[],
}