import { FloatButton } from "antd";
import { FloatButtonShape } from "antd/es/float-button/interface";
import React, { ReactNode } from "react";

interface Props {
  shape?: FloatButtonShape;
  children?: ReactNode | ReactNode[] | undefined,
}

export const InlineButtonGroup = ({children}: Props) => {
  return (
    <>
      <div className="inline-float-group">
        <FloatButton.Group shape="square">
          {children}
        </FloatButton.Group>
      </div>
    </>
  )
};