import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { View, useGlobalState } from "../GlobalState";
import { shallow } from "zustand/shallow";
import { useMainMenuState } from "../MainMenu";

const localStorageKey = 'latest-announcement';

export default function NewAnnouncementDetector() {
  const supabase = useGlobalState((state) => state.supabase);
  const [api, contextHolder] = notification.useNotification();
  const [view, setView] = useGlobalState((state) => [state.view, state.setView], shallow);
  const [selectedTab, setSelectedTab] = useMainMenuState((state) => [state.selectedTab, state.setSelectedTab], shallow);
  
  const [newestAnnounce, setNewestAnnounce] = useState<{id: string, tags: string}>();
  const [newestStoredAnnouncementId, setNewestStoredAnnouncementId] = useGlobalState((state) => [state.lastStoredAnnouncementId, state.setLastStoredAnnouncementId], shallow);

  // Only responsible for fetching the newest IDs
  useEffect(() => {
    if (!supabase)
      return;

    let interval: NodeJS.Timer | null = null;

    const process = () => {
      supabase
        .from('announcements')
        .select('id,tags')
        .limit(1)
        .order('created_at', {ascending: false})
        .maybeSingle()
        .then((response) => {
          if (response.data && response.count != 0) {
            const thisId = response.data.id;

            setNewestAnnounce({
              id: thisId,
              tags: response.data.tags
            });
          }
        });


      interval = setTimeout(process, 30000);
    };
    process();

    return () => {
      if (interval)
        clearTimeout(interval);
    }
  }, [supabase, setNewestAnnounce]);

  // Decides when to show them
  useEffect(() => {
    if (!newestAnnounce)
      return;
      
    const shouldShow = newestAnnounce?.id !== newestStoredAnnouncementId && (view == View.Menu || newestAnnounce.tags.includes('Critical'));

    if (!shouldShow)
      return;

    setNewestStoredAnnouncementId(newestAnnounce.id);

    // If we are already on the main menu looking at the announcement page, just refresh. Case 1.
    if (view == View.Menu && selectedTab[0] == 'news') {
      setSelectedTab(['about']);
      setTimeout(() => setSelectedTab(['news']), 100);
      return;
    }

    if (newestAnnounce.tags.includes('Critical')) {
      api.info({
        message: 'New Critical Announcement Posted!',
        key: newestAnnounce.id,
        description: <div style={{cursor: 'pointer'}}>A new important announcement has been posted. Click here to view.</div>,
        onClick: () => {
          setView(View.Menu);
          setSelectedTab(['news']);
          api.destroy(newestAnnounce.id);
        },
        duration: 10,
      })
    } else {
      api.info({
        message: 'New Announcement Posted!',
        key: newestAnnounce.id,
        description: <div style={{cursor: 'pointer'}}>A new announcement has been posted. Click here to view.</div>,
        onClick: () => {
          setView(View.Menu);
          setSelectedTab(['news']);
          api.destroy(newestAnnounce.id);
        },
        duration: 10,
      })
    }
  }, [view, setSelectedTab, setNewestStoredAnnouncementId, newestAnnounce]);

  return (
    <>
      {contextHolder}
    </>
  );
}