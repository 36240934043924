import { PlusCircleOutlined } from "@ant-design/icons";
import { theme } from "antd";
import React, { PropsWithChildren, useEffect } from "react";
import styled from "styled-components";
import { ToolType, TrayTool } from "../../../../types";
import { useMapState } from "../../MapDisplay";
import { useGlobalState } from "../../../Menu/GlobalState";

interface Props extends PropsWithChildren {
  data: TrayTool,
  index: number,
  row: number,
}

const ToolStyle = styled.div<{ hoveredBackground: string, active: boolean }>`
  padding: 0;
  border: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  cursor: pointer;
  transition: 0.3s, 0s box-shadow, 0s border-radius;
  border-radius: 4px;
  background-color: ${props => props.active ? props.hoveredBackground : 'unset'};

  width: 44px;
  height: 44px;

  & span {
    width: 50%;
    height: 50%;
  }

  & span svg {
    width: 100%;
    height: 100%;
  }

  & > svg {
    width: 50%;
    height: 50%;
  }

  &:hover {
    background-color: ${props => props.hoveredBackground};
  }

  &[active='1'] {
    background-color: ${props => props.hoveredBackground};
  }
`;

export default function Tool({ children, data, index, row }: Props) {
  const trayFocusedTool = useMapState((state) => state.trayFocusedTool);
  const setTrayFocusedTool = useMapState((state) => state.setTrayFocusedTool);
  const trayTwoFocusedTool = useMapState((state) => state.trayTwoFocusedTool);
  const setTrayTwoFocusedTool = useMapState((state) => state.setTrayTwoFocusedTool);
  const shortcutKeys = useGlobalState((state) => state.shortcutKeys);
  const fillMode = useMapState((state) => state.fillMode);

  const anyModalOpen = useGlobalState((state) => state.anyModalOpen);

  const { token } = theme.useToken();

  let focused = trayFocusedTool == data.type || (!trayFocusedTool && data.type == ToolType.Pan) ||
                  trayTwoFocusedTool == data.type;
  
  if (data.type == ToolType.Fill) {
    focused = trayTwoFocusedTool == data.type && data.extra_id == fillMode;
  }

  useEffect(() => {
    const listen = (e: KeyboardEvent) => {
      if (anyModalOpen)
        return;
      const andSubKey = shortcutKeys.shift ? (shortcutKeys.shift && row == 1) : (row == 0);

      if (andSubKey && Number(e.code.replace(/\D/g, "")) == index) {
        data.click();
      }

      if (data.checkExtraKeybind && data.checkExtraKeybind(e, shortcutKeys))
        data.click();
    };
    addEventListener('keydown', listen);
    return () => removeEventListener('keydown', listen);
  }, [index, shortcutKeys, data, anyModalOpen]);
  
  return (
    <ToolStyle hoveredBackground={'rgba(255, 255, 255, 0.063)'} active={focused} onClick={() => data.click()} className={"tool"}>
      {children}
    </ToolStyle>
  )
}