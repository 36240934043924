import React, {useState, useContext, PropsWithChildren} from "react";
import { useDrag } from "react-dnd";
import { Image as AntImage } from "antd";
import { useMapState } from "../../MapDisplay";

interface Props extends PropsWithChildren {
  ownerId: string;
  setDrawerOpen: (b: boolean) => void,
  drawerOpen: boolean,
  droppedCallback: () => void,
  setCurrentlyDragging: () => void,
}

export default function DraggableSVGToken({children, drawerOpen, droppedCallback, setCurrentlyDragging }: Props) {
  const [draggingState, setDraggingState] = useState(false);
  
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'token-sidebar',
      item: { children },
      collect: (monitor) => {
        const isDraggingNow = monitor.isDragging();
        // if (isDraggingNow)
        //   setDrawerOpen(false);

        if (isDraggingNow != draggingState) {
          if (!drawerOpen && !isDraggingNow) {
            droppedCallback();
          }

          setDraggingState(isDraggingNow);
          if (isDraggingNow)
            setCurrentlyDragging();
        }

        return ({
          opacity: isDraggingNow ? 0.5 : 1
        });
      }
    }),
    [drawerOpen, draggingState, setDraggingState, droppedCallback, setCurrentlyDragging]
  );
  
  return (
    <div ref={dragRef} style={{opacity, maxWidth: '100%', maxHeight: '100%', cursor: 'grab'}}
         className="constrainChildren">
      {children}
    </div>
  );
}