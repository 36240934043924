import * as React from "react";
import {PropsWithChildren} from "react";
const D12 = (props: any) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    {...props}
  >
    <g id="XMLID_1_">
      <path fill="#FFFFFF" id="XMLID_26_" d="M782.4796753,408.3996582c-0.0027466-0.1383667-0.013855-0.2755737-0.0246582-0.4133301
	c-0.0107422-0.1336365-0.0197754-0.2669678-0.0378418-0.3990784c-0.0183716-0.1329651-0.0458374-0.263855-0.0715332-0.3955078
	c-0.0263672-0.1331482-0.0510254-0.2663879-0.085022-0.3972778c-0.03302-0.1278381-0.0753174-0.2528992-0.1159668-0.3788452
	c-0.0419922-0.1305237-0.0822754-0.2614136-0.1315918-0.3890991c-0.0475464-0.1219482-0.104126-0.2402344-0.1583252-0.3597717
	c-0.057251-0.1255798-0.112793-0.2516174-0.1773682-0.3735657c-0.0614624-0.1156921-0.1315918-0.2269592-0.199646-0.3395386
	c-0.0722046-0.1193237-0.1430054-0.2391968-0.222168-0.354126c-0.0194702-0.0279236-0.0344238-0.058136-0.0541992-0.085907
	L676.5966187,257.965332c-0.8627319-1.2082825-2.0917358-2.1069489-3.505127-2.5626221l-170.9726563-55.0811768
	c-0.0416565-0.0133667-0.0843811-0.0198822-0.1263733-0.0324554c-0.1274109-0.0384521-0.2565613-0.0684814-0.3860779-0.099472
	c-0.1347046-0.0321198-0.2683716-0.0652771-0.4041138-0.0891418c-0.130188-0.0230865-0.2614136-0.0372314-0.3930054-0.0527649
	c-0.1357422-0.015976-0.2707825-0.0332489-0.4068909-0.0411377c-0.1340027-0.0078125-0.2680054-0.006424-0.4023743-0.0065155
	c-0.1343689,0.0000916-0.2683716-0.001297-0.4023743,0.0065155c-0.1361084,0.0078888-0.2711487,0.0251617-0.4068909,0.0411377
	c-0.1315918,0.0155334-0.2624817,0.0295868-0.3930054,0.0526733c-0.1361084,0.0240479-0.270813,0.0572968-0.4058533,0.0896606
	c-0.1284485,0.0307312-0.2565613,0.0605011-0.3836365,0.0986023c-0.0420227,0.0127563-0.0854187,0.0193634-0.1274109,0.0328979
	L326.9081421,255.40271c-1.4133606,0.4556732-2.6423645,1.3543396-3.5054626,2.5626221L218.7982788,404.5136108
	c-0.0204773,0.0286255-0.0357513,0.0597839-0.0558929,0.0886841c-0.0767212,0.1112061-0.1447754,0.2272339-0.2149048,0.3425903
	c-0.070816,0.116394-0.1433868,0.2315674-0.2065735,0.3513489c-0.0624847,0.1173401-0.1156006,0.2387695-0.1708069,0.3595886
	c-0.0569305,0.1244507-0.1156158,0.2477112-0.164917,0.3748779c-0.0475616,0.1222839-0.0857391,0.2476196-0.1260223,0.3725891
	c-0.0426941,0.1313171-0.086441,0.2616882-0.1211548,0.3950806c-0.0326385,0.1262207-0.0562439,0.2544861-0.0815887,0.3826904
	c-0.0270844,0.1365356-0.0555573,0.2723694-0.074295,0.4101868c-0.0173645,0.1275024-0.0263977,0.2562256-0.0364532,0.3851929
	c-0.0114594,0.1424255-0.0232697,0.2844238-0.0256958,0.4274597c-0.0007019,0.041748-0.0072937,0.0824585-0.0072937,0.1242981
	v184.9536133c0,1.4575806,0.4607086,2.8778076,1.3164978,4.0574951l101.9095917,140.4839478
	c0.0163269,0.0227661,0.0361328,0.0422363,0.0531311,0.0647583c0.0822754,0.1109009,0.1732483,0.2143555,0.2621155,0.3200073
	c0.0857544,0.1019897,0.1690674,0.2060547,0.2600403,0.3023682c0.0920105,0.097168,0.1912842,0.1863403,0.2885132,0.2780151
	c0.098938,0.0930176,0.1954651,0.1881714,0.2992554,0.2749023c0.1013794,0.0847168,0.2093506,0.1610718,0.3152466,0.2399902
	c0.1097107,0.0812988,0.2173157,0.1644287,0.331543,0.2390747c0.1100769,0.0722046,0.2256775,0.1356812,0.3399048,0.2016602
	c0.1190796,0.0686646,0.2371216,0.1386108,0.3600159,0.1999512c0.1163025,0.0580444,0.2371216,0.107605,0.3569031,0.1593018
	c0.1294861,0.055603,0.2583008,0.1118774,0.3909302,0.1594849c0.0367737,0.0133667,0.071167,0.0317993,0.1086426,0.0445557
	l173.6678467,59.1243286C498.4949951,799.8769531,499.2469788,800,499.9996643,800s1.5046692-0.1230469,2.2267761-0.3683472
	l173.6682129-59.1243286c0.0374756-0.0127563,0.0714722-0.031189,0.1086426-0.0445557
	c0.1326294-0.0476074,0.2614136-0.1038818,0.390564-0.1594849c0.1201172-0.0516968,0.2409668-0.1012573,0.3569336-0.1593018
	c0.12323-0.0613403,0.2409058-0.1312866,0.3603516-0.1999512c0.1141968-0.065979,0.2298584-0.1294556,0.3399048-0.2016602
	c0.1141968-0.074646,0.2218018-0.1577759,0.331543-0.2390747c0.105896-0.0789185,0.2138672-0.1552734,0.3152466-0.2399902
	c0.1034546-0.086731,0.2003174-0.1818848,0.2992554-0.2749023c0.097229-0.0916748,0.1964722-0.1808472,0.288147-0.2780151
	c0.0913086-0.0963135,0.1746216-0.2003784,0.260376-0.3023682c0.0888672-0.1056519,0.1798706-0.2091064,0.262146-0.3200073
	c0.0170288-0.022522,0.036438-0.0419922,0.0531006-0.0647583l101.9096069-140.4839478
	c0.8557739-1.1796875,1.3165283-2.5999146,1.3165283-4.0574951l0.0003052-184.9536133
	C782.4873047,408.4849854,782.4803467,408.4427795,782.4796753,408.3996582z M394.2519836,644.3881836l-12.039093-37.0529785
	l-53.3164673-164.0916443L500,318.9298706l171.1032104,124.3136902l-53.3164673,164.0916443l-12.0394287,37.0529785H394.2519836z
	 M666.677063,267.8552246l97.840332,137.0719604l-84.1032104,27.9986877l-173.504303-126.058075v-90.4832916L666.677063,267.8552246
	z M333.3226013,267.8552246l159.7671814-51.4707184v90.4834442l-116.6000977,84.7148438l-56.9042358,41.3430786
	l-84.1035309-27.9986877L333.3226013,267.8552246z M231.3321075,418.1108398l3.5578766,1.1843872l80.3897705,26.7626648
	L381.542511,649.992981l-55.0784302,72.3880615l-95.1319733-131.1417847V418.1108398z M499.9996643,785.7907104
	l-162.3605957-55.2741699l55.0180054-72.3088379h214.6855164l55.0176392,72.3088379L499.9996643,785.7907104z
	 M768.6675415,591.2392578l-95.131958,131.1417847l-55.0784302-72.3880615l66.2627563-203.9350891l83.947998-27.947052
	L768.6675415,591.2392578z"/>
    </g>
  </svg>
);
export default D12;
