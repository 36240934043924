import * as React from "react";

export default function Background() {
  return <div id="background" style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '-1',

    backgroundColor: '#0d0d0d',
  }} />
}