import 'core-js';
import { App as AntApp, ConfigProvider, theme } from 'antd';
const { defaultAlgorithm, darkAlgorithm } = theme;
import {useState, createContext, useEffect} from "react";
import {createRoot } from 'react-dom/client';

import Layout from './components/Layout';

import * as React from "react";
import { Auth } from '@supabase/auth-ui-react';
import {
  supabase,
  // Import predefined theme
  ThemeSupa,
} from '@supabase/auth-ui-shared'
import MainMenu from './components/Menu/MainMenu';
import { useGlobalState, View } from './components/Menu/GlobalState';
import MapDisplay from './components/Map/MapDisplay';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Coordinate } from 'ol/coordinate';
import { listen } from 'ol/events';
import {isDemo, MousePosition, UserData} from './types';
import { shallow } from 'zustand/shallow';
import { MenuProvider, useCommands } from 'kmenu';
import PatreonHook from './components/Patreon/PatreonHook';
import NewVersionDetector from './components/Map/NewVersionDetector';
import NewAnnouncementDetector from './components/Menu/InfoPages/NewAnnouncementDetector';
import AdBlockChecker from "./components/Utility/AdBlockChecker";
import GameDataSync from "./components/Utility/GameDataSync";
import NewMainMenu from "./components/NewMenu/NewMainMenu";

export default function App() {
  const supabase = useGlobalState((state) => state.supabase);
  const [userData, setUserData] = useGlobalState((state) => [state.userData, state.setUserData], shallow);
  const [session, setSession] = useGlobalState((state) => [state.session, state.setSession], shallow);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [shortcutKeys, setShortcutKeys] = useGlobalState((state) => [state.shortcutKeys, state.setShortcutKeys], shallow);

  const view = useGlobalState((state) => state.view);

  useEffect(() => {
    if (!supabase)
      return;

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    })

    return () => subscription.unsubscribe()
  }, [supabase]);

  // Get the user data for this user
  useEffect(() => {
    if (!session || !supabase)
      return;

    if (userData)
      return;

    supabase.from('user_data')
      .select('*')
      .eq('id', session.user.id)
      .limit(1)
      .maybeSingle()
      .then((response) => {
        if (response.error || response.count == 0) {
          setUserData({
            id: session.user.id,
          });
        } else {
          setUserData(response.data as UserData);
        }
      })
  }, [session, supabase, userData]);

  useEffect(() => {
    const keydownKeyup = (event: KeyboardEvent) => {
      if (event.shiftKey == shortcutKeys.shift && event.altKey == shortcutKeys.alt && event.ctrlKey == shortcutKeys.ctrl)
        return;

      setShortcutKeys({
        shift: event.shiftKey,
        alt: event.altKey,
        ctrl: event.ctrlKey,
      });
    }

    window.addEventListener("keydown", keydownKeyup);
    window.addEventListener("keyup", keydownKeyup);

    return () => {
      window.removeEventListener("keydown", keydownKeyup);
      window.removeEventListener("keyup", keydownKeyup);
    }
  }, [shortcutKeys]);

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      (window as any).mouseposition = {
        coordinate: [event.clientX, event.clientY],
        time: Date.now(),
      };
    };

    window.addEventListener('mousemove', listener);
    window.addEventListener('mouseup', listener);
    return () => {
      window.removeEventListener('mousemove', listener);
      window.removeEventListener('mouseup', listener);
    }
  }, []);
  
  let page = <></>;
  switch (view) {
    case View.Menu:
      // page = <MainMenu session={session} />;
      page = <NewMainMenu />;
      break;
    case View.Map:
      page = <MapDisplay />;
      break;
  }

  return (
    <MenuProvider config={{
      backdropColor: '#00000099',
      backdropBlur: 0.1,
      backgroundColor: '#171717',
      breadcrumbColor: '#303030',
      borderColor: '#333',
      inputColor: '#fff',
      inputBorder: '#333',
      headingColor: '#696969',
      commandActive: '#fff',
      commandInactive: '#696969',
      barBackground: '#ffffff10',
    }}>
      <ConfigProvider theme={{ algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm }}>
        <AntApp>
          <DndProvider backend={HTML5Backend}>
            <PatreonHook />
            {
              isDemo ? (
                <></>
              ) :
              <>
                <NewVersionDetector />
                <NewAnnouncementDetector />
                <AdBlockChecker />
              </>
            }
            <GameDataSync />
            <Layout scrollDisabled={view == View.Map}>
              {page}
            </Layout>
          </DndProvider>
        </AntApp>
      </ConfigProvider>
    </MenuProvider>
  );
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
