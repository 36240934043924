import { MailOutlined } from "@ant-design/icons";
import { Button, Card, Input, Typography } from "antd";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useHover } from "usehooks-ts";
import { useGlobalState } from "../../Menu/GlobalState";

interface Props {

}

const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(0.1px);
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
`;

export default function OTPLogin({}: Props) {
  const logger = useGlobalState((state) => state.logger);
  const supabase = useGlobalState((state) => state.supabase);
  const setSession = useGlobalState((state) => state.setSession);
  const [emailAddress, setEmailAddress] = useState<string>();
  const [accessCode, setAccessCode] = useState<string>();
  const [isSent, setIsSent] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [isError, setIsError] = useState<string>();

  const addNewRef = useRef(null);
  const addNewHovered = useHover(addNewRef);

  const accessCodeRef = useRef(null);
  const accessCodeHovered = useHover(addNewRef);

  let content = (
    <>
      <Typography.Title level={3} style={{ marginTop: 0 }}>Enter your email to get a magic link</Typography.Title>
      <Typography style={{ textAlign: 'center' }}>The email will come from 'supabase'.<br /> Copy and paste the code here to start playing.</Typography>
      <Input size="large" placeholder="Enter your email address" prefix={<MailOutlined />} style={{ flexGrow: 3, marginTop: 12 }} value={emailAddress} onChange={(change) => setEmailAddress(change.target.value)}/>
      <Button type={addNewHovered ? "primary" : 'default'} disabled={isPressed || !emailAddress || emailAddress.length == 0} loading={isPressed} size='large' style={{ marginTop: 12, marginBottom: 12, width: '100%' }} ref={addNewRef} onClick={() => {
        setIsPressed(true);
        supabase.auth.signInWithOtp({
          email: emailAddress,
          options: {
            emailRedirectTo: 'http://mapsfortable.top/',
          },
        }).then(({error, data}) => {
          if (error)
            setIsError(error.message);
          else
            setIsSent(true);

          logger.info('Sent OTP Quick Login', {
            email: emailAddress,
            error: error,
            data: data
          });

          setIsPressed(false);
        })
      }}>
        Send Magic Link
      </Button>
    </>
  );

  if (isSent) {
    content = (
      <>
        <Typography.Title level={3} style={{ marginTop: 0, textAlign: 'center' }}>Paste the code you received in your email</Typography.Title>
        <Typography style={{ textAlign: 'center' }}>The email will come from 'supabase'.<br /> It may take a few seconds for the email to arrive.</Typography>
        <Input size="large" placeholder="Enter your access code" prefix={<MailOutlined />} style={{ flexGrow: 3, marginTop: 12 }} value={accessCode} onChange={(change) => setAccessCode(change.target.value)}/>
        <Button type={accessCodeHovered ? "primary" : 'default'} disabled={isPressed || !accessCode || accessCode.length == 0} loading={isPressed} size='large' style={{ marginTop: 12, marginBottom: 12, width: '100%' }} ref={accessCodeRef} onClick={() => {
          setIsPressed(true);
          supabase.auth.verifyOtp({
            type: 'email',
            email: emailAddress,
            token: accessCode,
          }).then((response) => {
            if (response.error)
              setIsError(response.error.message);
            else
              setSession(response.data.session);

            logger.info('Verify OTP Quick Login', {
              email: emailAddress,
              error: response.error,
              data: response.data
            });

            setIsPressed(false);
          })
        }}>
          Start Playing
        </Button>
      </>
    )
  }
  
  return (
    <Backdrop>
      <Card title="Sign in to play" bordered={false} style={{ width: 450, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {content}
      </Card>
    </Backdrop>
  )
}