import {Modal, Tooltip, Typography, theme, Divider, Space, Input, Button, Popconfirm, Select, InputRef} from "antd";
import React, {useEffect, useRef, useState} from "react";
import { useGlobalState } from "../../../Menu/GlobalState";
import { shallow } from "zustand/shallow";
import RichEditor from "../../../Remirror/RichEditor";
import { useMapState } from "../../MapDisplay";
import {DatabaseMap, DatabaseMapPoi, isDemo} from "../../../../types";
import {useLocalStorage} from "usehooks-ts";
import {LuSave} from "react-icons/lu";

const featureDescKey = 'featuredesc';
const templatesKey = 'feature-templates';

export default function FeatureDescription() {
  const session = useGlobalState((state) => state.session);
  const logger = useGlobalState((state) => state.logger);
  const [openModals, setModalOpen] = useGlobalState((state) => [state.openModals, state.setModalOpen], shallow);
  const [mouseOverFeature, setMouseOverFeature] = useMapState((state) => [state.mouseOverFeature, state.setMouseOverFeature], shallow);
  const [resetEditor, setResetEditor] = useState<boolean>(true);
  const amGM = useMapState((state) => state.amGM);
  const [workingChanges, setWorkingChanges] = useState<string>();
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const { token } = theme.useToken();

  const [newTemplateName, setNewTemplateName] = useState('');
  const [localSavedTemplates, setLocalSavedTemplates] = useLocalStorage<{[key: string]: string}>(templatesKey, {});
  const [loadTemplateShowing, setLoadTemplateShowing] = useState<string>(null);

  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (!mouseOverFeature)
      setWorkingChanges(undefined);
    else
      setWorkingChanges(mouseOverFeature.desc_text);
    setResetEditor(true);
  }, [mouseOverFeature]);

  if (!mouseOverFeature)
    return (
      <></>
    );

  const closeModal = () => {
    if (amGM) {
      const newMapData = structuredClone(editingMapData) as DatabaseMap;
      const index = newMapData.pois.findIndex((item: DatabaseMapPoi) => item.poi_id == mouseOverFeature.poi_id);
      if (index >= 0) {
        const newPoi = {
          ...newMapData.pois[index],
          desc_text: workingChanges
        } as DatabaseMapPoi;
        newMapData.pois[index] = newPoi;
        setMouseOverFeature(newPoi);
        setEditingMapData(newMapData);
        logger.info('Updated description', {
          poi_id: newPoi.poi_id,
          map_id: newPoi.map_id,
          description: newPoi.desc_text,
          user: isDemo ? 'demo' : session.user.id,
        });
      }
    }
    setModalOpen(featureDescKey, false)
  };

  return (
    <Modal
        centered
        open={featureDescKey in openModals && openModals[featureDescKey]}
        onCancel={() => closeModal()}
        afterClose={() => closeModal()}
        onOk={null}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
        width={640}
      >
      {amGM ? (<Typography.Title level={3}>Edit POI Description</Typography.Title>) : (<></>)}
      <div className="remirror-theme">
        <RichEditor 
          stateJson={workingChanges}
          changeStateJson={(ne) => {
            setWorkingChanges(ne)
          }}
          reset={resetEditor}
          setReset={setResetEditor}
          editable={amGM}
        />
      </div>
      <Select
        style={{ width: 300, marginTop: '8px' }}
        placeholder="Load a saved Template"
        value={loadTemplateShowing}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input
                placeholder="Name"
                ref={inputRef}
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
              />
              <Button type="text" icon={<LuSave />} onClick={(e) => {
                setLocalSavedTemplates({
                  ...localSavedTemplates,
                  [newTemplateName]: workingChanges
                });
                setNewTemplateName('');
              }}>
                Save New Template
              </Button>
            </Space>
          </>
        )}
        onChange={(val, option) => {
          console.log(localSavedTemplates[val])
          setWorkingChanges(localSavedTemplates[val]);
          setResetEditor(true);
          setLoadTemplateShowing(val);
        }}
        options={Object.entries(localSavedTemplates).map((item) => {
          const label = (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            {item[0]}

            <Popconfirm
              title="Delete this template?"
              description="Are you sure you want to delete this template?"
              onConfirm={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const localSaved = {...localSavedTemplates};
                delete localSaved[item[0]];
                setLocalSavedTemplates(localSaved);
              }}
              onCancel={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              okText='Yes'
              cancelText='No'
            >
              <Button type="text" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
                X
              </Button>
            </Popconfirm>
          </div>);

          return {label: label, value: item[0]};
        })}
      />
    </Modal>
  );
}