import * as React from "react";
import {PropsWithChildren} from "react";
const D10 = (props: any) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    {...props}
  >
    <g id="XMLID_1_">
      <path fill="#FFFFFF" id="XMLID_25_" d="M789.4313965,526.1958008c0.0775757-0.2147827,0.1482544-0.4309692,0.2038574-0.6522217
	c0.0316772-0.1257935,0.0567017-0.2514038,0.0812378-0.3783569c0.0401001-0.2079468,0.0701904-0.4168091,0.0908813-0.6282349
	c0.0124512-0.1273193,0.0249023-0.25354,0.0304565-0.3811646c0.0092773-0.2207031,0.0031128-0.4415894-0.0090332-0.6635742
	c-0.0062256-0.1154175-0.0065308-0.2303467-0.0186157-0.3452759c-0.0276489-0.2633667-0.076416-0.5255737-0.1351318-0.7873535
	c-0.0157471-0.0700073-0.0224609-0.1412964-0.0402832-0.2107544c-0.0020752-0.0081787-0.0026245-0.0164185-0.0046387-0.0245972
	c-0.0839844-0.3208008-0.1930542-0.6303711-0.3197021-0.9307251c-0.0359497-0.0852051-0.0834961-0.1656494-0.1230469-0.2496338
	c-0.1060791-0.2254028-0.2184448-0.446167-0.3473511-0.6571655c-0.0611572-0.1000366-0.1297607-0.1953125-0.1962891-0.2927246
	c-0.1316528-0.1925659-0.2706299-0.3781738-0.4205933-0.5557861c-0.0418091-0.0495605-0.072937-0.1049194-0.1161499-0.1535034
	L505.1311646,202.2949066c-0.0070801-0.0079498-0.0158997-0.0134888-0.0229797-0.0214233
	c-0.0570068-0.0631714-0.1194153-0.1198425-0.1786804-0.1806793c-0.0806885-0.0831909-0.16745-0.1603546-0.2528076-0.2399292
	c-0.1302795-0.1211395-0.259552-0.2427826-0.398468-0.3532104c-0.085022-0.0677338-0.1741943-0.1257935-0.2619934-0.1890411
	c-0.0552673-0.039917-0.0993347-0.089859-0.156189-0.1283875c-0.0336914-0.0229034-0.0711975-0.0357819-0.105072-0.0579834
	c-0.1335754-0.0870972-0.2718201-0.1645966-0.4112854-0.2422638c-0.117157-0.0652313-0.2332764-0.1286621-0.3535461-0.1866302
	c-0.0713501-0.0344696-0.1378784-0.0774994-0.2106323-0.1095581c-0.0725708-0.032135-0.1481018-0.0508881-0.2213745-0.0803528
	c-0.0978088-0.0392303-0.1976929-0.0732727-0.2973938-0.1080017c-0.1010742-0.0351715-0.1985474-0.0789642-0.3008423-0.1092987
	c-0.0741272-0.0220337-0.1503296-0.037323-0.2256775-0.0569305c-0.1427307-0.0372467-0.2856445-0.063858-0.4299316-0.0917664
	c-0.1308289-0.0252228-0.2590332-0.0578003-0.3919067-0.0755157c-0.0983276-0.0132141-0.1970215-0.0136414-0.2960205-0.0225525
	c-0.1460266-0.0131226-0.2904968-0.0252228-0.4368591-0.0290222C500.1202393,200.0108032,500.0628662,200,500.003418,200
	c-0.0634155,0-0.1247559,0.0114899-0.1879883,0.0132141c-0.1382446,0.0036316-0.275116,0.0145264-0.4133606,0.026535
	c-0.1085205,0.0095062-0.2166748,0.010788-0.3245239,0.0253906c-0.1271667,0.0172882-0.2498474,0.0502014-0.3749695,0.0744019
	c-0.1482544,0.0284271-0.2947998,0.0558167-0.4416809,0.0941772c-0.0661926,0.0173645-0.1337585,0.029892-0.1994324,0.0492401
	c-0.1330566,0.0390625-0.2598877,0.0947876-0.3905334,0.1418762c-0.0561523,0.0203094-0.1126404,0.0392303-0.1679382,0.0610046
	c-0.1151123,0.0450134-0.2308655,0.0831146-0.3442383,0.134613c-0.0393982,0.0178833-0.0753479,0.0417328-0.1143799,0.0603943
	c-0.1446533,0.0689392-0.2844543,0.1448059-0.4247742,0.2242889c-0.1161194,0.0657501-0.2322388,0.1300354-0.3442078,0.2023621
	c-0.0438843,0.0283356-0.0919189,0.0452728-0.1354675,0.0747375c-0.0567017,0.0385284-0.1009216,0.0882874-0.1562195,0.1282196
	c-0.0960693,0.069458-0.1923218,0.1361542-0.2854614,0.2110748c-0.1123352,0.0902863-0.2156677,0.1906891-0.3224487,0.2880554
	c-0.1140747,0.1042023-0.2281189,0.2066803-0.3340454,0.3172607c-0.0397339,0.0414734-0.0834656,0.0781097-0.1221619,0.1206207
	c-0.0158997,0.0174561-0.0350952,0.0298157-0.0508118,0.0474396L211.8931122,519.284729
	c-0.0430298,0.0483398-0.0741425,0.1035156-0.1156158,0.152771c-0.1503296,0.1782837-0.2899628,0.3642578-0.4219818,0.5574951
	c-0.0660095,0.0969238-0.1344299,0.1916504-0.1952667,0.2911377c-0.1290741,0.2113647-0.2419128,0.4325562-0.3480225,0.6583252
	c-0.0393982,0.0838013-0.0867462,0.1640625-0.1226807,0.2490845c-0.1266785,0.300354-0.2357025,0.6099243-0.3196869,0.9307251
	c-0.0020752,0.0081787-0.002594,0.0164185-0.0046692,0.0245972c-0.017807,0.0692139-0.0243683,0.1403198-0.0400848,0.2099609
	c-0.0589294,0.2623291-0.107666,0.5250854-0.1353149,0.7888184c-0.012085,0.1141357-0.0124359,0.2283936-0.0186615,0.3430176
	c-0.0121002,0.2225952-0.0183105,0.4440308-0.0088043,0.6652832c0.0053558,0.1275024,0.0176239,0.25354,0.0302277,0.3807983
	c0.0207367,0.211853,0.0508118,0.4211426,0.0912476,0.6295166c0.0241852,0.1263428,0.0492401,0.2514038,0.0807037,0.3766479
	c0.0558167,0.2216797,0.1264801,0.4382324,0.204071,0.6533203c0.0375061,0.1036987,0.0684357,0.2081909,0.1109467,0.3104858
	c0.1311493,0.3150635,0.281311,0.6223145,0.4589539,0.9164429c0.0020752,0.0031738,0.0031128,0.0068359,0.0050201,0.0100098
	c0.0962524,0.1583862,0.2082214,0.3018799,0.3153534,0.4501343c0.0680847,0.0944824,0.1268463,0.1943359,0.1999359,0.2855835
	c0.1919861,0.2394409,0.3983154,0.4634399,0.6170807,0.6721802c0.0024261,0.0024414,0.0043182,0.005127,0.0069122,0.0075073
	l282.9754944,269.2562866c0.0404358,0.0383911,0.0884705,0.0640259,0.1296082,0.1012573
	c0.2446899,0.2220459,0.5057983,0.4260864,0.7831421,0.6125488c0.0587463,0.0394287,0.1124878,0.0870361,0.1722717,0.1246338
	c0.299469,0.1880493,0.6138,0.3571777,0.9457703,0.4995117c0.0283203,0.012146,0.0585632,0.015564,0.0870667,0.0272827
	c0.2927551,0.1209106,0.5961914,0.2026978,0.9011841,0.2819214c0.1322021,0.0343018,0.2604065,0.0877686,0.3934631,0.1140747
	c0.421814,0.0831299,0.850708,0.1262207,1.2820129,0.1293335C499.9680176,799.9954834,499.9832153,800,499.9984131,800
	c0.0005188,0,0.0010376-0.0001831,0.0015564-0.0001831S500.0010071,800,500.0015259,800
	c0.0153809,0,0.0305786-0.0045166,0.0458069-0.0045776c0.4311218-0.0031128,0.8598633-0.0462036,1.2816772-0.1292725
	c0.1339111-0.0264282,0.2628174-0.0801392,0.3957214-0.1147461c0.3042908-0.0791016,0.6072083-0.1607666,0.8992615-0.2813721
	c0.0285034-0.0117798,0.0585632-0.0150757,0.0869141-0.0272217c0.3317871-0.142334,0.6460876-0.3114014,0.9455566-0.4993896
	c0.0601501-0.0378418,0.1144104-0.0857544,0.1734924-0.1255493c0.2770081-0.1862183,0.5377808-0.3898315,0.7821045-0.6116333
	c0.0411377-0.0372314,0.0891724-0.0629883,0.1296082-0.1013794l282.9754944-269.2562866
	c0.0026245-0.0023804,0.0045166-0.0050659,0.006897-0.0075073c0.218811-0.2087402,0.4251099-0.4327393,0.6171265-0.6721802
	c0.0730591-0.0912476,0.1318359-0.1911011,0.1998901-0.2855835c0.1071777-0.1482544,0.2191162-0.291748,0.3153687-0.4501343
	c0.0018921-0.0031738,0.0029297-0.0068359,0.0050049-0.0100098c0.1776733-0.2941284,0.3278198-0.6013794,0.4589844-0.9164429
	C789.3630981,526.4042969,789.394043,526.2996826,789.4313965,526.1958008z M630.5391235,483.892395l-130.5391541,50.8838501
	L369.4656677,483.894104l130.5367126-261.6080322L630.5391235,483.892395z M644.8084717,481.6739502L529.1203003,249.8251801
	l233.737854,261.833374L644.8084717,481.6739502z M355.1937256,481.6736145l-118.0519257,29.9849243L470.8846436,249.819046
	L355.1937256,481.6736145z M359.4945984,494.7747192l133.6268005,52.086792V777.081543L230.7875061,527.4664307
	L359.4945984,494.7747192z M506.87854,777.081543V546.8643188l133.6314697-52.0884705l128.7024536,32.6905823L506.87854,777.081543z
	"/>
    </g>
  </svg>
);
export default D10;
