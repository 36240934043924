import { AutoComplete, Modal, Steps, theme, Typography, Slider, ColorPicker, Image as AntImage, InputNumber, message, Input, Switch } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AvatarOptions, MapAutoComplete } from "../../../types";
import { SupabaseClient, Session } from "@supabase/supabase-js";
import fuzzysort from "fuzzysort";
import { filterTitle } from "../../../util/filterutil";
import AvatarEditor, { Position } from 'react-avatar-editor';
import { PresetsItem } from "antd/es/color-picker/interface";
import { useLocalStorage } from "usehooks-ts";
import CanvasComponent from "../../Utility/CanvasComponent";
import { useMapState } from "../../Map/MapDisplay";
import { useMainMenuState } from "../MainMenu";
import { useGlobalState } from "../GlobalState";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (b: boolean) => void,
  forceRefresh: () => void;
  // If replacement mode is passed in, we want to simply replace an old picture we have. 
  // This means we skip the 'settings' part at the end.
  replacementMode?: string | undefined,
}

export default function CreateTokenModelNewImage({ isModalOpen, setIsModalOpen, forceRefresh, replacementMode }: Props) {
  const supabase = useGlobalState((state) => state.supabase);
  const session = useGlobalState((state) => state.session);
  const logger = useGlobalState((state) => state.logger);
  
  const { token } = theme.useToken();
  const [currentStep, setCurrentStep] = useState(0);
  const [portraitFile, setPortraitFile] = useState<File>();

  const [avatarOptions, setAvatarOptions] = useState<AvatarOptions>({});

  const [previousColorOptions, setPreviousColorOptions] = useLocalStorage<string[]>('previous-colors', []);
  const [stepTwoDataUrl, setStepTwoDataUrl] = useState<string>();
  const [stepThreeDataUrl, setStepThreeDataUrl] = useState<string>();
  const [stepThreeBlob, setStepThreeBlob] = useState<Blob>();

  const [stepThreeRedraw, setStepThreeRedraw] = useState(false);

  const stepTwoAvatarEditor = useRef<AvatarEditor>(null);
  const stepThreeCanvasRef = useRef<HTMLCanvasElement>(null);
  
  const [uploadingToken, setUploadingToken] = useState(false);

  const editingGameId = useGlobalState((state) => state.editingGameId);

  const [msg, contextHolder] = message.useMessage();

  const colorPickerOptions: PresetsItem[] = [
    {
      label: 'Recommended',
      colors: [
        '#000000',
        '#000000E0',
        '#000000A6',
        '#00000073',
        '#00000040',
        '#00000026',
        '#0000001A',
        '#00000012',
        '#0000000A',
        '#00000005',
        '#F5222D',
        '#FA8C16',
        '#FADB14',
        '#8BBB11',
        '#52C41A',
        '#13A8A8',
        '#1677FF',
        '#2F54EB',
        '#722ED1',
        '#EB2F96',
        '#F5222D4D',
        '#FA8C164D',
        '#FADB144D',
        '#8BBB114D',
        '#52C41A4D',
        '#13A8A84D',
        '#1677FF4D',
        '#2F54EB4D',
        '#722ED14D',
        '#EB2F964D',
      ],
    },
    {
      label: 'Recent',
      colors: previousColorOptions,
    },
  ];

  useEffect(() => {
    if (!isModalOpen)
      return;

    if (!session || !supabase)
      return;

    setCurrentStep(0);
    setAvatarOptions({
      borderColor: (colorPickerOptions[0].colors[Math.floor(Math.random()*colorPickerOptions[0].colors.length)] as string).substring(0, 7),
    });
    setStepTwoDataUrl(undefined);
    setStepThreeDataUrl(undefined);
    setStepThreeBlob(undefined);
  }, [supabase, isModalOpen, session, setCurrentStep, setStepTwoDataUrl, replacementMode]);

  const doUpload = () => {
    setUploadingToken(true);
    const formData = new FormData();
    formData.append('count', "1");

    console.log(replacementMode)

    let fileName = replacementMode ?? avatarOptions.stepFourName;
    if (!fileName.endsWith(".png"))
      fileName = `${fileName}.png`;
    if (!fileName.startsWith("TOKEN"))
      fileName = `TOKEN${fileName}`;

    formData.append(`image0`, stepThreeBlob, fileName);

    if (!replacementMode) {
      formData.append('tokenDisplayName', avatarOptions.stepFourName);
      if (avatarOptions.group)
        formData.append('tokenGroup', avatarOptions.group);
      formData.append('tokenForGameId', editingGameId);
      formData.append('tokenIsGlobal', avatarOptions.tokenIsGlobal ? '1' : '0');
    }

    const toLog = {
      user_id: session.user.id
    };

    for (const pair of formData.entries()) {
      // @ts-ignore
      toLog[pair[0]] = pair[1];
    }

    logger.info('Uploading image for token', {
      user_id: session.user.id,
      data: toLog
    });

    supabase.functions.invoke('imagecrud', {
      body: formData
    }).then(({data, error}) => {
      if (error) {
        msg.error(error);
      } else if (data.error) {
        msg.error(data.error);
      } else {
        msg.success('Uploaded new token!');
        forceRefresh();
        setIsModalOpen(false);
      }
      setUploadingToken(false);
    });
  };

  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  // If your scale is less than 1, background color must not have alpha.
  useEffect(() => {
    if (avatarOptions && avatarOptions.scale && avatarOptions.scale < 1) {
      if (avatarOptions.backgroundColor)
      {
        const newColor = `${avatarOptions.backgroundColor.length > 6 ? avatarOptions.backgroundColor.substring(0,7) : avatarOptions.backgroundColor}ff`;
        if (newColor != avatarOptions.backgroundColor) {
          console.log(newColor, avatarOptions.backgroundColor);
          setAvatarOptions({
            ...avatarOptions,
            backgroundColor: newColor,
          });
        }
      }
    }
  }, [avatarOptions]);

  const creationSteps = [
    {
      title: 'Upload',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center', justifyContent: 'center', width: '100%'}}>
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>Upload a file for the token's portrait</Typography>
          <Typography style={{ color: token.colorTextTertiary, marginBottom: 24 }}>This will also be the full art.</Typography>
          <Dragger name="file" multiple={false} action={(file: RcFile) => {
            setCurrentStep(currentStep + 1);
            setPortraitFile(file);
            setAvatarOptions({});
            return "";
          }}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drop a file to upload</p>
            <p className="ant-upload-hint">
              We'll edit this image in upcoming<br />steps to make your token.
            </p>
          </Dragger>
        </div>
      ),
      canProgress: () => false,
      canPrevious: () => true,
    },
    {
      title: 'Crop',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center'}}>
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>Crop your image to a token shape</Typography>
          <Typography style={{ color: token.colorTextTertiary, marginBottom: 12 }}>Click and drag the image to move it around.</Typography>

          <AvatarEditor
            image={portraitFile}
            width={250}
            height={250}
            borderRadius={125}
            color={[0, 0, 0, 0.4]} // RGBA
            scale={avatarOptions.scale ?? 1}
            rotate={avatarOptions.rotate ?? 0}
            backgroundColor={avatarOptions.backgroundColor ?? "#ffffff"}
            ref={stepTwoAvatarEditor}
            crossOrigin="anonymous"
            position={avatarOptions.position ?? undefined}
            onPositionChange={(pos) => setAvatarOptions({
              ...avatarOptions,
              position: pos
            })}
          />
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginTop: 12 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Scale</Typography>
            <Slider value={avatarOptions.scale ?? 1} min={0.1} max={2} onChange={(v: number) => setAvatarOptions({
              ...avatarOptions,
              scale: v
            })} step={0.01} style={{ flexGrow: 10 }} />
            <InputNumber min={1} max={50} value={avatarOptions.scale ?? 1} step={0.01} onChange={(v: number) => {
              setAvatarOptions({
                ...avatarOptions,
                scale: v
              });
            }} style={{ flexGrow: 1 }} />
          </div>
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center'}}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Rotation</Typography>
            <Slider value={avatarOptions.rotate ?? 0} min={0} max={360} onChange={(v: number) => setAvatarOptions({
              ...avatarOptions,
              rotate: v
            })} step={0.01} style={{ flexGrow: 10 }} />
            <InputNumber min={1} max={50} value={avatarOptions.rotate ?? 0} step={0.01} onChange={(v: number) => {
              setAvatarOptions({
                ...avatarOptions,
                rotate: v
              });
            }} style={{ flexGrow: 1 }} />
          </div>
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginBottom: 24 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Background Color</Typography>
            <div style={{ flexGrow: 10 }}>
              <ColorPicker presets={colorPickerOptions} value={avatarOptions.backgroundColor ?? "#ffffff"} onChange={(_, hex) => setAvatarOptions({
                ...avatarOptions,
                backgroundColor: hex,
              })} />
            </div>
          </div>
        </div>
      ),
      canProgress: () => true,
    },
    {
      title: 'Border',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center'}}>
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>Add a border to your token</Typography>
          <Typography style={{ color: token.colorTextTertiary, marginBottom: 12 }}>Borders help your token stand out against any map</Typography>
          <CanvasComponent 
            style={{ width: '250px', height: '250px'}}
            borderImagePadding={avatarOptions.borderSize ?? 5}
            needsRedraw={stepThreeRedraw}
            setNeedsRedraw={setStepThreeRedraw}
            baseDataUrl={stepTwoDataUrl}
            drawFunction={(c, ctx) => {
              const borderRadius = avatarOptions.borderSize ?? 5;
              ctx.beginPath();
              ctx.arc(c.width/2, c.height/2, 125 - (borderRadius / 2), 0, Math.PI*2);
              ctx.lineWidth = avatarOptions.borderSize ?? 5;
              ctx.strokeStyle = avatarOptions.borderColor ?? '#003300';
              ctx.stroke();
            }}
            passRef={stepThreeCanvasRef}
            fillBackColor={(avatarOptions.scale ?? 1) < 1 ? avatarOptions.backgroundColor : undefined}
          />
          {/* <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginTop: 12 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 2 }}>Border Size</Typography>
            <Slider value={avatarOptions.borderSize ?? 5} min={1} max={50} onChange={(v: number) => {
              setAvatarOptions({
                ...avatarOptions,
                borderSize: v
              });
              setStepThreeRedraw(true);
            }} step={0.1} style={{ flexGrow: 10 }} />
            <InputNumber min={1} max={50} value={avatarOptions.borderSize ?? 5} step={0.1} onChange={(v: number) => {
              setAvatarOptions({0
                ...avatarOptions,
                borderSize: v
              });
              setStepThreeRedraw(true);
            }} style={{ flexGrow: 1 }} />
          </div> */}
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginBottom: 24 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Border Color</Typography>
            <div style={{ flexGrow: 10 }}>
              <ColorPicker presets={colorPickerOptions} value={avatarOptions.borderColor ?? '#003300'} onChange={(_, hex) => {
                setAvatarOptions({
                  ...avatarOptions,
                  borderColor: hex,
                });
                setStepThreeRedraw(true);
              }} />
            </div>
          </div>
        </div>
      ),
      canProgress: () => true,
    },
    {
      title: 'Settings',
      content: (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 400, alignItems: 'center'}}>
          <Typography style={{ color: token.colorTextSecondary, marginTop: 24  }}>Fill out token settings</Typography>
          <Typography style={{ color: token.colorTextTertiary, marginBottom: 12 }}>These control how your token renders</Typography>
          <AntImage 
            src={stepThreeDataUrl}
          />
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginTop: 12 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Display Name</Typography>
            <Input value={avatarOptions.stepFourName ?? ''} onChange={(e) => {
              setAvatarOptions({
                ...avatarOptions,
                stepFourName: e.target.value,
              });
            }} style={{ flexGrow: 10 }} />
          </div>
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginTop: 12 }}>
            <Typography style={{ color: token.colorTextSecondary, flexGrow: 1 }}>Group?</Typography>
            <Input value={avatarOptions.group ?? ''} onChange={(e) => {
              setAvatarOptions({
                ...avatarOptions,
                group: e.target.value,
              });
            }} style={{ flexGrow: 10 }} />
          </div>
          <div style={{ display: 'flex', width: '70%', alignItems: 'center', justifyContent: 'center', marginBottom: 24 }}>
            <Typography style={{ color: token.colorTextSecondary }}>Global?</Typography>
            <Switch checked={avatarOptions.tokenIsGlobal ?? false} onChange={(check) => setAvatarOptions({
              ...avatarOptions,
              tokenIsGlobal: check,
            })} />
          </div>
        </div>
      ),
      canProgress: () => avatarOptions.stepFourName !== undefined && avatarOptions.stepFourName.length > 0,
    },
  ];

  const items = creationSteps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      {contextHolder}
      <Modal centered title="Create New Token" open={isModalOpen} maskClosable={currentStep == 0} cancelButtonProps={{ disabled: uploadingToken }} onCancel={() => {
        if (uploadingToken)
          return;
        if (currentStep == 0)
          setIsModalOpen(false)
        else
          setCurrentStep(currentStep - 1);
      }} okButtonProps={{ disabled: uploadingToken || !creationSteps[currentStep].canProgress(), loading: uploadingToken }} okText={currentStep != creationSteps.length - 1 ? 'Next' : 'Create!'} onOk={() => {
        const nextStep = currentStep + 1;
        if (nextStep >= creationSteps.length) {
          if (avatarOptions.backgroundColor)
          {
            const alreadyHasPrevious = previousColorOptions.includes(avatarOptions.backgroundColor);
            if (!alreadyHasPrevious && previousColorOptions.length > 10) {
              const [,...rest] = previousColorOptions;
              // Prune the earliest, add a new one.
              setPreviousColorOptions([
                ...rest,
                avatarOptions.backgroundColor
              ]);
            } else if (!alreadyHasPrevious) {
              // Prune the earliest, add a new one.
              setPreviousColorOptions([
                ...previousColorOptions,
                avatarOptions.backgroundColor
              ]);
            }
          }

          doUpload();
        } else {
          if (currentStep == 1) {
            if (stepTwoAvatarEditor && stepTwoAvatarEditor.current) {
              setStepTwoDataUrl(stepTwoAvatarEditor.current.getImageScaledToCanvas().toDataURL());
              setStepThreeRedraw(true);
            }
          }
          if (currentStep == 2) {
            if (stepThreeCanvasRef && stepThreeCanvasRef.current) {
              setStepThreeDataUrl(stepThreeCanvasRef.current.toDataURL());
              stepThreeCanvasRef.current.toBlob((b) => setStepThreeBlob(b));
            }
          }
          setCurrentStep(nextStep);
        }
      }} cancelText={currentStep == 0 ? 'Cancel' : 'Previous'}>
        <Steps current={currentStep} items={items} />
        <div style={contentStyle}>{creationSteps[currentStep].content}</div>
      </Modal>
    </>
  )
}