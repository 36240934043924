import React, { useState } from "react";
import { Image as AntImage } from "antd";
import { useMapState } from "../../MapDisplay";
import { useDrag } from "react-dnd";
import { DatabaseMapPoi } from "../../../../types";
import { Feature, Map } from "ol";
import { useGlobalState } from "../../../Menu/GlobalState";

interface Props {
  userId: string;
  droppedCallback: (s: string) => void,
}

export default function DraggablePlayerImage({ userId, droppedCallback }: Props) {
  const ownerId = useMapState((state) => state.ownerId);
  const map = useMapState((state) => state.map);
  const editingMapData = useGlobalState((state) => state.editingMapData);

  const myKey = `TOKENPLAYER${userId}.png`;

  const content = (
    <img
      src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${ownerId}/${myKey}`}
      style={{ maxWidth: '80%', maxHeight: '80%' }}
      onClick={() => {
        if (!editingMapData)
          return;
        
        const matchingPoi = editingMapData.pois?.find((poi) => poi.map_text == myKey);

        if (matchingPoi)
          performZoom(map, matchingPoi);
      }}
    />
  );

  const nonGMContent = (
    <img
      src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${ownerId}/${myKey}`}
      draggable={false}
      onClick={() => {
        if (!editingMapData)
          return;
        
        const matchingPoi = editingMapData.pois?.find((poi) => poi.map_text == myKey);

        if (matchingPoi)
          performZoom(map, matchingPoi);
      }}
    />
  )

  const amGM = useMapState((state) => state.amGM);

  const [draggingState, setDraggingState] = useState(false);

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: userId,
      item: { content },
      collect: (monitor) => {
        const isDraggingNow = monitor.isDragging();

        if (isDraggingNow != draggingState) {
          if (!isDraggingNow) {
            droppedCallback(`TOKENPLAYER${userId}.png`);
          }

          setDraggingState(isDraggingNow);
        }

        return ({
          opacity: isDraggingNow ? 0.5 : 1
        });
      }
    }),
    [draggingState, setDraggingState, droppedCallback]
  );
  
  const performZoom = (map: Map, toPoi: DatabaseMapPoi) => {
    const duration = 1000;
    const zoom = map.getView().getZoom();
    let parts = 2;
    let called = false;
    function callback(complete: any) {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
      }
    }
    map.getView().animate(
      {
        center: [toPoi.coordinate_x, toPoi.coordinate_y],
        duration: duration,
      },
      callback
    );
    map.getView().animate(
      {
        zoom: zoom - 1,
        duration: duration / 2,
      },
      {
        zoom: toPoi.find_zoom_amount,
        duration: duration / 2,
      },
      callback
    );
  };

  return (
    amGM ? (
      <div ref={dragRef} style={{ opacity, width: '100%', height: '100%', cursor: 'grab', justifyContent: 'center', alignItems: 'center', display: 'flex' }} className="constrainChildren">
        { content }
      </div>
    ) : (
      <>
        { nonGMContent }
      </>
    )
  );
}