import { useLocalStorage } from "usehooks-ts";
import { useMapState } from "../../MapDisplay";
import { useGlobalState } from "../../../Menu/GlobalState";
import { shallow } from "zustand/shallow";
import React, {ReactNode, useMemo, useState, useEffect, useRef, useCallback} from "react";
import {
  DatabaseGamePlayer,
  DatabaseMap,
  DatabaseMapPoi,
  DatabaseToken,
  Initiative,
  InitiativeSpot, SvgPathDrawSettings
} from '../../../../types';
import styled from "styled-components";
import chroma from "chroma-js";
import { hslToRgb, rgbToHex } from "../../../../util/mathtuil";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Input,
  InputNumber, InputRef,
  Modal,
  Popconfirm,
  Popover,
  theme,
  Tooltip,
  Typography
} from "antd";
import {LuSwords} from "react-icons/lu";
import {GiHealing} from "react-icons/gi";
import {FaExclamation, FaPersonRunning, FaRegHeart} from "react-icons/fa6";
import {TbHexagonLetterL} from "react-icons/tb";
import * as Icons from 'react-game-icons-auto';
import {FaArrowLeft, FaArrowRight} from "react-icons/fa";

interface Props {

}

const TopStack = styled.div`
  z-index: 90;
  width: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > div {
    display: -ms-flexbox;
    display: flex;
  }
`;

const TrayInset = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 8px;
  padding: 8px;
  place-content: flex-start center;
`;

const TrayContainer = styled.div`
  row-gap: 8px;

  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column-reverse nowrap;
  flex-flow: column-reverse nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
  transition: height 0.2s linear, top 0.2s linear, width ease-in-out 0.3s;
  z-index: 90;
  pointer-events: none;
  gap: 8px;
`;

const Row = styled.div<{}>`
  width: 100%;
  height: 56px;

  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
  transition: height 0.2s linear, top 0.2s linear, width ease-in-out 0.3s;
  gap: 8px;
`;

const PlayersSet = styled.div<{ background: string }>`
  height: 56px;
  flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  pointer-events: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  box-shadow: 0px 2px 10px rgba(5, 0, 56, 0.2);
  background-color: ${props => props.background};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 2px;
`;

const IndividualSpot = styled.div<{ hoveredBackground: string, imageActive: boolean, currentInitiative: boolean }>`
  padding: 0;
  border: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  transition: 0.3s, 0s box-shadow, 0s border-radius;
  border-radius: 4px;

  width: ${props => props.currentInitiative ? '72px' : '52px'};
  height: ${props => props.currentInitiative ? '72px' : '52px'};

  & img {
    max-width: 80%;
    max-height: 80%;
  }

  & span {
    width: 50%;
    height: 50%;
  }

  & span svg {
    width: 100%;
    height: 100%;
  }

  & img {
    filter: ${props => !props.imageActive ? 'grayscale(1)' : 'unset'};
  }

  & > svg {
    width: 50%;
    height: 50%;
  }

  &:hover {
    background-color: ${props => props.hoveredBackground};
  }

  &[active='1'] {
    background-color: ${props => props.hoveredBackground};
  }
`;

const getColor = (percentage: number) => {
  const percentageH = (percentage * 134) / 360;
  const toRGB = hslToRgb(percentageH, 1, .25);
  const toHex = rgbToHex(toRGB);
  return toHex;
}

interface IndividualProps {
  index: number,
  totalSlots: number,
  data: InitiativeSpot,
  swapSlots: (a: number, b: number) => void,
  setData: (f: ((previous: InitiativeSpot) => InitiativeSpot)) => void,
  myTurn: boolean,
  matchingPoi?: DatabaseMapPoi | undefined,
  tokenDataDictionary: {[key: string]: Partial<DatabaseToken>},
  playerDataDictionary: {[key: string]: Partial<DatabaseGamePlayer>},
}

function IndividualTracker({index, totalSlots, swapSlots, data, setData, myTurn, matchingPoi, tokenDataDictionary, playerDataDictionary}: IndividualProps) {
  const [changeValue, setChangeValue] = useState<number>();
  const [popoverVisible, setPopoverVisible] = useState<boolean>();
  
  const [healthText, setHealthText] = useState<string>(data?.health?.toString());
  const [reactionUsed, setUsedReaction] = useState<boolean>(data?.reaction_used);
  const [maxMovement, setMaxMovement] = useState<number>(data?.max_movement);
  const [maxMovementError, setMaxMovementError] = useState<boolean>(false);
  const [movement, setMovement] = useState<number>(data?.movement);
  const amGM = useMapState((state) => state.amGM);
  
  const setModalOpen = useGlobalState((state) => state.setModalOpen);
  
  const damageInputRef = useRef<HTMLInputElement>(null);
  const healingInputRef = useRef<HTMLInputElement>(null);

  const { token } = theme.useToken();

  // Update our modal status depending on the popover's visibility
  useEffect(() => {
    if (index === undefined)
      return;
    
    setModalOpen(`popover-initiative-${index}`, popoverVisible);
    
    if (data) {
      setHealthText(data.health.toString());
      setUsedReaction(data.reaction_used);
      setMaxMovement(data.max_movement);
      setMovement(data.movement);
      setMaxMovementError(false);
    }
  }, [popoverVisible, index, data]);
  
  // Make updates to our data
  useEffect(() => {
    setData((previous: InitiativeSpot) => {
      let change = false;
      let changeTo = previous;
      
      if (previous.health.toString() != healthText && parseInt(healthText) !== undefined) {
        change = true;
        changeTo = {
          ...changeTo,
          health: parseInt(healthText)
        };
      }
      
      if (previous.reaction_used != reactionUsed) {
        change = true;
        changeTo = {
          ...changeTo,
          reaction_used: reactionUsed
        };
      }
      
      if (previous.max_movement != maxMovement && Math.round(maxMovement / 5) == maxMovement / 5) {
        change = true;
        changeTo = {
          ...changeTo,
          max_movement: maxMovement,
        }
      }
      
      if (previous.movement != movement) {
        change = true;
        changeTo = {
          ...changeTo,
          movement: movement,
        }
      }
      
      if (change)
        return changeTo;
      
      return previous;
    })
  }, [healthText, reactionUsed, maxMovement, movement]);
  
  const percent = data.health / data.max_health;

  const isSvg = matchingPoi?.map_text.startsWith('svg');
  const svgSettings: SvgPathDrawSettings | undefined = isSvg ? JSON.parse(matchingPoi.map_text.substring(3)) as SvgPathDrawSettings : undefined;
  
  let displayName = '';
  let subtitle: string | undefined;
  if (matchingPoi) {
    if (isSvg) {
      displayName = `${svgSettings.iconName}${svgSettings.extraIdentifier ? ` ${svgSettings.extraIdentifier}` : ''}`;
    } else if (matchingPoi.token_id && matchingPoi.token_id in tokenDataDictionary) {
      const tokenData = tokenDataDictionary[matchingPoi.token_id];
      displayName = tokenData.display_name;
    } else if (matchingPoi.user_id && matchingPoi.user_id in playerDataDictionary) {
      const playerData = playerDataDictionary[matchingPoi.user_id];
      displayName = playerData.character_name;
      subtitle = `Played by ${playerData.player_name}`;
    }
  }
  
  return (
    <Popover open={popoverVisible} onOpenChange={(open) => setPopoverVisible(open)} placement="bottom" title={
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Typography.Text style={{fontSize: token.fontSizeLG}}>{displayName}</Typography.Text>
          {subtitle ? <Typography.Text type={"secondary"}
                                       style={{fontSize: token.fontSizeSM}}>{subtitle}</Typography.Text> : <></>}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '4px'}}>
          <Popconfirm
            title={`Damage ${displayName}`}
            description={(
              <div style={{ width: '150px' }}>
                <InputNumber ref={damageInputRef} min={1} max={100000} placeholder={"How much?"} style={{ width: '115%', marginLeft: '-24px' }} />
              </div>
            )}
            icon={
              <div style={{ marginRight: '8px' }}>
                <LuSwords />
              </div>
            }
            disabled={!amGM}
            cancelText={'Cancel'}
            okText={'Damage!'}
            onOpenChange={(open) => {
              if (open) {
                setTimeout(() =>
                  damageInputRef.current.focus(), 10);
              }
            }}
            onConfirm={() => {
              const tryParse = parseInt(damageInputRef.current.value, 10);
              if (!tryParse)
                return;
              const newHealth = Math.max(0, data.health - tryParse);
              setHealthText(newHealth.toString());
            }}
          >
            <Tooltip title={amGM ? `Damage ${displayName}` : 'Only the GM can damage a character'}>
              <Button icon={<LuSwords />} disabled={!amGM} type={'ghost'} />
            </Tooltip>
          </Popconfirm>
          <Typography.Paragraph 
            editable={{ 
              tooltip: 'Click to edit',
              triggerType: ['text'], 
              enterIcon: <></>,
              maxLength: 5,
              onChange: (newValue: string) => {
                const result = newValue.replace(/\D/g, "");
                setHealthText(result);
              },
          }}
            style={{ fontSize: '24px', color: getColor(percent), marginBottom: '0px', maxWidth: '50px', left: '0px' }}
            underline
          >
            {healthText}
          </Typography.Paragraph>
          /
          <Typography.Text>{data.max_health}</Typography.Text>
          <Popconfirm
            title={`Heal ${displayName}`}
            description={(
              <div style={{ width: '150px' }}>
                <InputNumber ref={healingInputRef} min={1} max={100000} placeholder={"How much?"} style={{ width: '115%', marginLeft: '-24px' }}/>
              </div>
            )}
            icon={
              <div style={{ marginRight: '8px' }}>
                <FaRegHeart />
              </div>
            }
            onOpenChange={(open) => {
              if (open) {
                setTimeout(() =>
                  healingInputRef.current.focus(), 10);
              }
            }}
            disabled={!amGM}
            cancelText={'Cancel'}
            okText={'Heal!'}
            onConfirm={() => {
              const tryParse = parseInt(healingInputRef.current.value, 10);
              if (!tryParse)
                return;
              const newHealth = Math.min(data.max_health, data.health + tryParse);
              setHealthText(newHealth.toString());
            }}
          >
            <Tooltip title={amGM ? `Heal ${displayName}` : 'Only the GM can heal a character'}>
              <Button icon={<FaRegHeart />} disabled={!amGM} type={'ghost'} />
            </Tooltip>
          </Popconfirm>
        </div>
      </div>
    } trigger="click" content={(
      <>
        <Card style={{ width: 300 }}>
          {amGM ? (
            <div style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'row',
              top: '4px',
              right: '4px',
              gap: '4px'
            }}>
              <Tooltip title={`Move ${displayName} left in initiative order`}>
                <Button
                  icon={<FaArrowLeft/>}
                  disabled={index == 0}
                  type={'dashed'}
                  style={{padding: '0px '}}
                  onClick={() => {
                    setPopoverVisible(false);
                    swapSlots(index, index - 1);
                  }}
                />
              </Tooltip>
              <Tooltip title={`Move ${displayName} right in initiative order`}>
                <Button
                  icon={<FaArrowRight/>}
                  disabled={index == totalSlots - 1}
                  type={'dashed'}
                  style={{padding: '0px '}}
                  onClick={() => {
                    setPopoverVisible(false);
                    swapSlots(index, index + 1);
                  }}
                />
              </Tooltip>
            </div>
          ) : (<></>)}
          <Checkbox checked={reactionUsed} onChange={(e) => setUsedReaction(e.target.checked)} disabled={!amGM}>Used
            Reaction?</Checkbox>
          <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '8px'
          }}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start'}}>
                <Typography.Text style={{fontSize: token.fontSizeSM}} type={"secondary"}>Movement</Typography.Text>
                <Typography.Text style={{fontSize: token.fontSizeLG}}>{movement * 5}ft</Typography.Text>
              </div>
              <div style={{alignSelf: 'end', marginLeft: '4px', marginRight: '12px'}}>
                <Typography.Text style={{fontSize: token.fontSizeSM}} type={"secondary"}>of</Typography.Text>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start'}}>
                <Typography.Text style={{fontSize: token.fontSizeSM}} type={"secondary"}>Range</Typography.Text>
                {
                  maxMovementError ? (
                    <Tooltip title={"Movement must be a multiple of 5."}>
                      <InputNumber style={{ maxWidth: '60px' }} step={5} status={maxMovementError ? 'error' : undefined} disabled={!amGM} value={maxMovement*5} onChange={(e) => {
                        setMaxMovement(e / 5);
                        if (Math.round(e / 5) != e / 5) {
                          setMaxMovementError(true);
                          return;
                        }
                        setMaxMovementError(false);
                      }}/>
                    </Tooltip>
                  ) :
                    (
                      <InputNumber style={{ maxWidth: '60px' }} step={5} status={maxMovementError ? 'error' : undefined} disabled={!amGM} value={maxMovement*5} onChange={(e) => {
                        setMaxMovement(e / 5);
                        if (Math.round(e / 5) != e / 5) {
                          setMaxMovementError(true);
                          return;
                        }
                        setMaxMovementError(false);
                      }}/>
                    )
                }
                
              </div>
            </div>
            <Button
              icon={<FaPersonRunning />}
              disabled={!amGM}
              onClick={() => setMovement((previous) => previous + maxMovement)}
              style={{ alignSelf: 'end' }}
            >
              Dash
            </Button>
          </div>
        </Card>
      </>
    )}>
      <IndividualSpot hoveredBackground={'rgba(255, 255, 255, 0.063)'} imageActive={percent > 0} key={index}
                      currentInitiative={myTurn}>
        <div style={{width: '80%', height: '80%'}}>
          {
            isSvg ? (
              <>
                <div style={{
                  borderRadius: '100px',
                  backgroundColor: svgSettings.background_color ?? '#000',
                  border: `1px solid ${svgSettings.border_color}`,
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  cursor: 'pointer'
                }}>
                  {/*@ts-ignore*/}
                  {Icons[svgSettings.iconName]({color: svgSettings.foreground_color ?? '#fff'})}
                  {svgSettings.extraIdentifier ? (
                    <div style={{ position: 'absolute', bottom: '-3px', right: '-3px', color: chroma(svgSettings.border_color).darken(1).hex(), fontWeight: 'bold', fontSize: '14px' }}>
                      {svgSettings.extraIdentifier}
                    </div>
                  ) : (<></>)}
                </div>
              </>
            ) : (
              <img
                src={matchingPoi ? (`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${matchingPoi.owner_id}/${matchingPoi.map_text}`) : ''}
                style={{maxWidth: '100%', maxHeight: '100%'}}
                draggable={false}
              />
            )
          }
          {reactionUsed ? (
            <div style={{position: 'absolute', bottom: '-10px', zIndex: '99', left: '-5px'}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#FFFFFF"
              >
                <path
                  d="M13.666 1.429l6.75 3.98l.096 .063l.093 .078l.106 .074a3.22 3.22 0 0 1 1.284 2.39l.005 .204v7.284c0 1.175 -.643 2.256 -1.623 2.793l-6.804 4.302c-.98 .538 -2.166 .538 -3.2 -.032l-6.695 -4.237a3.23 3.23 0 0 1 -1.678 -2.826v-7.285c0 -1.106 .57 -2.128 1.476 -2.705l6.95 -4.098c1 -.552 2.214 -.552 3.24 .015m-1.666 5.571h-2a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1l.117 -.007a1 1 0 0 0 .883 -.993v-2.332l2.2 2.932a1 1 0 0 0 1.4 .2l.096 -.081a1 1 0 0 0 .104 -1.319l-1.903 -2.538l.115 -.037a3.001 3.001 0 0 0 -1.012 -5.825m0 2a1 1 0 0 1 0 2h-1v-2z"/>
              </svg>
            </div>
          ) : (<></>)}
        </div>
        <div style={{
          position: "absolute",
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'start',
          display: 'flex'
        }}>
          <div style={{
            width: '70%',
            height: '5%',
            backgroundColor: '#5050505c',
            borderRadius: '5px',
            position: 'absolute'
          }}/>
          <div style={{width: '70%', height: '5%', borderRadius: '5px', position: 'absolute'}}>
            <div style={{
              width: `${percent * 100}%`,
              height: '100%',
              backgroundColor: getColor(percent),
              borderRadius: '5px'
            }}/>
          </div>
        </div>
      </IndividualSpot>
    </Popover>
  );
}

interface LineItemProps {
  matchingPoi: DatabaseMapPoi,

  health?: number | undefined;
  setHealth: (n: number) => void,

  initiative?: number | undefined;
  setInitiative: (n: number) => void,
}

function RollInitiativeLineItem({matchingPoi, initiative, setInitiative, health, setHealth}: LineItemProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', maxHeight: '125px', justifyContent: 'space-evenly'}}>
      <img
        src={`https://slsihiyehgypzhrfndiw.supabase.co/storage/v1/object/public/maps/${matchingPoi.owner_id}/${matchingPoi.map_text}`}
        style={{ width: 100, height: 100 }}
        draggable={false}
        />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Text>Health</Typography.Text>
        <InputNumber value={health} onChange={(value) => setHealth(value)}/>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Text>Initiative</Typography.Text>
        <InputNumber value={initiative} onChange={(value) => setInitiative(value)}/>
      </div>
    </div>
  );
}

export default function InitiativeTracker({}: Props) {
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const amGM = useMapState((state) => state.amGM);
  const setModalOpen = useGlobalState((state) => state.setModalOpen);
  const supabase = useGlobalState((state) => state.supabase);

  const [healthValues, setHealthValues] = useState<number[]>([]);
  const [initiativeValues, setInitiativeValues] = useState<number[]>([]);
  
  const [fetchedTokenData, setFetchedTokenData] = useState<{[key: string]: Partial<DatabaseToken>}>({});
  const lastNeededTokensArray = useRef<string[]>([]);
  
  const [fetchedPlayersData, setFetchedPlayersData] = useState<{[key: string]: Partial<DatabaseGamePlayer>}>({});
  const lastNeededPlayersArray = useRef<string[]>([]);
  
  const poiById: {[key: string]: DatabaseMapPoi} = {};
  (editingMapData.pois ?? []).forEach((poi) => {
    poiById[poi.poi_id] = poi;
  });

  const displayImages = useMemo(() => {
    return editingMapData.initiative?.spots.map((item, index) => {
      const matchingPoi = poiById[item.poi_id];

      return (
        <IndividualTracker key={index} index={index} totalSlots={editingMapData.initiative.spots.length} swapSlots={(a: number, b: number) => {
          const initiative = editingMapData.initiative;
          const atA = initiative.spots[a];
          const atB = initiative.spots[b];
          if (atA.roll > atB.roll)
            atA.roll = atB.roll - 1;
          else
            atB.roll = atA.roll - 1;
            
          initiative.spots[a] = atB;
          initiative.spots[b] = atA;
          setEditingMapData((previous) => ({
            ...previous,
            initiative: initiative
          }))
        }} data={editingMapData.initiative.spots[index]} setData={(newInitiative: (previous: InitiativeSpot) => InitiativeSpot) => {
            const currentWorking = structuredClone(editingMapData) as DatabaseMap;
            const initiative = currentWorking.initiative;
            const oldValue = initiative.spots[index];
            const newValue = newInitiative(initiative.spots[index]);
            
            if (newValue === oldValue)
              return;
            
            initiative.spots[index] = newValue;
            currentWorking.initiative = initiative;
            setEditingMapData(currentWorking);
        }} myTurn={index == editingMapData.initiative.current} matchingPoi={matchingPoi} tokenDataDictionary={fetchedTokenData} playerDataDictionary={fetchedPlayersData} />
      );
    })
  }, [editingMapData, amGM, fetchedTokenData, fetchedPlayersData]);

  useEffect(() => {
    if (!editingMapData.initiative)
      return;

    setModalOpen('initiative-players', editingMapData.initiative.unsorted_players !== undefined);
    
    if (!supabase)
      return;
    
    if (!editingMapData.initiative.unsorted_players) {
      // Collect a list of token data from these tokens
      const thesePois = editingMapData.initiative.spots.map((s) => s.poi_id).map((poi_id) => poiById[poi_id]);
      
      const uniqueTokenIds = thesePois.filter((poi) => poi && poi.token_id).map((poi) => poi.token_id);
      if (uniqueTokenIds.length > 0 && !uniqueTokenIds.every((item, index) => lastNeededTokensArray.current.length > index && lastNeededTokensArray.current[index] == item)) {
        supabase
          .from('tokens')
          .select('token_id,display_name')
          .in('token_id', uniqueTokenIds)
          .then((response) => {
            if (response.error)
              console.error(response.error);
            else
            {
              const temp: {[key: string]: Partial<DatabaseToken>} = {};
              response.data.forEach((data) => {
                temp[data.token_id] = {
                  ...data
                } as Partial<DatabaseToken>;
              });
              setFetchedTokenData(temp);
            }
          })
        lastNeededTokensArray.current = uniqueTokenIds;
      }
      
      const uniquePlayerIds = thesePois.filter((poi) => poi && poi.user_id).map((poi) => poi.user_id);
      if (uniquePlayerIds.length > 0 && !uniquePlayerIds.every((item, index) => lastNeededPlayersArray.current.length > index && lastNeededPlayersArray.current[index] == item)) {
        supabase
          .from('game_players')
          .select('user_id,character_name,player_name')
          .in('user_id', uniquePlayerIds)
          .then((response) => {
            if (response.error)
              console.error(response.error)
            else {
              const temp: {[key: string]: Partial<DatabaseGamePlayer>} = {};
              response.data.forEach((data) => {
                temp[data.user_id] = {
                  ...data
                } as Partial<DatabaseGamePlayer>
              });
              setFetchedPlayersData(temp);
            }
          })
        lastNeededPlayersArray.current = uniquePlayerIds;
      }
      
      // Now check if any tokens have disappeared.
      if (editingMapData.pois) {
        const mySpots = editingMapData.initiative.spots;
        let anyRemoved = false;
        for (let i = mySpots.length - 1; i >= 0; i -= 1) {
          const thisInitiative = mySpots[i];
          const matchingPoi = editingMapData.pois?.find((poi) => poi.poi_id == thisInitiative.poi_id);
          if (!matchingPoi) {
            mySpots.splice(i, 1);
            anyRemoved = true;
          }
        }
        if (anyRemoved) {
          const cloned = structuredClone(editingMapData) as DatabaseMap;
          cloned.initiative.spots = mySpots;
          
          if (mySpots.length == 0)
            cloned.initiative = undefined;
          
          setEditingMapData(cloned);
        }
      }
    }
  }, [editingMapData, supabase, setFetchedPlayersData, setFetchedTokenData]);

  if (!editingMapData.initiative)
    return <></>;

  if (editingMapData.initiative.unsorted_players) {
    if (!amGM)
      return <></>;

    // We are GM, so show us a modal where we are figuring out what the player initiative and health values are
    return (
      <Modal centered title={"Roll Initiative"} open={true} closable={false} cancelButtonProps={{style: {display: 'none'}}} onOk={() => {
        const newWorking = structuredClone(editingMapData) as DatabaseMap;
        const myInitiative = newWorking.initiative;
        editingMapData.initiative.unsorted_players.forEach((val, index) => {
          myInitiative.spots.push({
            poi_id: val.poi_id,
            roll: initiativeValues[index],
            health: healthValues[index],
            max_health: healthValues[index],
            max_movement: 6,
            movement: 6,
          });
        });
        myInitiative.unsorted_players = undefined;
        myInitiative.spots.sort((a, b) => b.roll - a.roll);
        newWorking.initiative = myInitiative;
        setEditingMapData(newWorking);
        setHealthValues([]);
        setInitiativeValues([]);
      }} onCancel={() => {}} maskClosable={false}>
        <Divider />
        {editingMapData.initiative.unsorted_players.map((missing_data, index) => (
          <>
            <RollInitiativeLineItem 
              matchingPoi={poiById[missing_data.poi_id]} 
              health={healthValues[index]}
              setHealth={(n) => healthValues[index] = n}
              initiative={initiativeValues[index]}
              setInitiative={(n) => initiativeValues[index] = n}
            />
            <Divider />
          </>
        ))}
      </Modal>
    );
  }

  return (
    <TopStack>
      <TrayInset>
        <TrayContainer>
          <Row>
            <PlayersSet background='rgb(23, 23, 23)'>
              {displayImages}
            </PlayersSet>
          </Row>
        </TrayContainer>
      </TrayInset>
    </TopStack>
  );
}